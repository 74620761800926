import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const Ovace: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>OVACE - Obstrução de via aérea por corpo estranho</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://www.cbm.df.gov.br/download/pop-ovace-bg-136-de-2022/?tmstv=1667559044">POP OVACE (BG 136 de 21/07/2022)</a>
            </p>

</ul>
              </Accordion>
              <Accordion title="Procedimentos - Paciente Adulto">
                <ul>
            <h3>PACIENTE ADULTO CONSCIENTE</h3>
            <ol>
              <li>Avalie a segurança da cena.</li>
              <li>
                Pergunte se o paciente está engasgado. Em caso positivo, pergunte se ele consegue tossir. O paciente que tosse é considerado com <b>obstrução parcial</b> e, nesse caso, incentive que ele continue tentando
                tossir. A manobra de compressão abdominal é contraindicada nesse
                caso. Monitore o paciente e faça contato com a regulação médica para
                orientações.</li>
              <li>
                Caso o paciente não consiga tossir, a <b>obstrução é total</b>. Explique que deverá desengasgá-lo e solicite o Suporte Avançado de Vida (SAV).</li>
              <li>Aplique 5 (cinco) golpes nas costas, da seguinte forma: </li>
              <ul>
              <li>Posicione-se de lado e ligeiramente atrás da vítima.</li>
              <li>Segure o tórax da vítima com a mão não dominante e incline ela para
frente de modo que, caso o objeto que causa a obstrução seja liberado, se facilite a sua saída pela boca como demonstrado na figura 1 e 2</li>
              <li>Aplique 5 (cinco) tapas na região interescapular com o mão dominante,
utilizando a região central da mão</li>
              </ul>
                <li>Certifique se a cada golge nas costas a obstrução foi solucionada, em
                  caso positivo interrompa o procedimento.</li>
                            <img src="assets/ovace/figuras.png" className="figura"/>
              <li> Se o procedimento anterior não foi efetivo, inicie a manobra de
compressão abdominal, observando as colocações a seguir:</li>
              <ul>
              <li>
                O socorrista deve se posicionar atrás da vítima, com seu corpo
lateralizado, apoiando no dorso do indivíduo com OVACE conforme
apresentado na figura 3.</li>
              <li>Coloque uma de suas mãos (fechada, com a parte lateral, do polegar,
voltada para o abdômen do paciente) entre o umbigo e o processo xifoide do
paciente. Posicione a outra mão sobre a primeira e realize compressões
abdominais com o movimento direcionado de fora para dentro e de baixo
para cima, simultaneamente (Figura 4).</li>
              <li>Realize compressões fortes e as repitas por 5 (cinco) vezes.</li>
                </ul>
              <li>Caso a obstrução ainda persista, continue alternando entre os 5 (cinco)
golpes nas costas e as 5 (cinco) compressões abdominais até que haja a
desobstrução ou até que o paciente fique inconsciente. Caso a vítima fique
inconsciente, siga as orientações a seguir. </li>
                                          <img src="assets/ovace/figuras2.png" className="figura"/>
              </ol>
            <h3>PACIENTE ADULTO INCONSCIENTE</h3>
            <ol>
              <li>
                Caso o paciente fique inconsciente durante as manobras de
                desobstrução no período de consciência, deite-o com segurança.</li>
              <li>
                Posicione-o em decúbito dorsal sobre uma superfície plana e rígida.</li>
              <li>
                Realize 30 compressões torácicas com as mãos posicionadas na
metade inferior do esterno, com um ritmo de 100 a 120 compressões por
minuto (Figura 5).</li>
              <li>
                Abra a boca e a via aérea do paciente e tente visualizar o objeto
obstrutor. Retire o objeto com o dedo indicador se conseguir visualizar o
objeto. Não faça varredura da cavidade oral às cegas (Figura 6).</li>
              <li>
                Realize 2 ventilações com dispositivo BVM acoplado a oxigenoterapia
a vazão de 15L/min (Figura 7).              </li>
              <li>
                Repita os passos 3, 4 e 5 até que ocorra a desobstrução.</li>
              <li>
                Considere que houve desobstrução quando forem conseguidas 2
ventilações efetivas e consecutivas. Prossiga conforme o Apêndice 1:
Possibilidades de situação e ações a serem desempenhadas durante a
ventilação na ovace.</li>
              <li>
                Cheque pulso carotídeo neste momento e se há movimentos
respiratórios espontâneos.</li>
              <li>
                 Realize RCP em caso de ausência de circulação.</li>
              <li>
                Caso o paciente seja reanimado, coloque-o em posição lateral de
segurança esquerda enquanto aguarda o SAV. </li>
              <li>Caso seja orientada a realização do transporte, mantenha o paciente
na posição de decúbito dorsal.</li>
                              <li>Monitore o paciente.</li>
                                                          <img src="assets/ovace/figuras3.png" className="figura"/>
            </ol>
                              </ul>
          </Accordion>
          
          <Accordion title="Procedimentos - Crianças e Lactentes">
            <h3>PACIENTE CRIANÇA CONSCIENTE MAIOR DE UM ANO</h3>
            <ol>
              <li>Avalie a segurança da cena.</li>
              <li>
                Se não houver barreira de comunicação devido à idade, pergunte se o
                paciente está engasgado, ou se comunique com os pais, cuidadores ou
                responsáveis. O paciente que tosse é considerado com obstrução
                parcial e, nesse caso, incentive que ele continue tentando tossir.
                Monitore o paciente e faça contato com a regulação médica para
                orientações.</li>
              <li>
                Caso o paciente não consiga tossir ou emitir sons a obstrução é total.
                Explique que deverá desengasgá-lo e solicite o SAV.</li>
              <li>Aplique 5 (cinco) golpes nas costas, da seguinte forma:</li>
                              <ul> 
              <li>
                Os golpes nas costas são mais eficazes se a criança for colocada de
cabeça para baixo.</li>
              <li>
                Se a criança for pequena apoie ela de bruços em seu antebraço,
descansando em sua coxa. Apoie a cabeça da criança colocando o polegar
de uma mão no ângulo da mandíbula e um ou dois dedos da mesma mão no
ângulo oposto da mandíbula.</li>
              <li>Se isso não for possível, coloque a criança em uma posição inclinada
para a frente e dê golpes nas costas por trás. Segure o tórax da vítima com a mão não dominante e incline ela para frente de modo que caso o objeto
que promove a obstrução seja liberado, facilite a sua saída pela boca,
conforme apresentado na Figura 8 e 9.              </li>
              <li>Aplique 5 (cinco) tapas na região interescapular com o mão dominante,
utilizando a região central da mão.              </li>
              <li>Certifique se a cada golge nas costas a obstrução foi solucionada, em
caso positivo interrompa o procedimento.</li>
<img src="assets/ovace/figuras4.png" className="figura"/>
                                 </ul> 
              <li>Se o procedimento anterior não foi efetivo, inicie a manobra de
compressão abdominal, observando as colocações a seguir:              </li>
                                               <ul> 
              <li>O socorrista deve se posicionar atrás do paciente (ajoelhe-se para
equalizar a diferença de altura), com seu corpo lateralizado, apoiando no
dorso do indivíduo com OVACE conforme apresentado na figura 10.              </li>
                                            <li>      Coloque uma de suas mãos (fechada, com a parte lateral, do polegar,
voltada para o abdômen do paciente) entre o umbigo e o processo xifoide do
paciente. Posicione a outra mão sobre a primeira e realize compressões
abdominais com o movimento direcionado de fora para dentro e de baixo
para cima, simultaneamente (figura 11). </li>
                                            <li>Realize compressões fortes e as repitas por 5 (cinco) vezes.   </li>
                                                              </ul> 
                                            <li>Caso a obstrução ainda persista, continue alterando entre os 5 (cinco)
golpes nas costas e as (cinco) compressões abdominais até que haja a
desobstrução ou até que o paciente fique inconsciente. Caso a vítima fique
inconsciente, siga as orientações a seguir.                                            </li>
<img src="assets/ovace/figuras5.png" className="figura"/>
              
                                                             
                          </ol>
            
            <h3>PACIENTE CRIANÇA INCONSCIENTE MAIOR DE UM ANO</h3>
            <ol>
              <li>Caso o paciente fique inconsciente durante as manobras de
desobstrução, deite-o com segurança.
</li>
              <li>
                 Posicione-o em superfície plana e rígida.</li>
              <li>
                Realize 15 compressões torácicas com as mãos posicionadas na
metade inferior do esterno, com um ritmo de 100 a 120 compressões por
minuto. A depender da compleição física do paciente, faça as compressões
com apenas 1 mão (Figura 12).
</li>
                            <li>
                Abra a boca e via aérea do paciente e tente visualizar o objeto obstrutor.
Retire o objeto com o dedo mínimo se conseguir visualizar o objeto. Não faça
varredura da cavidade oral às cegas.</li>
              <li>
                Realize 2 ventilações com dispositivo BVM acoplado a oxigenoterapia
a vazão de 5 a 7 L/min (Figura 13).</li>
              <li>
                Repita os passos 3, 4 e 5 até que ocorra a desobstrução.
</li>
              <li>
                Considere que houve desobstrução quando forem conseguidas 2
ventilações efetivas e consecutivas. Cheque pulso carotídeo neste momento
e se há movimentos respiratórios espontâneos.</li>
              <li>
                Realize RCP em caso de ausência de circulação.</li>
              <img src="assets/ovace/figuras6.png" className="figura"/>
              
            </ol>
            <h3>PACIENTE CRIANÇA CONSCIENTE MENOR DE UM ANO</h3>
            <ol>
              <li>Avalie a segurança da cena.</li>
              <li>
                Se apresente aos pais, cuidadores ou responsáveis e se diponha a
                ajudar. O paciente que emite sons mais vigorosos é considerado com <b>obstrução parcial</b>. Nesse caso, manobras de desobstrução são
contraindicadas. Monitore o paciente e faça contato com a regulação médica
                para orientações. Considera-se com <b>obstrução total</b> o indivíduo que não
emite sons, está com cianose ou sonolento. Se houver obstrução total, inicie
manobras de desobstrução.</li>
              <li>
                Segure a criança em seus braços e posicione-o com o peito apoiado em
um de seus antebraços apoiada em sua coxa. Incline discretamente a criança
para baixo (em um ângulo próximo de 45º) e realize 5 (cinco) tapotagens com
a mão espalmada entre as escápulas do bebê (de maneira a atingir as costas
e deslizar para a frente) (Figura 14).</li>

              <li>
                Apoie o dorso da criança sobre o antebraço (apoie-o sobre sua coxa),
incline-a discretamente para baixo (em um ângulo próximo de 45º) e realize 5
(cinco) compressões com 2 dedos logo abaixo da linha intermamilar (Figura
15).</li>
                           <li>
                Repita os passos 3 e 4 até que ocorra desobstrução.
</li>
                            <img src="assets/ovace/figuras7.png" className="figura"/>
            </ol>
            <h3>PACIENTE CRIANÇA INCONSCIENTE MENOR DE UM ANO</h3>
            <ol>
              <li>Caso o paciente fique inconsciente durante as manobras de
                desobstrução no período de consciência, deite-o com segurança.</li>
              <li>
                Posicione-o em superfície plana e rígida. Se necessário, utilize coxim
                ou outro material (fralda de pano ou fronha) entre as escápulas da
                criança para manter o alinhamento neutro da cabeça e garantir a
                permeabilidade das vias aéreas.</li>
              <li>
                Realize 15 compressões torácicas com dedos posicionados logo abaixo
da linha intermamilar, com um ritmo de 100 a 120 compressões por minuto
(Figura 16).</li>
              
              <li>
                Abra a boca e via aérea do paciente e tente visualizar o objeto obstrutor.
Retire o objeto com o dedo mínimo se conseguir visualizar o objeto. Não faça
varredura da cavidade oral às cegas (Figura 17).</li>
              <li>
                 Realize 2 ventilações efetivas e consecutivas (Figura 16).</li>
              <li>
                Repita os passos 3, 4 e 5 até que ocorra a desobstrução.</li>
              <li>
                Considere que houve desobstrução quando forem conseguidas 2
ventilações efetivas e consecutivas. Cheque pulso braquial ou femoral neste
momento e se há movimentos respiratórios espontâneos.</li>
              <li>
                Realize RCP em caso de ausência de circulação.</li>
              <li>
                Caso o paciente seja reanimado, coloque-o em posição lateral de
segurança esquerda enquanto aguarda o SAV.</li>
              <li>
                Caso seja orientado a realização do transporte, mantenha o paciente
na posição de decúbito dorsal.</li>
              <li>
                Monitore o paciente.</li>
                                          <img src="assets/ovace/figuras8.png" className="figura"/>
              
            </ol>
          </Accordion>

          <Accordion title="Resultados Esperados">
            <ul>
              <li>
                Desobstrução de vias aéreas em adultos, crianças e lactentes quando obstruídas por corpos estranhos.
              </li>
              <li>
                Prevenção de hospitalização e óbitos.
              </li>
            </ul>
          </Accordion>

          <Accordion title="Material recomendado">
            <ul>
              <li>
                Reanimador manual - BVM
              </li>
              <li>
                Cilindro com oxigênio
              </li>
              <li>
                Desfibrilador Externo Automático (DEA)
              </li>
              <li>
                Oxímetro de pulso
              </li>
              <li>
                Cânula orofaríngea
              </li>
              <li>
                Aspirador manual de secreções
              </li>
              <li>
                Tubo orotraqueal (suporte avançado de vida)*
              </li>
              <li>
                Cateter venoso periférico e material de punção venosa*
              </li>
              <p><b>
                *Procedimentos realizados, respectivamente, no Suporte Avança e Básico de Vida, e
somente por profissionais habilitados (médicos, enfermeiros e técnicos de
enfermagem, inscritos em seus respectivos conselhos de classe e habilitados pelo
                CBMDF).</b>
              </p>
            </ul>

          </Accordion>
          <Accordion title="Sinais e sintomas">
            <ul>
              <li>Tosse</li>
              <li>Cianose</li>
              <li>Dificuldade para falar</li>
              <li>Agitação</li>
              <li>Inconsciência</li>
              <li>Ausência de pulso periférico</li>
              <li>Gasping</li>
              <li>Ausência de pulso central</li>
            </ul>
          </Accordion>
          <Accordion title="Notas">
            <ul>
              <li>Solicitantes de socorro deverão receber orientações sobre como realizar a desobstrução via telefone, preferencialmente por videochamada.</li>
              <li>Na comunicação com leigos, prefira o termo desengasgamento, em substituição à Obstrução de Via Aérea por Corpo Estranho (OVACE).</li>
              <li>No Distrito Federal estão em vigor duas Leis Distritais que tratam sobre o tema:</li>
              <p>Lei 6.355, de 07 de agosto de 2019, que <b>"dispõe sobre a obrigatoriedade da inclusão do Curso de Manobras Heimlich no pré-natal das gestantes da rede hospitalar pública e privada no Distrito Federal"</b>.</p>
              <p>Lei 6258, de 29 de janeiro de 2019, que <b>dispõe sobre a obrigatoriedade de afixação, em restaurantes, lanchonetes, praças de alimentação de centros comerciais, shopping centers e estabelecimentos similares, de material publicitário de interesse do consumidor que demonstre a aplicação da manobra da vida ou manobra de Heimlich (compressão abdominal), empregada para desobstruir rapidamente as vias respiratórias.</b></p>
              <li>Cursos e treinamentos oferecidos pelo Corpo de Bombeiros Militar do Distrito Federal, que tratem sobre o tema deverão abordar, além das condutas, formas de prevenção da OVACE, principalmente em crianças.</li>
            </ul>
          </Accordion>

                    <Accordion title="Observações">
            <ul>
              <li>Em indivíduos obesos e gestantes conscientes, substitua a compressão abdominal por compressão torácica, sobre o esterno (Figura 12).</li>
              <img src="assets/ovace/ovace12.png" className="figura"/>
              <figcaption className="captionFigura">Figura 12</figcaption>
              <li>Em pacientes crianças maiores que um ano conscientes, porém com menor porte físico, utilize a técnica aplicada a crianças menores que um ano (lactentes). Realize as cinco tapotagens e cinco compressões torácicas até que ocorra a desobstrução.</li>
            </ul>
          </Accordion>
          <Accordion title="Possibilidades de erro">
            <ul>
              <li>Queda em decorrência de falta de apoio físico ao paciente que perde a consciência durante as manobras de desobstrução.</li>
              <li>Excesso de força nos golpes dorsais e epigástricos em crianças.</li>
              <li>Realização da manobra de compressão abdominal em pacientes que consigam tossir, ou seja, pacientes com obstrução parcial.</li>
              <li>Excesso de força compressora durante as compressões em crianças.</li>
              <li>Varredura da cavidade oral às cegas.</li>
            </ul>
          </Accordion>
          <Accordion title="Fatores Complicadores">
            <ul>
              <li> Condições emocionais de pais e de cuidadores.</li>
            </ul>
          </Accordion>
          <Accordion title="Glossário">
            <ul>
              <li><b>BVM</b> – dispositivo de reanimação ventilatória manual do tipo bolsa-valva-
    máscara (BVM). É também conhecido como Ambú®.</li>
</ul>
      </Accordion>
      <Accordion title="Apêndice">
        <img src="assets/ovace/apendice1.jpg" className="figura"/>
      </Accordion>
      <Accordion title="Base Legal e Referencial">
        <ul>
          <li>Olasveengen TM, Mancini ME, Perkins GD, Avis S, Brooks S, Castrén M,
Chung SP, Considine J, Couper K, Escalante R, Hatanaka T, Hung KKC,
Kudenchuk P, Lim SH, Nishiyama C, Ristagno G, Semeraro F, Smith CM,
Smyth MA, Vaillancourt C, Nolan JP, Hazinski MF, Morley PT; Adult Basic Life
Support Collaborators. Adult Basic Life Support: 2020 International
Consensus on Cardiopulmonary Resuscitation and Emergency
Cardiovascular Care Science With Treatment Recommendations.
Circulation. 2020 Oct 20;142(16_suppl_1):S41-S91.</li>
          <li>Topjian AA, Raymond TT, Atkins D, Chan M, Duff JP, Joyner BL Jr, Lasa
JJ, Lavonas EJ, Levy A, Mahgoub M, Meckler GD, Roberts KE, Sutton RM,
Schexnayder SM; Pediatric Basic and Advanced Life Support Collaborators.
Part 4: Pediatric Basic and Advanced Life Support: 2020 American Heart
Association Guidelines for Cardiopulmonary Resuscitation and
Emergency Cardiovascular Care. Circulation. 2020 Oct
20;142(16_suppl_2):S469-S523.</li>
          <li>Sociedade Brasileira de Pediatria
Disponível em: https://www.sbp.com.br/publicacoes/ Acesso em: 10 fev. 2021</li>
                    <li>Olasveengen TM, Semeraro F, Ristagno G, Castren M, Handley A, Kuzovlev
A, Monsieurs KG, Raffay V, Smyth M, Soar J, Svavarsdottir H, Perkins GD.
European Resuscitation Council Guidelines 2021: Basic Life Support.
European Resuscitation Council.2021. V.161. pag. 98-114.</li>
        </ul>
      </Accordion>
    </div>
  </IonContent>
</IonPage>

  );
}

export default Ovace;
