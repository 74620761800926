import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import '../Page.css'


const desativacao: React.FC = () => {

  return (

  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>ATO DO CHEFE DE GABINETE DO COMANDANTE-GERAL</IonTitle>
      </IonToolbar>
    </IonHeader>


    <IonContent fullscreen>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>Publicado no Boletim Geral 070, de 14 de abril de 2020</IonCardSubtitle>

          <IonCardTitle>TRANSCRIÇÃO DE OFÍCIO REFERENTE AO ATENDIMENTO AO PACIENTE VÍTIMA DE ACIDENTE POR ANIMAIS PEÇONHENTOS</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p align="justify">&#8195; 

            O CHEFE DE GABINETE DO COMANDANTE-GERAL, no uso das atribuições que lhe confere o art. 43 do Decreto Federal 7.163, de 29 abr. 2010, que regulamenta o inciso I do art. 10-B da Lei 8.255, de 20 nov. 1991, que dispõe sobre a organização básica do Corpo de Bombeiros Militar do Distrito Federal, resolve:
          </p>
		  
          <p align="justify">&#8195;
		  
            TORNAR PÚBLICO que foi recebido a Circular 10/2020 - SES/SVS/DIVEP/GEVITHA, por meio do qual a Senhora RENATA BRANDÃO ABUD, Gerente de Vigilância das Doenças Imunopreveníveis e de Transmissão Hídrica e Alimentar, da Diretoria de Vigilância Epidemiológica, da Secretaria de Estado de Saúde do Distrito Federal, COMUNICA SOBRE O ATENDIMENTO AO PACIENTE VÍTIMA DE ACIDENTE POR ANIMAIS PEÇONHENTOS, na forma a saber:
          </p>
		  <p align="justify">&#8195;		  
            "[...]
          </p>
		  <p align="justify">&#8195;		  
            PARA: COAPS, DIRAPS, NVEPI, NHEP, Rede de Urgência e Emergência, Farmácias Hospitalares,
          </p>
		  <p align="justify">&#8195;		  
            Prezados,
          </p>
		  <p align="justify">&#8195;		  
            Considerando a Circular n° 31/2020 37859402 da Secretaria Adjunta de Assistência à Saúde em complementação com a Circular n° 19/2020 (37498087) que versa sobre o Plano de Contingência - COVID-19 - DF, no qual ficou determinado que o HRAN é referência para atendimento dos pacientes imunocompetentes adultos, gestantes e crianças - suspeitos e confirmados 00060- 00118568/2020-14,
          </p>
		  <p align="justify">&#8195;		  
            Considerando a alteração nos fluxos do Plano de Contingência - ativação para liberação do Hospital Regional da Asa Norte - Versão 2,
          </p>
		  <p align="justify">&#8195;		  
            <b>Considerando que no HRAN não mais haverá atendimento ao paciente vítima de acidente por animais peçonhentos,</b>
          </p>
		  <p align="justify">&#8195;		  
            Informamos que o atendimento do paciente vítima de acidente por animais peçonhentos pode ser realizado em qualquer unidade hospitalar (ública ou privada), sendo que a recomendação é a não transferência do paciente e sim a retirada do soro em qualquer dos hospitais listados abaixo. Para a retirada do imunobiológico é necessária a prescrição médica e a ficha de notificação do acidente.
          </p>
		  <p align="justify">&#8195;		  
            Apesar do HRAN não realizar o atendimento ao paciente, será mantido um estoque estratégico na Farmácia para os hospitais particulares, hospitais militares e também para os hospitais da rede pública do Distrito Federal caso necessitem.
          </p>
		  <p align="justify">&#8195;		  
            Segue a listagem dos hospitais da rede que possuem soros antipeçonhentos para ampla divulgação.
          </p>
		  <p align="justify">&#8195;		  
            Unidades referências Soro Antivenenos:
          </p>
		  
		  <table>
			<tr>
				<td>Unidades de Saúde</td>
				<td>Soros</td>			
			</tr>
			
			<tr>
				<td>Hospital Regional de Planaltina</td>
				<td>Soro antibotrópico - Soro anticrotálico - Soro antiescorpiônico - Soro antiaracnídeo</td>
			</tr>
			
			<tr>
				<td>Hospital Regional de Sobradinho</td>
				<td>&nbsp;</td>
			</tr>
			
			<tr>
				<td>Hospital Regional da Ceilândia</td>
				<td>&nbsp;</td>
			</tr>
			
			<tr>
				<td>Hospital Regional de Taguatinga</td>
				<td>&nbsp;</td>
			</tr>
			
			<tr>
				<td>Hospital Regional de Santa Maria</td>
				<td>&nbsp;</td>
			</tr>
			
			<tr>
				<td>Hospital Materno Infantil de Brasília</td>
				<td rowspan="2">Soro antiescorpiônico</td>
			</tr>
			
			<tr>
				<td>Hospital regional do Guara</td>
			</tr>
			
			<tr>
				<td>Hospital Regional Leste</td>
				<td rowspan="2">Soro antibotrópico - Soro anticrotálico - Soro antiescorpiônico</td>
			</tr>
			
			<tr>
        <td>Hospital Regional de Brazlândia</td>
			</tr>
			
			<tr>
				<td>Hospital Regional Asa Norte</td>
				<td>Soro antibotrópico - Soro anticrotálico - Soro antiescorpiônico - soro antiaracnídeo - Soro Antielapídico - Soro antilonômico<br></br><b>Apenas estoque estratégico para a retirada pelas unidades hospitalares. NÃO HÁ ATENDIMENTO PARA O PACIENTE</b></td>
			</tr>
          </table>
		  
		  <p align="justify">&#8195;
		  
            Solicitamos ampla divulgação e estamos à disposição para esclarecimentos no telefone : 2017 1145 Ramal 8250 e pelo e-mail: animaispeconhentos.df@gmail.com
          </p>
		  <p align="justify">&#8195;		  
            Atenciosamente,
          </p>
		  <p align="justify">&#8195;		  
            Renata Brandão
          </p>
		  <p align="justify">&#8195;		  
            Gerente".
          </p>
		  <p align="justify">&#8195;
		  
            Em consequência, após a publicação da matéria em BG, encaminhar o processo ao COMOP e SUBCG, com vistas à DISAU, para nálise e deliberação no âmbito dos segmentos.
          </p>
		  
          <p>&#8195;
            <br></br>
            (NB CBMDF/GABCG 00060-00152155/2020-69)
          </p>
        </IonCardContent>
      </IonCard>
    </IonContent>
  </IonPage>

);
}

export default desativacao;
