import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const TraumatismoExtremidades: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Traumatismo em extremidades</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
           <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://drive.google.com/file/d/1oNMMpf56C0BRdrz1LFtopmhsJxlgWe78/view?usp=share_link">POP Trauma em Extremidades (BG 237 de 17/12/2015)</a>
            </p>

</ul>
              </Accordion>
          
          <Accordion title="Procedimentos">
            <ul>
              <li>Avaliar a cena</li>
              <li>Gerenciar riscos na cena de emergência;</li>
              <li>Avaliar a biomecânica envolvida;</li>
              <li>Avaliar permeabilidade de vias aéreas;</li>
              <li>Estabilizar manualmente a coluna cervical, se necessário;</li>
              <li>Mensurar e aplicar colar cervical, se necessário</li>
              <li>Avaliar a qualidade da respiração;</li>
              <li>Monitorizar a oximetria de pulso.</li>
              <li>iciar oxigenoterapia (10 – 15 L/min) se %SpO2 {'<'} 95%, ou se {'>'} 95%, mas com sinais de dificuldade respiratória (2 – 5 L/min).</li>
              <li>Controlar hemorragias e realizar curativos. Não empurrar fragmentos ósseos</li>
              <li>Realizar acesso venoso e reanimação volêmica, se necessário. Considerar essa conduta em caso de múltiplas fraturas;</li>
              <li>Inspecionar o membro do qual o paciente se queixa;</li>
              <li>Avaliar pulso periférico, perfusão, sensibilidade e motricidade;</li>
              <li>Expor o membro lesionado;</li>
              <li>Realizar a imobilização do membro observando a técnica mais adequada para a ocasião. Imobilizar, sempre que possível, uma articulação abaixo e outra acima do local lesionado;</li>
              <li>Avaliar pulso periférico, perfusão e sensibilidade após a imobilização;</li>
              <li>Imobilizar o paciente em prancha longa;</li>
              <li>Realizar avaliação secundária;</li>
              <li>Exame físico detalhado;</li>
              <li>Sinais vitais;</li>
              <li>SAMPLA;</li>
              <li>Encaminhar o paciente para hospital de referência.</li>
    * Procedimentos realizado somente por profissionais habilitados (médicos, enfermeiros, técnicos e auxiliares de enfermagem, inscritos em seus respectivos conselhos de classe.

              <h3>Observações</h3>
              <li>Sempre que possível, o membro deve ser imobilizado em posição anatômica. Caso não seja possível, ou por resistência à dor e ao movimento ou por estar em posição de proteção (muito comum em membros superiores) a imobilização deve ser realizada na posição encontrada;</li>
              <li>Em caso de lesões articulares, o membro deve ser imobilizado na posição encontrada.</li>
          </ul></Accordion>

          <Accordion title="Resultados Esperados">
            <ul>
              <li>Minimização da dor;</li>
              <li>Prevenção de danos adicionais;</li>
            </ul>
          </Accordion>

          <Accordion title="Material recomendado">
            <ul>
              <li>Colar cervical;</li>
              <li>Prancha longa;</li>
              <li>Tirantes;</li>
              <li>Gazes;</li>
              <li>Ataduras;</li>
              <li>Compressas cirúrgicas;</li>
              <li>Manta aluminizada</li>
              <li>Lençóis;</li>
              <li>Esfigmomanômetro;</li>
              <li>Estetoscópio;</li>
              <li>Oxímetro de pulso;</li>
              <li>Termômetro;</li>
              <li>Conjunto de oxigenoterapia com fluxômetro;</li>
              <li>Jelco no 14 ou 16;</li>
              <li>Equipo simples.</li>
              <li>Ringer Lactato ou Solução Fisiológica 0,9%– 2 frascos de 1000 ml cada ou 4 de 500 ml.</li>
              <li>Talas moldáveis;</li>
              <li>Tala de tração;</li>
              <li>Talas de madeira.</li>
            </ul>
          </Accordion>

          <Accordion title="Sinais e sintomas">
            <ul>
              <li>Dor;</li>
              <li>Edema;</li>
              <li>Deformidade;</li>
              <li>Crepitação;</li>
              <li>Exposição óssea;</li>
              <li>Alteração das funções motora, sensitiva, vascular.</li>
            </ul>
          </Accordion>

          <Accordion title="Possibilidades de erro">
            <ul>
              <li>Manipulação inadequada do membro lesionado;</li>
              <li>Imobilização precária;</li>
            </ul>
          </Accordion>

          <Accordion title="Fatores Complicadores">
            <ul>
              <li>Segurança da cena;</li>
              <li>Múltiplas fraturas.</li>
            </ul>
          </Accordion>

          <Accordion title="Glossário">
            <ul>
              <li><b>%SpO2</b> – percentual de saturação periférica de oxigênio. Indica o percentual de hemoglobinas ligadas ao oxigênio.</li>
              <li><b>Motricidade:</b> verificação da capacidade de movimentação espontânea no membro.</li>
              <li><b>Oximetria:</b> procedimento que visa medir a concentração de oxigênio no sangue.</li>
              <li><b>Perfusão:</b> chegada de sangue em determinado órgão ou tecido. A avaliação é feita realizando-se pressão local e em seguida liberando para que se examine a velocidade do retorno sanguíneo.</li>
              <li><b>Pulso periférico:</b> pulso palpável na periferia (radial, ulnar, tibial).</li>
            </ul>
          </Accordion>

          <Accordion title="Referencial bibliográfico">
            <ul>
              <li>Atendimento Pré-Hospitalar ao Traumatizado – PHTLS (NAEMT). 7a ed. Rio de Janeiro: Elsevier, 2014.</li>
              <li>Brasil. Ministério da Saúde. Secretaria de Atenção à Saúde. Protocolos de intervenção para o SAMU 192 – Serviço de Atendimento Móvel de Urgência. Brasília: Ministério da Saúde, 2014.</li>
              <li>Eduardo OR, Félix VM, Silva AGB. Protocolo de atendimento pré-hospitalar CBMDF. Brasília: CBMDF, 2003.</li>
              <li>Rasia CA, Barros CC, Marcelino SC, Fernandes RWC, Pontes FC, Pedroso, GB, et al. Manual de atendimento pré-hospitalar. Brasília: Corpo de Bombeiros Militar do Distrito Federal, 2007.</li>
            </ul>
          </Accordion>

    </div>
  </IonContent>
</IonPage>

  );
}

export default TraumatismoExtremidades;
