import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import '../Page.css'


const desativacao: React.FC = () => {

  return (

  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>ATO DO COMANDANTE OPERACIONAL</IonTitle>
      </IonToolbar>
    </IonHeader>


    <IonContent fullscreen>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>Publicado no Boletim Geral 078, de 27 de abril de 2020</IonCardSubtitle>

          <IonCardTitle>ATO DO COMANDANTE OPERACIONAL - XXXI - NOTIFICAÇÃO COMPULSÓRIA DE CASOS DE ATENDIMENTO PRÉ-HOSPITALAR QUE ENVOLVA SITUAÇÃO DE VIOLÊNCIA</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p align="justify">&#8195; 

            O COMANDANTE OPERACIONAL, no uso das atribuições que lhe conferem os arts 22 e 23, do Decreto 31.817, de 21 jun. 2010, que regulamenta o art. 10-B, inciso II, da Lei 8.255, de 20 nov. 1991, que dispõe sobre a organização básica do CBMDF, e
          </p>
          <p align="justify">&#8195; 

          Considerando  a  Portaria n° 204,  de  17  fev. 2016,  que  define  a  Lista  Nacional  de  Notificação Compulsória de doenças, agravos e eventos de saúde pública nos serviços de saúde públicos e privados em todo o território nacional, nos termos do anexo, e dá outras providências;
          </p>
          <p align="justify">&#8195; 
    Considerando  a  Portaria  140,  de  8 ago. 2016,  que  regulamenta  as  atividades  da  vigilância epidemiológica relacionada à coleta, ao fluxo e à consolidação de dados de notificação compulsória de doenças,  agravos  e  eventos  de  saúde  pública  por  meio  do  Sistema  de  Informação  de  Agravos  de Notificação - SINAN; 
          </p>
          <p align="justify">&#8195; 
    Considerando que a notificação compulsória é a comunicação obrigatória,imediata (em até 24 horas a partir do conhecimento) ou semanal, à autoridade de saúde, realizada pelos profissionais de saúde ou responsáveis pelos estabelecimentos de saúde, públicos ou privados, sobre a ocorrência de suspeita ou confirmação de doença, agravo ou evento de saúde pública;
          </p>
          <p align="justify">&#8195; 
    Considerando que o conceito de agravo consiste em qualquer dano à integridade física ou mental do indivíduo,  provocado  por  circunstâncias  nocivas,  tais  como  acidentes,  intoxicações  por  substâncias químicas, abuso de drogas ou lesões decorrentes de violências interpessoais, como agressões e maus tratos, e lesão autoprovocada; 
          </p>
          <p align="justify">&#8195; 
    Considerando  a obrigatoriedade do  preenchimento  das  fichas  de  notificação  compulsória  de violências  e  da notificação imediata às  autoridades  competentes  de  saúde,  seja  ela  interpessoal  ou autoprovocada, como casos de tentativas de suicídio e violência sexual, pelo profissional de saúde ou responsável pelo serviço assistencial que prestar o primeiro atendimento ao paciente, em até 24 horas do conhecimento da ocorrência do evento, pelo meio mais rápido de comunicação. Tal determinação tem como objetivo dar visibilidade às situações de violência, de modo que possa ser identificado o perfil das vítimas e agressores, bem como o dimensionamento do atendimento para apontar quais as estratégias mais eficazes para a prevenção de novas agressões;
          </p>
          <p align="justify">&#8195; 
    Considerando que as notificações seguem um fluxo de compartilhamento entre as esferas de gestão do Sistema Único de Saúde - SUS, conforme Anexo 10 (37782232), estabelecido pela Secretaria de Vigilância em Saúde (SVS) do Ministério da Saúde, o Complexo Regulador em Saúde do Distrito Federal (CRDF), reconhecendo a importância da intersetorialidade, estabelece um fluxo de notificação compulsória da violências atendidas pelo SAMU; e, considerando a Portaria Conjunta 40, de 5 dez. 2018, que trata da instituição do Serviço Unificado de Atendimento Pré-Hospitalar (SUAPH), o Corpo de Bombeiros Militar do Distrito Federal (CBMDF) estabelece e torna público, para o conhecimento dos profissionais, o fluxo de compartilhamento das notificações dentro e fora do órgão, resolve: 
          </p>
          <p align="justify">&#8195; 
    DETERMINAR: 
          </p>
          <p align="justify">&#8195; 
    1) A Notificação Compulsória, pelas equipes de atendimento (URSB, UR, MR e AEROMÉDICO) de todas as ocorrências de APH do CBMDF que envolvam violências (doméstica, sexual e/outras violências).
          </p>
          <p align="justify">&#8195; 
    2)  As guarnições  deverão encaminhar,  o  mais  rápido  possível,  preferencialmente  até  24hs após atendimento da ocorrência, a Ficha de Atendimento de Ocorrência em conjunto com a Ficha de Notificação Compulsória  de  Violência,  conforme Anexo  10  (37782232),  para  a Seção  de  Controle  Operacional  e Estatística (SUCOE), localizada no Grupamento de Atendimento de Emergências Pré-Hospitalar (GAEPH) CBMDF; 
          </p>
          <p align="justify">&#8195; 
3) A SUCOE/GAEPH encaminhará as fichas de notificação compulsória ao Núcleo de Vigilância Epidemiológica   do   Complexo   Regulador   (NVEP/CRDF), digitalizadas,   através   do   e-mail: notificasamudf@gmail.com, o  mais  rápido  possível.  O  NVEP  lançará  as  fichas  de  notificação  de violênciasno  Sistema  de  Notificação  de  Agravos  de  Notificação (SINAN)  e  assim  as  informações alcançarão a área técnica de violência na Secretaria de Saúde. Mesmo em casos de pacientes que não forem  encaminhados  à  Unidade  de  Saúde,  o  procedimento  deve  ser  mantido  para  que  a  Vigilância Epidemiológica do território seja acionada. 
          </p>
          <p align="justify">&#8195; 
  4) A  SUCOE/GAEPH  ficará  responsável  pelo  arquivamento  digital das  fichas  de  atendimento  e notificação compulsória dos casos suspeitos ou confirmados de violências.
  Para  fins  de  notificação,  deverá  ser  consideradaa  DEFINIÇÃO  DE  CASO:  Caso  suspeito  ou confirmado  de  violência  doméstica/intrafamiliar,  sexual,  autoprovocada,  tráfico  de  pessoas,  trabalho escravo, trabalho infantil, intervenção legal e violências homofóbicas contra mulheres, homens em todas as  idades.  No  caso  de  violência extrafamiliar/comunitária,  somente  serão  objetos  de  notificação  as violências contra crianças, adolescentes, mulheres, pessoas idosas, pessoas com deficiência, indígenas e população LGBT.
          </p>
          <p align="justify">&#8195; 
  5) As secretarias das unidades operacionais do CBMDF deverão disponibilizar impressos de Ficha de Notificação Compulsória de Casos de Violência, conforme Anexo 10 (37782232), para ficarem em viaturas de APH. 
          </p>
          <p align="justify">&#8195; 
  * Esta norma foi republicada tendo em vista a falta de anexos na publicação anterior.
          </p>

          <p>&#8195;
            <br></br>
  (NB CBMDF/COMOP/GACOP- 00053-00028489/2020-75
          </p>

        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Anexo 9</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

              <img src="assets/NotificacaoCompulsoria/notificacao1.png" className="figura"/>
              <img src="assets/NotificacaoCompulsoria/notificacao2.png" className="figura"/>

        </IonCardContent>
      </IonCard>
    </IonContent>
  </IonPage>

  );
}

export default desativacao;
