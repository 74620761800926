import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import '../Page.css'


const desativacao: React.FC = () => {

  return (

  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>ATO DO COMANDANTE OPERACIONAL</IonTitle>
      </IonToolbar>
    </IonHeader>


    <IonContent fullscreen>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>Publicado no Boletim Geral 226, de 02 de dezembro de 2016</IonCardSubtitle>

          <IonCardTitle>INSTRUÇÃO NORMATIVA 23/2016 – COMOP</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p align="right">&#8195; 
            Estabelece padronização, organização e parâmetros de conduta relativos à
logística, materiais, equipamentos e viaturas de Atendimento Pré-Hospitalar
(APH) no âmbito do COMOP.
          </p>
          <br></br>
          <p align="center">&#8195; 
            <b>CAPÍTULO I</b>
            <br></br>
            <b>DAS DISPOSIÇÕES GERAIS</b>
            <br></br>
          </p>
          <p align="justify">&#8195; 
            <b>Art. 1°</b> Esta Instrução Normativa disciplina a operacionalização, padronização e organização de
parâmetros de conduta relativos à logística, materiais, equipamentos e viaturas de Atendimento PréHospitalar (APH), no âmbito do Comando Operacional do
CBMDF.
          </p>
          <p align="justify">&#8195; 
            <b>Art. 2°</b> Para fins desta Instrução Normativa considera-se:<br></br>
I – UR – Unidade de Resgate (ou viatura do tipo C);<br></br>
II – URSB – Unidade de Resgate de Suporte Básico (viatura do tipo B);<br></br>
III – OBM – Organização Bombeiro Militar;<br></br>
IV – GBM - Grupamento Bombeiro Militar;<br></br>
V – MR – Motorresgate;<br></br>
VI – DEA – Desfibrilador Externo Automático;<br></br>
VII- GAEPH – Grupamento de Atendimento de Emergência Pré-Hospitalar;<br></br>
VIII- APH – Atendimento Pré-Hospitalar;<br></br>
IX- SUPAT – Subseção de Patrimônio do GAEPH;<br></br>
X- KED – Kendrick Extrication Device;<br></br>
XI – CSU/APH - Curso de Socorros de Urgência em Atendimento Pré-Hospitalar;<br></br>
XII - CTE - Curso Técnico de Emergência;<br></br>
XIII - APH-B - Curso de Atendimento Pré-Hospitalar Básico<br></br>
XIV- SAMU-DF – Serviço de Atendimento Móvel de Urgência do Distrito Federal;<br></br>
XV– SeDEP - Seção de Doutrina, Ensino e Pesquisa do GAEPH. XVI – AMV –
Auto Múltiplas Vítimas.<br></br>
XVII – SELID – Setor de Limpeza e Desinfecção.
          </p>
          <p align="center">&#8195; 
            <b>CAPÍTULO II</b>
            <br></br>
            <b>DO EMPRÉSTIMO DE MATERIAIS DE APH JUNTO AO GAEPH</b>
            <br></br>
          </p>
          <p align="justify">&#8195; 
            <b>Art. 3°</b> O empréstimo mediante cautela de materiais e equipamentos de qualquer natureza junto ao
GAEPH será regido por esta norma.
          </p>
          <p align="justify">&#8195; 
          <b>§ 1°</b> O empréstimo ordinário de materiais somente será realizado para unidades do CBMDF,
conforme as disposições veiculadas no Boletim Geral n° 100 de 28 de maio de 2013.<br></br>
            I- O empréstimo será autorizado para OBM, desde que devidamente justificada a necessidade, e que
sua destinação seja exclusivamente para o serviço operacional.<br></br>
II- Em se tratando de empréstimo para órgãos externos, a solicitação deve ser endereçada ao
Comandante Operacional.
            </p>
          <p align="justify">&#8195; 
            <b>§ 2°</b> Empréstimos extraordinários a outros órgãos e instituições públicas somente ocorrerão
mediante autorização por escrito do Comando do GAEPH ou do COMOP.
            </p>
          <p align="justify">&#8195; 
            <b>Art. 4°</b> A retirada de material da Seção de Doutrina, Ensino e Pesquisa e da Subseção de
Patrimônio, por parte de unidades do CBMDF será realizada mediante a apresentação de memorando
assinado pelo Comandante da unidade solicitante no qual conste:<br></br>
I – A motivação do pedido;<br></br>
II – Local de utilização;<br></br>
III – Data e evento a ser empregado e/ou período de utilização; <br></br>
IV – Previsão de devolução.
          </p>
          <p align="justify">&#8195; 
            <b>Art. 5°</b> Em casos de instruções externas ao CBMDF ministradas por militares da Corporação, a
solicitação com a ordem de missão emitida pelo Comando Operacional para atender a instituição externa
deverá ser anexada ao memorando a que se refere o Art.4°.
          </p>
          <p align="justify">&#8195; 
            <b>Art. 6°</b> Em casos de eventos e instruções internas, tais como projeto Bombeiros nas Quadras,
instrução diária, dentre outros, a ordem de missão emitida pelo Comando Operacional ou o Quadro de
Trabalho Semanal (assinado pelo Comandante) deverá ser anexado ao memorando a que se refere o Art.
4° desta norma.

          </p>
          
<p align="justify">&#8195; 
          Parágrafo único. Os memorandos de solicitação de cautela de material de APH deverão ser
inclusos em processos exclusivos para este fim no SEI e, quando for o caso, deverão ser associados
digitalmente ao processo de origem.
            </p>
          
          <p align="justify">&#8195; 
            <b>Art. 7°</b> A solicitação de empréstimo deverá ser realizada com antecedência mínima de 48 horas.
          </p>

                    <p align="justify">&#8195; 
            <b>Art. 8°</b> A autorização de empréstimo do material estará sujeito à análise por parte da SeDEP ou
SUPAT, levando-se em consideração a disponibilidade de material e a necessidade de utilização em
instruções internas do GAEPH.
          </p>

                              <p align="justify">&#8195; 
            <b>Art. 9°</b> As cautelas referentes a materiais da Seção de Doutrina, Ensino e Pesquisa terão prazo
para vencimento (data de devolução), com validade máxima de 7 (sete) dias corridos.
          </p>

                                        <p align="justify">&#8195; 
            <b>Art. 10</b> As cautelas referentes a materiais da Subseção de Patrimônio e demais seções do GAEPH,
terão prazo para vencimento (data de devolução) estipulados no próprio documento de cautela.
          </p>

                                                  <p align="justify">&#8195; 
            <b>Art. 11</b> Apenas militares da SeDEP e da SUPAT estão autorizados a realizar empréstimos, receber
materiais devolvidos, emitir e dar baixa em cautelas, exceto em casos especiais e com anuência do
Comandante do GAEPH.
          </p>

                                                  <p align="justify">&#8195; 
            <b>Art. 12</b> A não devolução do material cautelado no prazo determinado nos Art.9º e Art.10 acarretará
em possíveis sanções disciplinares.
          </p>
                                                 <p align="justify">&#8195; 
            <b>Art. 13</b> A Retirada e devolução de material se darão em dias úteis das 13h00min às 18h30min,
exceto em casos especiais previamente autorizados pelo GAEPH.
          </p>

                                                           <p align="justify">&#8195; 
            <b>Art. 14</b> Os responsáveis pela devolução do material deverão realizar contato telefônico prévio com
a SeDEP ou com a SUPAT para verificar a disponibilidade de militares para recebimento no período
matutino (08h00min – 12h00min).
          </p>

                                                                     <p align="justify">&#8195; 
            <b>Art. 15</b> O transporte de material cautelado é responsabilidade do solicitante. Art. 16 Fica
terminantemente proibido o uso destes materiais para fins
particulares.

          </p>

                                                                               <p align="justify">&#8195; 
            <b>Art. 17</b> Para a retirada do material é necessário que o militar recebedor possua cadastro no Sistema
Eletrônico de Informação (SEI).

          </p>

                                                                                         <p align="justify">&#8195; 
Parágrafo único. No caso de militares ou civis que não possuam acesso ao SEI, o empréstimo de
material ficará sujeito à avaliação do Comando do GAEPH.

          </p>

                                                                                         <p align="justify">&#8195; 
            <b>Art. 18</b> Empréstimos que não atendam ao estabelecido nessa nota de instrução não serão
autorizados.
          </p>

                                                                                                   <p align="justify">&#8195; 
            <b>Art. 19</b> Em caso de extravio de material deverá ser confeccionado memorando ao Comandante da
OBM solicitante e ao Comandante do GAEPH.
          </p>

                                                                                                             <p align="justify">&#8195; 
            <b>Art. 20</b> Em casos de extravio de materiais deverá ser instaurado de imediato procedimento
administrativo próprio, por parte do detentor da carga, com a finalidade de apurar as circunstâncias do
ocorrido.
          </p>

                    <p align="center">&#8195; 
            <b>CAPÍTULO III</b>
            <br></br>
            <b>DOS EQUIPAMENTOS ELETRÔNICOS DE APH</b>
            <br></br>
          </p>
          <p align="justify">&#8195; 
            <b>Art. 21</b> A utilização e conservação dos oxímetros de pulso, esfigmomanômetros digitais e
Desfibriladores Externo Automáticos (DEAS) deverão observar os parâmetros disciplinados pela presente
norma.
          </p>

                              <p align="center">&#8195; 
            <b>Seção I</b>
            <br></br>
            <b>Da limpeza e conservação</b>
            <br></br>
          </p>
          <p align="justify">&#8195; 
            <b>Art. 22</b> A manutenção básica de primeiro escalão deve ser realizada na OBM que detém a posse do
bem.
          </p>
                    <p align="justify">&#8195; 
            Parágrafo Único. O manual do usuário fornecido pelo fabricante do equipamento sempre deverá ser
consultado antes de qualquer utilização ou manutenção de primeiro escalão.
          </p>

                    <p align="justify">&#8195; 
            <b>Art. 23</b> Os equipamentos devem ser limpos diariamente para eliminar sujidades comuns. Os
Oxímetros de Pulso (CO-Oxímetro Rad 57c) e seus sensores, assim como os DEA devem ser limpos após
cada utilização com álcool 70 (álcool etílico hidratado 70° INPM).
          </p>
                              <p align="justify">&#8195; 
            <b>§ 1°</b> Equipamentos similares aos descritos no Art. 21 devem ser higienizados conforme Art. 23, a
menos que haja contraindicação expressa do fabricante.
          </p>
                                        <p align="justify">&#8195; 
            <b>§ 2°</b> Nenhum líquido deve entrar em contato com as partes internas e placas eletrônicas dos
equipamentos incluindo álcool.
          </p>

                              <p align="justify">&#8195; 
            <b>Art. 24</b> Os sensores do CO-Oxímetro Rad 57c deverão ser limpos desde a ponta onde se encontra
o conector, passando pelo cabo e terminando no sensor, onde deverá ser limpo tanto interna quanto
externamente.
          </p>

                                        <p align="justify">&#8195; 
            <b>Art. 25</b> Deve-se evitar a colagem de esparadrapo ou fitas adesivas para identificação dos
equipamentos ou identificação de problemas nos mesmos.
          </p>

                                                  <p align="justify">&#8195; 
            <b>Art. 26</b> Caso existam resíduos de adesivo ou esparadrapo nas partes plásticas dos equipamentos,
estes devem ser removidos completamente com o uso de óleo mineral e uma gaze.
          </p>
                              <p align="center">&#8195; 
            <b>Seção II</b>
            <br></br>
            <b>Da operação e manutenção</b>
            <br></br>
          </p>
          <p align="justify">&#8195; 
            <b>Art. 27</b> Os equipamentos a que se refere essa norma devem ser testados diariamente na assunção
do serviço e a conferência deve ser registrada em livro, ou livro digital, para o caso dos equipamentos que
compõem viaturas e em livro próprio para outros casos.
          </p>

                    <p align="justify">&#8195; 
            <b>Art. 28</b> Qualquer dano ou mau funcionamento dos equipamentos devem ser registrados em livro e
ser comunicado por escrito ao Comandante da Unidade ou
detentor da carga patrimonial do equipamento, onde deve ser informado se possível o número de
tombamento do bem e detalhes do que foi verificado.
          </p>

                              <p align="justify">&#8195; 
            <b>Art. 29</b> Os equipamentos que apresentarem falhas de operação, necessitarem de manutenção
corretiva ou de reposição de componentes deverão ser encaminhados ao GAEPH para avaliação e
providências.
          </p>

                                        <p align="justify">&#8195; 
            <b>Art. 30</b> Os equipamentos serão encaminhados para manutenção preventiva e corretiva em empresa
especializada conforme cada caso e de acordo com a duração e especificidades de cada contrato de
manutenção vigente na Corporação.
          </p>
                                                  <p align="justify">&#8195; 
            <b>Art. 31</b> Nos casos em que seja necessária a manutenção especializada os equipamentos serão
recolhidos na Subseção de Patrimônio do GAEP, mediante cautela para manutenção.
          </p>

                                                            <p align="justify">&#8195; 
         Parágrafo único- Nestes casos e conforme a disponibilidade de equipamentos, outro aparelho será
disponibilizado pelo GAEPH mediante cautela temporária até o fim da manutenção.
          </p>

                                                            <p align="justify">&#8195; 
            <b>Art. 32</b> Equipamentos que foram apresentados com componentes faltando ou danos característicos
de mau uso implicarão em abertura de processo apuratório e, conforme o caso, sansões legais e
disciplinares cabíveis.
          </p>

                              <p align="center">&#8195; 
            <b>Seção III</b>
            <br></br>
            <b>Dos Oxímetros de Pulso (CO-Oxímetro Rad 57c)</b>
            <br></br>
          </p>
          <p align="justify">&#8195; 
            <b>Art. 33</b> Deve-se evitar a utilização de pilhas de má qualidade, já que pode ocasionar a não leitura
da saturação, mesmo que o indicador de bateria esteja com a marcação completa.
          </p>
                    <p align="justify">&#8195; 
            <b>Art. 34</b> Deve-se verificar o diâmetro do dedo do paciente a fim de selecionar o sensor adequado
para a aferição DCI-DC3 (adulto) ou DCIP-DC3 (pediátrico). Desde que o equipamento consiga registrar a
leitura sem indicar erro a escolha do sensor não influenciará no resultado da aferição. Grande parte dos
pacientes terão dedos compatíveis com os dois sensores.
          </p>
                    <p align="justify">&#8195; 
            <b>Art. 35</b> A radiação luminosa pode interferir na leitura, por isso leituras sob a luz direta do sol devem
ser evitados ou devem ser feitas utilizando o protetor de luminosidade.
          </p>

                              <p align="center">&#8195; 
            <b>Seção IV</b>
            <br></br>
            <b>Dos Desfibriladores Externo Automáticos</b>
            <br></br>
          </p>
          <p align="justify">&#8195; 
            <b>Art. 36</b> A substituição de eletrodos (pás) e baterias para DEA será feita pelo GAEPH conforme
disponibilidade do material na Corporação;
          </p>
         
                              <p align="justify">&#8195; 
            <b>Art. 37</b> Para a substituição de eletrodos de DEA utilizados em ocorrência a viatura deve se
direcionar a Subseção de Patrimônio do GAEPH com uma cópia da ficha de atendimento referente à
ocorrência e circunstâncias em que foi utilizado o DEA.
          </p>

                              <p align="justify">&#8195; 
Parágrafo Único. Caso não seja possível efetuar uma cópia, o chefe da guarnição pode preencher
uma nova ficha idêntica a original e identificá-la como cópia.
          </p>

                                        <p align="justify">&#8195; 
            <b>Art. 38</b>  Nos finais de semana e horários em que a SUPAT não estiver aberta o Dia à Prontidão do
GAEPH fará a substituição do eletrodo conforme disponibilidade.
          </p>

                                        <p align="center">&#8195; 
            <b>Seção V</b>
            <br></br>
            <b>Dos Esfigmomanômetros Digitais</b>
            <br></br>
          </p>
          <p align="justify">&#8195; 
            <b>Art. 39</b> O equipamento não deve ser armazenado junto a materiais pontiagudos ou perfuro
cortantes que possam danificar a braçadeira.
          </p>
          <p align="justify">&#8195; 
            <b>Art. 40</b> Baterias fracas podem ocasionar erros de leitura.
          </p>


                                                 <p align="center">&#8195; 
            <b>Seção VI</b>
            <br></br>
            <b>Das Prescrições Diversas</b>
            <br></br>
          </p>
          <p align="justify">&#8195; 
            <b>Art. 41</b> As pilhas dos equipamentos devem ser retiradas quando não houver previsão de uso por
um período maior que 3 (três) dias. Pilhas esquecidas dentro dos equipamentos costumam vazar
danificando os componentes eletrônicos.
          </p>

                    <p align="justify">&#8195; 
            <b>Art. 42</b> Fica terminantemente proibido o uso destes materiais para fins particulares de acordo com o
art. 18 do Cap. III do MAP/GDF.
          </p>

          
                    <p align="justify">&#8195; 
            <b>Art. 43</b> Em caso de extravio de material deverá ser confeccionado memorando ao Comandante da
OBM que detêm a carga do bem.
          </p>
                    <p align="justify">&#8195; 
            <b>Art. 44</b> Os materiais extraviados implicarão em abertura de procedimento administrativo, possível
ressarcimento e sanções disciplinares, de acordo com o Art. 20 do Cap. III do Manual De Administração
Patrimonial – MAP/GDF. (Decreto n° 16.109 de 1/12/1994) e itens 19, 22 e 113, anexo I do Regulamento Disciplinar Do Exército – RDE
(Decreto 4.346 de 26/08/2002);
          </p>

                                                 <p align="center">&#8195; 
            <b>CAPÍTULO IV</b>
            <br></br>
            <b>DO MATERIAL DE APH NAS UNIDADES OPERACIONAIS DO CBMDF</b>
            <br></br>
          </p>
                                                           <p align="center">&#8195; 
            <b>Seção I</b>
            <br></br>
            <b>Das Normas Gerais</b>
            <br></br>
          </p>
          
          <p align="justify">&#8195; 
            <b>Art. 45</b> O controle do consumo e utilização será compartilhado entre a Unidade e o GAEPH sendo
de extrema importância para a conservação e manutenção da operacionalidade e vida útil dos materiais e
equipamentos adquiridos pelo CBMDF.
          </p>
                    <p align="justify">&#8195; 
            <b>Art. 46</b> A reposição ordinária dos materiais e equipamentos será feita de segunda a sexta feira no
GAEPH de 07h00min as 19h00min.
          </p>
                              <p align="justify">&#8195; 
            <b>Art. 47</b> Cada Unidade Operacional deverá disponibilizar um local e um armário específico para
armazenamento do material de APH operacional e material de farmácia.
          </p>
                                        <p align="justify">&#8195; 
            <b>Art. 48</b> As quantidades de materiais de consumo serão especificadas de acordo com o tamanho da
OBM e o número de ocorrências atendidas diariamente, dessa forma, essas quantidades poderão ser
alteradas pelo GAEPH a qualquer tempo.
          </p>

                                                  <p align="justify">&#8195; 
            <b>Art. 49</b> O GAEPH fará a fiscalização e reabastecimento dos armários das OBMs quinzenalmente.
                                                  </p>
                                                            <p align="justify">&#8195; 
            <b>Art. 50</b> No caso de falta de material, as OBMs devem informar o GAEPH a fim de modificar as
quantidades entregues quinzenalmente.
                                                  </p>
                                                                      <p align="justify">&#8195; 
            <b>Art. 51</b> As OBMs podem solicitar a reposição de material junto ao GAEPH a qualquer tempo, porém
o transporte do material fora do agendamento quinzenal deve ser providenciado pela OBM solicitante.
                                                  </p>

                                                                                <p align="justify">&#8195; 
            <b>Art. 52</b> Os materiais serão entregues conforme a disponibilidade em estoque no
GAEPH.
                                                  </p>

                                                          <p align="center">&#8195; 
            <b>Seção II</b>
            <br></br>
            <b>Das Bolsas de APH</b>
            <br></br>
          </p>
          
          <p align="justify">&#8195; 
            <b>Art. 53</b> Tendo em vista o melhor aproveitamento do material operacional de APH e o melhor
atendimento à comunidade, o material de APH nas Unidades Operacionais deve atender aos critérios de
padronização do GAEPH.
          </p>
                    <p align="justify">&#8195; 
            <b>Art. 54</b> Além das bolsas presentes nas URs/URSBs cada Unidade Operacional deve ter duas
bolsas devidamente identificadas e com composições específicas para cada função.
          </p>
                              <p align="justify">&#8195; 
            <b>§ 1°</b> A disposição das bolsas se dará da seguinte forma:<br></br>
                                I- A bolsa identificada com a sigla VS deve compor uma viatura de salvamento da unidade, na
forma da tabela 4.<br></br>
II- A bolsa identificada com a sigla SECOM deve permanecer na Seção de Comunicação ou na
Sala da Vida da OBM, na forma da tabela 4.
          </p>
                              <p align="justify">&#8195; 
            <b>§ 2°</b> A composição das bolsas das URs/URSBs se dará da seguinte forma: <br></br>
                  I-Uma bolsa voltada para atendimentos de trauma;<br></br>
                                II-Uma bolsa voltada para atendimentos clínicos; <br></br>
                                III- Uma bolsa voltada para aferição de sinais vitais;<br></br>
IV- Uma bolsa de medicação (exclusiva para viatura de suporte básico de vida - URSB)
          </p>
                    <p align="justify">&#8195; 
            <b>§ 3°</b> As demais viaturas serão compostas por bolsas menores contendo materiais básicos para
contenção de hemorragias e realização de procedimentos de emergência até a chegada da Unidade de
Resgate.
          </p>
                              <p align="justify">&#8195; 
            <b>§ 4°</b> A reposição desses materiais será feita pelo GAEPH mediante solicitação. 
          </p>
                                        <p align="justify">&#8195; 
            <b>Art. 55</b> Os militares
da prontidão devem zelar devidamente pelo material
verificando a necessidade de limpeza e desinfecção ou substituição de materiais. 
          </p>
                                                  <p align="justify">&#8195; 
            <b>Art. 56</b> Qualquer material de APH que necessite de limpeza e desinfecção deve ser enviado ao
GAEPH. 
          </p>
                                                           <p align="justify">&#8195; 
Parágrafo único. O envio de materiais ao GAEPH para limpeza e desinfecção pode ser feita a
qualquer tempo durante o período de expediente ou em data agendada, juntamente com os materiais da
UR da OBM correspondente.
          </p>
                                                            <p align="justify">&#8195; 
            <b>Art. 57</b> Equipamentos que foram apresentados com componentes faltando ou danos característicos
de mau uso implicarão em abertura de processo apuratório e conforme o caso, sansões legais e
disciplinares cabíveis.
          </p>

                                                          <p align="center">&#8195; 
            <b>CAPÍTULO V</b>
            <br></br>
            <b>DO MATERIAL DE APH DAS VIATURAS</b>
            <br></br>
          </p>

                                                                    <p align="center">&#8195; 
            <b>Seção I</b>
            <br></br>
            <b>Das Normas Gerais</b>
            <br></br>
          </p>
          
          <p align="justify">&#8195; 
            <b>Art. 58</b> Cada viatura do tipo UR conterá 03 (três) bolsas, sendo uma para atendimento a casos
clínicos, uma para trauma e uma para verificação de sinais vitais e será padronizada de acordo com o
modelo do veículo (Mercedes Sprinter alongada, Mercedes Sprinter curta e furgão Renault Master ou outra
similar adquirida pela Corporação).
          </p>

                    <p align="justify">&#8195; 
       Parágrafo único. A padronização será diferente para viaturas do tipo UR e URSB independente da
marca e modelo do veículo
          </p>

                    <p align="justify">&#8195; 
            <b>Art. 59</b> Constará em cada viatura uma lista com os materiais específicos e as quantidades de cada
item.
          </p>
          
                              <p align="justify">&#8195; 
            <b>§ 1°</b> A quantidade mínima de alguns itens descritos nesta norma será condição necessária para a
ativação da viatura no Socorro.
          </p>
                                        <p align="justify">&#8195; 
            <b>§ 2°</b> As quantidades mínimas de materiais e medicamentos exclusivos das viaturas URSB com
regulação médica, bem como os protocolos de atuação e conferência de material, serão regidas pela
normativa de regulação médica e integração com o SAMU-DF.
          </p>
                                                                                                  <p align="center">&#8195; 
            <b>Seção II</b>
            <br></br>
            <b>Da Operacionalização Viaturas do Tipo UR e AMV</b>
            <br></br>
          </p>
          
          <p align="justify">&#8195; 
            <b>Art. 60</b> Ao assumir a viatura o Chefe de guarnição deve realizar a conferência completa de
materiais atentando para a verificação das quantidades mínimas exigidas dos itens essenciais:<br></br>
I - Equipamentos de Proteção Individual (EPIs);<br></br>
II - Materiais de farmácia (gazes, soro fisiológico, ataduras e similares); <br></br>
            III - Materiais operacionais de APH;<br></br>
IV - Quantidade de O2 (oxigênio medicinal) nos cilindros da viatura.
          </p>            
                    <p align="justify">&#8195; 
            <b>Art. 61</b> Qualquer alteração na viatura ou no material deve ser registrada em livro ata próprio de
papel ou digital e levada ao conhecimento do Comandante da OBM.
          </p>  

                              <p align="justify">&#8195; 
Parágrafo único. Quando a alteração impedir a ativação da viatura para o socorro ou estiver
relacionada a materiais permanentes (com tombamento), deve ser confeccionado, obrigatoriamente,
memorando ao Comandante da OBM, informando se possível, o número de patrimônio do material a que
se refere à alteração e detalhes do ocorrido.
          </p>  

                              <p align="justify">&#8195; 
            <b>Art. 62</b> Cada OBM fica responsável pela leitura do livro ata ou digital das URs ativadas na Unidade
e providências iniciais quanto às alterações.
          </p>  
                                        <p align="justify">&#8195; 
            <b>§ 1°</b> As anotações e alterações referentes a APH e as URs podem ser registradas no livro do Dia a
Prontidão assim como ocorre com as demais viaturas. Nesse caso o Dia a Prontidão e o chefe da
guarnição da UR respondem solidariamente pelas informações prestadas.
          </p>  
                                                  <p align="justify">&#8195; 
            <b>§ 2°</b> As viaturas que, por qualquer motivo, não disponham de livro ata ou lista de conferência de
material devem providenciar a aquisição junto a Subseção de Patrimônio do GAEPH, juntamente com o
modelo de preenchimento do livro.
          </p>  
                                                            <p align="justify">&#8195; 
            <b>§ 3°</b> Para evitar duplicidade no controle, uma vez adotado o controle por livro digital (SEI) o livro ata
de papel deve ser utilizado apenas em casos e inoperância do sistema digital e as informações devem ser
transcritas para o meio digital tão logo o mesmo volte à normalidade.
          </p>  
                                                                      <p align="justify">&#8195; 
            <b>§ 4°</b> A ausência da lista de conferência não exime a OBM da responsabilidade quanto ao material,
especialmente equipamentos tombados.
          </p>  

                                                                      <p align="justify">&#8195; 
            <b>§ 5°</b> Cada OBM deverá seguir um check list padrão adotado pelo GAEPH comum a todas as URs,
observando os materiais em falta e que necessitem de reposição, bem como, os que estejam danificados e
fora de condições de uso.
          </p>  

                                                                               <p align="justify">&#8195; 
            <b>§ 6°</b> Cada OBM deverá fiscalizar diariamente a conferência das listas de materiais das URs
ativadas na Unidade.
          </p>  

    <p align="center">&#8195; 
            <b>Seção III</b>
            <br></br>
            <b>Das Macas Reservas e Materiais Fixos nas Viaturas</b>
            <br></br>
          </p>
          
          <p align="justify">&#8195; 
            <b>Art. 63</b> Sempre que for necessária a desativação das viaturas para manutenção junto ao CEMEV,
os cilindros de oxigênio médio ou grande devem permanecer na viatura. Materiais considerados “fixos da
viatura” devem permanecer no interior da viatura, pois seu uso pressupõe a viatura ativada. Os demais
materiais devem ser recolhidos no quartel antes do envio da viatura ao Centro de Manutenção de Viaturas.
          </p> 

                    <p align="justify">&#8195; 
            <b>§ 1°</b> Cada OBM deverá confeccionar lista própria de materiais fixos da viatura pertencentes à carga
da OBM com tombamento, os quais não deverão ser retirados sem a ciência do detentor da carga e afixar
em local visível na viatura para conferencia diária.
          </p>
                              <p align="justify">&#8195; 
            <b>§ 2°</b> Cilindros de oxigênio de porte médio (fixos nas URs) não devem ser retirados da viatura em
hipótese alguma exceto para reabastecimento.
          </p>
                                        <p align="justify">&#8195; 
            <b>§ 3°</b> Uma vez que o reabastecimento é feito no GAEPH, apenas os militares responsáveis pelo
reabastecimento devem estar autorizados a retirar cilindros de porte médio das viaturas.
          </p>

                                                  <p align="justify">&#8195; 
            <b>§ 4°</b> No caso da viatura ser desativada permanentemente para leilão os militares do CEMEV
também estão autorizados a retirar estes equipamentos e encaminha-los ao GAEPH para onde a carga
patrimonial dos mesmos deve ser transferida.
          </p>

                                                          <p align="justify">&#8195; 
            <b>§ 5°</b> Quando houver previsão da viatura ser desativada por mais de 6 (seis) meses, devido a
tomada de contas, ou qualquer outro motivo, os militares do CEMEV também devem retirar estes
equipamentos e encaminha-los ao GAEPH, este último deve dar a destinação apropriada e confeccionar Termo de Guarda e Responsabilidade do bem. Em média a carga do cilindro tem validade de 6 (meses) e
este procedimento evita o desperdício.
          </p>

                                                                    <p align="justify">&#8195; 
            <b>§ 6°</b> Cada GBM deve possuir um em sua carga patrimonial um cilindro de pequeno porte que, salvo
casos especiais, deve estar sempre na viatura do tipo UR ativada na Unidade.
          </p>

                                                                              <p align="justify">&#8195; 
            <b>§ 7°</b> Em caso de baixa ou manutenção da viatura os cilindros portáteis (pequenos porte) devem
permanecer na unidade que detém a carga patrimonial até o retorno da viatura. Ao contrário dos cilindros
fixos, não há necessidade da viatura para a utilização dos cilindros portáteis.
          </p>

                                                                                        <p align="justify">&#8195; 
            <b>Art. 64</b> Os cilindros de oxigênio fixos devem acompanhar a viatura a que pertencem sempre que
esta for movimentada. No caso de mudança do detentor da carga patrimonial da viatura, estes itens devem
ter suas cargas patrimoniais transferidas juntamente com a viatura para o mesmo detentor de carga, salvo
casos especiais e que tenham anuência do Comandante do GAEPH.
          </p>

                                                                                     <p align="justify">&#8195; 
            <b>Art. 65</b> Somente os militares do GAEPH responsáveis pelo reabastecimento dos cilindros de
oxigênio estão autorizados a colocar ou retirar cilindros de oxigênio nas viaturas, salvo o caso de baixa
permanente da viatura para leilão.
          </p>

   <p align="center">&#8195; 
            <b>Seção IV</b>
            <br></br>
            <b>Da movimentação e transferência de Macas Reservas e Principais</b>
            <br></br>
          </p>
          
          <p align="justify">&#8195; 
            <b>Art. 66</b> As macas reservas das viaturas devem ficar armazenadas nas OBMs em local de fácil
acesso aos socorristas de serviço.
          </p> 
                    <p align="justify">&#8195; 
            <b>Art. 67</b> Sempre que for necessária a utilização da maca reserva da viatura, o destino da maca
principal, o motivo de sua retirada e a previsão para retorno devem ser anotados no livro ata da viatura ou
livro digital (SEI).
          </p> 

                              <p align="justify">&#8195; 
            <b>§ 1°</b> O socorrista ou chefe da viatura que retirou a maca reserva, fica responsável pelo lançamento
das informações no livro ata da viatura ou livro digital (SEI).
          </p> 
                                        <p align="justify">&#8195; 
            <b>§ 2°</b> O Socorrista ou chefe da viatura deve cientificar o militar que rendê-lo das informações
necessárias para recuperação da maca principal, caso esta não seja recuperada antes da passagem de
serviço ou caso haja substituição da guarnição.
          </p> 
                                                  <p align="justify">&#8195; 
            <b>§ 3°</b> O militar que assumir o serviço em seguida fica responsável pela recuperação da maca junto
aos hospitais ou instituição em que se encontre.
          </p> 
                                                            <p align="justify">&#8195; 
            <b>Art. 68</b> Por se tratar de equipamento tombado, todas as movimentações de macas entre a OBM e
Hospitais devem ser registradas no livro da viatura ou livro digital (SEI).
          </p> 
                                                                      <p align="justify">&#8195; 
            <b>§ 1°</b> Não será confeccionada cautela em nome de integrantes da guarnição de UR para os casos de
movimentação de macas entre a OBM e Hospitais.
          </p> 
                                                                                <p align="justify">&#8195; 
            <b>§ 2°</b> Caso não seja possível a recuperação da maca e esta permaneça no hospital por mais de
24h, o Comandante da OBM e O Superior de Dia devem ser informados a fim de tomarem providências.
          </p> 

                                                                                          <p align="justify">&#8195; 
            <b>Art. 69</b> As macas não devem ser movimentadas entre as OBMs fora das viaturas a que pertencem.<br></br>
                 I - Nos casos em que seja estritamente necessária a movimentação avulsa da maca entre as
OBMs, será necessária a autorização do Superior de Dia e a confecção de cautela específica com a
previsão de retorno.<br></br>
II - Caso não haja previsão de retorno ou caso a movimentação seja permanente, deve ser
providenciada, assim que possível, a transferência da carga patrimonial da maca para:<br></br>
a) Comandante da OBM recebedora, no caso de macas que serão utilizadas como reserva nesta
OBM;<br></br>
b) O detentor da carga da viatura que receber a maca, no caso de macas que
serão utilizadas como maca principal da viatura, a fim de evitar problemas quando da
movimentação de viaturas.<br></br>
III - Em ambos os casos previstos no inciso no Art.69º, inciso II, os detentores de cargas devem ser
informados dos motivos que tornaram necessárias as referidas substituições.                                                                           
          </p> 

   <p align="center">&#8195; 
            <b>Seção V</b>
            <br></br>
            <b>Das Responsabilidades do GAEPH e OBMs Quanto às Macas</b>
            <br></br>
          </p>
          
          <p align="justify">&#8195; 
            <b>Art. 70</b> Uma vez por trimestre ou quando houver necessidade a maca reserva deve ser
encaminhada a Setor de Limpeza e Desinfecção do GAEPH juntamente com a maca principal, na data da
limpeza e desinfecção semanal da viatura publicado em BG, para regulagem, ajustes de encaixe e limpeza
e desinfecção terminal.
          </p> 

                    <p align="justify">&#8195; 
            <b>§ 1°</b> Para situações onde houver contaminação da maca, principalmente em decorrência de 
                      atendimento de pacientes vítimas de traumas, onde ocorra exposição a secreções biológica,
                      não sendo possível realizar a descontaminação efetiva da mesma com os procedimentos de limpeza
                      concorrente, então, a maca deverá ser imediatamente baixada e encaminhada para a SELID do GAEPH,
                      para que seja realizada a limpeza terminal e a mesma esteja em condição de uso novamente.
          </p> 

                              <p align="justify">&#8195; 
            <b>§ 2°</b> Há previsão de recebimento de duas macas reservas diferentes em cada OBM, 
                                uma para viaturas Mercedes e outra para Viaturas Renault, de forma 
                                que deve ser enviada para o GAEPH apenas a maca reserva da viatura
                                que está ativa na OBM.
          </p> 

                                        <p align="justify">&#8195; 
            <b>§ 3°</b> Cada maca tem que ser regulada para uma viatura específica e não
necessariamente encaixará em outra viatura do mesmo modelo e marca.
          </p>

                                                  <p align="justify">&#8195; 
            <b>Art. 71</b> Cada maca tem que ser regulada para uma viatura específica e não
necessariamente encaixará em outra viatura do mesmo modelo e marca.
          </p>
                                                            <p align="justify">&#8195; 
            <b>§ 1°</b> O recolhimento será feito mediante cautela.
          </p>

                                                                   <p align="justify">&#8195; 
            <b>§ 2°</b> No caso de recolhimento pelo GAEPH, fica estipulado o prazo de um mês 
                para o GAEPH apontar um novo detentor de carga para a maca recolhida.
          </p>

                                                                             <p align="justify">&#8195; 
            <b>§ 3°</b> Após vencimento do prazo a cautela deve ser cancelada e a carga da maca deve ser transferida para o GAEPH.
          </p>
                                                  <p align="justify">&#8195; 
            <b>Art. 72</b> Casos omissos serão solucionados provisoriamente pelo Superior de Dia e em caráter 
                                                    definitivo pelo Comandante do GAEPH.
          </p>

  <p align="center">&#8195; 
            <b>Seção VI</b>
            <br></br>
            <b>Da Transferência do Material Operacional de APH das Viaturas</b>
            <br></br>
          </p>
          
          <p align="justify">&#8195; 
            <b>Art. 73</b> A utilização do material das URs para ativação de outras URs ou reposição de material
            de outras viaturas será permitida apenas em caráter de urgência, quando o material necessário não puder
            ser providenciado pela Subseção de Patrimônio do GAEPH.
          </p> 
        

                    <p align="justify">&#8195; 
            <b>§ 1°</b> Nesses casos deverá ser registrado em livro próprio o material retirado, nome e matrícula 
                      do militar responsável pelo material e prefixo da viatura a que se destina o material;
          </p> 
                              <p align="justify">&#8195; 
                      <b>§ 2°</b> Casos omissos serão definidos em caráter provisório Pelo Superior de Dia e em
          caráter definitivo pelo Comandante do GAEPH.
          </p>            

<p align="center">&#8195; 
            <b>Seção VII</b>
            <br></br>
            <b>Da Padronização dos Materiais na Bolsa de APH das viaturas do tipo UR.</b>
            <br></br>
          </p>
          
          <p align="justify">&#8195; 
            <b>Art. 74</b> Todo profissional de APH deve saber localização exata de cada material
            na viatura e nas bolsas de APH, minimizando falhas e imprevistos.
          </p> 

                  <p align="justify">&#8195; 
            <b>Art. 75</b> A padronização e organização do material de APH dentro das bolsas de APH 
                    dar-se-ão conforme as tabelas 1, 2, 3, 4 e 5.
          </p> 


          
          
          <p>&#8195;
            <br></br>
  (NB 35/2015-SELEG/CBMDF-COMOP-ALUJD /00053-SEI004573/2015-0020449)
</p>
                 </IonCardContent>
      </IonCard>
    </IonContent>
  </IonPage>

  );
}

export default desativacao;
