import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import './Page.css'


const manualAPH: React.FC = () => {

  return (

  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>Manual de APH</IonTitle>
      </IonToolbar>
    </IonHeader>


    <IonContent fullscreen>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle></IonCardSubtitle>

          <IonCardTitle>Download</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p>            
            <a href="https://www.cbm.df.gov.br/download/manual-aph-2a-edicao/">Manual de APH</a>
            <p>
              <img src="assets/home/Manual de APH.jpeg" className="figura"/>
            </p>
            </p>


        </IonCardContent>
      </IonCard>
    </IonContent>
  </IonPage>

  );
}

export default manualAPH;
