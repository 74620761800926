import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const Trabalhodeparto: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Assistência ao Trabalho de Parto</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://www.cbm.df.gov.br/download/pop-assistencia-ao-trabalho-de-parto-bg-141-de-2022/?tmstv=1667848762">POP Assistência ao Trabalho de Parto (BG 141 de 28/07/2022)</a>
            </p>

</ul>
              </Accordion>
          
                    <Accordion title="Resultados Esperados">
            <ul>
             <li> Salvaguardar a saúde da mulher e do recém nascido, em ambiente de
APH, antes, durante e após o parto.</li>
             <li>Avaliar adequadamente a paciente, intervindo e tratando as alterações
encontradas em cada fase do atendimento, tendo por objetivo garantir
a melhor assistência ao binômio mãe-filho até a chegada a uma
unidade de saúde ou do recurso avançado.</li>

</ul>
              </Accordion>
          
                              <Accordion title="Material Recomendado">
            <ul>
             <li> 6 campos estéreis (2 deles para o recém-nascido)</li>
             <li>Compressas estéreis</li>
             <li>Luvas estéreis</li>
             <li>Lâmina de bisturi</li>
             <li>2 clamps de cordão umbilical</li>
             <li>Saco coletor de urina para armazenar a placenta</li>
             <li>Saco plástico transparente (saco de polietileno de 30x50cm)</li>
             <li>Sonda n° 8 ou 10</li>
             <li>Reanimador manual – BVM</li>
             <li>Oxímetro de pulso adulto</li>
             <li>Cilindro com oxigênio</li>
             <li>Catéter nasal de O2</li>
             <li>Máscara com reservatório de O2</li>
             <li>Cobertor térmico</li>
             <li>Catéter venoso periférico e material de punção venosa (viaturas com
enfermeiro ou técnico de enfermagem)</li>

</ul>
              </Accordion>
          
                                        <Accordion title="Sinais e Sintomas">
            <ul>
             <li>Dor e enrijecimento abdominal</li>
             <li>Perda vaginal de líquido ou sangue</li>
             <li>Perda vaginal do tampão mucoso (muco espesso)</li>
             <li>3 ou mais contrações uterinas em 10 minutos</li>
             <li>Gestante relata vontade de evacuar</li>
             <li>Abaulamento da vulva ou coroamento</li>
</ul>
              </Accordion>
          
                                                  <Accordion title="Procedimentos">
            <ul>
             <li><b>Sempre solicitar o consentimento para atendimento, fornecer
suporte emocional, garantir a privacidade da gestante e mantê-la
               acompanhada.</b></li>
             <li><b>Identificar precocemente presença de trabalho de parto e em qual
               fase do parto se encontra;</b></li>
             <li>Na entrevista (SAMPLA), o socorrista deve incluir:</li>
              
                          <ul>
             <li>Confirmar se foi feito o pré natal;</li>
             <li>Idade gestacional e/ou data provável do parto;</li>
             <li>Paridade (n° de filhos e tipos de parto);</li>
             <li>Perda vaginal atual (muco, sangue e líquido amniótico);</li>
             <li>Presença de contração uterina;</li>
             <li>Local onde fez o pré-natal e hospital definido para o parto;   </li>        
                                        </ul>
              
                           <li>Além das informações habituais, exame físico deve incluir:</li>
              
                          <ul>
             <li>Colocar a paciente em decúbito lateral esquerdo, repousar sua mão
sobre o ventre materno e realizar a contagem da frequência e duração
das contrações em 10 minutos;</li>
             <li>Perda vaginal atual (muco, sangue e líquido amniótico): sinais de parto
iminente ou até mesmo de complicação;</li>
             <li>Realizar a inspeção da vulva: abaulamento e coroamento, caso haja
indicação para tal.</li>
              </ul>
              
              <li><b>CONDUTA EM PARTO NÃO EXPULSIVO</b></li>
              
                          <ul>
             <li>Contrações uterinas efetivas, sem presença de partes fetais na vulva.</li>
                           <ul>
                                          <li>Colocar a paciente deitada para o lado esquerdo ou outra
posição mais confortável;</li>
                                         <li>Cobrir a parturiente evitando a exposição desnecessária;</li>
                                         <li>Fazer contato com a regulação médica e passar as informações
de forma sistematizada, informar data provável do parto, local onde foi feito o pré-natal e o hospital definido para o parto;</li>
                                         <li>Preparar para o transporte;</li>
                                         <li>Manter atenção para a evolução do parto.</li>
                            </ul>
             <li>Perda vaginal atual (muco, sangue e líquido amniótico): sinais de parto
iminente ou até mesmo de complicação;</li>
             <li>Realizar a inspeção da vulva: abaulamento e coroamento, caso haja
indicação para tal.</li>
              </ul>
              
              
              
              
                            <li><b>CONDUTA EM PARTO IMINENTE</b></li>
              
                          <ul>
             <li>Contrações efetivas (3 ou mais em 10 minutos, duração acima de 30
segundos cada);</li>
             <li>Presença de puxos;</li>
             <li>Pressão no períneo (gestante relata vontade de evacuar);</li>
             <li>Distensão perineal e/ou apresentação do feto na vulva.</li>
             <li><b>Os mesmos sinais e sintomas podem aparecer em gestantes com
menos de 22 semanas, tratando-se neste caso de ameaça de
               aborto.</b></li>
                            
                                                      <ul>
             <li>Contato com a regulação médica;</li>
             <li>Considerar a realização do parto em ambiente domiciliar ou na ambulância;</li>
             <li>Posicionar a paciente adotando a posição que ofereça maior conforto
e segurança à mãe e ao concepto</li>
             <li>Higienizar períneo com soro fisiológico (SF 0,9%);</li>
             <li>Trocar luvas de procedimento por luvas estéreis;</li>
             <li>Posicionar os campos sob os glúteos, sobre o abdome e pernas da
paciente;</li>
             <li>No coroamento: proteger o períneo com uma das mãos com ajuda de
uma compressa e controlar o desprendimento súbito do polo cefálico
com a outra mão;</li>
             <li>Avaliar a região do pescoço do RN para detectar a presença de
circular de cordão umbilical. Em caso de presença de circular:</li>
                                              <ul>
             <li>Na grande maioria dos casos, a circular será FROUXA, desfazer a
circular após o expulsão da cabeça;</li>
             <li>Em raros casos de circular de cordão TENSA, clampear o cordão
umbilical em dois pontos e cortá-lo com material estéril;</li>
                                                </ul>
                   <li>Acompanhar o desprendimento dos ombros;</li>
                   <li>Colocar o RN lateralizado sobre o abdome da mãe, cobrindo-o com o
campo, sem tracionar o cordão umbilical;    </li>                             
                   <li>Aguardar de 1 a 3 minutos para clampear o cordão ou quando cessar
a pulsação - clampeamento tardio. Exceto quando o RN não inicia a
respiração ou não apresenta bom tônus e movimentos ativos;
presença de sangramento vaginal excessivo durante o parto ou nó
verdadeiro do cordão; parturiente tem alguma doença de transmissão
vertical (HIV, sífilis - visto em cartão pré-natal) ou tem Rh sanguíneo
negativo e parto gemelar;</li>
                   <li>Clampear (1° clamp : 15 a 20 cm a partir do abdome do RN, 2° clamp
: 3 a 4 cm à frente do 1° clamp) e cortar o cordão umbilical;</li>
                   <li>Avaliar e pontuar a escala do apgar;</li>
                   <li>Realizar a assistência ao RN;</li>
                   <li>Anotar o nome da mãe e do RN, sexo do bebê, hora de nascimento e data;</li>
                   <li>Realizar novo contato com regulação médica;</li>
                   <li>Preparar para o transporte;</li>
                   <li>Se houver a dequitação, acondicionar a placenta em saco coletor
plástico, anotar nome da mãe, data, hora da dequitação e encaminhar
junto com a paciente;</li>
                   <li>Registrar achados, procedimentos, condições do parto e RN na ficha
de atendimento (Preencher uma ficha para a mãe e outra para o RN).      </li>
                                                        
                   <li>CUIDADOS COM A PUÉRPERA:</li>
                                                        <ol>
                   <li>Avaliação primária (ênfase aos sinais de choque circulatório);</li>
                   <li>Limpeza da região genital;</li>
                   <li>Colocar absorvente higiênico;</li>
                   <li>Massagear com cuidado o abdome para estimular a involução
uterina logo após o nascimento do RN.</li>
                     </ol>
                     
                     <li>ASSISTÊNCIA AO RN:</li>
                                                        <ol>
                   <li>Retirar o RN do abdome materno e colocá-lo sobre superfície plana;</li>
                   <li>Posicionar a cabeça em leve extensão, observando a respiração;</li>
                   <li>Aspirar boca e nariz (sonda no 8 ou 10), somente se tiver
secreção que impeça a respiração;</li>
                   <li>Secar o RN, e desprezar os campos úmidos;</li>
                   <li>Cobrir a cabeça do bebê com pano limpo ou touca;</li>
                   <li>Envolver em outro campo estéril limpo e seco;</li>
                   <li>Classificar o RN no 1° e 5° minutos após o nascimento de
acordo com o Escore de Apgar;</li>
                                                          <p>
                        <img src="assets/trabalhodeparto/escore.png" className="figura"/>
                                                          </p>
                   <li>Avaliar a temperatura axilar: normal entre 36,5 e 37,5°C;</li>
                   <li>Se a temperatura axilar estiver normal, envolver o RN em campo
estéril e manta metálica sobre o campo; se {'<'} 36,5°C, envolver o RN em
campo estéril, colocar sobre esse campo um cobertor e, sobre o
cobertor, a manta metálica; se {'>'} 37,5°C, envolver o RN somente em
campo estéril.</li>
                     </ol>
                                                          
                            </ul>
                            

              </ul>
              
                                           <li><b>REANIMAÇÃO NEONATAL</b> - Vide POP de Reanimação neonatal.</li>  
                            
                            <li><b>PARTO CONSUMADO</b></li>
                                                        <ol>
                   <li>Avaliação primária da parturiente e do RN (ênfase no padrão
respiratório, frequência cardíaca e sangramentos);</li>
                   <li>Avaliação secundária caso ambos estejam estáveis;</li>
                   <li>Seguir com os cuidados com a parturiente e com o RN de
acordo com os procedimentos do parto iminente e
assistência ao RN;</li>
                   <li>Se for possível calcular o Escore de Apgar;</li>
                   <li>Entrar em contato com a regulação médica;</li>
                   <li>Preparar para transporte.</li>
                     </ol>
              
              
</ul>
              </Accordion>
                              
                    <Accordion title="Fatores Complicadores">
            <ul>
              <li><b>PARTO COM APRESENTAÇÃO ANÔMALA (NÃO-CEFÁLICO)</b></li>
              <ol>
             <li>Avaliação primária e secundária;</li>
             <li>Informar a paciente e os familiares sobre a condição;</li>
             <li>Realizar contato com a regulação médica e receber orientações
sobre procedimentos e transporte da parturiente;</li>
             <li>Posicioná-la em decúbito lateral esquerdo ou POSIÇÃO
GENUPEITORAL (posição de prece);</li>
             <li>ATENTAR PARA OS SEGUINTES PRINCÍPIOS, CASO SEJA
ORIENTADO A AGUARDAR SERVIÇO AVANÇADO:</li>
                            <ul>
                              <li><b>Apresentação de ombros ou membro superior:</b> É improvável haver nascimento por via vaginal;</li>
                              <li><b>Apresentação pélvica:</b> é melhor assistida com a paciente na posição de
quatro apoios e o socorrista deve amparar a saída do
corpo fetal, sem tracionar;</li>
                              <li><b>Sinais da Distócia de Ombro:</b></li>
                                                          <ul>
                                           <li> Após 60 segundos da saída da cabeça, não ocorreu a
saída dos ombros;</li>
                                           <li> “Sinal da Tartaruga”: visualização da retração da cabeça
fetal contra o períneo materno durante contrações;</li>
                                           <li> Geralmente não acontece a rotação externa da cabeça
fetal e o queixo fica impactado no períneo dando a impressão de face fetal
com excesso de gordura;</li>
                                                                                        </ul>
                                          </ul>
                </ol>

                            <li><b>PROLAPSO DE CORDÃO UMBILICAL</b></li>
              <ul>
             <li>Situação em que o feto não nasceu, porém o cordão umbilical
se exteriorizou pelo canal de parto.</li>
             <li>Seja ágil: a criança pode estar em perigo, causado pela
compressão do cordão entre a cabeça e o canal de parto.
Enquanto o cordão estiver comprimido, a criança não
receberá quantidades adequadas de sangue e oxigênio. No
caso de prolapso do cordão, transporte a mãe em decúbito
dorsal, com os quadris elevados sobre dois ou três
travesseiros ou cobertores dobrados, e administre oxigênio.
Isso fará com que a criança escorregue um pouco para
dentro do útero e receba mais oxigênio. Se a mãe puder
manter a posição genupeitoral (ajudada pelo socorrista), o
resultado será ainda melhor. Essa posição é difícil de ser
mantida durante o transporte.
</li>
                </ul>
              
                                          <li><b>PRÉ ECLÂMPSIA E ECLÂMPSIA</b></li>
              <ul>
                <li>PA sistólica {'≥'}140 mmHg e/ou pressão arterial diastólica {'≥'} 90 mmHg,
mantida persistentemente após a 20ª semana de gestação, sem histórico
prévio de hipertensão arterial sistêmica.</li>
                <li>Sintomas da pré-eclâmpsia:</li>
              <ul>
                <li>Cefaleia intensa;</li>
                <li>Distúrbios visuais;</li>
                <li>Dor na região epigástrica e/ou dor torácica;</li>
                <li>Náuseas e vômitos;</li>
                <li>Sangramento vaginal.</li>
             
                              </ul>
                <li>Eclâmpsia: sintomas da pré-eclâmpsia + crises convulsivas ou
coma.</li>
                <ol>
                <li>Realizar avaliação primário, priorizando a ventilação e
circulação;</li>
                <li>Oferecer O2 sob máscara com reservatório, se saturação abaixo
de 95%;</li>
                <li>Avaliar se já tem histórico de aumento de pressão e/ou
pré-eclâmpsia/eclâmpsia, pesquisar se houve perda de
consciência ou crise epiléptica;</li>
                <li>Avaliação secundária (sinais vitais);</li>
                <li>Entrar em contato com a Central de Regulação, passando as
informações e preparar a paciente para transporte;</li>
                <li>Posicioná-la em decúbito lateral esquerdo;</li>
                <li>Se crise epiléptica, manter a lateralização à esquerda e ofertar
os cuidados de acordo com o capítulo de crise epiléptica do
Manual de APH.</li>
                  </ol>
                </ul>
              
                                                    <li><b>HEMORRAGIA GESTACIONAL</b></li>
              <ul>
                <li>Sangramento {'<'} 22 semanas de gestação: suspeitar de
abortamento</li>
                <li>Sintomas:</li>
                              <ul>
                <li>Perda sanguínea vaginal;</li>
                <li>Dor pélvica;</li>
                <li>Sinais de choque.</li>
                                              </ul>
                                <li>Conduta do socorrista:</li>
                              <ol>
<li>Avaliação primária e entrevista da paciente (História de trauma?
Quantidade e coloração de sangue perdido? Tem contrações?
Fez uso de algum medicamento abortivo ou procedimento com
esse objetivo?);</li>
<li>Realizar avaliação secundária;</li>
<li>Todo material ensanguentado deverá ser acondicionado e
transportado para a unidade hospitalar;</li>
<li>Entrar em contato com a regulação médica, e viabilizar
transporte da paciente;</li>
<li>Colocar a paciente em decúbito lateral esquerdo, mantendo
aquecimento;</li>
                                          </ol>
                <ul>
                  <li><b>Estar atento aos sinais de trabalho de parto!</b></li>
                </ul>

                            </ul>
              
                                                                  <li><b>HEMORRAGIA PUERPERAL</b></li>
              <ul>
                <li><b>A causa mais comum é a atonia/ hipotonia uterina no pós parto.</b></li>
                <li> Conduta do socorrista:</li>
                <ol>
                <li>Realizar o exame primário (sinais de choque circulatório);</li>
                <li>Ofertar oxigênio de acordo com a saturação apresentada da
paciente;</li>
                <li>Aquecer a puérpera;</li>
                <li>Colocar lençol limpo para estimar a perda de sangue;</li>
                <li>Verificar os sinais vitais e anotar todos os dados apresentados;</li>
                <li>Entrar em contato com a Regulação Médica, seguir orientações
para transporte;</li>
                <li>Avaliação continuada da paciente durante todo o transporte.</li>
                </ol>
                              </ul>

              
</ul>
              </Accordion>
          
                                                  <Accordion title="Observações">
            <ul>
             <li>A primeira hora após o parto é o período em que há maior risco de
hemorragia na parturiente, sendo a HEMORRAGIA PÓS-PARTO a
maior causa de mortalidade materna no mundo desenvolvido.</li>
             <li>As principais complicações são: hemorragia excessiva,
apresentação anômala (pélvica ou membros, de ombro), prolapso
de cordão e distócia de ombros (quando apenas a cabeça fetal
saiu e os ombros ficam presos).
</li>
             <li><b>Não atrasar a regulação e transporte para realizar Avaliação
               Secundária nos casos anteriores!</b></li>
             <li>A infusão de fluidos deve ser feita de maneira cautelosa. A não ser
que a situação exija a permanência no local, não se justifica retardar
o transporte ao hospital a fim de se obter o acesso venoso
periférico. Não se recomenda a infusão agressiva de líquidos, já
que tal conduta tem sido associada a consequências deletérias para
a paciente, tais como: hemodiluição, diminuição dos fatores de
coagulação, coagulopatias e rompimento de coágulos ativos.
Considere a manutenção da pressão arterial sistólica de 90 mmHg
em pacientes hipotensas.
</li>
             <li>O Ringer Lactato é a solução de primeira escolha devido à sua
composição ser mais semelhante ao plasma e servir como solução
tampão, desejável na acidose metabólica. Em sua falta, o socorrista
deve optar pela Solução Fisiológica 0,9%.
</li>
             <li>Atentar para a segurança do neonato para o caso de posições
diferentes da ginecológica.</li>
</ul>
              </Accordion>
          
                                                            <Accordion title="Glossário">
            <ul>
              <li><b>Puxos:</b> vontade incontrolável de fazer força.</li>
              <li><b>Puérpera:</b> mulher que deu à luz há pouco tempo (até 42 dias pós parto).</li>
              <li><b>Coroamento:</b> a abertura vaginal ficará abaulada e o pólo cefálico da criança
poderá ser visto. Último sinal antes que a cabeça e o resto da criança
nasçam.</li>
</ul>
              </Accordion>
          
                                                                      <Accordion title="Base legal e referencial">
            <ul>
              <li>BRASIL. Ministério da Saúde. Secretaria de Ciências, Tecnologia e Insumos
- Estratégicos. Departamento de Gestão e Incorporação de Tecnologias em
Saúde. Diretrizes Nacionais de Assistência ao Parto Normal. Brasília: Editora
do Ministério da Saúde; 2017.</li>
              <li>CBMDF. Manual de Atendimento Pré-Hospitalar. 2022.</li>
              <li>Tratado de Obstetrícia Febrasgo / Editores César Eduardo Fernandes,
Marcos Felipe Silva de Sá ; Coordenação Corintio Mariani Neto. - 1. ed. - Rio
de Janeiro: Elsevier, 2019.</li>
              <li>Sobieray NLEC, Souza BM. Prevalência de episiotomia e complicações
perineais quando da sua realização ou não em uma maternidade de baixo
risco do complexo HC/UFPR. Arq Med Hosp Fac Cienc Med Santa Casa São
Paulo, São Paulo. 2019 Mai/Ago; 64(2): 93-9.</li>
              <li>Conheça as posições para exames, Enfermagem Florence, 2019.
Disponível em:
https://enfermagemflorence.com.br/conheca-as-posicoes-para-exames/ Acesso em
19 de maio de 2021.</li>
              <li>Emergências Obstétricas e Trauma na Gestante, Manual de Atendimento
Pré-Hospitalar-SIATE/CBPR, 19: 253-65.</li>

</ul>
              </Accordion>

  </div>
</IonContent>
</IonPage>

  );
}

export default Trabalhodeparto;
