import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const Pneumotorax: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Pneumotorax (simples, aberto e fechado)</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://drive.google.com/file/d/17r0pccJ4UWcGY2eHNfSi-xdMmrVqAE8Q/view?usp=share_link">POP Pneumotórax (BG 237 de 17/12/2015)</a>
            </p>

</ul>
              </Accordion>
          
          <Accordion title="Procedimentos">
            <ul>
              <li>Avaliar a cena</li>
              <li>Gerenciar riscos na cena de emergência;</li>
              <li>Avaliar a biomecânica envolvida;</li>
              <li>Manter via aérea pérvia com manobra para trauma. Estabilizar manualmente a coluna cervical;</li>
              <li>Mensurar e aplicar colar cervical;</li>
              <li>Avaliar a qualidade da respiração;</li>
              <li>Inspecionar o tórax. Em caso se ferimento aberto, cobri-lo com curativo oclusivo de 3 pontos (realizado com material plástico ou aluminizado e fixado em apenas 3 pontos);</li>
              <li>Monitorizar a oximetria de pulso.</li>
              <li>Iniciar oxigenoterapia (10 – 15 L/min) se %SpO2 {'<'} 95%, ou se {'>'} 95%, mas com sinais de dificuldade respiratória (2 – 5 L/min).</li>
              <li>Considerar ventilação com BVM se frequência respiratória {'<'} 12 rpm ou {'>'} 30 rpm;</li>
              <li>Controlar hemorragias;</li>
              <li>Realizar acesso venoso;</li>
              <li>Realizar, se necessário, a reanimação volêmica;</li>
              <li>Realizar imobilização em prancha longa rígida;</li>
              <li>Promover controle da temperatura corporal por meio da utilização de lençóis, mantas aluminizadas e/ou controle da temperatura do salão de atendimento da viatura;</li>
              <li>Realizar avaliação secundária;</li>
              <li>Exame físico detalhado;</li>
              <li>Sinais vitais;</li>
              <li>SAMPLA;</li>
              <li>Encaminhar ao hospital de referência</li>

              <h3>Observações</h3>
              <li>A infusão de fluidos deve ser feita de maneira cautelosa. A não ser que a situação exija a permanência no local, não se justifica retardar o transporte ao hospital a fim de se obter o acesso venoso periférico. Não se recomenda a infusão agressiva de líquidos, já que tal conduta tem sido associada à consequências deletérias para o paciente, tais como: hemodiluição, diminuição dos fatores de coagulação, coagulopatias e rompimento de coágulos ativos. Considere manutenção da pressão arterial sistólica {'>'} 90 mmHg em pacientes hipotensos.</li>
              <li>O Ringer Lactato é a solução de primeira escolha devido à sua composição ser mais semelhante ao plasma e servir como solução tampão, desejável na acidose metabólica. Em sua falta, o socorrista deve optar pela Solução Fisiológica 0,9%.</li>
              <li>A imobilização de fraturas na cena de emergência só deve ser considerada em condições clínica estáveis.</li>
              <li>Faça contato com a central de regulação médica e considere o acionamento de Suporte Avançado de Vida.</li>
            </ul>
          </Accordion>

          <Accordion title="Resultados Esperados">
            <ul>
              <li>Aumento da sobrevida;</li>
              <li>Minimizar o agravamento do quadro clínico</li>
            </ul>
          </Accordion>

          <Accordion title="Material recomendado">
            <ul>
              <li>Colar cervical;</li>
              <li>Prancha longa;</li>
              <li>Tirantes;</li>
              <li>Gazes;</li>
              <li>Ataduras;</li>
              <li>Compressas cirúrgicas;</li>
              <li>Manta aluminizadas</li>
              <li>Lençóis;</li>
              <li>Esfigmomanômetro;</li>
              <li>Estetoscópio;</li>
              <li>Oxímetro de pulso;</li>
              <li>Termômetro;</li>
              <li>Conjunto de oxigenoterapia com fluxômetro;</li>
              <li>Jelco no 14 ou 16;</li>
              <li>Equipo simples.</li>
              <li>Ringer Lactato ou Solução Fisiológica 0,9%– 2 frascos de 1000 ml cada ou 4 de 500 ml.</li>
            </ul>
          </Accordion>

          <Accordion title="Sinais e sintomas">
            <ul>
              <h4><b>Simples</b></h4>
              <li>Dor torácica característica (pleurítica);</li>
              <li>Hematoma torácico;</li>
              <li>Deformidade torácica;</li>
              <li>Dificuldade respiratória;</li>
              <li>Cianose</li>
              <li>Diminuição murmúrio vesicular</li>
              <li>Timpanismo torácico à percussão no lado da lesão</li>

              <h4><b>Aberto</b></h4>
              Acrescente-se:
              <li>Ferimento aberto pelo qual se ouve ruídos provocados pela aspiração de ar e borbulhamento.</li>

              <h4><b>Hipertensivo</b></h4>
              Acrescente-se:
              <li>Sofrimento respiratório;</li>
              <li>Desvio da traqueia em direção contrária ao lado da lesão;</li>
              <li>Distenção de veias jugulares externas;</li>
              <li>Sinais de choque.</li>

            </ul>
          </Accordion>

          <Accordion title="Possibilidades de erro">
            <ul>
              <li>Subestimar o quadro clínico;</li>
              <li>Não identificar sinais de choque;</li>
              <li>Retardar o transporte</li>
            </ul>
          </Accordion>

          <Accordion title="Fatores Complicadores">
            <ul>
              <li>Segurança da cena;</li>
              <li>Sinais de choque;</li>
              <li>Conflitos institucionais.</li>
            </ul>
          </Accordion>

          <Accordion title="Glossário">
            <ul>
              <li><b>Pneumotórax:</b> presença de ar na cavidade torácica. Caso continue o acúmulo esse ar aumentará a pressão no meio e provocará o pneumotórax hipertensivo;</li>
              <li><b>Dor pleurítica:</b> dor característica descrita como em pontada, lancinante e que piora com respiração profunda e tosse.</li>
              <li><b>Murmúrio vesicular:</b> sons respiratórios normais. No exame físico são normalmente auscultados com o auxílio de estetoscópio.</li>
              <li><b>Timpanismo:</b> som timpânico produzido ao se realizar técnica de percussão contra a parede torácica.</li>
              <li><b>Oximetria:</b> procedimento que visa medir a concentração de oxigênio no sangue.</li>
            </ul>
          </Accordion>

          <Accordion title="Referencial bibliográfico">
            <ul>
              <li>Atendimento Pré-Hospitalar ao Traumatizado – PHTLS (NAEMT). 7a ed. Rio de Janeiro: Elsevier, 2014.</li>
              <li>Brasil. Ministério da Saúde. Secretaria de Atenção à Saúde. Protocolos de intervenção para o SAMU 192 – Serviço de Atendimento Móvel de Urgência. Brasília: Ministério da Saúde, 2014.</li>
              <li>Eduardo OR, Félix VM, Silva AGB. Protocolo de atendimento pré-hospitalar CBMDF. Brasília: CBMDF, 2003.</li>
              <li>Rasia CA, Barros CC, Marcelino SC, Fernandes RWC, Pontes FC, Pedroso, GB, et al. Manual de atendimento pré-hospitalar. Brasília: Corpo de Bombeiros Militar do Distrito Federal, 2007.</li>
              <li>Sharma A, Jindal P. Principles of diagnosis and management of traumatic pneumothorax. Journal of Emergencies, Trauma and Shock. 2008; 1(1).</li>
            </ul>
          </Accordion>

    </div>
  </IonContent>
</IonPage>

  );
}

export default Pneumotorax;
