import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const Hemotorax: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Hemotórax</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
           <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://drive.google.com/file/d/1F4iEgb6eZgjqQ-J6DT4he_twUtd8PGq4/view?usp=share_link">POP Hemotórax (BG 237 de 17/12/2015)</a>
            </p>

</ul>
              </Accordion>
          
              <Accordion title="Procedimentos">
                <ul>
                  <li>Avaliar a cena;</li>
                  <li>Gerenciar riscos na cena de emergência;</li>
                  <li>Avaliar a biomecânica envolvida;</li>
                  <li>Manter via aérea pérvia com manobra para trauma.</li>
                  <li>Estabilizar manualmente a coluna cervical;</li>
                  <li>Mensurar e aplicar colar cervical;</li>
                  <li>Avaliar a qualidade da respiração;</li>
                  <li>Inspecionar o tórax;</li>
                  <li>Monitorizar a oximetria de pulso.</li>
                  <li>Iniciar oxigenoterapia (10 – 15 L/min) se %SpO2 {'<'} 95%, ou se {'>'} 95%, mas com sinais de dificuldade respiratória (2 – 5 L/min).</li>
                  <li>Considerar ventilação com BVM se frequência respiratória {'<'} 12 rpm ou {'>'} 30 rpm;</li>
                  <li>Controlar hemorragias;</li>
                  <li>Realizar acesso venoso;</li>
                  <li>Realizar reanimação volêmica, se necessário. Ficar atento aos sinais de choque;</li>
                  <li>Promover controle da temperatura corporal por meio da utilização de lençóis, mantas aluminizadas e/ou controle da temperatura do salão de atendimento da viatura;</li>
                  <li>Imobilizar em prancha longa;</li>
                  <li>Realizar avaliação secundária;</li>
                  <li>Realizar exame físico detalhado (atenção para resposta e simetria pupilar);</li>
                  <li>Sinais Vitais;</li>
                  <li>SAMPLA;</li>
                  <li>Encaminhar ao hospital de referência.</li>
                  
                  <h3>Observações</h3>
                  <li>Fazer contato com a regulação médica e informar a situação.</li>
                  <li>A resolução do caso é cirúrgica. Por isso o transporte rápido deve ser priorizado.</li>
                </ul>
              </Accordion>

          <Accordion title="Resultados Esperados">
            <ul>
              <li>Aumento da sobrevida;</li>
              <li>Prevenção do choque circulatório</li>
            </ul>
          </Accordion>

          <Accordion title="Material recomendado">
            <ul>
              <li>Colar cervical;</li>
              <li>Prancha longa;</li>
              <li>Tirantes;</li>
              <li>Gazes;</li>
              <li>Ataduras;</li>
              <li>Compressas cirúrgicas;</li>
              <li>Manta aluminizada</li>
              <li>Lençóis;</li>
              <li>Esfigmomanômetro;</li>
              <li>Estetoscópio;</li>
              <li>Oxímetro de pulso;</li>
              <li>|Termômetro;</li>
              <li>Conjunto de oxigenoterapia com fluxômetro;</li>
              <li>Jelco no 14 ou 16;</li>
              <li>Equipo simples.</li>
              <li>Ringer Lactato ou Solução Fisiológica 0,9%– 2 frascos de 1000 ml cada ou 4 de 500 ml.</li>
            </ul>
          </Accordion>

          <Accordion title="Sinais e sintomas">
            <ul>
              <li>Dor torácica,</li>
              <li>Deformidade torácica;</li>
              <li>Dispneia;</li>
              <li>Sinais acentuados de choque;</li>
              <li>Diminuição ou ausência de murmúrios vesiculares;</li>
              <li>Som maciço à percussão torácica.</li>
            </ul>
          </Accordion>

          <Accordion title="Possibilidades de erro">
            <ul>
              <li>Não reconhecimento de sinais e sintomas;</li>
              <li>Não realizar a prevenção do choque</li>
              <li>Retardar o transporte</li>
            </ul>
          </Accordion>

          <Accordion title="Fatores Complicadores">
            <ul>
              <li>Segurança da cena;</li>
              <li>Choque circulatório associado;</li>
              <li>Inabilidade no reconhecimento de sinais e sintomas.</li>
            </ul>
          </Accordion>

          <Accordion title="Glossário">
            <ul>
              <li><b>Hemotórax:</b> sangue acumulado na cavidade torácica;</li>
              <li><b>Murmúrio vesicular:</b> sons respiratórios normais. No exame físico são normalmente auscultados com o auxílio de estetoscópio.</li>
              <li><b>Oximetria:</b> procedimento que visa medir a concentração de oxigênio no sangue.</li>
            </ul>
          </Accordion>

          <Accordion title="Referencial bibliográfico">
            <ul>
              <li>Atendimento Pré-Hospitalar ao Traumatizado – PHTLS (NAEMT). 7a ed. Rio de Janeiro: Elsevier, 2014.</li>
              <li>Brasil. Ministério da Saúde. Secretaria de Atenção à Saúde. Protocolos de intervenção para o SAMU 192 – Serviço de Atendimento Móvel de Urgência. Brasília: Ministério da Saúde, 2014.</li>
            </ul>
          </Accordion>

    </div>
  </IonContent>
</IonPage>

  );
}

export default Hemotorax;
