import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const EmergenciaSaudeMental: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Emergências em saúde mental</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://www.cbm.df.gov.br/download/pop-emergencias-em-saude-mental-bg-172-de-2022/?tmstv=1667925370">POP Emergências em Saúde Mental (BG 172 de 15/09/2022)</a>
            </p>

</ul>
              </Accordion>
          
          <Accordion title="Procedimentos">
            <ul>
              <li>Avaliar a segurança da cena e gerenciar riscos</li>
              <li>Aproximar-se do local com sinais luminosos e sonoros desligados;</li>
              <li>Informar-se sobre os motivos de seu comportamento (com o paciente ou familiares);</li>
              <li>Não confrontar a realidade do paciente. Não tentar trazê-lo para a nossa realidade.</li>
              <li>Definir um mediador. Ele será o responsável pela comunicação com o paciente. Ficar atento para a possibilidade de o próprio paciente escolher o mediador por meio de manifestação verbal explícita ou linguagem não verbal;</li>
              <li>Substituir o mediador, caso julgue necessário;</li>
              <li>Identificar-se e informar o motivo de estar ali. Oferecer ajuda.</li>
              <li>Ser receptivo ao paciente.</li>
              <li>Estabelecer comunicação de maneira clara, tranquila e serena. Ficar atento à linguagem</li>
              <li>rbal e não verbal do paciente;</li>
              <li>Identificar eventos ou relações conflituosas que possam ter desencadeado a crise.</li>
              <li>Negociar formas de resolução de conflitos;</li>
              <li>Considerar: acolher familiares e pessoas envolvidas; limites da mediação.</li>
              <li>Identificar objetos ou artefatos que podem ser utilizados para agressão ou autoagressão.</li>
              <li>Acionar apoio policial via CIADE em casos que envolvam utilização de armas (de fogo ou branca).</li>

              <h3>Contenção física</h3>
              <li>Iniciar a técnica quando o número mínimo de profissionais exigidos estiver presente (5 bombeiros);</li>
              <li>• Explicar aos familiares a decisão;</li>
              <li>• Realizar o controle do espaço. Posicionar a equipe em semicírculo em relação ao paciente com o mediador ao meio.</li>
              <li>Decidir previamente uma palavra chave que dará início à ação. Realizar o comando sem alterar o tom de voz;</li>

              <ul>
                <h4>Descrição da técnica de contenção física</h4>
                <li>Dois bombeiros avançarão em direção aos membros superiores. Cada um segurará um dos punhos com as duas mãos e posicionará a articulação do cotovelo do paciente embaixo de sua axila, prendendo-o sob seu tórax;</li>
                <li>Outros dois bombeiros avançarão em direção aos membros inferiores. Com o braço mais próximo o bombeiro envolverá a região posterior da coxa do paciente com a mão na região patelar. Com o outro braço estendido segurar o tornozelo contra o chão.</li>
                <li>Após a imobilização dos membros, o mediador se posiciona atrás do paciente e imobiliza cabeça e tórax. Um dos braços do mediador é direcionado por baixo da axila do paciente e fixado sob o tórax. A palma da outra mão é posicionada sobre a fronte do paciente;</li>
                <li>Durante a contenção física, o paciente deverá ser mantido próximo ou sobre o solo para se evitar o risco de quedas.</li>

                <h4>Descrição da técnica de contenção mecânica</h4>
                <li>Imobilizar na maca/prancha em posição anatômica;</li>
                <li>Membros inferiores – mantidos afastados com fixação de joelhos e tornozelos;</li>
                <li>Membros superiores – imobilizados ao longo do corpo com a palma da mão para cima.</li>
                <li>Fixação dos punhos;</li>
                <li>O mediador é o responsável pela passagem das ataduras de amarração;</li>
                <li>Introduzir a atadura por baixo das articulações e avançar até punhos e tornozelos. O nó deve ser confeccionado na parte anterior. Utilize nó apropriado, observada a integridade física do paciente;</li>
                <li>Tórax – é a última atadura a ser posicionada. Nos homens deve ficar na altura dos mamilos e em mulheres abaixo das mamas. Evitar compressão do tórax e posicionamento sobre o diafragma para não prejudicar a ventilação. A elevação natural do tronco não deve ultrapassar 30°.</li>
              </ul>

              <h4>Observações</h4>
              <li>As contenções físicas e mecânicas não deverão ser utilizadas como punição ou medida disciplinar;</li>
              <li>Em hipótese alguma o paciente deverá ser contido por meio da utilização cordas tipo “cabos da vida” ou similares;</li>
              <li>Quando no hospital, a contenção física só deverá ser retira mediante solicitação do médico responsável.</li>
              <li>A CIADE sempre deverá considerar o deslocamento de viatura de salvamento para apoio à Unidade de Resgate.</li>
              <li>Conforme Portaria no 185/2012 – Secretaria de Saúde do DF, o paciente deverá ser encaminhando para a clínica médica ou pediátrica das emergências dos hospitais regionais do Distrito Federal.</li>
            </ul>
          </Accordion>


          <Accordion title="Resultados Esperados"><ul>
              <li>Preservar integridade física do paciente, equipe profissional e terceiros;</li>
              <li>Intervenções pautadas pela humanização do atendimento;</li>
              <li>Minimizar conflitos;</li>
            </ul>
          </Accordion>

          <Accordion title="Material recomendado">
            <ul>
              <li>Ataduras;</li>
              <li>Gazes;</li>
              <li>Compressas cirúrgicas;</li>
              <li>Equipamentos de proteção individual.</li>
            </ul>
          </Accordion>

          <Accordion title="Sinais e sintomas">
            <ul>
              <h4>Sugestivos de Crise</h4>
              <li>Agitação;</li>
              <li>Relatos de desejo de morrer;</li>
              <li>Medo;</li>
              <li>Choro;</li>
              <li>Tristeza;</li>
              <li>Insegurança;</li>
              <li>Alteração na percepção da realidade (delírios e alucinações);</li>

              <h4>Sugestivos de violência potencial</h4>
              <li>Hiperatividade;</li>
              <li>Angústia;</li>
              <li>Inquietude;</li>
              <li>Irritabilidade;</li>
              <li>Atitudes hostis.</li>
            </ul>
          </Accordion>

          <Accordion title="Possibilidades de erro">
            <ul>
              <li>Negligenciar a segurança;</li>
              <li>Uso de força desproporcional em situações que necessitem de contenção física e mecânica;</li>
              <li>Subestimar o potencial de violência do paciente.</li>
            </ul>
          </Accordion>

          <Accordion title="Fatores Complicadores">
            <ul>
              <li>Conflitos familiares;</li>
              <li>Paciente violento;</li>
              <li>Preconceitos em relação ao agravo mental.</li>
            </ul>
          </Accordion>

          <Accordion title="Referencial bibliográfico">
            <ul>
              <li>Sander J. A caixa de ferramentas de Michel Foucault, a reforma psiquiátrica e os desafios contemporâneos. Psicologia e Sociedade. 2010; 22(2): 382-387.</li>
              <li>Kondo H, Vilella, JC, Borba, LO, Paes, MR, Maftum MA. Abordagem da equipe de enfermagem ao usuário na emergência em saúde mental em um pronto atendimento. Revista da Escola de Enfermagem da USP. 2011; 45(2): 501-7.</li>
              <li>Dutra VFD, Rocha RM. O processo de desinstitucionalização psiquiátrica: subsídios para o cuidado integral. Revista de Enfermagem da UERJ. 2011; 19(3): 386-91.</li>
              <li>Brasil. Ministério da Saúde. Secretaria de Atenção à Saúde. DAPE. Coordenação Geral de Saúde Mental. Reforma psiquiátrica e política de saúde mental no Brasil. Documento apresentado à Conferência Regional de Reforma dos Serviços de Saúde Mental: 15 anos depois de Caracas. OPAS.</li>
              <li>Brasília, 2005.</li>
              <li>Pitta AMF. Um balanço da Reforma Psiquiátrica Brasileira: instituições, atores e políticas. Ciências e Saúde Coletiva. 2011; 16(12): 4579-89.</li>
              <li>Brasil. Ministério da Saúde. Portaria GM/MS n.o 336, de 19 de fevereiro de 2002.</li>
              <li>Almeida AB, Nascimento ERP, Rodrigues J, Schweitzer G. Intervenção nas situações de crise psíquica: dificuldades e sugestões de uma equipe de atenção pré-hospitalar. Revista Brasileira de Enfermagem. 2014; 67(5):708-14.</li>
              <li>Brasil. Distrito Federal. Secretaria de Estado de Saúde do Distrito Federal. Institui as normas para atenção às emergências em Saúde Mental no âmbito do SUS-DF. Portaria no 185, de 12 de setembro de 2012, Brasília, 2012.</li>
              <li>Brasil. Ministério da Saúde. Secretaria de Atenção à Saúde. Protocolos de intervenção para o SAMU 192 – Serviço de Atendimento Móvel de Urgência. Brasília: Ministério da Saúde, 2014.</li>
            </ul>
          </Accordion>

        </div>
      </IonContent>
    </IonPage>

  );
}

export default EmergenciaSaudeMental;
