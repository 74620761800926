import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const MulheresVitimasViolencia: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Atendimento a mulheres vítimas de violência</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://www.cbm.df.gov.br/download/atendimento-a-mulheres-vitimas-de-violencia-bg-45-09-03-2021/?tmstv=1667928800">POP Atendimento a Mulheres Vítimas de Violência (BG 045 de 09/03/2021)</a>
            </p>

</ul>
              </Accordion>
          
          <Accordion title="Procedimentos">
            <ul>
              <h4>AVISO:</h4>
              <li>
                Certificar-se com o despachante operacional que a cena está segura e se a PM encontra-se no local. Se não estiver, solicitá-la.
              </li>

              <h4>DESLOCAMENTO:</h4>
              <li>
                Durante o deslocamento solicitar ao COCB complementação das informações sobre o evento;
              </li>
              <li>
                Definir militar responsável pelo primeiro contato com a vítima (caso haja uma <b>mulher</b> na guarnição, <b>dar preferência a ela</b>).
              </li>

              <h4>CHEGADA AO LOCAL:</h4>
              <li>
                Avaliar a <b>segurança da cena</b> e gerenciar riscos;
              </li>
              <li>
                Averiguar a presença do agressor no local e se ele está em posse de algum tipo de armamento (arma branca, de fogo ou qualquer outro objeto que possa oferecer risco à guarnição e/ou a terceiros);
              </li>
              <li>
                Identificar a necessidade de recursos adicionais;
              </li>
              <li>
                Informar ao COCB quando da chegada ao local do evento e fazer um relato prévio do evento;
              </li>

              <h4>ATENDIMENTO:</h4>
              <li>
                <b>Paciente consciente e estável:</b> Abordar a vítima e questionar o mecanismo de agressão para direcionamento do atendimento, devendo obter relato diretamente da vítima. Caso a vítima não esteja em condições emocionais/psicológicas, a guarnição poderá colher informações referentes ao mecanismo do trauma junto a terceiros que tenham presenciado o fato.
              </li>
              <li>
                <b>Paciente inconsciente ou instável:</b> Realizar a avaliação do paciente e regulação médica de acordo com o estado do paciente e decisão do médico regulador.
              </li>

              <h4><em>Em caso de paciente consciente e estável:</em></h4>
              <li>
                Garantir, se possível, que o atendimento da vítima seja realizado sem a presença do agressor no local para evitar intimidações ou novas agressões (ele deve ser afastado, preferencialmente, pela PMDF)
              </li>
              <li>
                Identificar-se e oferecer ajuda</li>
              <li>
                Estabelecer comunicação de maneira clara e tranquila</li>
              <li>
                Estabelecer relação de confiança com a vítima</li>
              <li>
                Não expor a vítima, tanto fisicamente quanto emocionalmente</li>
              <li>
                Não emitir juízo de valor</li>
              <li>
                Orientar à vítima que <b>não jogue fora</b> o vestuário e <b>não tome banho</b> a fim de preservar possíveis provas de crime</li>
              <li>
                Realizar somente perguntas necessárias ao tratamento pré-hospitalar</li>
              <li>
                Proceder com o APH de acordo com as lesões identificadas</li>
              <li>
                Não infantilizar a vítima (não a tratar com pena). Exemplo de frases a não serem utilizadas: <em>- Nossa, que horror! - Tadinha de você;</em> dentre outras com mesmo teor ou ideia</li>
              <li>
                Ficar atento à linguagem verbal e não verbal da vítima (algumas vezes ela pode estar escondendo algum ferimento)</li>
              <li>
                <b>Evitar contato físico</b> com a vítima, como abraço, aperto de mão, entre outros (fazê-lo apenas se for de iniciativa dela)</li>
              <li>
                Garantir o atendimento não-revitimizador. Repassar todas as informações coletadas às autoridades policiais para que essas possam tomar as medidas necessárias, conforme fluxo de sua Instituição. Também com o objetivo de <b>evitar</b> que a vítima <b>repita</b> toda a história</li>
              <li>
                A abordagem ao possível agressor deverá ser realizada <b>somente</b> pela PMDF ou autoridade policial no local, exceto no caso de o agressor necessitar de atendimento pré-hospitalar. Se possível, coletar os dados do agressor com a PMDF</li>
              <li>
                Certificar-se se existem crianças (filhos) na cena. Se existirem, repassar imediatamente a informação sobre a existência de menores na cena ao COCB e solicitar o apoio de <b>órgãos competentes</b> como: PMDF, CONSELHO TUTELAR ou POLÍCIA CIVIL por meio da DPCA (Delegacia de Proteção à Criança ou Adolescente).</li>

              <h4><em>Orientações a serem repassadas:</em></h4>
            <li>
              Orientar a mulher sobre o fato dela <b>poder/dever</b> fazer o registro de ocorrência policial e requerer a concessão de <b>medidas protetivas</b> de urgência diretamente à autoridade policial, que se encarregará de solicitar ao Poder Judiciário a concessão de tais medidas, caso sejam necessárias <b>(a ausência do registro de ocorrência policial em nada auxilia para a quebra do ciclo de violência)</b></li>
            <li>
              Informar a vítima sobre seus <b>direitos</b> (listados no Apêndice)</li>
            <li>
              Informar a vítima sobre os <b>órgãos da rede integrada</b> de atendimento aos quais ela tem acesso (listados no Apêndice).</li>
          </ul>
          </Accordion>

          <Accordion title="Transporte, comunicação e regresso">
            <ul>
              <h4>REGULAÇÃO MÉDICA:</h4>
              <li>Relatar os fatos ao médico regulador <b>sem</b> a presença da vítima e de forma <b>sigilosa</b>.</li>
              <li>Preencher a Ficha de Notificação Compulsória de caráter OBRIGATÓRIO. OBS: As Fichas de Notificação Compulsória deverão ficar disponível em pasta nas viaturas de APH.</li>

              <h4>TRANSPORTE AO HOSPITAL:</h4>
              <li>Dar suporte emocional contínuo e atencioso</li>
              <li>Ao chegar ao hospital, repassar ao médico a informação de possível vítima de violência doméstica, para que toda a rede de apoio possa ser acionada, a partir de então. Contar os fatos <b>sem</b> a presença da vítima para que ela não tenha que repetir a história novamente.</li>

              <h4>CHEGADA NA OBM:</h4>
              <li>Encaminhar a Ficha de Notificação Compulsória e a Ficha de Atendimento de Ocorrência para a Secretaria do GBM ao qual pertencer a viatura tipo UR ou URSB em até 24hs após o atendimento da ocorrência. A Secretaria da OBM deverá encaminhá-las ao Núcleo de Vigilância Epidemiológica do Complexo Regulador (NVEP/CRDF), digitalizadas, através do e-mail notificasamudf@gmail.com, o mais rápido possível. As fichas físicas deverão ser encaminhadas a SUCOE/GAEPH para arquivamento.
              </li>
            </ul>
          </Accordion>


          <Accordion title="Resultados Esperados">
            <ul>
              <li>
                Atendimento de qualidade e não re-vitimizador às mulheres que são vítimas de violência doméstica;
              </li>
              <li>
                Conhecimento pleno, por parte da paciente, da rede de atendimento na qual pode ser inserida;
              </li>
              <li>
                Preservação da integridade física e psicológica da paciente, da equipe profissional e de terceiros.
              </li>
            </ul>
          </Accordion>

          <Accordion title="Material recomendado">
            <ul>
              <li>
                <b>Viatura:</b> UR, URSB, MR ou outra que estiver disponível (ASE ou ABSL), com apoio da VTR de Salvamento.
              </li>
              <li>
                <b>Materiais:</b> todos os utilizados para o pronto atendimento da UR, URSB, MR ou VTR de Salvamento.
              </li>
            </ul>

          </Accordion>
          <Accordion title="Sinais e sintomas">
            <ul>
              <li>
                Comportamentos: agitação, medo, choro, tristeza, insegurança, angústia, apatia, desespero ou qualquer outro fato narrado pela vítima.
              </li>
              <li>
                Traumas mais comuns: equimoses com diferentes colorações, hemorragias, fraturas, luxações e/ou entorses de membros, ferimentos por arma branca e/ou arma de fogo.
              </li>
            </ul>
          </Accordion>

          <Accordion title="Possibilidades de erro">
            <ul>
              <li>Falta de atenção ao procedimento</li>
              <li>
                Desconhecimento da rede integrada de atendimento</li>
              <li>
                Desconhecimento das medidas protetivas e dos direitos da vítima</li>
              <li>
                Falta de familiaridade sobre violência doméstica</li>
              <li>
                Falta de empatia</li>
              <li>
                Não utilização do atendimento não re-vitimizador.</li>
            </ul>
          </Accordion>

          <Accordion title="Fatores Complicadores">
            <ul>
              <li>Segurança da cena (presença do agressor)</li>
              <li>
                Demora na chegada da PM</li>
              <li>
                Número reduzido de profissionais</li>
              <li>
                Impossibilidade de coleta de fatos</li>
              <li>
                Paciente já ter tomado banho e/ou trocado as vestimentas.</li>
            </ul>
          </Accordion>

          <Accordion title="Glossário">
            <ul>
              <li>
                <b>ABSL:</b> Viatura de Salvamento do CBMDF (Auto Busca e Salvamento Leve).</li>
              <li>
                <b>APH:</b> Atendimento pré hospitalar.</li>
              <li>
               <b> ASE:</b> Viatura de Salvamento do CBMDF (Auto Salvamento e Extinção).</li>
              <li>
                <b>Atendimento não re-vitimizador:</b> Evitar que a vítima seja questionada sobre o mesmo fato diversas vezes, no local dos fatos, na delegacia pelo agente, pelo delegado, pelo conselho tutelar, pelo juiz, etc.</li>
              <li>
                <b>COCB:</b> Central de Operações e Comunicações Bombeiro Militar.</li>
              <li>
                <b>Medidas Protetivas:</b> Tutelas de urgência previstas na Lei 11.340/2006 com a finalidade de salvaguardar a integridade física, psicológica e patrimonial da vítima de violência doméstica.</li>
              <li>
                <b>UR:</b> viatura do CBMDF do tipo Unidade de Resgate. É uma viatura tipo “C” de atendimento pré hospitalar.</li>
              <li>
                <b>URSB:</b> viatura do CBMDF do tipo Unidade de Resgate de Suporte Básico.</li>
            </ul>
          </Accordion>

          <Accordion title="Fluxograma de atendimento">
            <img src="assets/MulheresVitimasViolencia/MulheresVitimasViolencia1.png" className="figura"/>
          </Accordion>

          <Accordion title="Apêndice">
              <h4><em>Rede de apoio e os direitos das vítimas de violência doméstica</em></h4>
            <ol>
              <h4>Órgãos da rede integrada de atendimento à vítima:</h4>
              <li><b>PCDF:</b> Delegacia Eletrônica – Site para denúncia de violência doméstica e pedido de medida protetiva no DF. Site: <a href="url">https://www.pcdf.df.gov.br/servicos/delegacia-eletronica</a></li>
              <li>
                <b>DEAM</b> – Delegacia Especial de Atendimento à Mulher. Locais: Asa Sul (EQS 204/205) e Ceilândia (QNM 02). Telefones: <a href="url">https://www.pcdf.df.gov.br/informacoes/lista-telefonica/95/-deam-ii- delegacia-especial-de-atendimento-a-mulher-ii</a></li>
              <li>
                <b>DCAs</b> – Delegacias da Criança e do Adolescente. Telefones: <a href="url">https://www.pcdf.df.gov.br/informacoes/lista-telefonica/62/dca- delegacia-da-crianca-e-do-adolescente</a></li>
              <li>
                <b>CEAM</b> - Centro Especializado de Atendimento à Mulher. Realizam o acolhimento, acompanhamento interdisciplinar (social, psicológico, pedagógico e de orientação jurídica). Locais: Asa Sul, Planaltina e Ceilândia.  Telefones: <a href="url">http://www.mulher.df.gov.br/centro- especializado-de-atendimento-a-mulher-ceam/</a></li>
              <li>
                <b>NAFAVD</b> - Núcleo de Atendimento à Família e aos Autores de Violência Doméstica. São unidades de atendimento que realizam acompanhamento interdisciplinar com homens e mulheres envolvidos/as em situações de violência doméstica e familiar contra mulheres. Telefones: <a href="ulr">encurtador.com.br/syGQ2</a></li>
              <li>
                <b>Casa Abrigo</b> – Oferta o serviço de acolhimento institucional para mulheres vítimas de violência doméstica, bem como de seus dependentes. Necessário ter registro de ocorrência e encaminhamento da DEAM.</li>
              <li>
                <b>Central de Atendimento à Mulher</b> – Ligue 180 (24 horas por dia, incluindo sábados, domingos e feriados). As denúncias são registradas e encaminhadas aos órgãos competentes.</li>
              <li>
                <b>Conselho Tutelar</b> - órgão permanente e autônomo, não jurisdicional, encarregado pela sociedade de zelar pelo cumprimento dos direitos da criança e do adolescente. Telefones: <a href="url">http://www.crianca.df.gov.br/wp- conteudo/uploads/2018/02/CONSELHOS-TUTELARES.pdf</a> Informações: <a href="url">http://www.crianca.df.gov.br/centro-18-de-maio/</a></li>
              <li>
                <b>PROVIDs</b> – Policiamento de Prevenção Orientado à Violência Doméstica da PMDF. Telefones: <a href="ulr">encurtador.com.br/amKV4</a></li>
            </ol>
            
              <ol>
              <h4>Órgãos da rede integrada de atendimento à vítima:</h4>
              <li>Atendimento policial e pericial especializado, ininterrupto e prestado por servidores - preferencialmente do sexo feminino - previamente capacitados</li>
              <li> 
                Salvaguarda da integridade física, psíquica e emocional da depoente</li>
              <li>
                Garantia de que, em nenhuma hipótese, a mulher em situação de violência doméstica e familiar, familiares e testemunhas terão contato direto com investigados ou suspeitos e pessoas a eles relacionadas</li>
              <li>
                Encaminhamento da ofendida ao hospital ou posto de saúde e ao Instituto Médico Legal, conforme fluxo da rede de atenção à saúde (decisão intra-hospitalar).  Transporte para a ofendida e seus dependentes para abrigo ou local seguro, quando houver risco de vida, conforme fluxo específico judicial. Acompanhamento policial, se necessário, para assegurar a retirada de seus pertences do local da ocorrência ou do domicílio familiar</li>
              <li>
                Opção de propor ação de divórcio ou de dissolução de união estável
                no Juizado de Violência Doméstica e Familiar contra a Mulher.</li>
            </ol>

            <ol>
              <h4>Medidas Protetiva de Urgência contra o Agressor:</h4>

              Constatada a prática de violência doméstica e familiar contra a mulher, o juiz poderá aplicar, de imediato, ao agressor, em conjunto ou separadamente, as seguintes medidas protetivas de urgência, entre outras:

              <li>
                Suspensão da posse ou restrição do porte de armas, com comunicação ao órgão competente</li>
              <li>
                Afastamento do lar, domicílio ou local de convivência com a ofendida</li>
              <li>
                Proibição de determinadas condutas, entre as quais:
                a) aproximação da ofendida, de seus familiares e das testemunhas, fixando o limite mínimo de distância entre estes e o agressor;
                b) contato com a ofendida, seus familiares e testemunhas por qualquer meio de comunicação;
                c) frequentação de determinados lugares a fim de preservar a integridade física e psicológica da ofendida</li>
              <li>
                Restrição ou suspensão de visitas aos dependentes menores, ouvida a equipe de atendimento multidisciplinar ou serviço similar</li>
              <li>
                Prestação de alimentos provisionais ou provisórios</li>
              <li>
                Comparecimento do agressor a programas de recuperação e reeducação</li>
              <li>
                Acompanhamento psicossocial do agressor, por meio de atendimento individual e/ou em grupo de apoio.</li>
            </ol>

            <ol>
              <h4>Medidas Protetivas de Urgência à Ofendida:</h4>
              <li>
                Poderá o juiz, quando necessário, sem prejuízo de outras medidas: Encaminhar a ofendida e seus dependentes a programa oficial ou comunitário de proteção ou de atendimento</li>
              <li>
                Determinar a recondução da ofendida e a de seus dependentes ao respectivo domicílio, após afastamento do agressor</li>
              <li>
                Determinar o afastamento da ofendida do lar, sem prejuízo dos direitos relativos a bens, guarda dos filhos e alimentos</li>
              <li>
                Determinar a separação de corpos</li>
              <li>
                Determinar a matrícula dos dependentes da ofendida em instituição de educação básica mais próxima do seu domicílio, ou a transferência deles para essa instituição, independentemente da existência de vaga</li>
              <li>
                Para a proteção patrimonial dos bens da sociedade conjugal ou daqueles de propriedade particular da mulher, o juiz poderá determinar, liminarmente, as seguintes medidas, entre outras:</li>
              <li>
                Restituição de bens indevidamente subtraídos pelo agressor à ofendida</li>
              <li>
                Proibição temporária para a celebração de atos e contratos de compra, venda e locação de propriedade em comum, salvo expressa autorização judicial</li>
              <li>
                Suspensão das procurações conferidas pela ofendida ao agressor</li>
              <li>
                Prestação de caução provisória, mediante depósito judicial, por perdas e danos materiais decorrentes da prática de violência doméstica e familiar contra a ofendida.</li>
            </ol>
          </Accordion>

          <Accordion title="Base Legal e Referencial">
            <ul>
              <li>
            BRASIL. Lei no 11.340, de 7 de agosto de 2006. Lei Maria da Penha. Cria mecanismos para coibir a violência doméstica e familiar contra a mulher.  Brasília: Presidência da República, 2006.  Disponível em: http://www.planalto.gov.br/ccivil_03/_ato2004-2006/2006/lei/l11340.htm. Acesso em: 21 set. 2020.</li>
          <li>
            CORPO DE BOMBEIROS MILITAR DO DISTRITO FEDERAL. Boletim Geral 080, de 29 de abril de 2020. Brasília, DF. Disponível em: https://www.cbm.df.gov.br/. Acesso em: 21 set. 2020.</li>
          <li>
            CORPO DE BOMBEIROS MILITAR DO DISTRITO FEDERAL. Boletim Geral 104, de 3 de junho de 2020. Brasília, DF. Disponível em: https://www.cbm.df.gov.br/. Acesso em: 25 fev. 2021.</li>
          <li>
            CORPO DE BOMBEIROS MILITAR DO DISTRITO FEDERAL. Brasília, 2020.  Disponível em: https://biblioteca.cbm.df.gov.br/jspui/handle/123456789/161. Acesso em: 21 set. 2020.</li>
          <li>
            COSTA, Camilla Pilotto Muniz. Ocorrências de violência contra a mulher: um diagnóstico do nível de preparação e oportunidades de melhoria para atuação segura do bombeiro militar. 2020. Trabalho de Conclusão de Curso (Curso de Formação de Oficiais)</li>
        </ul>
      </Accordion>
    </div>
  </IonContent>
</IonPage>

  );
}

export default MulheresVitimasViolencia;
