import { useState } from 'react';
import { IonRouterOutlet, IonSearchbar, IonList, IonItem, IonLabel, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import { IonReactRouter } from '@ionic/react-router';
import { Route, Link, RouteComponentProps, Redirect } from 'react-router-dom';

const Portarias: React.FC = () => {

  const [searchText, setSearchText] = useState("");
  const listaAvaliacao = [
    {titulo:"Avaliação do paciente", rota:"/pops/AbordagemDoPaciente"},
  ];
  const listaTrauma = [
    {titulo:"Fratura de costelas", rota:"/pops/FraturaCostelas"},
    {titulo:"Hemotórax", rota:"/pops/Hemotorax"},
    {titulo:"Pneumotórax", rota:"/pops/Pneumotorax"},
    {titulo:"Tórax instável", rota:"/pops/ToraxInstavel"},
    {titulo:"Trauma abdominal", rota:"/pops/TraumaAbdominal"},
    {titulo:"Trauma raquimedular", rota:"/pops/TraumaRaquimedular"},
    {titulo:"Traumatismo de face", rota:"/pops/TraumatismoFace"},
    {titulo:"Traumatismo em extremidades", rota:"/pops/TraumatismoExtremidades"},
    {titulo:"Traumatismo cranioencefálico", rota:"/pops/TCE"},
     {titulo:"Atendimento Pré-Hospitalar ao afogado", rota:"/pops/afogamento"},
  ];
  const listaClinico = [
    {titulo:"Acidente vascular cerebral", rota:"/pops/AcidenteVascularCerebral"},
    {titulo:"Choque circulatório", rota:"/pops/ChoqueCirculatorio"},
    {titulo:"Crise convulsiva", rota:"/pops/CriseConvulsiva"},
    {titulo:"Dor torácica não traumática", rota:"/pops/DorToracica"},
    {titulo:"Emergências em saúde mental", rota:"/pops/EmergenciaSaudeMental"},
    {titulo:"OVACE", rota:"/pops/ovace"},
    {titulo:"Aferição de Sinais Vitais", rota:"/pops/SinaisVitais"},
    {titulo:"RCP em adultos", rota:"/pops/RCPAdulto"},
    {titulo:"RCP em lactentes e crianças", rota:"/pops/RCPLactente"},
    {titulo:"RCP em neonatos", rota:"/pops/RCPNeonato"},
    {titulo:"RCP em gestante", rota:"/pops/RCPGestante"},
    {titulo:"DEA com eletrodos reutilizáveis", rota:"/pops/DEA"},    
    {titulo:"Assistência ao Trabalho de Parto", rota:"/pops/Trabalhodeparto"},
    {titulo:"Glicemia Capilar", rota:"/pops/GlicemiaCapilar"},
  ];
  const listaOutros = [
   
    {titulo:"Atendimento a mulheres vítimas de violência", rota:"/pops/MulheresVitimasViolencia"},
    {titulo:"Descarte de resíduos sólidos", rota:"/pops/DescarteResiduos"},
    {titulo:"Contaminação por Material Biológico", rota:"/pops/MaterialBiologico"},
    {titulo:"SUAPH", rota:"/pops/SUAPH"},
    {titulo:"Incidente com Múltiplas Vítimas", rota:"/pops/IMV"},
    {titulo:"Abordagem à Tentativa de Suicídio", rota:"/pops/Suicidio"},
  ];



  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Procedimentos Operacionais Padrão</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonSearchbar value={searchText} 
          onIonChange={e => setSearchText(e.detail.value!)}>
        </IonSearchbar>
        <IonList>

          {listaAvaliacao
            .filter(({ titulo, rota }) => titulo
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toUpperCase()
              .includes(searchText
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toUpperCase()))
            .map(l => (
              <IonItem key={l.titulo} routerLink={l.rota}>
                <IonLabel className="avaliacao">{l.titulo}</IonLabel>
              </IonItem>
          ))}

          <IonItem>
            <IonLabel>Trauma</IonLabel>
          </IonItem>

          {listaTrauma
            .filter(({ titulo, rota }) => titulo
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toUpperCase()
              .includes(searchText
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toUpperCase()))
            .map(l => (
              <IonItem key={l.titulo} routerLink={l.rota}>
                <IonLabel className="trauma">{l.titulo}</IonLabel>
              </IonItem>
          ))}

          <IonItem>
            <IonLabel>Clínico</IonLabel>
          </IonItem>
          {listaClinico
            .filter(({ titulo, rota }) => titulo
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toUpperCase()
              .includes(searchText
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toUpperCase()))
            .map(l => (
              <IonItem key={l.titulo} routerLink={l.rota}>
                <IonLabel className="clinico" >{l.titulo}</IonLabel>
              </IonItem>
          ))}

          <IonItem>
            <IonLabel>Outros</IonLabel>
          </IonItem>

          {listaOutros
            .filter(({ titulo, rota }) => titulo
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toUpperCase()
              .includes(searchText
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toUpperCase()))
            .map(l => (
              <IonItem key={l.titulo} routerLink={l.rota}>
                <IonLabel className="outros" >{l.titulo}</IonLabel>
              </IonItem>
          ))}


        </IonList>

      </IonContent>
    </IonPage>
  );
};

export default Portarias;
