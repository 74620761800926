import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const DorToracica: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Dor torácica não traumática</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://drive.google.com/file/d/1CpZAQMKWJz0uEIXvm1lbP8QTMgBp4Ikz/view?usp=share_link">POP Dor Torácica (BG 237 de 17/12/2015)</a>
            </p>

</ul>
              </Accordion>
          
          <Accordion title="Procedimentos">
          <ul>
            <h4>Avaliar o paciente num tempo ≤ 10 min</h4>
            <li>Avaliar a cena;</li>
            <li>Gerenciar riscos na cena de emergência;</li>
            <li>Avaliar vias aéreas, respiração e circulação;</li>
            <li>Tranquilizar o paciente e posicioná-lo em posição de Fowler (cabeceira da maca elevada 45°).</li>
            <li>Mantê-lo em repouso;</li>
            <li>Ofertar oxigênio (4 L/min) se %SpO2 {'<'} 95% ou desconforto respiratório.</li>
            <li>Realizar avaliação secundária;</li>
            <li>Sinais vitais;</li>
            <li>SAMPLA (com ênfase na dor: surgimento, tempo de início e duração, localização, irradiação, forma);</li>
            <li>Realizar acesso venoso, conforme orientação do médico regulador*;</li>
            <li>Estar preparada para fornecer RCP;</li>
            <li>Encaminhar com prioridade ao hospital de referência.</li>
            *Procedimentos realizados somente por profissionais habilitados (médicos, enfermeiros, técnicos e auxiliares de enfermagem, inscritos em seus respectivos conselhos de classe.

            <h4>Observações</h4>
            <li>O uso desnecessário e por tempo prolongado de oxigênio é prejudicial por poder causar vasoconstrição sistêmica, aumento da resistência vascular periférica e diminuição do débito cardíaco;</li>
            <li>Na suspeita de dor cardíaca isquêmica, realize contato com o médico regulador para a melhor tomada de decisão. Informar o tempo de aparecimento dos sinais sintomas é para que se decida o melhor hospital de destino. A depender desse tempo, considere o transporte para hospital com centro de emergência cardiológica.</li>
            <li>Quando autorizado pelo regulador médico, o socorrista deve orientar os pacientes a mastigar um comprimido de Ácido Acetilsalicílico (AAS®, Aspirina®) desde que não possua histórico de alergia a esse fármaco ou sinais e sintomas de hemorragia gastrointestinal ativa</li>
            <li>ou recente.</li>
            <li>Até que se descarte a gravidade do caso, a ocorrência relativa à dor torácica deve ser atendida com prioridade, desde a entrada na CIADE, até o despacho da viatura (brado).</li>
            <li>Em ocorrências que envolvam dor torácica, principalmente onde se suspeita de origem cardíaca isquêmica, a maca deve ser levada até o paciente, e não o contrário. O repouso é uma intervenção primordial para que se evite o aumento da demanda por oxigênio</li>
          </ul>
        </Accordion>

          <Accordion title="Observações iniciais">
            <ul>
              <li>A dor torácica possui etiologia cardíaca e não cardíaca. Nas cardíacas são observadas as de origem isquêmica e não isquêmica;</li>
              <li>Devido à sua elevada morbimortalidade e maior ocorrência pré-hospitalar, as orientações contidas nesse documento terão seu foco voltado para a dor torácica de origem cardíaca isquêmica.</li>
            </ul>
              <img src="assets/DorToracica/DorToracica1.png" className="figura"/>
            *O quadro traz alguns exemplos da etiologia e não encerra todas as prováveis causas.
          </Accordion>

          <Accordion title="Resultados Esperados">
            <ul>
              <li>Diminuição da morbimortalidade;</li>
              <li>Prevenção do choque cardiogênico;</li>
              <li>Reconhecimento dos sinais e sintomas e gravidade</li>
            </ul>
          </Accordion>

          <Accordion title="Material recomendado">
            <ul>
              <li>Prancha longa;</li>
              <li>Tirantes;</li>
              <li>Maca;</li>
              <li>Gazes;</li>
              <li>Lençóis;</li>
              <li>Esfigmomanômetro;</li>
              <li>Estetoscópio;</li>
              <li>Oxímetro de pulso;</li>
              <li>Termômetro;</li>
              <li>Conjunto de oxigenoterapia com fluxômetro;</li>
              <li>Jelco no 14 ou 16;</li>
              <li>Equipo simples.</li>
              <li>Solução salina isotônica (Ringer Lactato ou Solução Fisiológica 0,9%) – 2 frascos de 1000 ml cada ou 4 de 500 ml.</li>
            </ul>
          </Accordion>

          <Accordion title="Sinais e sintomas">
            <ul>
              <li>Dor torácica retroesternal, precordial ou epigástrica que irradia para mandíbula, pescoço, região dorsal e membro superiores, usualmente prolongada ({'>'} 20 min). Caracteriza-se por ser em opressão, aperto, queimação. Seu surgimento é geralmente desencadeado por estresse físico ou emocional.</li>
              <li>Ansiedade;</li>
              <li>Tontura, sensação de desfalecimento</li>
              <li>Dispneia;</li>
              <li>Náuseas;</li>
              <li>Sudorese;</li>
              <li>Fatores de risco (histórico de doença arterial coronariana, tabagismo, diabetes, hipertensão, dislipidemia, obesidade, idade, abuso de drogas/estimulantes).</li>
            </ul>
          </Accordion>


        <Accordion title="Possibilidades de erro">
          <ul>
            <li>Subestimar a gravidade do quadro clínico;</li>
            <li>Negligenciar sinais e sintomas;</li>
            <li>Demora no acionamento/despacho do socorro.</li>
          </ul>
        </Accordion>

          <Accordion title="Fatores Complicadores">
            <ul>
              <li>Segurança da cena;</li>
              <li>Choque cardiogênico associado;</li>
            </ul>
          </Accordion>

          <Accordion title="Glossário">
            <ul>
              <li><b>Etiologia:</b> origem, causa do evento.</li>
              <li><b>Isquemia:</b> diminuição ou cessação do fluxo sanguíneo em determinado órgão ou tecido;</li>
              <li><b>Angina Pectoris:</b> dor no peito decorrente da diminuição do fluxo sanguíneo no miocárdio.</li>
              <li><b>Infarto Agudo do Miocárdio:</b> necrose do miocárdio causada pela isquemia prolongada do miocárdio.</li>
            </ul>
          </Accordion>

          <Accordion title="Referencial bibliográfico">
            <ul>
              <li>ACLS: American Heart Association. Edição em Português do Suporte Avançado de Vida em Cardiologia. Manual do Profissional. 2014.</li>
              <li>AMLS: atendimento pré-hospitalar às emergências clínicas. 1a ed. Rio de Janeiro: Elsevier, 2014.</li>
              <li>Brasil. Ministério da Saúde. Secretaria de Atenção à Saúde. Protocolos de intervenção para o SAMU 192 – Serviço de Atendimento Móvel de Urgência. Brasília: Ministério da Saúde, 2014.</li>
              <li>Burman RA, Zakariassen E, Hunskaar S. Management of chest pain: a prospective study from Norwegian out-of-hours primary care. BMC Family Practice 2014, 15 (51).</li>
            </ul>
          </Accordion>

    </div>
  </IonContent>
</IonPage>

  );
}

export default DorToracica;
