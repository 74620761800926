import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import { ReactDom } from 'react-dom';
import Menu from './components/Menu';
import Portarias from './pages/Portarias';
import Home from './pages/Home';
import Pops from './pages/Pops';
import DetalhesPops from './pages/DetalhesPops';
import ManualAPH from './pages/ManualAPH';
import { Login } from './pages/Login';
import { useState, useEffect } from 'react';
import OneSignal from "react-onesignal";
import Libras from './pages/Libras';
import ApoioSocorrista from './pages/apoioSocorrista';
import noticias from './pages/noticias';
import fichas from './pages/fichas';

import desativacaoUr from './pages/INs/desativacao-ur';
import empregoAph from './pages/INs/emprego-militares-aph';
import usoColete from './pages/INs/uso-colete';
import retencaoMacas from './pages/INs/retencao-macas';
import nusam from './pages/INs/nusam';
import desconcentracao from './pages/INs/desconcentracao';
import celularFuncional from './pages/INs/celular-funcional';
import notificacaoCompulsoria from './pages/INs/notificacao-compulsoria';
import alimentacaoHospitais from './pages/INs/alimentacao-hospitais';
import viaturasReguladas from './pages/INs/viaturas-reguladas';
import postosHospitalares from './pages/INs/postos-hospitalares';
import animaisPeconhentos from './pages/INs/animais-peconhentos';
import prioridadedeativacao from './pages/INs/prioridadedeativacao';
import restricaoredepublica from './pages/INs/restricaoredepublica';
import escalacondutores from './pages/INs/escalacondutores';
import padronizacao from './pages/INs/padronizacao';

import cadastroinicial from './pages/FICHASaph/cadastroinicial';
import gerarnovaficha from './pages/FICHASaph/gerarnovaficha';
import comopreencher from './pages/FICHASaph/comopreencher';
import modeloderecusa from './pages/FICHASaph/modeloderecusa';
import perguntas from './pages/FICHASaph/perguntas';

import AcidenteVascularCerebral from './pages/POPs/AcidenteVascularCerebral';
import AbordagemDoPaciente from './pages/POPs/AbordagemDoPaciente';
import ChoqueCirculatorio from './pages/POPs/ChoqueCirculatorio';
import CriseConvulsiva from './pages/POPs/CriseConvulsiva';
import DescarteResiduos from './pages/POPs/DescarteResiduos';
import DorToracica from './pages/POPs/DorToracica';
import EmergenciaSaudeMental from './pages/POPs/EmergenciaSaudeMental';
import FraturaCostelas from './pages/POPs/FraturaCostelas';
import Hemotorax from './pages/POPs/Hemotorax';
import MulheresVitimasViolencia from './pages/POPs/MulheresVitimasViolencia';
import Ovace from './pages/POPs/Ovace';
import SinaisVitais from './pages/POPs/SinaisVitais';
import Pneumotorax from './pages/POPs/Pneumotorax';
import ToraxInstavel from './pages/POPs/ToraxInstavel';
import TraumaAbdominal from './pages/POPs/TraumaAbdominal';
import TraumaRaquimedular from './pages/POPs/TraumaRaquimedular';
import TraumatismoFace from './pages/POPs/TraumatismoFace';
import TraumatismoExtremidades from './pages/POPs/TraumatismoExtremidades';
import RCPAdulto from './pages/POPs/RCPAdulto';
import RCPLactente from './pages/POPs/RCPLactente';
import RCPNeonato from './pages/POPs/RCPNeonato';
import IMV from './pages/POPs/IMV';
import RCPGestante from './pages/POPs/RCPGestante';
import Trabalhodeparto from './pages/POPs/Trabalhodeparto';
import MaterialBiologico from './pages/POPs/MaterialBiologico';
import GlicemiaCapilar from './pages/POPs/GlicemiaCapilar';
import SUAPH from './pages/POPs/SUAPH';
import TCE from './pages/POPs/TCE';
import Suicidio from './pages/POPs/Suicidio';
import DEA from './pages/POPs/DEA';
import afogamento from './pages/POPs/afogamento';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Google Analytics */
import ReactGA from 'react-ga4';

ReactGA.initialize("G-2LHF6ZMV3M");


const PrivateRoutes = () => {
  useEffect(() => {
    OneSignal.init({
      appId: "fa5d8da9-0150-4076-af67-2dc7aef41c0f"
    });
  }, []);

   useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <IonReactRouter>
      <IonSplitPane contentId="main">
        <Menu />
        <IonRouterOutlet id="main">
          <Route exact path="/pops" component={Pops} />
          <Route path="/pops/AbordagemDoPaciente" component={AbordagemDoPaciente} />
          <Route path="/pops/AcidenteVascularCerebral" component={AcidenteVascularCerebral} />
          <Route path="/pops/MulheresVitimasViolencia" component={MulheresVitimasViolencia} />
          <Route path="/pops/ChoqueCirculatorio" component={ChoqueCirculatorio} />
          <Route path="/pops/CriseConvulsiva" component={CriseConvulsiva} />
          <Route path="/pops/DescarteResiduos" component={DescarteResiduos} />
          <Route path="/pops/DorToracica" component={DorToracica} />
          <Route path="/pops/EmergenciaSaudeMental" component={EmergenciaSaudeMental} />
          <Route path="/pops/FraturaCostelas" component={FraturaCostelas} />
          <Route path="/pops/Hemotorax" component={Hemotorax} />
          <Route path="/pops/ovace" component={Ovace} />
          <Route path="/pops/SinaisVitais" component={SinaisVitais} />
          <Route path="/pops/Pneumotorax" component={Pneumotorax} />
          <Route path="/pops/ToraxInstavel" component={ToraxInstavel} />
          <Route path="/pops/TraumaAbdominal" component={TraumaAbdominal} />
          <Route path="/pops/TraumaRaquimedular" component={TraumaRaquimedular} />
          <Route path="/pops/TraumatismoFace" component={TraumatismoFace} />
          <Route path="/pops/TraumatismoExtremidades" component={TraumatismoExtremidades} />
          <Route path="/pops/DEA" component={DEA} />
          <Route path="/pops/RCPAdulto" component={RCPAdulto} />
          <Route path="/pops/RCPLactente" component={RCPLactente} />
          <Route path="/pops/RCPNeonato" component={RCPNeonato} />
          <Route path="/pops/IMV" component={IMV} />
          <Route path="/pops/afogamento" component={afogamento} />
          <Route path="/pops/RCPGestante" component={RCPGestante} />
          <Route path="/pops/Trabalhodeparto" component={Trabalhodeparto} />
          <Route path="/pops/MaterialBiologico" component={MaterialBiologico} />
          <Route path="/pops/GlicemiaCapilar" component={GlicemiaCapilar} />
          <Route path="/pops/SUAPH" component={SUAPH} />
          <Route path="/pops/TCE" component={TCE} />
          <Route path="/pops/Suicidio" component={Suicidio} />
          <Route path="/noticias" component={noticias} />
          <Route path="/fichas" component={fichas} />
          <Route path="/libras" component={Libras} />
          <Route path="/portarias" component={Portarias} />
          <Route path="/home" component={Home} />
          <Route path="/apoio" component={ApoioSocorrista} />
          <Route path="/manual" component={ManualAPH} />
          <Route path="/login" component={Login} />
          <Route path="/INs/desativacao-ur" component={desativacaoUr} />
          <Route path="/INs/emprego-militares-aph" component={empregoAph} />
          <Route path="/INs/uso-colete" component={usoColete} />
          <Route path="/INs/retencao-macas" component={retencaoMacas} />
          <Route path="/INs/nusam" component={nusam} />
          <Route path="/INs/desconcentracao" component={desconcentracao} />
          <Route path="/INs/celular-funcional" component={celularFuncional} />
          <Route path="/INs/notificacao-compulsoria" component={notificacaoCompulsoria} />
          <Route path="/INs/alimentacao-hospitais" component={alimentacaoHospitais} />
          <Route path="/INs/viaturas-reguladas" component={viaturasReguladas} />
          <Route path="/INs/postos-hospitalares" component={postosHospitalares} />
          <Route path="/INs/animais-peconhentos" component={animaisPeconhentos} />
          <Route path="/INs/prioridadedeativacao" component={prioridadedeativacao} />
          <Route path="/INs/restricaoredepublica" component={restricaoredepublica} />
          <Route path="/INs/escalacondutores" component={escalacondutores} />
          <Route path="/INs/padronizacao" component={padronizacao} />
          <Route path="/FICHASaph/cadastroinicial" component={cadastroinicial} />
          <Route path="/FICHASaph/gerarnovaficha" component={gerarnovaficha} />
          <Route path="/FICHASaph/comopreencher" component={comopreencher} />
          <Route path="/FICHASaph/modeloderecusa" component={modeloderecusa} />
          <Route path="/FICHASaph/perguntas" component={perguntas} />
          <Redirect exact from="/" to="/home" />
        </IonRouterOutlet>
      </IonSplitPane>
    </IonReactRouter>
  );
};


type Props = {
  onAuthenticate: any
};

const PublicRoutes: React.FC<Props> = ({onAuthenticate}) => {
  return (
    <IonReactRouter>
      <Route path="/login" component={() => <Login onAuthenticate={onAuthenticate}/>} exact={true} />
      <Route path="/" render={() => <Redirect to="/login" />} />
    </IonReactRouter>
  );
};


const App = () => {
  const [authenticated, onAuthenticate] = useState(localStorage.getItem("tokenzim"));
  return (
    <IonApp>
      {!authenticated ? <PrivateRoutes /> : <PublicRoutes onAuthenticate={onAuthenticate}/>}
    </IonApp>
    )
};

export default App;
