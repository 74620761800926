import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import '../Page.css'


const desativacao: React.FC = () => {

  return (

  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>TUTORIAL DE PREENCHIMENTO DAS FICHAS DIGITAIS DE APH</IonTitle>
      </IonToolbar>
    </IonHeader>


    <IonContent fullscreen>

      <IonCard>
        <IonCardHeader>

          <IonCardTitle>MODELO DE RECUSA</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          
          <p align="justify">&#8195; 
Utilize este modelo caso deseje utilizar nas ocorrências. Basta preencher e anexar à ficha por meio de uma foto.
          </p>
          
          <p align="justify">&#8195;
            <a href="https://drive.google.com/file/d/1R2oNCs0IE3g6VEyadaFr-yBDMoWfd8E7/view?usp=share_link">Modelo de Recusa de Atendimento</a>
              </p>
          
              <p align="justify">&#8195;
  <a href="https://drive.google.com/file/d/1DrOHA4y5DB7IiaDtv3U8RXrEwKnL1ahn/view?usp=share_link">Modelo de Recusa de Transporte</a>
            </p>


 

                    <p align="justify">&#8195; 
          
          Se tiver dúvida sobre como preencher outros itens, entre em contato conosco!
                                </p>

        </IonCardContent>
      </IonCard>
    </IonContent>
  </IonPage>

);
}

export default desativacao;
