import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton,
  IonCard,
  IonImg
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const afogamento: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Atendimento Pré-Hospitalar ao Afogado</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://www.cbm.df.gov.br/download/atendimento-pre-hospitalar-ao-afogado-bg-49-15-03-2021/?tmstv=1678904371">POP Atendimento Pré-Hospitalar ao Afogado (BG 49 de 15/03/2021)</a>
            </p>

</ul>
              </Accordion>
                              
          <Accordion title="Resultados Esperados">
         
              <ul>
            <li>Agilidade e efetividade na classificação dos graus de afogamento;</li>
            <li>Qualidade nos tratamentos realizados de acordo com o grau de afogamento;</li>
            <li>Eficiência na escolha e utilização dos recursos disponíveis.</li>
          </ul>

    </Accordion>

    <Accordion title="Material Recomendado">
      <ul>
        <li>Reanimador manual - BVM</li>
        <li>Cilindro com oxigênio</li>
        <li>Desfibrilador Externo Automático (DEA)</li> 
        <li>Cobertor térmico</li>
        <li>Tirante aranha</li>
        <li>Prancha rígida</li>
        <li>Oxímetro de pulso</li>
        <li>Cânula orofaríngea</li>
        <li>Aspirador manual de secreções</li>
        <li>Máscara pocket</li>
        <li>Colar cervical</li>
        <li>Tubo orotraqueal (suporte avançado de vida)</li>
        <li>Catéter venoso periférico e material de punção venosa (viaturas com enfermeiro ou técnico de enfermagem)</li>
      </ul>
    </Accordion>

    <Accordion title="Sinais e Sintomas">
      <ul>
        <li>Tosse</li>
        <li>Espuma boca/nariz</li>
        <li>Inconsciência</li>
        <li>Ausência de pulso periférico</li>
        <li>Ausência de respiração efetiva</li>
        <li>Gasping</li>
        <li>Ausência de pulso central</li>
      </ul>
    </Accordion>

    <Accordion title="Classificação de graus de afogamento (vide Apêndice 3)">
      <ul>
                <li>Resgate: Vítima responsiva e consciente, sem tosse, espuma na e/ou nariz ou dificuldade respiratória.</li>
        <p></p>
        <li>Grau I : Vítima responsiva e consciente, com tosse mas sem espuma na boca e/ou nariz, não apresenta dificuldade respiratória.</li>
        <p></p>
        <li>Grau II: Vítima responsiva e consciente com pouca quantidade de espuma na boca e/ou nariz, pode apresentar algum grau de dificuldade respiratória.</li>
        <p></p>
        <li>Grau III: Vítima responsiva podendo apresentar alteração do nível de consciência e dificuldade respiratória, muita espuma na boca e/ou nariz. Pulso periférico(Radial) palpável sem sinais de hipotensão ou choque.</li>
        <p></p>
        <li>Grau IV: Vítima responsiva podendo apresentar alteração do nível de consciência, e dificuldade respiratória, muita espuma na boca e/ou nariz. Pulso periférico(Radial) ausente, hipotensão ou choque.</li>
        <p></p>
        <li>Grau V: Vítima arresponsiva, em parada respiratória mas com pulso central (carotídeo em adultos) presente.</li>
        <p></p>
        <li>Grau VI: Vítima arresponsiva em parada cárdio-respiratória(PCR).</li>
  
      </ul>
    </Accordion>    
          
          <Accordion title="Observações">
            <p><b>Avaliação de pulso central</b></p>
      <ul>
        <li>Adultos: Carotídeo</li>
        <li>Crianças: Carotídeo</li>
        <li>Lactentes: Braquial ou femoral</li>
                            </ul>
            <p>*Em casos de suspeita ou confirmação de COVID-19 os EPIs preconizados deverão ser utilizados. (Vide POP para atendimento de pacientes com COVID-19)</p>
    </Accordion>
          
  

    <Accordion title="Procedimentos Adulto">
      <p>Realize o Briefing com a guarnição, discuta possíveis estratégias a partir das informações prévias e defina quem irá executar cada função.</p>
      <p></p>
      <ol>
        <li>Avalie a segurança da cena.</li>
        <li>Forme uma impressão geral do paciente (estado respiratório, circulatório e neurológico).</li>
        <li>Avalie a responsividade do paciente – chame por ele.</li>
        <li>Paciente na água: Se o paciente estiver inconsciente e sem respiração, ainda na água, poderão ser feitas até 10 ventilações (técnica recomendada com um ou mais socorristas em águas rasas), conforme a técnica de salvamento aquático que o caso requerer.</li>
        <li>Paciente em terra: Se o paciente estiver inconsciente, abra a via aérea e verifique a respiração através do VOS (Ver, Ouvir e Sentir).</li>
        <li>Se o paciente não estiver respirando, faça 5 ventilações de resgate.</li>
        <li>Em seguida cheque pulso central observando movimentos na vítima ou reação à ventilação feita em até 10 segundos.</li>
        <li>Se o paciente tem pulso e não respira, prossiga com a Reanimação Respiratória.</li>
        <li>Se o paciente não tiver pulso central, inicie a Reanimação Cardiopulmonar.</li>
        
      </ol>
      <p></p>
      <p><b>Reanimação Respiratória</b></p>
      <p></p>
      
      <ol>
        <li>Acione o Suporte Avançado de Vida (SAV).</li>
        <li>Realize avaliação da cavidade oral, remova mecanicamente possíveis obstruções e aspire vias aéreas, se necessário.</li>
        <li>Mensure e aplique uma cânula orofaríngea, se houver o equipamento disponível.</li>
        <li>Mantenha a permeabilidade de vias aéreas.</li>
        <li>Realize as ventilações com BVM e/ou Máscara Pocket conforme disponibilidade, sendo que em caso de uso do BVM deverá ser conectado o oxigênio com vazão de 15 L/min.</li>
        <li>Realize ventilações utilizando a técnica adequada de vedação da máscara com abertura simultânea da via aérea. (Apêndices 1 A e 1 C).</li>
        <li>Realize 10 ventilações por minuto (1 a cada 6 segundos)</li>
        <li>Reavalie pulso central e respiração a cada 2 minutos.</li>
                
      </ol>
      
      <p></p>
      <p><b>Reanimação Cardiopulmonar</b></p>
      <p></p>
      <ol>
        <li>Acione o SAV.</li>
        <li>Inicie compressões torácicas de alta qualidade (Apêndice 2) enquanto o DEA é instalado. O DEA deve ser utilizado assim que disponível.</li>
        <li>Realize aspiração da cavidade oral, se necessário.</li>
        <li>Mensure e aplique uma cânula orofaríngea.</li>
        <li>Inicie RCP padrão (compressões torácicas e ventilações).</li>
        <li>Realize as ventilações com BVM e/ou Máscara Pocket conforme disponibilidade, sendo que em caso de uso do BVM este deverá ser conectado o oxigênio com vazão de 15 L/min.</li>
        <li>Para a realização das ventilações, utilize técnica adequada de vedação da máscara com abertura simultânea da via aérea. (Apêndices 1 A e 1 C).</li>
        <li>Inicie o primeiro ciclo com 30 compressões por 2 ventilações – 5 ciclos - durante 2 minutos (Siga as instruções do DEA para reinício ou interrupção das compressões torácicas), independente do número de socorristas.</li>
        <li>A partir do segundo ciclo siga conforme número de socorristas:</li>
        
            </ol>
      <ul>
        <li>1 socorrista: 30 compressões por 2 ventilações – 5 ciclos - durante 2
minutos (Siga as instruções do DEA para reinício ou interrupção das
compressões torácicas).</li>
        <li>2 socorristas*: 15 compressões por 2 ventilações – 10 ciclos - durante
2 minutos (Siga as instruções do DEA para reinício ou interrupção das
compressões torácicas).</li>
        <li>Se houver via aérea avançada (realizada pelo Suporte Avançado de
Vida), realizar compressões (100 a 120 por minuto) e ventilações (1 a
cada 6 segundos) simultaneamente. </li>
      </ul>
      <p>Realize a substituição do socorrista responsável pelas compressões
torácicas a cada 2 minutos.</p>
      <p>*Obs.: Em caso de dois socorristas presentes, e somente em casos de
afogamento, o protocolo de RCP após o primeiro ciclo poderá passar a 15
compressões para 2 ventilações</p>
      <p><b>Reanimação Cardiopulmonar Somente com as Mãos (RCP Hands-Only)</b> 
Prevista para situações onde não há segurança operacional para a
realização de ventilações – ausência de dispositivos de barreira na
ventilação.</p>
      <ul>
        <li>Acione SAV</li>
        <li>Inicie compressões torácicas de alta qualidade de forma ininterrupta
(entre 100 e 120 compressões por minuto). </li>
        <li>Instale o DEA</li>
        <li>Siga as instruções do DEA para reinício ou interrupção das
compressões torácicas.</li>
        <li>Reavalie pulso central e respiração a cada 2 minutos de RCP.</li>
        <li>Continue com as compressões torácicas até a chegada do SAV.</li>
     
        
      </ul>
      
    </Accordion>

    <Accordion title="Procedimento - Crianças e Lactentes">
      
      <p>Realize o Briefing com a guarnição, discuta possíveis estratégias a partir das
informações prévias e defina quem irá executar cada função. </p>
      <ol>
        <li>Avalie a segurança da cena.</li>
        <li>Forme uma impressão geral do paciente (estado respiratório,
circulatório e neurológico).</li>
        <li>Avalie a responsividade do paciente – estímulo no pé.</li>
        <li>Paciente na água: Se o paciente estiver inconsciente e sem
respiração, ainda na água, poderão ser feitas até 10 ventilações
(técnica recomendada com um ou mais socorristas em águas rasas),
conforme a técnica de salvamento aquático que o caso requerer.</li>
        <li>Paciente em terra: Se o paciente estiver inconsciente, abra a via aérea
e verifique a respiração através do VOS (Ver, Ouvir e Sentir).</li>
        <li>Se o paciente não estiver respirando, faça 5 ventilações de resgate.</li>
        <li>Em seguida cheque pulso central observando movimentos na vítima
ou reação à ventilação feita em até 10 segundos.</li>
        <li>Se o paciente tem pulso e não respira, prossiga com a Reanimação
Respiratória</li>
        <li>Se o paciente não tiver pulso central, inicie a Reanimação
Cardiopulmonar.</li>
        
         </ol>
      <p></p>
      <p><b>Reanimação Respiratória</b></p>
      <p></p>
      <ol>
        <li>Acione o SAV</li>
          <li>Realize aspiração da cavidade oral, se necessário.</li>
            <li>Aplique uma cânula orofaríngea. </li>
              <li>Realize abertura de vias aéreas (considere a utilização de um coxim
sob as escápulas do paciente).</li>
                <li>Realize a abertura da válvula de pressão do BVM.</li>
                  <li>Conecte o oxigênio ao BVM infantil com vazão de 05 a 07 L/min. </li>
                    <li>Realize ventilações com BVM utilizando técnica adequada de vedação
da máscara com abertura simultânea da via aérea (Apêndice 1B).
Utilize tamanho adequado de máscara.</li>
                      <li>Realize de 20 a 30 ventilações por minuto (1 a cada 2 a 3 segundos).</li>
                        <li>Reavalie pulso central e respiração a cada 2 minutos. </li>
      </ol>
                         <p></p>
      <p><b>Reanimação Cardiopulmonar</b></p>
      <p></p>
      <ol>
        <li>Acione o SAV.</li>
        <li>Inicie compressões torácicas de alta qualidade (Apêndice 2) enquanto
o DEA é instalado. O DEA deve ser utilizado assim que disponível. </li>
        <li>Realize avaliação da cavidade oral, remova mecanicamente possíveis
obstruções e aspire vias aéreas, se necessário</li>
        <li>Mensure e aplique uma cânula orofaríngea, se disponível.</li>
        <li>Conecte o oxigênio ao BVM infantil com vazão de 05 a 07 L/min.</li>
        <li>Inicie RCP padrão (compressões torácicas e ventilações).</li>
        <li>Inicie o primeiro ciclo com 30 compressões por 2 ventilações – 5 ciclos
- durante 2 minutos (Siga as instruções do DEA para reinício ou
interrupção das compressões torácicas), independente do número de
socorristas.</li>
        <li>A partir do segundo ciclo siga conforme número de socorristas: </li>
      
      <ul>
        <li>1 socorrista: 30 compressões por 2 ventilações – 5 ciclos - durante 2
minutos (Siga as instruções do DEA para reinício ou interrupção das
compressões torácicas).</li>
        <li>2 ou mais socorristas: 15 compressões por 2 ventilações – 10 ciclos
- durante 2 minutos (Siga as instruções do DEA para reinício ou
interrupção das compressões torácicas).</li>
        <li>Se houver via aérea avançada (realizada pelo Suporte Avançado de
Vida), realizar compressões (100 a 120 por minuto) e ventilações (uma
a cada 2 a 3 segundos) simultaneamente.</li>
        
       
      </ul>
        <li>Para a realização das ventilações, utilize técnica adequada de
vedação da máscara com abertura simultânea da via aérea. (Apêndice
1B)</li>
        <li>Realize a substituição do socorrista responsável pelas compressões
torácicas a cada 2 minutos.</li>
        
      </ol>
      <p>*Obs. Em caso de dois socorristas presentes, e somente em casos de
afogamento, o protocolo de RCP após o primeiro ciclo poderá passar a 15
compressões para 2 ventilações.</p>
      <p></p>
      <p><b>Reanimação Cardiopulmonar Somente com as Mãos (RCP HandsOnly)</b> Prevista para situações onde não há segurança operacional para a
realização de ventilações – ausência de dispositivos de barreira na
ventilação.</p>
      <p></p>
      <ul>
        <li>Acione SAV.</li>
        <li>Inicie compressões torácicas de alta qualidade de forma ininterrupta
(100 a 120 compressões por minuto). </li>
        <li>Instale o DEA.</li>
        <li>Siga as instruções do DEA para reinício ou interrupção das
compressões torácicas. </li>
        <li>Reavalie pulso central e respiração a cada 2 minutos de RCP.</li>
        <li>Continue com as compressões torácicas até a chegada do SAV.Continue com as compressões torácicas até a chegada do SAV.</li>
      </ul>
          
      
      
                        
    </Accordion>

    <Accordion title="Transporte, comunicação, limpeza e regresso">
      <p>Caso ocorra retorno espontâneo da circulação ou respiração, informe-se com
a regulação médica sobre a unidade hospitalar de destino ainda na cena ou
conforme orientação da equipe de SAV presente no local.</p>
      <ul>  
        <li>Comunique o Hospital tão logo saiba o destino.</li>
        <li>Realize debriefing com todos que participaram do atendimento, direta
ou indiretamente. </li>
        <li>Promova a reposição de materiais e equipamentos.</li>
        <li>Realize a limpeza da viatura e dos equipamentos que, porventura,
tenham sofrido contaminação.</li>
        <li>Informe ao COCB e a CERU/SAMU - DF a reativação da viatura e
suas possíveis restrições.</li>
        
     
        

      </ul>
    </Accordion>
<Accordion title="Recomendações">
  
  <p>Reveze, obrigatoriamente, os socorristas na realização das compressões a cada 2 minutos ou antes, se necessário.</p>
  <ul>
    <li>Havendo efetivo, designar um militar para o controle temporal dos
ciclos de reanimação</li>
    <li>Nos ritmos chocáveis a desfibrilação é prioridade e não deve ser
retardada para obtenção de via aérea avançada, acesso vascular ou
outros procedimentos.</li>
    <li>Não atrasar ventilações para aspiração de espuma e pequena
quantidade de secreção em via aérea, muito comuns em vítimas de
afogamento. Remover/Aspirar apenas grandes quantidades de
secreção como vômito ou material sólido. </li>
    <li>Utilizar imobilização com colar cervical e prancha rígida apenas em
situações nas quais haja clara evidência de trauma antes ou durante o
afogamento.</li>
    <li>Cessar esforços e considerar óbito quando:
-Vítima submersa há mais de 60 minutos em água com temperadura {'>'} 6°C ou mais de 90 minutos em água com temperatura {'<'} 6°C.
-Sinais de decomposição corporal e/ou presença de livores.</li>
  </ul>
  
          </Accordion>
        <Accordion title="Fatores Complicadores">
          <ul>
            <li>Segurança da cena.</li>
            <li>Falta de informações.</li>
            <li>Dificuldade de acesso à Regulação Médica.</li>
            <li>Indisponibilidade de EPIs</li>
            <li>Indisponibilidade de materiais.</li>
            <li>Número reduzido de profissionais.</li>
            <li>Comunicação ineficaz. Ressalta-se a importância da comunicação em
alça fechada.</li>
            
          </ul>
          </Accordion>
          <Accordion title="Glossário">
            <ul>
              <li><b>Briefing</b>: conjunto de informações utilizadas para definições de estratégias
de execução de uma tarefa. Aqui se definem as funções de cada um e as
ações esperadas para o bom desempenho da equipe.</li>
              <li><b>Comunicação em alça fechada</b>: a comunicação verbal é repetida para o
solicitante, de forma a confirmar aquilo que foi pedido e fazer uma checagem
dupla</li>
              <li><b>CERU/SAMU-DF</b>: Central de Regulação de Urgências do SAMU-DF.</li>
              <li><b>Debriefing</b>: é a maximização da aprendizagem por meio da experiência.
Nesse momento, por meio da experiência partilhada, se analisam as ações
da equipe, se o planejado foi cumprido (e os motivos do não cumprimento) e
se propõe estratégias de melhoria para a próxima ocorrência.</li>
              <li><b>Suporte Avançado de Vida (SAV)</b>: modalidade de assistência em saúde ao paciente gravemente enfermo, com a presença obrigatória do profissional
médico e enfermeiro, necessitando de materiais e equipamentos que
possibilitem a realização de procedimentos invasivos. O SAV é realizado pelo
CBMDF por meio do resgate aeromédico, enquanto o SAMU possui as
viaturas do tipo USA (Unidade de Suporte Avançado) terrestres.</li>
              <li><b>Reanimador Manual - BVM (Bolsa-Válvula-Máscara)</b>: Dispositivo utilizado
para realizar ventilações artificiais de forma não invasiva.</li>
              
              </ul>
          </Accordion>
          <Accordion title="Apêndice">
            
            <img src="assets/afogado/afogado1.png" className="figura"/>
            <img src="assets/afogado/afogado2.png" className="figura"/>
            <img src="assets/afogado/afogado3.png" className="figura"/>
            <img src="assets/afogado/afogado4.png" className="figura"/>
            <img src="assets/afogado/afogado5.png" className="figura"/>
            </Accordion>
          <Accordion title="Base legal e referencial">
            
           
            <ul>
              <li>SZPILMAN, D.; Curso de Emergências Aquáticas. Manual da SOBRASA - Sociedade Brasileira de Salvamento Aquático, 2019.</li>
              <li>Acidente por Afogamento, Biblioteca Virtual em Saúde do Ministério da Saúde, 2017. Disponível em: <a href="http://bvsms.saude.gov.br/dicas-em-saude/2401-acidentes-por-afogament"></a>  Acesso em: 18 de fevereiro de 2021.</li>
              <li>Acidente por Submersão – Afogamento, Sociedade Brasileira de Pedriatria, 2014. Disponível em: https://www.sbp.com.br/imprensa/detalhe/nid/acidentes-por-submersao-afogamentos/ Acesso em: 18 de fevereiro de 2021.</li>
              <li>Acidentes de Mergulho, Rede Sarah de Hospitais de Reabilitação, 2015. Disponível em: http://www.sarah.br/programas-educacionais/estudos-epidemiologicos/mergulho/ Acesso em: 18 de fevereiro de 2021.</li>
              <li>Informação Mundial Sobre Afogamento (Traduzido pela Associação de Prevenção do Afogamento), Organização Mundial de Saúde, 2014. Disponível em: https://apps.who.int/iris/bitstream/handle/10665/143893/9789241564786-por.pdf Acesso em: 18 de fevereiro de 2021.</li>
              <li>American Heart Association – AHA, Diretrizes de Reanimação Cardiopulmonar e Atendimento Cardiovascular de Emergência, 2020.</li>
              <li>SCHMIDT, A.; SEMPSROTT, J.; HAWKINS, S.; ARASTU, A.; CUSHING, T.; AUERBACH, P.; Wilderness Medical Society Clinical Practice Guidelines for the Treatment and Prevention of Drowning: 2019 Update.</li>
              <li>Szpilman D, Bierens JML, Handley A, Orlowski JP. Drowning. Nem Engl J Med. 2012, 366:2102-2110</li>
            </ul>
          </Accordion>
          
  </div>
</IonContent>
</IonPage>

  );
}

export default afogamento;

