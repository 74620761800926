import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const ToraxInstavel: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Tórax instável</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://drive.google.com/file/d/1MP4fP0qCgmY_sevft50nLDEuZtJ_UH16/view?usp=share_link">POP Tórax Instável (BG 237 de 17/12/2015)</a>
            </p>

</ul>
              </Accordion>
          
          <Accordion title="Procedimentos">
            <ul>
              <li>Avaliar a cena;</li>
              <li>Gerenciar riscos na cena de emergência;</li>
              <li>Avaliar a biomecânica envolvida;</li>
              <li>Manter via aérea pérvia e, se necessário, estabilizar manualmente a coluna cervical;</li>
              <li>Mensurar e aplicar colar cervical;</li>
              <li>Avaliar a qualidade da respiração;</li>
              <li>Avaliar a oximetria de pulso.</li>
              <li>Iniciar oxigenoterapia (10 – 15 L/min) se %SpO2 {'<'} 95%, ou se {'>'} 95%, mas com sinais de dificuldade respiratória (2 – 5 L/min).</li>
              <li>Considerar ventilação com BVM se frequência respiratória {'<'} 12 rpm ou {'>'} 30 rpm ou hipóxia;</li>
              <li>Controlar hemorragias externas.</li>
              <li>Avaliar a possibilidade de hemorragias internas;</li>
              <li>Realizar acesso venoso, conforme orientação do médico regulador; *</li>
              <li>Iniciar a infusão de fluido, conforme orientação do médico regulador;</li>
              <li>Imobilizar em prancha longa;</li>
              <li>Realizar avaliação secundária;</li>
              <li>Exame físico detalhado;</li>
              <li>Sinais vitais;</li>
              <li>SAMPLA;</li>
              <li>Encaminha o paciente para o hospital de referência</li>
            </ul>
          </Accordion>

          <Accordion title="Resultados Esperados">
            <ul>
              <li>Minimização da dor;</li>
              <li>Prevenção do choque circulatório;</li>
              <li>Minimização de danos</li>
            </ul>
          </Accordion>

          <Accordion title="Material recomendado">
            <ul>
              <li>Colar cervical;</li>
              <li>Prancha longa;</li>
              <li>Tirantes;</li>
              <li>Gazes;</li>
              <li>Ataduras;</li>
              <li>Compressas cirúrgicas;</li>
              <li>Manta aluminizada;</li>
              <li>Lençóis;</li>
              <li>Esfigmomanômetro;</li>
              <li>Estetoscópio;</li>
              <li>Oxímetro de pulso;</li>
              <li>Termômetro;</li>
              <li>Conjunto de oxigenoterapia com fluxômetro;</li>
              <li>Jelco no 14 ou 16;</li>
              <li>Equipo simples.</li>
              <li>Ringer Lactato ou Solução Fisiológica 0,9%– 2 frascos de 1000 ml cada ou 4 de 500 ml.</li>
            </ul>
          </Accordion>

          <Accordion title="Sinais e sintomas">
            <ul>
              <li>Dor intensa</li>
              <li>Taquipneia</li>
              <li>Dispneia;</li>
              <li>Hipoxemia;</li>
              <li>Cianose</li>
              <li>Movimento torácico paradoxal</li>
              <li>Crepitação;</li>
              <li>Instabilidade à palpação do seguimento fraturado</li>
            </ul>
          </Accordion>

          <Accordion title="Possibilidades de erro">
            <ul>
              <li>Negligenciar monitorização da saturação periférica de oxigênio;</li>
              <li>Não oferecer suporte ventilatório adequado;</li>
              <li>Não expor o tórax.</li>
            </ul>
          </Accordion>

          <Accordion title="Fatores Complicadores">
            <ul>
              <li>Segurança da cena;</li>
              <li>Hipoxemia</li>
            </ul>
          </Accordion>

          <Accordion title="Glossário">
            <ul>
              <li><b>Hipoxemia</b> – baixa concentração de oxigênio na corrente sanguínea.</li>
              <li><b>BVM:</b> dispositivo de reanimação manual do tipo bolsa-valva-máscara. É comumente denominado de Ambú®.</li>
              <li><b>Oximetria:</b> procedimento que visa medir a concentração de oxigênio no sangue.</li>
            </ul>
          </Accordion>

          <Accordion title="Referencial bibliográfico">
            <ul>
              <li>Atendimento Pré-Hospitalar ao Traumatizado – PHTLS (NAEMT). 7a ed. Rio de Janeiro: Elsevier, 2014.</li>
              <li>Batle CE, Evans FA. Predictors of mortality in patients with flail chest: a systematic review. Emergency Medicine Journal. 2015;0.</li>
              <li>Bemelman M, Poeze M, Blokhuis TJ, Leenen LPH. Historic overview of treatment techniques for rib fractures and flail chest. European Journal of Trauma and Emergency Surgery. 2010; 36.</li>
              <li>Brasil. Ministério da Saúde. Secretaria de Atenção à Saúde. Protocolos de intervenção para o SAMU 192 – Serviço de Atendimento Móvel de Urgência. Brasília: Ministério da Saúde, 2014.</li>
              <li>Lee C, Revell M, Porter K, Steyn R. The prehospital management of chest injuries: a consensus statement. Faculty of Pre-hospital Care, Royal College of Surgeons of Edinburgh. Emergency Medicine Journal. 2007; 24.</li>
            </ul>
          </Accordion>

        </div>
  </IonContent>
</IonPage>

  );
}

export default ToraxInstavel;
