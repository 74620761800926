import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import '../Page.css'


const desativacao: React.FC = () => {

  return (

  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>ATOS DO CHEFE DE GABINETE DO COMANDANTE-GERAL</IonTitle>
      </IonToolbar>
    </IonHeader>


    <IonContent fullscreen>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>Publicado no Boletim Geral 165, de 31 de agosto de 2016</IonCardSubtitle>

          <IonCardTitle>ATOS DO CHEFE DE GABINETE DO COMANDANTE-GERAL - XXXIV- INFORMAÇÃO SOBRE FUNCIONAMENTO DO NÚCLEO DE SAÚDE MENTAL -TRANSCRIÇÃO DE OFÍCIO RECEBIDO </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p align="justify">&#8195; 
            O CHEFE DE GABINETE DO COMANDANTE-GERAL, no uso das atribuições que lhe confere o art.43 do Decreto Federal 7.163, de 29 abr.2010, que regulamenta o inciso I do art.10-B da Lei 8.255, de 20 nov.1991, que dispõe sobre a organização básica do CBMDF, resolve:
          </p>
          <p align="justify">&#8195; 
            TORNAR  PÚBLICOque foi  recebido  o Ofício  46/2016-GASMU/DIURE/SAIS/SES,  da  Secretaria  de Saúde, por meio do qual o Gerente de Apoio ao Serviço Pré-Hospitalar Móvel de Urgência, informa sobre o funcionamento do Núcleo de Saúde Mental -NUSAM/SAMU, na forma a seguir:
          </p>
          <p align="justify">&#8195; 
            “...
          </p>
          <p align="justify">&#8195; 
            Senhor (a) Comandante (a),
          </p>
          <p align="justify">&#8195; 
            O  SAMU  do  Distrito  Federal  conta,  desde  2012,  com  uma  equipe  multidisciplinar  especializada  em intervenção em crise, saúde mental e atenção psicossocial, composta por médicos, psicólogos, assistentes sociais e enfermeiros.
          </p>
          <p align="justify">&#8195; 
            De  todos  os  atendimentos  realizado  pelo   SAMU,   aproximadamente  12,5%   são   emergências psicossociais.  Em  especial:  a)  surtos  psicóticos,  agitação  e  agressividade;  b)  comportamento  suicida;  c) crises  de  ansiedade;  d)  intoxicação  e  abstinência  por  álcool  e  drogas;  e)  violência  sexual  e  física;  f) situações de negligência.
          </p>
          <p align="justify">&#8195; 
            Nesse sentido, disponibilizamos nossos serviços como retaguarda ás emergências psicossociais no contexto do CIADE/CBM (crises psiquiátricas, crises psicológicas, urgências comportamentais e situações envolvendo violência sexual e física).
          </p>
          <p align="justify">&#8195; 
            Informamos  que,  a  partir  de  julho  de  2016,  o  Núcleo  de  Saúde  Mental -NUSAM -funcionará  24 horas  por  dia.  Para  acioná-lo,  basta  ligar  para  o  SAMU  192.  Para  maiores  informações,  ligar  no  telefone 3213-5209.
          </p>
          <p align="justify">&#8195; 
            Atenciosamente,
          </p>
          <p align="justify">&#8195; 
            RAFAEL VINHAL DA COSTA
          </p>
          <p align="justify">&#8195; 
            Gerente de Apoio ao Serviço Pré-Hospitalar Móvel de Urgência
          </p>
          <p align="justify">&#8195; 
            GASMU/DIURE/CATES/SAIS/SES
          </p>
          <p align="justify">&#8195; 
            Matr. 1443.639-6"
          </p>

          <p>
            <br></br>&#8195;

            Em conseqüência:
          </p>

          <p align="justify">&#8195; 
            a)  todos  os  socorristas  foram  esclarecidos  sobre  o  funcionamento  do  serviço,  por  ocasião  dos seminários que ocorreram em 15 e 17 de junho do ano em curso, no auditório da FEPECS, observando os termos do Memorando 45/2016 - CBMDF/GAEPH/SETES;
          </p>
          <p align="justify">&#8195; 
            b) os titulares dos demais setores envolvidos providenciem o que lhes couberem.
          </p>

          <p>&#8195;
            <br></br>
  (CBMDF -Nota de Boletim-CBMDF_GABCG /SEI-053-051097/2016-0624065)
</p>
                 </IonCardContent>
      </IonCard>
    </IonContent>
  </IonPage>

  );
}

export default desativacao;
