import { IonImg, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, isPlatform, IonToast } from '@ionic/react';
import { useParams } from 'react-router';
import './Page.css';


const Home: React.FC = () => {

  function timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
};

  function MostrarToast() {
    if (!isPlatform("pwa") && isPlatform("android")) {
    //setTimeout(function(){ alert("After 5 seconds!"); }, 5000);
    //if (!isPlatform("pwa") && isPlatform("desktop")) {
      return <IonToast
        isOpen={true}
        message="Instale o aplicativo do GAEPH clicando em  ⋮  e 'Adicionar à tela inicial'."
        position="center"
        buttons={[
          {
            text: 'Ok',
            role: 'cancel',
          }
        ]}
      />
      
    } else if(!isPlatform("pwa") && isPlatform("ios")) {

      return <IonToast
        isOpen={true}
        message="Instale o aplicativo do GAEPH clicando em 📤 e 'Adicionar à tela inicial'."
        position="center"
        buttons={[
          {
            text: 'Ok',
            role: 'cancel',
          }
        ]}
      />
    }
    return null
  }


  return (
    <IonPage>
      <IonHeader class ="menu2">
        <IonToolbar color>
                <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>GAEPH - CBMDF</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <MostrarToast />
        
          <IonCard>
        <IonImg src="/assets/ENTRADA.jpeg"/>
          <IonCardHeader>
            <IonCardSubtitle>Hello, World!</IonCardSubtitle>
            <IonCardTitle>Seja bem-vindo!</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            Olá, socorrista do CBMDF. Este é o novo aplicativo do GAEPH. Instale em seu dispositivo móvel e fique em dia com as atualizações em APH na Corporação! Navegue pelo menu e conheça as funcionalidades.
           
        
            
          </IonCardContent>
        </IonCard>
        

        
        


      
      </IonContent>
    </IonPage>
  );
};
export default Home;
