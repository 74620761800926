import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import './Page.css'


const apoio: React.FC = () => {

  return (

  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>Atendimento em Libras</IonTitle>
      </IonToolbar>
    </IonHeader>


    <IonContent fullscreen>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle></IonCardSubtitle>

          <IonCardTitle>Atendimento em Libras</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p>&#8195;
            Dificuldades em atender um paciente surdo? 
          </p>

          <p>&#8195;
            Assista a algumas dicas de como se comunicar na Língua Brasileira de Sinais (Libras) nas situações mais comuns nos atendimentos em APH.
          </p>

        </IonCardContent>
      </IonCard>


      <IonCard>
          <iframe width="100%" height="500" src="https://www.youtube.com/embed/TpNAqKDx-yQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <IonCardHeader>
          <IonCardSubtitle>
            Introdução
          </IonCardSubtitle>

          <IonCardTitle>
            1. Apresentação e orientações ao socorrista
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

        </IonCardContent>
      </IonCard>

<IonCard>
  <iframe width="560" height="315" src="https://www.youtube.com/embed/8KoEths4BYM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <IonCardHeader>
          <IonCardSubtitle>
            Perguntas gerais
          </IonCardSubtitle>

          <IonCardTitle>
            2. Olá! Sou bombeiro militar! Fique calmo! Nós podemos te ajudar? Sim ou não?
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

        </IonCardContent>
      </IonCard>

      <IonCard>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/GvgMyO5ZbqM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <IonCardHeader>
          <IonCardSubtitle>
            Perguntas gerais
          </IonCardSubtitle>

          <IonCardTitle>
            3. Você lembra o que aconteceu? Pode escrever?
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

        </IonCardContent>
      </IonCard>


<IonCard>
  <iframe width="560" height="315" src="https://www.youtube.com/embed/8Wl5Gfoqbyw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <IonCardHeader>
          <IonCardSubtitle>
            Perguntas gerais
          </IonCardSubtitle>

          <IonCardTitle>
            4. O que você está sentindo? Onde dói?
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

        </IonCardContent>
      </IonCard>


<IonCard>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/RSmem9a0ykA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <IonCardHeader>
          <IonCardSubtitle>
            Perguntas gerais
          </IonCardSubtitle>

          <IonCardTitle>
            5. Você caiu ou desmaiou? Sim ou não?
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

        </IonCardContent>
      </IonCard>

<IonCard>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/HBHYihngJck" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <IonCardHeader>
          <IonCardSubtitle>
            Perguntas gerais
          </IonCardSubtitle>

          <IonCardTitle>
            6. Você tem documento de identidade? Pode pegar, por favor?
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

        </IonCardContent>
      </IonCard>

      <IonCard>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/mtIuYgwfCEw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <IonCardHeader>
          <IonCardSubtitle>
            Avaliação primária
          </IonCardSubtitle>

          <IonCardTitle>
            7. Você sente dor ao respirar? Sim ou não?
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

        </IonCardContent>
      </IonCard>

<IonCard>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/HxBVnqiPqdA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <IonCardHeader>
          <IonCardSubtitle>
            Avaliação primária
          </IonCardSubtitle>

          <IonCardTitle>
            8. Está com dificuldade para respirar? Sim ou não?
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

        </IonCardContent>
      </IonCard>

<IonCard>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/RJiP3W8ymQs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <IonCardHeader>
          <IonCardSubtitle>
            Avaliação primária
          </IonCardSubtitle>

          <IonCardTitle>
            9. Talvez o socorrista precise cortar as suas roupas para te examinar
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

        </IonCardContent>
      </IonCard>

<IonCard>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/xqyOB-L45XI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <IonCardHeader>
          <IonCardSubtitle>
            Avaliação primária
          </IonCardSubtitle>

          <IonCardTitle>
            10. Consegue apertar a mão do bombeiro?
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

        </IonCardContent>
      </IonCard>

<IonCard>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/a2_4rnf6z0Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <IonCardHeader>
          <IonCardSubtitle>
            Avaliação primária
          </IonCardSubtitle>

          <IonCardTitle>
            11. Começou a sentir essa dor hoje ou antes? Há quantos dias?
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

        </IonCardContent>
      </IonCard>

<IonCard>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/rW-_6fN0kMg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <IonCardHeader>
          <IonCardSubtitle>
            Avaliação primária
          </IonCardSubtitle>

          <IonCardTitle>
            12. Em uma escala de 1 a 10, qual a intensidade da dor?
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

        </IonCardContent>
      </IonCard>


<IonCard>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/1n-HYNwzkH8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <IonCardHeader>
          <IonCardSubtitle>
            Avaliação primária
          </IonCardSubtitle>

          <IonCardTitle>
            13. A dor irradia para outro lugar? Caso positivo, para onde?
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

        </IonCardContent>
      </IonCard>


<IonCard>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/ZTUWXaxIfGU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <IonCardHeader>
          <IonCardSubtitle>
            Avaliação primária
          </IonCardSubtitle>

          <IonCardTitle>
            14. Por favor, fique parado. Se estiver difícil respirar, avise.
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

        </IonCardContent>
      </IonCard>

<IonCard>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/xCGQvU1iQzk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <IonCardHeader>
          <IonCardSubtitle>
            Avaliação primária
          </IonCardSubtitle>

          <IonCardTitle>
            15. Como seu braço fica melhor, dobrado ou esticado?
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

        </IonCardContent>
      </IonCard>

<IonCard>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/j1L_xiZHqEQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <IonCardHeader>
          <IonCardSubtitle>
            Avaliação primária
          </IonCardSubtitle>

          <IonCardTitle>
            16. Como sua perna fica melhor, dobrada ou esticada?
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

        </IonCardContent>
      </IonCard>


<IonCard>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/F7nyzZhOL7Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <IonCardHeader>
          <IonCardSubtitle>
            Avaliação  secundária
          </IonCardSubtitle>

          <IonCardTitle>
            17. Você possui alergia a remédios? Caso positivo, pode escrever o nome do remédio?
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

        </IonCardContent>
      </IonCard>


<IonCard>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/mvI2iji6lwI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <IonCardHeader>
          <IonCardSubtitle>
            Avaliação secundária
          </IonCardSubtitle>

          <IonCardTitle>
            18. Você usa algum remédio diariamente? Pode escrever o nome do remédio?
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

        </IonCardContent>
      </IonCard>


<IonCard>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/W_SbD8ZVVFQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <IonCardHeader>
          <IonCardSubtitle>
            Avaliação secundária
          </IonCardSubtitle>

          <IonCardTitle>
            19. Você tem algum problema de saúde? Pode escrever?
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

        </IonCardContent>
      </IonCard>


<IonCard>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/ui2RSaGtPrY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <IonCardHeader>
          <IonCardSubtitle>
            Avaliação secundária
          </IonCardSubtitle>

          <IonCardTitle>
            20. Você comeu recentemente? Pode escrever?
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

        </IonCardContent>
      </IonCard>


<IonCard>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/VEosoa5Nq4M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <IonCardHeader>
          <IonCardSubtitle>
            Avaliação secundária
          </IonCardSubtitle>

          <IonCardTitle>
            21. Fez uso de álcool ou drogas? Pode escrever?
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

        </IonCardContent>
      </IonCard>


<IonCard>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/aCB4xw_99x4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <IonCardHeader>
          <IonCardSubtitle>
            Avaliação secundária
          </IonCardSubtitle>

          <IonCardTitle>
            22. Agora o bombeiro fará um exame físico. Se você sentir dor, avise.
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

        </IonCardContent>
      </IonCard>


<IonCard>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/9K7rjiUzLuI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <IonCardHeader>
          <IonCardSubtitle>
            Avaliação secundária
          </IonCardSubtitle>

          <IonCardTitle>
            23. Agora o socorrista irá ligar para o médico para perguntar se precisa levar você ao hospital.
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

        </IonCardContent>
      </IonCard>



<IonCard>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/BXJT2pIBywc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <IonCardHeader>
          <IonCardSubtitle>
            Avaliação secundária
          </IonCardSubtitle>

          <IonCardTitle>
            24. Desejo melhoras!
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

        </IonCardContent>
      </IonCard>

    </IonContent>
  </IonPage>

);
}

export default apoio;
