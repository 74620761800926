import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const CriseConvulsiva: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Crise Convulsiva</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
           <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://drive.google.com/file/d/1BC36vEWYgmuwNYsAzMk24mYumM1k9lA1/view?usp=share_link">POP Crise Convulsiva (BG 237 de 17/12/2015)</a>
            </p>

</ul>
              </Accordion>
          
          <Accordion title="Procedimentos">
            <ul>
              <h4>Antes da crise</h4>
              <li>Avaliar a cena;</li>
              <li>Gerenciar riscos;</li>
              <li>Retirar objetos próximos que possam causar lesão;</li>
              <li>Apoiar a cabeça de paciente a fim de evitar seu choque contra o chão;</li>
              <li>Afrouxar roupas;</li>
              <li>Monitorar o tempo de duração da crise convulsiva;</li>
              <li>Lateralizar a cabeça do paciente em caso de Sialorreia;</li>
              <li>Não introduzir objetos na boca do paciente;</li>
              <li>Não conter os movimentos do paciente.</li>

              <h4>Após a crise</h4>
              <li>Avaliar a cena;</li>
              <li>Gerenciar riscos;</li>
              <li>Tranquilizar o paciente. Promove suporte emocional e manter privacidade do paciente;</li>
              <li>Manter permeabilidade das vias aéreas com manobra apropriada. Inspecionar a cavidade oral e, se necessário, aspirar secreções;</li>
              <li>Avaliar a respiração;</li>
              <li>Monitorizar %SpO2;</li>
              <li>Iniciar oxigenoterapia (10 – 15 L/min) se %SpO2 {'<'} 95%, ou se {'>'} 95%, mas com sinais de dificuldade respiratória (2 – 5 L/min).</li>
              <li>Realizar acesso venoso, conforme orientação do médico regulador*;</li>
              <li>Realizar avaliação secundária;</li>
              <li>Sinais vitais (pesquisar por febre);</li>
              <li>SAMPLA (informar-se sobre o histórico de doença neurológica ou outra situação potencialmente desencadeadora e uso de medicamentos);</li>
              <li>Encaminhar o paciente para hospital de referência;</li>
              *Procedimentos realizado somente por profissionais habilitados (médicos, enfermeiros, técnicos e auxiliares de enfermagem, inscritos em seus respectivos conselhos de classe.

              <h4>Observações</h4>
              <li>Em geral, as crises convulsivas são rápidas;</li>
              <li>Em casos em crise que durem mais que cinco minutos, faça contato com a central de regulação para orientações do médico regulador. Esses casos requerem drogas anticonvulsivantes e por isso requerem prioridade no transporte;</li>
              <li>As crises recorrentes, assim como as crises longas, podem ocasionar hipóxia. Nesses casos fique atentos a sinais, como a hipóxia.</li>
              <li>Quando possível coloque o paciente em decúbito lateral para evitar aspiração.</li>
              <li>Um período curto apneico pode acontecer durante ou imediatamente após uma convulsão generalizada.</li>
              <li>A utilização de cânula orofaríngea propicia proteção da língua. Em caso da presença de aura, esse procedimento poderá ser utilizado.</li>
            </ul>
          </Accordion>

          <Accordion title="Resultados Esperados">
            <ul>
              <li>Prevenção da hipóxia;</li>
              <li>Minimização de lesões;</li>
            </ul>
          </Accordion>

          <Accordion title="Material recomendado">
            <ul>
              <li>Prancha longa;</li>
              <li>Maca;</li>
              <li>Tirantes;</li>
              <li>Gazes;</li>
              <li>Lençóis;</li>
              <li>Esfigmomanômetro;</li>
              <li>Estetoscópio;</li>
              <li>Oxímetro de pulso;</li>
              <li>Termômetro</li>
              <li>Cânulas orofaríngeas;</li>
              <li>Aspirador e sondas para aspiração;</li>
              <li>Conjunto de oxigenoterapia com fluxômetro;</li>
              <li>Jelco no 14 ou 16;</li>
              <li>Equipo simples.</li>
              <li>Solução salina isotônica (Ringer Lactato ou Solução Fisiológica 0,9%) – 2 frascos de 1000 ml cada ou 4 de 500 ml.</li>
            </ul>
          </Accordion>

          <Accordion title="Sinais e sintomas">
            <ul>
              <h4>Antes da crise</h4>
              <li>Perda súbita da consciência;</li>
              <li>Contrações musculares bruscas e involuntárias;</li>
              <li>Sialorreia;</li>
              <li>Desvio ou tremores dos olhos;</li>
              <li>Cianose;</li>
              <li>Incontinência urinária e fecal;</li>
              <li>Trismo.</li>

              <h4>Após a crise</h4>
              <li>Alteração do nível de consciência;</li>
              <li>Desorientação;</li>
              <li>Confusão mental;</li>
              <li>Flacidez muscular;</li>
            </ul>
          </Accordion>

          <Accordion title="Possibilidades de erro">
            <ul>
              <li>Conter o paciente;</li>
              <li>Não proteger a cabeça do paciente;</li>
              <li>Negligenciar a avaliação primária;</li>
              <li>Subestimar crises longas e/ou recorrentes.</li>
            </ul>
          </Accordion>

          <Accordion title="Fatores Complicadores">
            <ul>
              <li>Crises longas e/ou recorrentes;</li>
              <li>Obstrução de vias aéreas;</li>
              <li>Hipóxia.</li>
              <li>Agitação do paciente.</li>
            </ul>
          </Accordion>

          <Accordion title="Glossário">
            <ul>
              <li><b>%SpO2:</b> percentual de saturação periférica de oxigênio.</li>
              <li><b>Aura:</b> sentimento, antes de uma convulsão iniciar, algumas pessoas experimentam uma sensação ou advertência.</li>
              <li><b>Hipóxia:</b> baixa concentração de oxigênio em nível celular.</li>
              <li><b>Incontinência fecal:</b> perda involuntária de fezes.</li>
              <li><b>Incontinência urinária:</b> perda involuntária de urina.</li>
              <li><b>Sialorreia:</b> produção excessiva de saliva.</li>
              <li><b>Trismo:</b> constrição mandibular devido à contratura involuntária dos músculos mastigatórios</li>
            </ul>
          </Accordion>

          <Accordion title="Referencial bibliográfico">
            <ul>
              <li>AMLS: atendimento pré-hospitalar às emergências clínicas. 1a ed. Rio de Janeiro: Elsevier, 2014.</li>
              <li>Brasil. Ministério da Saúde. Secretaria de Atenção à Saúde. Protocolos de intervenção para o SAMU 192 – Serviço de Atendimento Móvel de Urgência. Brasília: Ministério da Saúde, 2014.</li>
              <li>Eduardo OR, Félix VM, Silva AGB. Protocolo de atendimento pré-hospitalar CBMDF. Brasília: CBMDF, 2003.</li>
              <li>Rasia CA, Barros CC, Marcelino SC, Fernandes RWC, Pontes FC, Pedroso, GB, et al. Manual de atendimento pré-hospitalar. Brasília: Corpo de Bombeiros Militar do Distrito Federal, 2007.</li>
            </ul>
          </Accordion>

        </div>
  </IonContent>
</IonPage>

  );
}

export default CriseConvulsiva;
