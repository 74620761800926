import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent, 
  IonButtons, 
  IonMenuButton,
  IonImg
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import './Page.css'


const noticias: React.FC = () => {

  return (

  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>Últimas notícias do APH no CBMDF</IonTitle>
      </IonToolbar>
    </IonHeader>


    <IonContent fullscreen>

        
      
      <IonCard>
        <IonImg src="/assets/home/fotopep2.jpeg"/>
          <IonCardHeader>
            <IonCardSubtitle>PEP 2022</IonCardSubtitle>
            <IonCardTitle>PEP 2022</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
              Nos meses de outubro a fevereiro de 2023 o GAEPH está realizando o Programa de Educação Permanente em Atendimento Pré-Hospitalar 2022, com o objetivo de atualizar e padronizar a atuação dos nossos socorristas nas seguintes temáticas: Ações prioritárias na  Avaliação do Paciente; Emergências Clínicas e O papel do socorrista no Resgate Veicular. Além disso, estão sendo discutidas as práticas mais recentes abordadas nos nossos Procedimentos Operacionais Padrão (POPs) a fim de tomarem melhores decisões e garantir um atendimento de qualidade aos pacientes. O PEP 2022 foi desenvolvido num contexto de construção coletiva da equipe de ensino do GAEPH, que o fundamentou no ensino híbrido, sendo 1 (um) mês de ensino à distância e 2 (dois) dias de ensino presencial, com o objetivo de favorecer o aprimoramento de competências teóricas, práticas e atitudinais na sua importante missão de salvar vidas.
<p>
  Instruir para SALVAR! </p>
</IonCardContent>
        </IonCard>
      
       <IonCard>
        <IonImg src="/assets/home/foto manual2.jpeg"/>
          <IonCardHeader>
            <IonCardSubtitle>Manual de APH</IonCardSubtitle>
            <IonCardTitle>Publicada a 2ª Edição!</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
              Em setembro de 2022, o GAEPH publicou a 2ª Edição do Manual de Atendimento Pré-Hospitalar do Corpo de Bombeiros Militar do Distrito Federal, totalmente revisada e atualizada. O Manual de APH 2022 possui 21 capítulos e aborda de forma didática e sintetizada todos os conteúdos teóricos fundamentais do nosso atendimento, trazendo conceitos, fluxogramas, imagem e descrição de equipamentos e técnicas. Ele está disponível para download em nosso aplicativo e na intranet do CBMDF. Não deixe de baixá-lo!</IonCardContent>
        </IonCard>

        <IonCard>
        <IonImg src="/assets/libras.jpeg"/>
          <IonCardHeader>
            <IonCardSubtitle>Língua Brasileira de Sinais</IonCardSubtitle>
            <IonCardTitle>Atendimento em Libras</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
              Dificuldades em atender um paciente surdo? Acesse a sessão de Libras no menu e assista a algumas dicas de como se comunicar em Libras nas situações mais comuns nos atendimentos em APH.
          </IonCardContent>
        </IonCard>


    </IonContent>
  </IonPage>

);
}

export default noticias;
