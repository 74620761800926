import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const TraumaRaquimedular: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Trauma raquimedular</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://drive.google.com/file/d/1toc_n6UnNX6GazHh-reUjCV7qDWd-mSy/view?usp=share_link">POP Trauma Raquimedular (BG 237 de 17/12/2015)</a>
            </p>

</ul>
              </Accordion>
          
          <Accordion title="Procedimentos">
            <ul>
              <li>Avaliar a cena;</li>
              <li>Gerenciar riscos;</li>
              <li>Avaliar a biomecânica envolvida;</li>
              <li>Manter via aérea pérvia com manobra para trauma.</li>
              <li>Estabilizar manualmente a coluna cervical;</li>
              <li>Mensurar e aplicar colar cervical;</li>
              <li>Avaliar a qualidade da respiração;</li>
              <li>Monitorizar a oximetria de pulso.</li>
              <li>Iniciar oxigenoterapia (10 – 15 L/min) se %SpO2 {'<'} 95%, ou se {'>'} 95%, mas com sinais de dificuldade respiratória (2 – 5 L/min).</li>
              <li>Considerar ventilação com BVM se frequência respiratória {'<'} 12 rpm ou {'>'} 30 rpm;</li>
              <li>Controlar hemorragias;</li>
              <li>Realizar acesso venoso periférico com jelco no 14 ou 16, preferencialmente em veias antecubitais, conforme orientação do médico regulador;*</li>
              <li>Iniciar a infusão de Ringer Lactato, conforme orientação do regulador médico a fim de manter pressão arterial sistólica {'>'} 90mmHg.</li>
              <li>Promover controle da temperatura corporal por meio da utilização de lençóis, mantas aluminizadas e/ou controle da temperatura do salão de atendimento da viatura;</li>
              <li>Imobilizar toda a coluna vertebral em prancha rígida longa;</li>
              <li>Realizar avaliação secundária;</li>
              <li>Exame físico detalhado (atenção para resposta e simetria pupilar);</li>
              <li>Sinais Vitais;</li>
              <li>SAMPLA;</li>
              <li>Encaminhar ao hospital de referência.</li>
              * Procedimentos realizado somente por profissionais habilitados (médicos, enfermeiros,
técnicos e auxiliares de enfermagem, inscritos em seus respectivos conselhos de classe).

              <h4>Observações</h4>
              <li>A infusão de fluidos deve ser feita de maneira cautelosa. A não ser que a situação exija a permanência no local, não se justifica retardar o transporte ao hospital a fim de se obter o acesso venoso periférico. Não se recomenda a infusão agressiva de líquidos, já que tal conduta tem sido associada a consequências deletérias para o paciente, tais como: hemodiluição, diminuição dos fatores de coagulação, coagulopatias e rompimento de coágulos ativos. Considere manutenção da pressão arterial sistólica {'>'} 90 mmHg em pacientes hipotensos.</li>
            <li>O Ringer Lactato é a solução de primeira escolha devido à sua composição ser mais semelhante ao plasma e servir como solução tampão, desejável na acidose metabólica. Em sua falta, o socorrista deve optar pela Solução Fisiológica 0,9%.</li>

            </ul>
          </Accordion>

          <Accordion title="Resultados Esperados">
            <ul>
              <li>Manipulação mínima;</li>
              <li>Minimização de danos motores;</li>
            </ul>
          </Accordion>

          <Accordion title="Material recomendado">
            <ul>
              <li>Colar cervical;</li>
              <li>Prancha longa;</li>
              <li>Maca;</li>
              <li>Tirantes;</li>
              <li>KED;</li>
              <li>Gazes;</li>
              <li>Ataduras;</li>
              <li>Compressas cirúrgicas;</li>
              <li>Manta aluminizada</li>
              <li>Lençóis;</li>
              <li>Esfigmomanômetro;</li>
              <li>Estetoscópio;</li>
              <li>Oxímetro de pulso;</li>
            </ul>
          </Accordion>

          <Accordion title="Sinais e sintomas">
            <ul>
              <li>Dor local;</li>
              <li>Deformidade na coluna vertebral;</li>
              <li>Arreflexia flácida;</li>
              <li>Esforço respiratório;</li>
              <li>Responsividade à estímulos apenas acima da clavícula;</li>
              <li>Paralisia total ou parcial de membros;</li>
              <li>Formigamento ou fraqueza nas extremidades;</li>
              <li>Priapismo;</li>

              <h4>Observações</h4>
              <li>Ficar atento à sinais sugestivos de choque neurogênico (hipovolemia relativa, bradicardia e hipotensão e pele quente nas extremidades);</li>
            </ul>
          </Accordion>

          <Accordion title="Possibilidades de erro">
            <ul>
              <li>Imobilização inadequada;</li>
              <li>Imobilização com a cabeça hiperestendida;</li>
              <li>Má fixação na prancha longa;</li>
              <li>Complicações por uso inadequado de colares cervicais.</li>
            </ul>
          </Accordion>

          <Accordion title="Fatores Complicadores">
            <ul>
              <li>Segurança da cena;</li>
              <li>Dificuldade no acesso ao Suporte Avançado de Vida;</li>
              <li>Habilidades inadequadas;</li>
              <li>Conflitos institucionais;</li>
            </ul>
          </Accordion>

          <Accordion title="Glossário">
            <ul>
              <li><b>Priapismo</b> – ereção involuntária e dolorosa;</li>
              <li><b>Arreflexia flácida</b> – ausência de reflexos acompanhada por flacidez;</li>
              <li><b>Choque neurogênico</b> – tipo de choque circulatório decorrente da perda da inervação simpática sobre a musculatura lisa dos vasos sanguíneos e que provoca vasodilatação.</li>
              <li><b>Oximetria:</b> procedimento que visa medir a concentração de oxigênio no sangue.</li>
              <li><b>Suporte Avançado de Vida:</b> modalidade de assistência em saúde na qual são realizadas intervenções invasivas.</li>
            </ul>
          </Accordion>

          <Accordion title="Referencial bibliográfico">
          <ul>
            <li>Ahn H, Singh J, Nathens N, MacDonald RD, Travers A, Tallon J, et al. Pre-Hospital care management of a potential spinal cord injured patient: a systematic review of the literature and evidence-based guidelines. Journal of Neurotrauma. 2011; 28.</li>
            <li>Atendimento Pré-Hospitalar ao Traumatizado – PHTLS (NAEMT). 7a ed. Rio de Janeiro: Elsevier, 2014.</li>
            <li>Brasil. Ministério da Saúde. Secretaria de Atenção à Saúde. Protocolos de intervenção para o SAMU 192 – Serviço de Atendimento Móvel de Urgência. Brasília: Ministério da Saúde, 2014.</li>
            <li>Eduardo OR, Félix VM, Silva AGB. Protocolo de atendimento pré-hospitalar CBMDF. Brasília: CBMDF, 2003.</li>
            <li>Hasler RM, Exadaktylos AK, Bouamra O, Benneker LM, Clancy M, Sieber R, et al. Epidemiology and predictors of spinal injury in adult major trauma patients: European cohort study. European Spine Journal. 2011; 20.</li>
            <li>Rasia CA, Barros CC, Marcelino SC, Fernandes RWC, Pontes FC, Pedroso, GB, et al. Manual de atendimento pré-hospitalar. Brasília: Corpo de Bombeiros Militar do Distrito Federal, 2007.</li>
            <li>Sundstrøm T, Asbjørnsen H, Habiba S, Sunde GA, Wester K. Prehospital use of cervical collars in trauma patients: a critical review. Journal of Neurotrauma. 2014; 31.</li>
          </ul>
        </Accordion>

    </div>
  </IonContent>
</IonPage>

  );
}

export default TraumaRaquimedular;
