import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const RCPLactente: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>RCP em lactentes e crianças</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://www.cbm.df.gov.br/download/rcp-lactente_ecrianca-bg-031-de-14-de-fevereiro-de-2022-pdf/">POP RCP em lactentes e crianças (BG 031 de 14/02/2022)</a>
            </p>

</ul>
              </Accordion>
          
                    <Accordion title="Resultados esperados">
            <ul>
            <li>Reconhecimento precoce da parada respiratória ou PCR em lactentes e
crianças;</li>
            <li>Emprego imediato das manobras de RCP;</li>
            <li>Eficiência na escolha e utilização dos recursos disponíveis.</li>

</ul>
              </Accordion>
          
                              <Accordion title="Material recomendado">
            <ul>
            <li>Capacete</li>
            <li>Máscara cirúrgica</li>
            <li>Óculos de proteção</li>
            <li>Luvas de procedimento</li>
            <li>Desfibrilador Externo Automático (DEA)</li>
            <li>Reanimador manual (Bolsa-Valva Máscara)</li>
            <li>Prancha longa</li>
            <li>Conjunto de oxigenoterapia com fluxômetro</li>
            <li>Oxímetro de pulso</li>
            <li>Cânula Orofaríngea</li>
            <li>Aspirador manual ou elétrico</li>

</ul>
              </Accordion>
          
                                       <Accordion title="Sinais e sintomas">
            <ul>
            <li>Arresponsividade;</li>
            <li>Ausência de respiração efetiva;</li>
            <li>Ausência de pulso central.</li>
</ul>
              </Accordion>
          
                                                 <Accordion title="Observações">
            <ul>
            <li>Neonatos, lactentes e crianças pequenas têm maior probabilidade de
desenvolver bradicardia causada por hipoxemia</li>
            <li>Avaliação do pulso central:</li>
                          <ul>
            <li>Crianças maiores de 1 ano: Carotídeo</li>
            <li>Lactentes: Braquial ou femoral</li>
                                    </ul>
</ul>
              </Accordion>
          
                                                           <Accordion title="Procedimentos">
            <ul>
            <li> Realize o Briefing com a guarnição. Discuta possíveis estratégias a
partir das informações prévias. Defina quem irá executar cada função.
</li>
            <li>Avalie a segurança da cena.</li>
            <li>Forme uma impressão geral do paciente (estado respiratório,
circulatório e neurológico).</li>
            <li>Avalie a responsividade do paciente – chamar a criança e fazer
estímulo vigoroso no pé.</li>
            <li>Paciente sem responsividade: avalie respiração e pulso
simultaneamente por até 10 segundos.</li>
            <li>Respiração ausente ou gasping: realizar reanimação respiratória.</li>
            <li>Respiração ausente/anormal e pulso ausente: reanimação
cardiorrespiratória.</li>
              
              <li><b>Reanimação respiratória</b></li>
                          <ul>
            <li>Acione o SAV.</li>
            <li>Realize aspiração da cavidade oral, se necessário.</li>
            <li>Aplique uma cânula orofaríngea.</li>
            <li>Realize abertura de vias aéreas. Considere a utilização de um coxim sob
as escápulas do paciente.</li>
            <li>Realize a abertura da válvula de pressão do BVM.</li>
            <li>Conecte o oxigênio ao BVM com vazão de 05 a 07 L/min.</li>
            <li>Realize ventilações com BVM utilizando técnica adequada de vedação
da máscara com abertura simultânea da via aérea (Apêndice).</li>
            <li>Utilize tamanho adequado de máscara.</li>
            <li>Realize de 20 a 30 ventilações por minuto (1 a cada 2 a 3 segundos).</li>
            <li>Reavalie pulso central após 2 minutos.</li>
            <li>Inicie RCP se pulso ausente ou frequência cardíaca {'<'} 60 bpm. Caso
contrário, reinicie a reanimação respiratória, se refratária.</li>
                                    </ul>
              
              <li><b>Reanimação Cardiopulmonar Padrão (RCP Standard)</b></li>
                                     <ul>             
              <li>Acione o SAV - Suporte Avançado de Vida</li>
              <li>Inicie compressões torácicas de alta qualidade enquanto o DEA é
instalado. O DEA deve ser utilizado assim que disponível.</li>
              <li>Realize avaliação da cavidade oral, remova mecanicamente possíveis
obstruções, aspire vias aéreas, se necessário.</li>
              <li>Mensure e aplique uma cânula orofaríngea.</li>
              <li>Conecte o oxigênio ao BVM com vazão de 05 a 07 L/min.</li>
              <li>Inicie RCP padrão (compressões torácicas e ventilações):</li>
                                                                            <ul>
              <li>15 compressões por 2 ventilações, durante 2 minutos (Siga as instruções do DEA para reinício ou interrupção das compressões
torácicas).</li>
                                                          
                                                                                                                   </ul>
              <li>Para a realização das ventilações, utilize técnica adequada de vedação
da máscara com abertura simultânea da via aérea (Apêndice)</li>
              <li>Realize a substituição do socorrista responsável pelas compressões
torácicas a cada 2 minutos, ou sempre que for necessário.</li>
                                                   </ul>
              
                            <li><b>Reanimação Cardiopulmonar Somente com as Mãos</b> (RCP Hands-Only) -
Prevista para situações onde não há segurança operacional para a realização
de ventilações.</li>
              
                                     <ul>             
              <li>Acione SAV</li>
              <li>Inicie compressões torácicas de alta qualidade de forma ininterrupta.</li>
              <li>Instale o DEA.</li>
              <li>Siga as instruções do DEA para reinício ou interrupção das
compressões torácicas.
</li>
              <li>Reavalie pulso central a cada 2 minutos de RCP.</li>
              <li>Continue com as compressões torácicas até a chegada do SAV</li>
                                                                            </ul>
              

              
</ul>
              </Accordion>
          
          
          
          
                                                           <Accordion title="Recomendações">
            <ul>
            <li>Reveze, obrigatoriamente, os socorristas na realização das
compressões a cada 2 minutos, ou antes, se necessário.</li>
            <li>Nos ritmos chocáveis, a desfibrilação é prioritária. Ela não deve ser
retardada para obtenção de via aérea avançada, acesso vascular ou
outros procedimentos.</li>
              <li><b>Qualidade da RCP em crianças</b></li>
              
                          <ul>
            <li>A compressão torácica deve ser realizada no terço inferior do esterno,
com 1 ou 2 mãos, a depender da compleição.</li>
            <li>Profundidade: 5 cm</li>
            <li>Frequência: 100 a 120 compressões por minuto.</li>
            <li>Permitir retorno torácico após cada compressão.</li>
            <li>Evitar ventilação excessiva.</li>
                                    </ul>
              
                            <li><b>Qualidade da RCP em lactentes</b></li>
              
                          <ul>
            <li>A compressão torácica deve ser realizada logo abaixo da linha
intermamilar, utilizando 2 dedos para comprimir se houver apenas 1
socorrista e 2 polegares se houver 2 socorristas. É possível a utilização
de 1 mão apenas em casos onde não se consiga a profundidade
necessária com a forma padrão de compressão.</li>
            <li>Profundidade: pelo menos ⅓ do diâmetro anteroposterior do tórax.</li>
            <li>Frequência: 100 a 120 compressões por minuto</li>
            <li>Permitir retorno torácico após cada compressão.</li>
            <li>Evitar ventilação excessiva.</li>
                                    </ul>
</ul>
              </Accordion>
          
                                                           <Accordion title="Fatores complicadores">
            <ul>
            <li>Segurança da cena.</li>
            <li>Dificuldade de acesso à Regulação Médica.</li>
            <li>Indisponibilidade de EPIs.</li>
            <li>Número reduzido de profissionais.</li>
            <li>Comunicação ineficaz. Ressalta-se a importância da comunicação em
alça fechada.</li>
</ul>
              </Accordion>
          
                                                                     <Accordion title="Glossário">
            <ul>
            <li><b>Briefing:</b> conjunto de informações utilizadas para definições de
estratégias de execução de uma tarefa. Aqui se definem as funções de
cada um e as ações esperadas para o bom desempenho da equipe.</li>
            <li><b>Comunicação em alça fechada:</b> a comunicação verbal é repetida para
o solicitante, de forma a confirmar aquilo que foi pedido e fazer uma
checagem dupla.</li>
            <li><b>CERU/SAMU-DF:</b> Central de Regulação de Urgências do SAMU-DF.</li>
            <li><b>Debriefing:</b> é a maximização da aprendizagem por meio da
experiência. Nesse momento, por meio da experiência compartilhada,
se analisam as ações da equipe, se o planejado foi cumprido (e os
motivos do não cumprimento) e se propõem estratégias de melhoria
para a próxima ocorrência.</li>
            <li><b>Suporte Avançado de Vida:</b> modalidade de assistência em saúde ao
paciente gravemente enfermo, com a presença obrigatória do
profissional médico e enfermeiro, necessitando de materiais e
equipamentos que possibilitem a realização de procedimentos
invasivos.</li>
</ul>
              </Accordion>
                              
          
           <Accordion title="Base legal e referencial">
            <ul>
            <li>Topjian AA, Raymond TT, Atkins D, Chan M, Duff JP, Joyner BL Jr, Lasa
JJ, Lavonas EJ, Levy A, Mahgoub M, Meckler GD, Roberts KE, Sutton
RM, Schexnayder SM; Pediatric Basic and Advanced Life Support
Collaborators. Part 4: Pediatric Basic and Advanced Life Support: 2020
American Heart.
</li>
            <li>Associação Americana do Coração. Destaques das Diretrizes de RCP e
ACE de 2020 da American Heart Association.
</li>
            <li>D. Karl; Basic Life Support Provider Handbook, 2015-2020 Guidelines
and Standards.</li>
            <li>D. Karl; Pediatric Advanced Life Support Provider Handbook,
2015-2020 Guidelines and Standards.</li>
</ul>
              </Accordion>
          
                     <Accordion title="Apêndice">
            <ul>
              <li><b>Manobras de abertura das vias aéreas com vedação da máscara do BVM</b>
</li>
            <li>A – Vedação da máscara com abertura de vias aéreas em crianças</li>
              <img src="assets/RCPlactentes/apendiceA.png" className="figura"/>
            <li>B - Vedação da máscara com abertura de vias aéreas em lactentes</li>
                            <img src="assets/RCPlactentes/apendiceB.png" className="figura"/>

</ul>
              </Accordion>


  </div>
</IonContent>
</IonPage>

  );
}

export default RCPLactente;
