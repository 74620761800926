import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import '../Page.css'


const desativacao: React.FC = () => {

  return (

  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>ATO DO COMANDANTE OPERACIONAL</IonTitle>
      </IonToolbar>
    </IonHeader>


    <IonContent fullscreen>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>Publicado no Boletim Geral 207, de 1° de novembro de 2019</IonCardSubtitle>
          <IonCardSubtitle>ANEXO 15</IonCardSubtitle>

          <IonCardTitle>INSTRUÇÃO NORMATIVA N° 54/2019 - COMOP</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p align="right">&#8195; 
            Dispõe   sobre   o serviço a ser realizado por bombeiros militares subordinados ao Comando Operacional nos hospitais do Distrito Federal e dá outras providências.
          </p>

          <p align="justify">&#8195; 

            O  COMANDANTE  OPERACIONAL,  no exercício da competência prevista no art. 22, inciso V do Decreto 31.817, de 21 jun. 2010, que regulamenta o art. 10-B, inciso II, da Lei 8.255, de 20 nov. 1991, que dispõe sobre a organização básica do Corpo de Bombeiros Militar do Distrito Federal - CBMDF; e considerando o teor dos documentos que instruem o Processo SEI n° 053-00079356/2019-23, resolve:
          </p>
          <p align="justify">&#8195;

            TORNAR PÚBLICA a presente Instrução Normativa, nos termos que se seguem.
          </p>

          <p align="center">&#8195;

            CAPÍTULO I 
          </p>
          <p align="center">&#8195;
            DA FINALIDADE
          </p>
          <p align="justify">&#8195;
            Art. 1° Esta norma regula as funções desenvolvidas por militares nos hospitais HRC, HRT e IHBDF, ou outros que porventura sejam acescentados, tendo como objetivos:
          </p>
          <p align="justify">&#8195;
            I - Diminuição do tempo-resposta das ocorrências atendidas pelo CBMDF;
          </p>
          <p align="justify">&#8195;
            II - Aumento do tempo em que as Unidades de Resgate (UR) permanecem ativadas para o socorro;
          </p>
          <p align="justify">&#8195;
            III - Rápida recuperação dos materiais de Atendimento Pré-Hospitalar retidos nas unidades hospitalares;
          </p>
          <p align="justify">&#8195;
            IV - Intermediação entre as equipes de socorro do CBMDF e as equipes de emergência do hospital, de modo a agilizar o atendimento das vítimas;
          </p>
          <p align="justify">&#8195;
            Art. 2° Todos os objetivos convergem para a mesma finalidade, que é prestar um atendimento pré-hospitalar de excelência à população do Distrito Federal, atendendo as ocorrências emergenciais nos padrões internacionais.
          </p>
          <p align="justify">&#8195;
            Art. 3° Todos os órgãos e militares envolvidos devem dirigir suas ações no sentido de cumprir os objetivos e a finalidade descritos nesta norma.
          </p>
          <p align="center">&#8195;

            CAPÍTULO II
          </p>
          <p align="center">&#8195;
            DOS RECURSOS EMPREGADOS
          </p>
          <p align="justify">&#8195;
            Art. 4° Inicialmente, o serviço será desenvolvido por militares voluntários (GSV), podendo ser realizado também por militares da escala normal de serviço ou do expediente, conforme disponibilidade de pessoal.
          </p>
          <p align="justify">&#8195;
            Art. 5° O serviço será desenvolvido diariamente e de forma initerrupta, da seguinte forma:
          </p>
          <p align="justify">&#8195;
            I - HRC: 01 (um) militar de 07h00 às 19h00 e 01 (um) militar de 19h00 às 07h00;
          </p>
          <p align="justify">&#8195;
            II - HRT: 01 (um) militar de 07h00 às 19h00 e 01 (um) militar de 19h00 às 07h00;
          </p>
          <p align="justify">&#8195;
            III - IHBDF: 01 (um) militar de 07h00 às 19h00 e 01 (um) militar de 19h00 às 07h00.
          </p>
          <p align="justify">&#8195;
            Parágrafo Único. Os horários poderão ser alterados, conforme interesse da Corporação.
          </p>
          <p align="center">&#8195;

            CAPÍTULO III
          </p>
          <p align="center">&#8195;
            DAS FUNÇÕES
          </p>
          <p align="justify">&#8195;
            Art.6° Compete ao bombeiro militar de serviço nos hospitais:
          </p>
          <p align="justify">&#8195;
            I – Auxiliar na recuperação dos materiais de APH do CBMDF;
          </p>
          <p align="justify">&#8195;
            II - Identificar a localização exata dos materiais utilizados em APH pelo CBMDF no hospital, colocando-os em local sob sua guarda, assim que forem liberados, para que não sejam extraviados;
          </p>
          <p align="justify">&#8195;
            II - Auxiliar na comunicação entre as equipes de resgate do CBMDF e o hospital, sempre que possível;
          </p>
          <p align="justify">&#8195;
            IV - Estabelecer um contato direto com os funcionários dos hospitais para liberação rápida dos materiais utilizados em APH pelo CBMDF;
          </p>
          <p align="justify">&#8195;
            V - Manter relação de cordialidade com os funcionários, servidores, médicos, enfermeiros e demais colaboradores do hospital;
          </p>
          <p align="justify">&#8195;
            VI - Manter o local de trabalho limpo e organizado;
          </p>
          <p align="justify">&#8195;
            VII - Solicitar à equipe do hospital materiais para realização de higiene mínima dos materiais recuperados, de forma a não guardá-los de qualquer maneira;
          </p>
          <p align="justify">&#8195;
            VIII - Solicitar o material previsto no inciso anterior ao GBM mais próximo ou ao GAEPH, caso o hospital não tenha ou não forneça;
          </p>
          <p align="justify">&#8195;
            IX - Responsabilizar-se sobre os materiais sob sua cautela (mesa, cadeira, HT etc.), comunicando imediatamente quaisquer alterações.
          </p>
          <p align="justify">&#8195;
            X - Apresentar-se em até 15 (quinze) minutos do início do serviço ao Dia a Prontidão do GAEPH, trazendo ao conhecimento deste quaisquer informações importantes relacionadas ao serviço;
          </p>
          <p align="justify">&#8195;
            XI - Comunicar imediatamente aos responsêaveis respectivos GBMs sobre os materiais que estiverem disponíveis para serem transportados;
          </p>
          <p align="justify">&#8195;
            XII - Ser proativo na resolução das demandas apresentadas, buscando sempre uma postura de prevenção em detrimento da reação;
          </p>
          <p align="justify">&#8195;
            XIII - Estar atento à comunicação via rádio, respondendo sempre que for chamado.
          </p>
          <p align="justify">&#8195;
            Parágrafo Único. Quando o serviço for realizado de forma ininterrupta, o militar que sai de serviço deve repassar as informações e alterações pessoalmente ao militar que entra de serviço.
          </p>
          <p align="justify">&#8195;
            Art. 7° Compete ao Dia a Prontidão do GAEPH:
          </p>
          <p align="justify">&#8195;
            I - Inserir a presença ou falta dos militares no sistema SEIOP;
          </p>
          <p align="justify">&#8195;
            II - Comunicar à Secretaria do GAEPH sobre as alterações do serviço (faltas, atrasos etc.);
          </p>
          <p align="justify">&#8195;
            III - Colaborar para que os objetivos previstos nesta norma sejam efetivados, auxiliando no que for possível.
          </p>
          <p align="justify">&#8195;
            Art. 8° Compete à Secretaria (SEGAE) do GAEPH:
          </p>
          <p align="justify">&#8195;
            I - Enviar, diretamente à Seção de Coordenação da GSV (SEGSV/COMOP), impreterivelmente até o 1° dia útil de cada mês, o relatório mensal constante no anexo "b", da Portaria n.° 11, de 26/04/12, publicada no Boletim Geral n. 81 de 27/04/12;
          </p>
          <p align="justify">&#8195;
            II - Trazer ao conhecimento do Comandante do GAEPH quaisquer informações relevantes sobre o serviço nos hospitais;
          </p>
          <p align="justify">&#8195;
            III - propor soluções ao Comandante do GAEPH, quando for o caso.
          </p>
          <p align="center">&#8195;

            CAPÍTULO IV
          </p>
          <p align="center">&#8195;
            DO ACIONAMENTO
          </p>
          <p align="justify">&#8195;
            Art. 9° Os militares de serviço nos hospitais poderão ser acionados via rádio do CBMDF, de acordo com a canaleta utilizada na área de atuação do hospital.
          </p>
          <p align="justify">&#8195;
            Parágrafo Único. Poderão também ser acionados por celular funcional, cujos números serão amplamente divulgados.
          </p>
          <p align="center">&#8195;

            CAPÍTULO V
          </p>
          <p align="center">&#8195;
            DAS DISPOSIÇÕES FINAIS
          </p>
          <p align="justify">&#8195;
            Art. 10. As alterações devem ser comunicadas imediatamente ao Dia a Prontidão do GAEPH.
          </p>
          <p align="justify">&#8195;
            Art. 11. Os casos omissos deverão ser informados ao Comandante do GAEPH, para análise e deliberação, e serão resolvidos em última instância pelo Comandante Operacional.
          </p>
          <p align="justify">&#8195;
            Art. 12. A presente Instrução Normativa entra em vigor na data de sua publicação, revogando-se as disposições em contrário.
          </p>

          <p>&#8195;
            <br></br>
            (NB CBMDF/COMOP/GACOP SEI-053-092587/2019-22)
          </p>

        </IonCardContent>
      </IonCard>
    </IonContent>
  </IonPage>

);
}

export default desativacao;
