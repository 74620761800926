import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import '../Page.css'


const desativacao: React.FC = () => {

  return (

  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>ATO DO COMANDANTE OPERACIONAL</IonTitle>
      </IonToolbar>
    </IonHeader>


    <IonContent fullscreen>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>Publicado no Boletim Geral 028, de 10 de fevereiro de 2020</IonCardSubtitle>

          <IonCardTitle>ATO DO COMANDANTE OPERACIONAL - III - INSTRUÇÃO NORMATIVA 56/2020-CBMDF/COMOP</IonCardTitle>
        </IonCardHeader>
          <IonCardContent>

            <p align="right">&#8195; 
            Dispõe   sobre   o   emprego   de   militares   no   serviço operacional de APH e dá outras providências.
          </p>

          <p align="justify">           
            <br></br>
            &#8195; 

              O COMANDANTE OPERACIONAL, no uso das atribuições que lhe confere o art. 22 do Decreto 31.817, de 21 jun. 2010, que regulamenta o art. 10-B, inciso II, da Lei 8.255, de 20 nov. 1991, que dispõe sobre a organização básica do CBMDF; considerando osProcessos SEI 00053-00042651/2017-62 e SEI 0053-00111250/2019-21 (proposta de alteração dos artigos 5°e 7°da IN 41/2018), resolve:</p>

            <p align="justify">&#8195; 
            TORNAR PÚBLICA a presente Instrução Normativa.</p>

            <p align="justify">&#8195; 
            Art. 1° Esta norma estabelece regras relativas ao emprego de militares no serviço operacional de APH.</p>

            <p align="justify">&#8195; 
            Art. 2° Os Grupamentos Especializados e Multiempregos que possuam Unidades de Resgate e Suporte Básico deverão providenciar, com antecedência, a substituição dos militares Socorristas/Auxiliares com afastamentos regulamentares previstos (férias, abono, Licença Especial, Dispensas médicas prolongadas, etc).</p>

            <p align="justify">&#8195; 
            Art. 3° A distribuição dos militares especializados em APH, que concorrem à escala de serviço de 24x72, deve ser equalizada pelos escalantes de cada Unidade, de modo a permitir uma distribuição equânime nas quatro alas de serviço.</p>

            <p align="justify">&#8195; 
            Art. 4° Diante da falta de militares especializados em APH durante o serviço de 12 horas, o grupamento com a falta deve procurar Socorristas/Auxiliares na escala de 12 horas, dentro do seu respectivo Comando de Área.</p>

            <p align="justify">&#8195; 
            Art. 5° Nas situações em que não haja substitutos no quartel e nem no respectivo Comando de Área, o militar mais antigo da unidade deve informar ao Coordenador de Operações via COCB, o qual fará uma busca por militares especializados na escala de 12 horas nos demais Comandos de Área.</p>

            <p align="justify">&#8195; 
            Art. 6° Não havendo militares Socorristas/Auxiliares disponíveis na escala de 12 horas, conforme descrito nos artigos anteriores, os militares Socorristas/Auxiliares escalados de 24 horas na unidade serão acionados para compor a UR ou a URSB.</p>

            <p align="justify">&#8195; 
            Art. 7° Quando o militarproveniente da escala de 24 horas for escaladonafunçãode Socorrista ou Auxiliar, em viaturas tipo UR ou URSB, terá a prerrogativa de ser liberado às 19 horas e de cumprir a escala regulamentar de UR ou URSB, desde quesatisfaça as seguintescondições:a) completar 10 horasininterruptas deserviço,exclusivamente na UR ou URSB, quando for designado namesma unidade;b) completar 9 horas ininterruptas de serviço, exclusivamente na UR ou URSB, quando movimentadode outra unidade.</p>

            <p align="justify">&#8195; 
            Art. 8° Por ocasião da confecção das escalas de serviço, sempre que a Unidade Operacional não possuir o efetivo de militares especializados, em número suficiente para compor a UR ouURSB, o Comando de Área deverá ser informado via SEI, para deliberação e possívelsolução para o caso específico.</p>

            <p align="justify">&#8195; 
            Art. 9° A presente Instrução Normativa entra em vigor na data de sua publicação.</p>

            <p align="justify">&#8195; 
            Art. 10 Revoga-se a Instrução Normativa 41/2018, publicada noBG 066, de 6 abr. 2018.</p>
          <p>&#8195;
            <br></br>
            (NB CBMDF/COMOP/GACOP-00053-00040888/2017-17)
          </p>
            </IonCardContent>
      </IonCard>
    </IonContent>
  </IonPage>

  );
}

export default desativacao;
