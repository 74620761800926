import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import '../Page.css'


const desativacao: React.FC = () => {

  return (

  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>ATO DO COMANDANTE OPERACIONAL</IonTitle>
      </IonToolbar>
    </IonHeader>


    <IonContent fullscreen>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>Publicado no Boletim Geral 232, de 10 de dezembro de 2015</IonCardSubtitle>

          <IonCardTitle>ATO DO COMANDANTE OPERACIONAL - XLI - VIATURAS REGULADAS PELA CENTRAL 192</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p align="justify">&#8195; 

            O COMANDANTE OPERACIONAL, no uso das atribuições que lhe confere o art. 22 do Decreto 31.817, de 21 jun. 2010, que regulamenta o art. 10-B, inciso II, da Lei 8.255, de 20 nov. 1991, que dispõe sobre a organização básica do CBMDF, resolve:
          </p>
          <p align="justify">&#8195; 
            Considerando o Termo de Cooperação Técnica firmado entre o CBMDF, e o SAMU-DF, referente à Regulação Médica;
          </p>

          <p align="justify">&#8195; 
            Considerando que as UR's do GAEPH, 2° GBM e 15° GBM, são reguladas pela Central 192 (SAMU); 
          </p>

        <p align="justify">&#8195; 
          Considerando que as UR's reguladas devem permanecer disponíveis para acionamento via 192, ou 193, de modo a justificar a parceria firmada;
        </p>

      <p align="justify">&#8195; 
        Considerando o grande número de prevenções e operações que envolvem o GAEPH, o 2° GBM e o 15° GBM;
      </p>

    <p align="justify">&#8195; 
      Considerando que a SEOPE/EMOPE, é o órgão do COMOP responsável pelo planejamento operacional das prevenções e operações; e
    </p>

  <p align="justify">&#8195; 
    Considerando que o Superior de Dia é a autoridade de serviço responsável por fiscalizar e dar cumprimento às ordens emanadas pelo COMOP.
  </p>
  <p align="justify">&#8195; 
    ORIENTAR o Chefe da SEOPE/EMOPE, e o Superior de Dia que evitem utilizar as UR's Reguladas pela Central 192:
  </p>

<p align="justify">&#8195; 
1) Nas prevenções ou operações em que a viatura deverá permanecer "fixa no local";
          </p>

        <p align="justify">&#8195; 
          2) Nos remanejamentos diários para outro Grupamento no intuito de suprir a baixa de viatura de APH.
        </p>


        <p align="justify">           
          <br></br>&#8195;

          Em  consequência,  os órgãos envolvidos tomem conhecimento e providências.
        </p>

        <p>&#8195;
          <br></br>
          (NB CBMDF_EMOPE_SEOPE SEI-053-048250/2015)
        </p>
      </IonCardContent>
    </IonCard>
  </IonContent>
</IonPage>

);
}

export default desativacao;
