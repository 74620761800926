import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const DescarteResiduos: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Descarte de resíduos sólidos</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://drive.google.com/file/d/14wAZY0T5N4fLxh16TKyMKG52z1taZ0gt/view?usp=share_link">POP Descarte de Resíduos Sólidos (BG 237 de 17/12/2015)</a>
            </p>

</ul>
              </Accordion>
          
          
          <Accordion title="Procedimentos">
            <ul>
              <li>Averiguar a presença de resíduos gerados pelo atendimento, recolhê-los e acondiciona-los de acordo com a especificidade do material ao final do atendimento;</li>
              <li>Realizar o descarte de resíduos potencialmente infectantes em saco de lixo da cor branco leitoso, identificado pelo símbolo universal de risco biológico, resistente à ruptura e vazamento, impermeável, respeitados os limites de peso de cada saco. Devem ser utilizados dois sacos, com preenchimento somente até 2/3 de sua capacidade, sendo proibido o seu esvaziamento ou reaproveitamento. O recipiente para acondicionamento do saco de lixo deverá ser rígido, resistente à punctura, ruptura e vazamento.</li>
              <li>Descartar os sacos de lixo ao atingirem sua capacidade de resíduos infectantes, depositando- os em um dos seguintes locais: unidades de saúde do Distrito Federal (UPA ou hospitais públicos), desde que em local apropriado, ou no Grupamento de Atendimento de Emergência Pré-hospitalar do Corpo de Bombeiros Militar do Distrito Federal (GAEPH – CBMDF) nos locais indicados pela Seção de Assepsia.</li>
              <li>Descartar materiais perfurocortantes separadamente, no local de sua geração, imediatamente após o uso, em recipientes, rígidos, resistentes à punctura, ruptura e vazamento, com tampa, devidamente identificados como perfurocortantes e pelo símbolo universal de risco biológico. O volume de resíduos descartados não deve ultrapassar 2/3 da capacidade do recipiente.</li>
              <li>Descartar os recipientes de acondicionamento de materiais perfurocortantes logo após atingirem sua capacidade, em um dos seguintes locais: unidades de saúde do Distrito Federal (UPA ou hospitais públicos), desde que em local apropriado, ou no Grupamento de Atendimento de Emergência Pré-hospitalar do Corpo de Bombeiros Militar do Distrito Federal (GAEPH – CBMDF) nos locais indicados pela Seção de Assepsia.</li>

              <em>É expressamente proibido o esvaziamento desses recipientes para o seu reaproveitamento. As agulhas e objetos cortantes (bisturi) descartáveis devem ser desprezados juntamente com as seringas, sendo proibido reencapá-las ou proceder a sua retirada manualmente.</em>


              <h4>Observações</h4>
              <li>Ao descartar o recipiente de perfurocortantes, o responsável técnico deverá providenciar
                juntamente com a seção de assepsia a reposição de um recipiente novo.</li>
            </ul>  
          </Accordion>


          <Accordion title="Resultados Esperados">
            <ul>
              <li>Evitar acidentes com materiais contaminados.</li>
            </ul>
          </Accordion>

          <Accordion title="Material recomendado">
            <ul>
              <li>Saco branco, identificado pelo símbolo universal de risco biológico.</li>
              <li>Coletor de materiais perfurocortantes.</li>
              <li>Luvas.</li>
              <li>Máscara facial.</li>
              <li>Óculos de proteção.</li>
            </ul>
          </Accordion>

          <Accordion title="Sinais e sintomas">
          <ul>
            <li>Não se aplica</li>
          </ul>
        </Accordion>

        <Accordion title="Possibilidades de erro"><ul>
            <li>Tentar retirar algo de dentro do saco de lixo contendo material contaminado ou do recipiente com material perfurocortante.</li>
            <li>Reencapar objetos perfurocortantes.</li>
            <li>Descartar materiais perfurocortantes em local inapropriado.</li>
          </ul>
        </Accordion>

<Accordion title="Fatores Complicadores"><ul>
            <li>Indisponibilidade de materiais de acondicionamento de resíduos sólidos e perfurocortantes;</li>
            <li>Não comprometimento com essas orientações;</li>
            <li>Acúmulo de resíduos gerados pelo serviço de atendimento pré-hospitalar no interior da unidade de resgate.</li>
          </ul>
        </Accordion>

        <Accordion title="Glossário">
          <ul>
            <li><b>Resíduos potencialmente infectantes:</b> resíduos com a possível presença de agentes biológicos que, por suas características de maior virulência ou concentração, podem apresentar risco de infecção. </li>
            <li><b>Perfurocortantes:</b> são os objetos e instrumentos contendo cantos, bordas, pontos ou protuberâncias rígidas e agudas, capazes de cortar ou perfurar. Enquadra-se nesse grupo lâminas de barbear, bisturis, agulhas, escalpes, ampolas de vidro, lâminas, tesouras e outros assemelhados provenientes de serviços de saúde.</li>
          </ul>
        </Accordion>

        <Accordion title="Referencial bibliográfico">
          <ul>
            <li>Resolução RDC n.o 33, de 25 de fevereiro de 2003 – ANVISA.</li>
            <li>Procedimento Operacional Padrão do Serviço de Atendimento Móvel de Urgência de Santa Catarina, 2006.</li>
            <li>Resolução RDC n.° 306, de 7 de dezembro de 2004 – ANVISA.</li>
            <li>Rasia CA, Barros CC, Marcelino SC, Fernandes RWC, Pontes FC, Pedroso, GB, et al. Manual de atendimento pré-hospitalar. Brasília: Corpo de Bombeiros Militar do Distrito Federal, 2007.</li>
            <li>Brasil. Ministério da Saúde. Secretaria de Atenção à Saúde. Departamento de Ações Programáticas Estratégicas. Exposição a Materiais Biológicos - Saúde do Trabalhador, Protocolos de Complexidade Diferenciada. Ministério da Saúde, 2006.</li>
          </ul>
        </Accordion>

      </div>
    </IonContent>
  </IonPage>

);
}

export default DescarteResiduos;
