import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const RCPNeonato: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>RCP em neonatos</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://www.cbm.df.gov.br/download/reanimacao-cardiopulmonar-neonatal/">POP RCP em neonatos (BG 031 de 14/02/2022)</a>
            </p>

</ul>
              </Accordion>
          
                             <Accordion title="Resultados esperados">
            <ul>
            <li>Eficiência no reconhecimento e abordagem do RN vítima de PCR.</li>
            <li>Atendimento à Reanimação Cardiopulmonar do RN com manobras
precoces e efetivas.</li>
            <li>Utilização efetiva dos recursos disponíveis.</li>

</ul>
              </Accordion>
          
                                       <Accordion title="Material recomendado">
            <ul>
            <li>Capacete</li>
            <li>Máscara cirúrgica</li>
            <li>Óculos de proteção</li>
            <li>Luvas de procedimento</li>
            <li>Dispositivo Bolsa-vávula-máscara (BVM) <b>NEONATAL</b></li>
            <li>Conjunto de oxigenoterapia com fluxômetro</li>
            <li>Sensor de oxímetro de pulso nenonatal</li>
            <li>Aspirador manual ou elétrico</li>

</ul>
              </Accordion>
          
                                                 <Accordion title="Sinais e sintomas">
            <ul>
            <li>Quando avaliar se o RN necessita de reanimação de acordo com a
situação encontrada:</li>

                         <ul>
            <li>Gestação menor que 37 semanas</li>
            <li>Respiração irregular ou não chorou ao nascer</li>
            <li>RN com ausência de tônus muscular em flexão ou movimentos ativos</li>
            </ul>
</ul>
              </Accordion>
          
          
                                                           <Accordion title="Observações">
            <ul>
            <li>Formas de avaliação do pulso central:</li>
                          <ul>
            <li>Pulsação do cordão umbilical</li>
            <li>Pulso braquial</li>
            </ul>

</ul>
              </Accordion>
                              

                                                                               <Accordion title="Procedimentos">
            <ul>
            <li><b>RN de qualquer idade gestacional que: (a) não inicia a respiração ou (b)
              tem respiração irregular ou (c) está hipotônico:</b></li>
              
                          <ul>
            <li>Reportar-se rapidamente à regulação médica</li>
            <li>Clampear o cordão umbilical imediatamente</li>
            <li>Prover calor: envolvendo o RN totalmente com manta térmica ou lençol e,
se possível, colocar uma touca de lã (não retirar durante a reanimação)</li>
            <li>Posicionamento: Colocar o RN em decúbito dorsal com leve extensão do
pescoço, para manter a permeabilidade das vias aéreas.</li>
            <li>Limpar a face do bebê com gaze (a aspiração de vias aéreas só é
necessária caso se observe obstrução das vias aéreas após VPP)</li>
            <li>Se o RN apresentar apneia, respiração irregular e/ou FC {'<'} 100 bpm: iniciar
                VPP, preferencialmente nos primeiros <b>60 segundos de vida</b> (Golden minute).
Um profissional da equipe inicia a VPP e o outro instala o sensor do oxímetro
de pulso.</li>
            <li>Realizar VPP com bolsa-valva-máscara no ritmo de 40 a 60 insuflações por
minuto (regra mnemônica: <b>“aperta...solta...solta...aperta...solta... solta...”</b>)</li>
            <li>Instalar o sensor neonatal no pulso radial direito do RN e avaliar a SatO2 de
acordo com o tempo de vida, conforme tabela a seguir:</li>
          <img src="assets/RCPneonatos/tempodevida.png" className="figura"/>
            </ul>
              
                         <li><b>OXIGÊNIO SUPLEMENTAR</b></li>
                          <li><b>RN {'>'} 34 semanas:</b></li>
              
              
                          <ul>
                            <li>Iniciar a <b>VPP com ar ambiente</b> por 30 segundos.</li>
            <li>Caso FC continue {'<'} 100bpm, reavaliar e corrigir a técnica da VPP
(ajuste da máscara, permeabilidade de vias aéreas, pressão inspiratória)
e ventilar por mais 30 segundos (a necessidade de oxigênio suplementar é excepcional em RN {'≥'}34 semanas se a VPP é feita com a técnica
adequada)</li>
            <li>Persistindo FC{'<'}100bpm, realizar VPP com oxigênio a 100% (5L/min)
por mais 30 segundos enquanto o outro profissional reporta-se à
regulação médica.</li>
            <li>Após 30 segundos:</li>
                                                      <ul>
            <li>FC {'>'} 100bpm: assistência ao RN</li>
            <li>FC {'<'} 100bpm e {'>'}60bpm: continuar VPP com O2 suplementar</li>
            <li>FC {'<'} 60bpm: iniciar compressões torácicas</li>
                          </ul>

            </ul>             
               <li><b>RN {'<'} 34 semanas:</b></li>
              
              
                          <ul>
                            <li><b>Iniciar a VPP utilizando oxigênio a 100%</b> por 30 segundos;</li>
            <li>Se após 30 segundos o RN não melhorar (mantiver FC {'<'}100 bpm ou
respiração irregular ou a SatO2 baixa), reavaliar e corrigir a técnica da
VPP (ajuste da máscara, permeabilidade de vias aéreas, pressão
inspiratória) e ventilar por mais 30 segundos
</li>
            <li>Após 30 segundos, proceder como item 4 referente ao RN {'>'} 34
semanas</li>

            </ul>
                          

                                                     <li><b>COMPRESSÕES TORÁCICAS:</b></li>
              
              
                          <ul>
                            <li>Caso <b>FC {'<'} 60bpm</b>, iniciar compressões torácicas, preferencialmente
                              com a técnica dos <b>polegares sobrepostos</b> (Apêndice 1) sobre o terço
inferior do esterno (logo abaixo da linha intermamilar) e as mãos
envolvendo o tórax do RN, sincronizando compressão e ventilação, na
                              proporção de 3 (três) compressões para 1 (uma) ventilação <b>(3:1)</b> com
O2 a 100%.</li>
            <li>Comprimir na profundidade de 1/3 do diâmetro anteroposterior do
tórax, permitindo a reexpansão plena do tórax após cada compressão</li>
            <li><b>Após 60 segundos de RCP</b></li>
                                           <ul>           
            <li>FC {'>'} 60bpm: interromper compressões e manter VPP até FC {'>'} 100bpm.</li>
            <li>FC {'<'} 60bpm: verificar as técnicas da ventilação e compressão torácica e corrigir se necessário.</li>
            <li>Se todas as técnicas estiverem corretas e a FC permanecer {'<'} 60 bpm:
manter as manobras de ressuscitação com ritmo de 3:1 e seguir as
orientações da Regulação Médica.</li>
                          </ul>


            </ul>
                          </ul>
              </Accordion>

                                                                                  <Accordion title="Recomendações">
            <ul>
            <li>O foco deve ser a VPP! <b>A ventilação pulmonar é o procedimento
              mais importante e efetivo na reanimação do RN!</b></li>

</ul>
              </Accordion>
              

                                                                                                   <Accordion title="Fatores complicadores">
            <ul>
            <li>Segurança da cena.</li>
            <li>Dificuldade de acesso à Regulação Médica.</li>
            <li>Indisponibilidade de EPIs.</li>
            <li>Número reduzido de profissionais.</li>
            <li>RN prematuro extremo ({'<'} 30 semanas).</li>
            <li>Ausência de BVM e/ou sensor de oxímetro neonatais.</li>
            <li>Ansiedade e preocupação por parte dos familiares.</li>
</ul>
              </Accordion>

                                                                                                   <Accordion title="Glossário">
            <ul>
              <li><b>Período neonatal:</b> intervalo de tempo desde o nascimento até o 28º dia
de vida da criança</li>
              <li><b>RN:</b> recém-nascido.</li>
              <li><b>Golden minute:</b> corresponde ao 1o minuto após o nascimento, quando,
ao final do mesmo, o RN deve estar respirando bem ou ser ventilado
com BVM</li>
              <li><b>VPP:</b> Ventilação por Pressão Positiva. Tem por objetivo garantir que os
pulmões do recém-nascido inflem, garantindo a dilatação da vasculatura
pulmonar.</li>
              <li><b>Pressão Inspiratória:</b> neste caso, trata-se da pressão exercida na
insuflação do dispositivo bolsa-valva-máscara necessária para a correta
expansibilidade pulmonar do RN.</li>

</ul>
              </Accordion>
              
              
                                                                                                                 <Accordion title="Base legal e referencial">
            <ul>
              <li>Protocolo de Suporte Básico de Vida do Ministério da Saúde.</li>
              <li>Neonatal Ressucitation: 2020 American Heart Association Guidelines for
Cardiopulmonary Resuscitation and Emergency Cardiovascular</li>
              <li>Wyllie J, Bruinenberg J, Roehr CC, Rüdiger M, Trevisanuto D, Urlesberger
B.European Resuscitation Council Guidelines for Resuscitation 2015: Section
7. Resuscitation and support of transition of babies at birth. Resuscitation.
2015 Oct; 95:249-63.doi:10.1016/j.resuscitation.2015.07.029</li>
</ul>
              </Accordion>   
          
                     <Accordion title="Apêndice 1">
            <ul>
                            <li><b>Técnica de RCP com polegares sobrepostos / RCP com BVM
                              neonatal</b></li>
          <img src="assets/RCPneonatos/rcp.png" className="figura"/>
</ul>
              </Accordion>   

                                   <Accordion title="Apêndice 2">
            <ul>
                            <li><b>Orientações para RCP de alta qualidade:</b></li>
          <img src="assets/RCPneonatos/orientacoes.png" className="figura"/>
</ul>
              </Accordion>   
          
                                             <Accordion title="Apêndice 3">
            <ul>
                            <li><b>Fluxograma de reanimação neonatal:</b></li>
          <img src="assets/RCPneonatos/fluxograma.png" className="figura"/>
</ul>
              </Accordion>  
              
              
  </div>
</IonContent>
</IonPage>

  );
}

export default RCPNeonato;
