import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const ChoqueCirculatorio: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Choque Circulatório</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://www.cbm.df.gov.br/download/pop-choque-circulatorio-bg-152-de-2022/?tmstv=1667320701">POP Choque Circulatório (BG 152 de 12/08/2022)</a>
            </p>

</ul>
              </Accordion>
              <Accordion title="Procedimentos - Paciente adulto">
                <ul>
                  <li>Gerencie riscos na cena de emergência;</li>
                  <li>Avalie a biomecânica envolvida;</li>
                  <li>Gerenciar riscos na cena de emergência;</li>
                  <li>Faça uso de EPI completo conforme contexto de atendimento (vítima de trauma com ou sem diagnóstico ou suspeita de doença infectocontagiosa);</li>
                  <li>Controle imediatamente sangramentos externos que ameaçam a vida, conforme as técnicas descritas neste POP;</li>
                  <li>Avalie vias aéreas e coluna cervical;</li>
                  <li>Avalie a ventilação;</li>
                  <li>Avalie a circulação por meio de sinais sugestivos de choque circulatório, tais como: pele pálida ou cianótica e fria, ausência de pulso radial, tempo de enchimento capilar {'>'} 2 segundos, pressão de pulso diminuída;</li>
                  <li>Investigue a presença de hemorragias internas por meio de palpação abdominal (presença de hematomas, dor, distenção abdominal) e trauma torácico com alteração na expansabilidade pulmonar;</li>
                  <li>Estabilize a pelve com cinta pélvica ou lençol se houver suspeita de trauma pélvico;</li>
                  <li>Avalie o estado neurológico;</li>
                  <li>Promova controle da temperatura corporal por meio da utilização de lençóis, mantas aluminizadas e/ou controle da temperatura do salão de atendimento da viatura;</li>
                  <li>Realize acesso venoso com, preferencialmente, 2 jelcos de grosso calibre (n° 18), conforme orientação do médico regulador*;</li>
                  <li>Inicie a infusão cautelosa de fluido, preferencialmente com Ringer Lactato, conforme orientação do médico regulador*;</li>
                  <li>Imobilize fraturas, se as condições clínicas do paciente permitir;</li>
                  <li>Encaminhe ao hospital de referência, conforme orientação da regulação.</li>
                  
<h3><b>Observações</b></h3>
                  <h7>*Procedimentos realizados somente por profissionais habilitados (médicos, enfermeiros e técnicos de enfermagem, inscritos em seus respectivos conselhos de classe e habilitados pelo CBMDF).</h7>
                 
                  <h3><b>Técnicas</b></h3>
                  <h7>A ordem abaixo em que as técnicas aparecem não obedece a uma ordem de prioridade. A utilização das técnicas deve seguir a indicação clínica para cada tipo de sangramento, conforme fluxograma no Anexo 2.</h7>
                    <br></br>
                  <li><b>Pressão direta:</b> consiste na aplicação de pressão diretamente sobre o ferimento. Deve ser realizada com compressas ou gazes (com tamanho a depender da extensão do ferimento), preferencialmente estéreis, diretamente no foco hemorrágico. O tempo necessário para o controle do sangramento é variável. Todavia, deve-se realizar a pressão direta por pelo menos 5 minutos. Alternativamente, em situações com sangramentos multifocais, a pressão direta pode ser realizada por meio de curativos compressivos.</li>
                  <li><b>Preenchimento de feridas:</b> consiste em realizar o preenchimento de ferimentos
    cavitários a fim de se aumentar a pressão sobre o local do sangramento. Utilizado,
    sobretudo, em áreas juncionais como virilha, axila e pescoço.
    Não recomendado o uso dessa técnica se o ferimento se encontra na cavidade
    torácica ou abdominal.
    Não é recomendado o preenchimento com gazes comuns soltas.
    É comumente realizada com a utilização de gazes hemostáticas de longo
    comprimento e embaladas de forma sanfonada (em “Z”), pois dessa forma se obtém
    maior continuidade no preenchimento, além de facilitar a retirada.
    Pela ausência das gases hemostáticas como material padronizado do CBMDF,
    pode-se utilizar de ataduras dispostas na apresentação sanfonada fornecida pela
    Subseção de Material Operacional e Farmácia/GAEPH.
    Como continuidade da técnica, após o preenchimento, exerça forte pressão sobre a
    ferida.
    </li>
    <li><b>Torniquete:</b> é uma forma de pressão direta em que se realiza a
    pressão sobre o ferimento por meio da utilização de gazes fixadas por atadura. É
    reservado para situações em que existe sangramentos multifocais ou para fixar
    gazes em sangramentos já controlados.</li>
  <li><b>Curativo compressivo:</b> um torniquete realiza grande pressão no local de aplicação,
    comprimindo vasos e controlando o sangramento. Deve-se utilizar torniquetes
    comerciais, com efetividade comprovada. Nenhum torniquete comercial é
    atualmente reutilizável.
    Um torniquete deve ser usado em membros, quando:
    Como primeira opção em sangramentos graves em membros (contínuo e
      abundante, ou em jatos).
    Como segunda opção em sangramentos menores não controlados por pressão
    direta em membros.
    Torniquete já utilizado: avaliar a possibilidade de utilização em caso de não
    possuir outro torniquete novo ou em caso de não efetividade de outras técnicas.
  </li>

</ul>
              </Accordion>

              <Accordion title="Resultados Esperados">
                <ul>
                  <li>Reconhecer sinais de hipoperfusão;</li>
                  <li>Prevenir a falência/disfunção orgânica;</li>
                  <li>Diminuir sinais de hipoperfusão;</li>
                  <li>Evitar o agravamento;</li>
                  <li>Aumentar a sobrevida.</li>
                </ul>
              </Accordion>

              <Accordion title="Material recomendado">
                <ul>
                  <li>Torniquete tático;</li>
                  <li>Maca;</li>
                  <li>Tirantes;</li>
                  <li>Gazes comuns em embalagens convencionais;</li>
                  <li>Ataduras;</li>
                  <li>Compressas cirúrgicas;</li>
                  <li>Manta aluminizada</li>
                  <li>Lençóis;</li>
                  <li>Esfigmomanômetro;</li>
                  <li>Estetoscópio;</li>
                  <li>Oxímetro de pulso;</li>
                  <li>Conjunto de oxigenoterapia com fluxômetro;</li>
                  <li>Talas rígidas e moldáveis;</li>
                  <li>Jelcos de tamanhos variados, dando prioridade para aqueles de maiores calibres,
                    conforme condições clínicas do paciente e disponibilidade do material;</li>
                  <li>Equipo simples;</li>
                  <li>Solução salina isotônica (Ringer Lactato ou Solução Fisiológica 0,9%).</li>
                </ul>
              </Accordion>

              <Accordion title="Sinais e sintomas">
                <ul>
                  <li>Rebaixamento do nível consciência;</li>
                  <li>Agitação;</li>
                  <li>Confusão mental;</li>
                  <li>Agressividade;</li>
                  <li>Desorientação;</li>
                  <li>Sede;</li>
                  <li>Taquicardia (frequência cardíaca {'>'} 100 batimentos por minuto, se adulto);</li>
                  <li>Pressão arterial sistólica {'<'} 90 mmHg;</li>
                  <li>Taquipneia (frequência respiratória {'>'} 20 respirações por minuto);</li>
                  <li>Desconforto respiratório;</li>
                  <li>Tempo de enchimento capilar {'>'} 2 segundos;</li>
                  <li>Sudorese;</li>
                  <li>Pele fria;</li>
                  <li>Saturação periférica de oxigênio (%SpO2) {'<'} 95%.</li>
                </ul>
              </Accordion>
          
          
                    <Accordion title="Observações">
            <ul>
          
                  <li>No choque de origem distributiva (anafilático, séptico e neurogênico) a
pele poderá apresentar-se quente e ruborizada.</li>
                 <li>Fique atento a sinais de congestão (ortopneia, edema, estase de jugular,
estertores crepitantes pulmonares) no choque cardiogênico. Tais sinais também
podem ser observação na reposição volêmica indiscriminada, sobretudo em idosos,
crianças, cardiopatas e outras doenças crônicas. Nesses casos, faça a reposição
conforme orientação do médico regulador e evite a ressuscitação volêmica agressiva
(volume e velocidade de infusão elevados).</li>
                    </ul>
              </Accordion>

              <Accordion title="Recomendações">
                <ul>
                  <li>A infusão de fluídos em vítimas com sangramento maciço deve ser feita
de maneira cautelosa e sob orientação médica direta (no local) ou por meio da
regulação. Não se recomenda a infusão agressiva de líquidos, já que tal conduta
tem sido associada a consequências deletérias para o paciente, tais como:
hemodiluição, diminuição dos fatores de coagulação, coagulopatias e rompimento
de coágulos ativos. Os bolus iniciais devem buscar uma resposta hemodinâmica
satisfatória (melhora da palpação do pulso radial, por exemplo). Considere a
pressão arterial sistólica alvo de 80-90 mmHg (PAM: 60-65mmHg) em pacientes
hipotensos;</li>
                  <li>A não ser que a situação exija a permanência no local, não se justifica
retardar o transporte ao hospital a fim de se obter o acesso venoso periférico.</li>
                  <li>A escolha da solução para reposição volêmica será conforme orientação
médica. Contudo, o Ringer Lactato é a solução de primeira escolha devido à sua
composição ser mais semelhante ao plasma e servir como solução tampão,
desejável na acidose metabólica. Em sua falta, pode optar pela Solução Fisiológica
0,9%.</li>
                  <li>A imobilização de fraturas na cena de emergência só deve ser
considerada em condições clínica estáveis.</li>
                  <li>Avalie a presença de trauma pélvico e se necessário, realize a
estabilização pélvica com cinta pélvica ou lençol.</li>
                  <li>Em nenhuma hipótese, oferte líquidos e/ou alimentos.</li>
                  <li>Em situações de choque hemorrágico, assim como demais situações de
instabilidade do quadro clínico do paciente, o socorrista deverá realizar o
acionamento de Suporte Avançado de Vida (SAV) solicitando à Central de
Regulação Médica e avaliando a relação tempo resposta do SAV e tempo de
chegada do pronto socorro.</li>
                  <li>Sempre que for possível identificar o local de origem do sangramento,
aplique o torniquete de 5 cm a 7 cm acima da lesão (essa forma de aplicação é a
preferencial). Caso o ambiente, ou contexto não permita a localização exata da
origem do sangramento em um membro, aplique o torniquete o mais próximo
possível da raiz do membro</li>
                  <li>Se não houver controle do sangramento mesmo após aperto máximo,
um outro torniquete deve ser aplicado próximo ao primeiro.</li>
                  <li>Anote o horário de aplicação e informe à equipe de recebimento no
hospital.</li>
                  <li>Não afrouxe o torniquete no ambiente pré-hospitalar. Isso apenas deve
ser feito na sala de emergência, por recomendação médica.</li>
                  <li>Não aplique o torniquete sobre articulações</li>
                  <li>Torniquetes podem ser utilizados em crianças, desde que a
circunferência de membros seja compatível. Caso haja impossibilidade de utilização
em hemorragia exsanguinante nesse público, utilize preenchimento de feridas.</li>
                  
                </ul>
              </Accordion>

              <Accordion title="Fatores Complicadores">
                <ul>
                  <li>Segurança da cena;</li>
                  <li>Dificuldade no acesso ao Suporte Avançado de Vida;</li>
                  <li>Indisponibilidade de torniquete tático;</li>
                  <li>Efeito garrote do torniquete improvisado.</li>
                </ul>
              </Accordion>

              <Accordion title="Glossário">
                <ul>
                  <li><b>Equipo:</b> dispositivo fechado utilizado para a infusão de líquidos.</li>
                  <li><b>Hipoperfusão:</b> baixo fluxo sanguíneo em órgãos e tecidos ou de maneira sistêmica.</li>
                  <li><b>Jelco:</b> dispositivo composto por agulha e cânula para acesso à vasos sanguíneos.</li>
                  <li><b>Oximetria:</b> procedimento que visa medir a concentração de oxigênio no sangue.</li>
                    <li><b>Pressão de pulso:</b> diferença matemática entre a pressão arterial sistólica (PAS) e a
    diastólica. (PAD). Pressão de pulso = PAS – PAD. Sua diminuição indica redução do
    sangue ejetado pelo coração.</li>
  <li><b>Suporte Avançado de Vida:</b> modalidade de assistência em saúde na qual são
    realizadas intervenções invasivas.</li>
  <li><b>Taquicardia:</b> frequência cardíaca elevada. Acima de 100 batimentos por minuto.</li>
  <li><b>Taquipneia:</b> frequência respiratória elevada. Acima de 20 respirações por minuto.</li>
  <li><b>Falência/Disfunção Orgânica:</b> consequência da hipoperfusão gerando uma lesão
    tecidual/morte celular.</li>
</ul>
          </Accordion>

          <Accordion title="Referencial bibliográfico">
            <ul>
              <li>ATLS: Advanced trauma life support. 10th Ed. Chicago: American College of
                Surgeons; 2018.</li>
              <li>Basil. Secretaria de Estado de Saúde do Distrito Federal. Subsecretaria de
                Atenção Integral à Saúde. Comissão Permanente de Protocolos de Atenção à Saúde.
                Uso de Ácido Tranexâmico por USAs. Brasília – DF, 2020.</li>
              <li>CRASH-2 trial collaborators, Shakur H, Roberts I, Bautista R, Caballero J, Coats
                T, Dewan Y, El-Sayed H, Gogichaishvili T, Gupta S, Herrera J, Hunt B,
                Iribhogbe P, Izurieta M, Khamis H, Komolafe E, Marrero MA, Mejía-Mantilla J,
                Miranda J, Morales C, Olaomi O, Olldashi F, Perel P, Peto R, Ramana PV, Ravi RR,
                Yutthakasemsunt S. Effects of tranexamic acid on death, vascular occlusive events,
                and blood transfusion in trauma patients with significant haemorrhage (CRASH-2): a
                randomised, placebo-controlled trial. Lancet. 2010 Jul 3;376(9734):23-32</li>
              <li>El Sayed MJ, Tamim H, Mailhac A, Mann NC. Trends and Predictors of Limb
                Tourniquet Use by Civilian Emergency Medical Services in the United States. Prehosp
                Emerg Care. 2017;21(1):54-62.</li>
              <li>El-Menyar A, Sathian B, Asim M, Latifi R, Al-Thani H. Efficacy of prehospital
                administration of tranexamic acid in trauma patients: A meta-analysis of the
                randomized controlled trials. Am J Emerg Med. 2018 Jun;36(6):1079- 1087.</li>
              <li>DV, Doucet J, Levy M, Stuke L, Zietlow SP, Goodloe JM, VanderKolk WE, Fox AD,
                Sanddal ND. Guidance Document for the Prehospital Use of Tranexamic Acid in
                Injured Patients. Prehosp Emerg Care. 2016 Sep- Oct;20(5):557-9.</li>
              <li>Goolsby C, Rojas LE, Andersen M, Charlton N, Tilley L, Pasley J, Rasmussen TE,Levy MJ. Potentially survivable fatal vascular access hemorrhage with tourniquet use:
                A post-mortem analysis. J Am Coll Emerg Physicians Open. 2020 Aug 17;1(6):1224-
                1229.</li>
              <li>Graham, CA; Parke, TRJ. Critical care in the emergency department: shock and
                circulatory support. Critical Care. Emergency Medicine Journal. 2005;22.</li>

              <li>Henry R, Matsushima K, Ghafil C, Henry RN, Theeuwen H, Golden AC,
                Abramson TM, Inaba K, Increased Use of Prehospital Tourniquet and Patient Survival:
                Los Angeles Countywide Study, Journal of the American College of Surgeons (2021).</li>

              <li>Kragh JF Jr, Dubick MA. Bleeding Control With Limb Tourniquet Use in the
                Wilderness Setting: Review of Science. Wilderness Environ Med. 2017
                Jun;28(2S):S25-S32.</li>

              <li>Kragh JF Jr, Walters TJ, Baer DG, Fox CJ, Wade CE, Salinas J, Holcomb JB.
                Practical use of emergency tourniquets to stop bleeding in major limb trauma. J
                Trauma. 2008 Feb;64(2 Suppl):S38-49; discussion S49-50.</li>

              <li>Mitra B, Bernard S, Gantner D, Burns B, Reade MC, Murray L, Trapani T, Pitt V,
                McArthur C, Forbes A, Maegele M, Gruen RL; PATCH-Trauma study investigators;
                PATCH-Trauma Study investigators. Protocol for a multicentre prehospital randomised
                controlled trial investigating tranexamic acid in severe trauma: the PATCH-Trauma
                trial. BMJ Open. 2021 Mar 15;11(3):e046522.</li>

              <li>Peng HT. Hemostatic agents for prehospital hemorrhage control: a narrative
                review. Mil Med Res. 2020 Mar 25;7(1):13.</li>

              <li>Pereira B, Dorigatti A, Calderon L, Negrão M, Meirelles G, Duchesne J. Prehospital environment bleeding: from history to future prospects. Anaesthesiol Intensive
                Ther Semler MW, Self WH, Rice TW. Balanced Crystalloids versus Saline in Critically
                Ill Adults. N Engl J Med. 2018; 378(20): 1951.
                doi:10.1056/NEJMc1804294.</li>

              <li>Smith AH, Laird C, Porter K, Bloch M. Haemostatic dressings in prehospital care.
                Emerg Med J. 2013 Oct;30(10):784-9</li>

              <li>Spahn DR, Bouillon B, Cerny V, Duranteau J, Filipescu D, Hunt BJ, Komadina R,
                Maegele M, Nardi G, Riddez L, Samama CM, Vincent JL, Rossaint R. The European
                guideline on management of major bleeding and coagulopathy following trauma: fifth
                edition. Crit Care. 2019 Mar 27;23(1):98</li>

              <li>Tactical Combat Casualty Care. TCCC Guidelines (2021). Committe on TCC,
                2021.</li>

              <li>Te Grotenhuis R, van Grunsven PM, Heutz WM, Tan EC. Prehospital use of
                hemostatic dressings in emergency medical services in the Netherlands A prospective
                study of 66 cases. Injury. 2016 May;47(5):1007-11</li>

              <li>Teixeira PGR, Brown CVR, Emigh B, Long M, Foreman M, Eastridge B, Gale S,
                Truitt MS, Dissanaike S, Duane T, Holcomb J, Eastman A, Regner J; Texas
                Tourniquet Study Group. Civilian Prehospital Tourniquet Use Is Associated with
                Improved Survival in Patients with Peripheral Vascular Injury. J Am Coll Surg. 2018
                May;226(5):769-776</li>

              <li>Travers S, Lefort H, Ramdani E, Lemoine S, Jost D, Bignand M, Tourtier JP.
                Hemostatic dressings in civil prehospital practice: 30 uses of QuikClot Combat Gauze.
                Eur J Emerg Med. 2016 Oct;23(5):391-4</li>


            </ul>
          </Accordion>

                        <Accordion title="Anexo 1">
              <ul>
                <h3><b>ORIENTAÇÕES PARA UTILIZAÇÃO DO ÁCIDO TRANEXÂMICO NO APH</b></h3>
                  <h7>*Trata-se de uma medicação utilizada atualmente apenas no Suporte Avançado de vida.</h7>
                   <br></br>
                <h7><b>QUANDO UTILIZAR</b></h7>
                   <br></br>
                <li>Trauma penetrante em pescoço, tórax, abdome ou pelve com sinais de choque (FC {'>'}100bpm, PAS {'<'} 90mmHg, pulso radial filiforme, sudoreico, perfusão lentificada {'>'} 2 segundos); </li>
<li>Trauma em extremidades com sangramento ativo e perda de mais de 500mL
de sangue ou sinais de choque hipovolêmico grau II ou mais (FC {'>'} 100bpm,
PAS {'<'} 90mmHg, pulso radial filiforme, sudoreico, perfusão lentificada {'>'} 2
segundos); </li>
                  <li>Trauma contuso em pescoço, tórax, abdome ou pelve com sinais de choque
(FC {'>'}100bpm, PAS {'<'} 90mmHg, pulso radial filiforme, sudoreico, perfusão
lentificada {'>'} 2 segundos); </li>
                 <li> Hemorragia visível ativa com perda de mais de 500mL de sangue. </li>
                  <li>Paciente com hemorragia importante pós-parto e sinais de choque hipovolêmico grau II ou mais (FC {'>'} 100bpm, PAS {'<'} 90mmHg, pulso radial filiforme, sudoreico, perfusão lentificada {'>'} 2 segundos).</li>
                     <br></br>
                  <h7><b>QUANDO NÃO UTILIZAR</b></h7>
                    <br></br>
                  <li>Hipersensibilidade</li>
                    <li>Mais de 3 horas do trauma ou do início da hemorragia</li>
                   <li> Gestante</li>
                    <li>Insuficiência renal crônica</li>
                    <li>Não há evidências de eventos trombóticos com o uso do ácido tranexâmico,
mas ainda há preocupações teóricas, e recomenda-se cuidado em pacientes
com histórico recente ou significativo de tromboembolismo venoso.</li>
                    <br></br>
                    <h7><b>FORMA DE ADMINISTRAÇÃO</b></h7>
                <br></br>
                      <h7>Considerando a forma de apresentação disponível para o Ácido Tranexâmico, administre como a seguir:</h7>
                        
                      <li>Dilua 4 ampolas de 250 mg (1g total) em 100 ml de Solução Fisiológica 0,9%</li>
                      <li>Administre endovenoso em 10 min – Vazão de 10ml/min </li>
                                
                            </ul>
          </Accordion>
                
                
         <Accordion title="Anexo 2">
              <ul>
                <img src="assets/ChoqueCirculatorio/fluxograma.jpg" className="figura"/>
                
                
                
                            </ul>
          </Accordion>
                
                
                
        </div>
      </IonContent>
    </IonPage>

  );
}

export default ChoqueCirculatorio;
