import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import '../Page.css'


const desativacao: React.FC = () => {

  return (

  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>ATOS DO COMANDANTE OPERACIONAL</IonTitle>
      </IonToolbar>
    </IonHeader>


    <IonContent fullscreen>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>Publicado no Boletim Geral 081, de 2 de maio de 2019</IonCardSubtitle>

          <IonCardTitle>ATOS DO COMANDANTE OPERACIONAL - XXXVI - ATENDIMENTO PRÉ-HOSPITALAR DO CBMDF</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p align="justify">&#8195; 

            O  COMANDANTE  OPERACIONAL,  no  uso  das  atribuições  que  lhe  conferemosarts.  22  e  23,  do Decreto 31.817, de 21 jun. 2010, que regulamenta o art. 10-B, inciso II, da Lei 8.255, de 20 nov. 1991, que dispõe sobre a organização básica do CBMDF, combinado com os arts. 64 e 65 do Decreto 4.346, de 26 ago.2002 –RDE, resolve:
          </p>
          <p align="justify">&#8195; 
            TORNAR  PÚBLICO,  como Anexo 10,  informação  de  atendimento  pré-hospitalar  do  CBMDF  e retenção de maca nos hospitais.
          </p>
          <p>&#8195;
            <br></br>
    (NB CBMDF/COMOP/GACOP-00053-00025599/2019-41)
          </p>
        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>ANEXO 10</IonCardSubtitle>

          <IonCardTitle>ATENDIMENTO PRÉ-HOSPITALAR DO CBMDF - RETENÇÃO DE MACA NOS HOSPITAIS</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

          <p align="justify">&#8195; 
            O COMANDANTE DO COMANDO OPERACIONAL, no uso das atribuições que lhe confere o art. 22 e 23, do Decreto n.° 31.817, de 21 de junho de 2010, que regulamenta o inciso II, do art. 10-B, da Lei  n.° 8.255, de 20  de novembro de 1991, que  dispõe sobre a Organização Básica do CBMDF, combinado com os arts. 64 e 65 do Decreto 4.346, de 26 de agosto de 2002 -RDE resolve:
          </p>
          <p align="justify">&#8195; 
            Considerando o atual contexto de dificuldades que o serviço de Atendimento Pré-Hospitalar do Corpo de Bombeiros  Militar  do  Distrito  Federal  têm  enfrentado  no  que  se  refere  as  constantes  retençõesde macas  de  viaturas  do  tipo  Unidade  de  Resgate - UR  e  Unidade  de  Resgate  e  Suporte  Básico  de  Vida - URSB;
          </p>
          <p align="justify">&#8195; 
            Considerando que a retençãode macas tempor consequência leva a baixa temporária da viatura de atendimento de emergências pré-hospitalar, desguarnecendo a região administrativa específica de origem da viatura,  ou  até  mesmo, regiões administrativas  do  entorno  tendo  em  vista  que  as  viaturas  de  APH atuam em conjunto com outras unidades;
          </p>
          <p align="justify">&#8195; 
            Considerando  que  a  falta  de  viatura  de  APH  disponível  para  atendimento  de  emergências  pode contribuir  para  agravo,  sequelas  ou  até  mesmo  óbito  do  paciente/vítima  que  ao  acionar  o  socorro  fica impossibilitado de ser atendido em decorrência da baixa da viatura;
          </p>
          <p align="justify">&#8195; 
            Considerando  que  o Conselho  Federal  de  Medicina  (CFM),  em  Resolução  nº 2.110  de  25  de setembro  de  2014,  em  seu Art.  21 diz  que: “É de responsabilidade do médico receptor da unidade de saúde  que  faz  o  primeiro  atendimento  a paciente  grave  na  sala  de  reanimação  liberar  a  ambulância  e  a equipe, juntamente com seus equipamentos, que não poderão ficar retidos em nenhuma hipótese”.
          </p>
          <p align="justify">&#8195; 
            Parágrafo único. “No caso de falta de macas ou qualquer outra condição que impossibilite a liberaçãoda  equipe,  dos  equipamentos  e  da  ambulância,  o  médico  plantonista  responsável  pelo  setor  deverá comunicar  imediatamente  o  fato  ao  coordenador  de  fluxo  e/ou  diretor  técnico,  que  deverá  (ão) tomar  as providências   imediatas para   a   liberação   da   equipe   com   a   ambulância,   sob   pena   de   ser   (em) responsabilizados pela retenção da mesma.”
          </p>
          <p align="justify">&#8195; 
            Considerando  a  Resolução  CFM  n° 1.672/2003: Art.  1°-I “O hospital  previamente estabelecido como  referência  não  pode  negar  atendimento  aos  casos  que  se  enquadrem  em  sua  capacidadede resolução”. Art.   2° “Os  médicos  diretores  técnicos  das  instituições,  inclusive  os  dos  serviços  de atendimento pré-hospitalar, serão responsáveis pela efetiva aplicação destas normas”;
          </p>
          <p align="justify">&#8195; 
            Considerando Consulta  pública  realizada  pelo Conselho  Regional  de  Medicina  do  Estado  de  São Paulo (CREMESP) n° 14.402/04:  Assunto: Sobre  as macas das ambulâncias serem retidas em hospitais, sob  o  pretexto  de  “vaga  zero”,  acarretando  demora  na  liberação  das  mesmas  para  subsequentes atendimentos. O Diretor Clínico do hospital que reter a ambulância, será responsável no atraso dos demais casos de atendimento";
          </p>
          <p align="justify">&#8195; 
            Considerando  que  o  próprio  Código  Penal  em  seu - Art.  257- Subtrair,  ocultar  ou  inutilizar  por ocasião  de  incêndio,  inundação,  naufrágio,  ou  outro  desastre  ou  calamidade,  aparelho,  material  ou qualquer meio  destinado  a serviço de combate ao perigo,  de socorro ou salvamento; impedir  ou dificultar serviço  de  tal  natureza: - Pena - reclusão  de  2  (dois)  a  5  (cinco)  anos,  e  multa.  Portanto,  sempre  que houver  obstrução  do  atendimento,  sem  motivo  que  o  justifique  que  caracterize  infração  aos  ditames  do Código Penal, pode ser aberto Boletim de Ocorrência na Delegacia de polícia da região, com as devidas justificativas, 
          </p>
          <p align="justify">&#8195; 
            DETERMINA que  as condutas a serem tomadas pelos militares que tripulam as viaturas do tipo UR ou  URSB,  no  caso  de  retenção  de  macas  nas  unidades  de  saúde,  deverão seguir  as  seguintes  ações sequencialmente,  onde  cada  etapa  subsequente  ocorrerá  somente  se  a  etapa  anterior  não  atingir  o objetivo  final  que  é  a  liberação  da  maca  e  a  ativação  da  viatura  para  assistir  a  população  do  Distrito Federal.
          </p>
          <p align="justify">&#8195; 
            No  caso  de  retenção  de  macas pela  unidade  de  saúde,  o  militar  responsável  técnico  da  viatura  de APH deverá:
          </p>
          <p align="justify">&#8195; 
            1°)  Reportar-se  ao médico  da  unidade  de  saúde  em  questão  informando  sobre  os  prejuízos  que  a retenção  da  maca  acarretará  ao  serviço  de  atendimento  pré-hospitalar, a saber, o desguarnecimento da região de  origem  da  UR/URSB, no  que  se  refere  a  impossibilidade  de  atendimento  a  chamados  de emergências local ou entorno, solicitando liberação imediata das macas. Não havendo êxito;
          </p>
          <p align="justify">&#8195; 
            2°) reportar-se ao Chefe de Equipe da unidade de saúde no intuito de que o mesmo seja informando sobre a atual situação, alertando-o para os prejuízos causados ao atendimento de APH diante da situação da maca retida. Aguardar no máximo 30 (trinta) minutos. Não havendo êxito;
          </p>
          <p align="justify">&#8195; 
            3°) reportar-se à Central de Regulação Médica do SAMU-DF, tendo em vista a Portaria Conjunta n.°40/2018  entre  Secretaria  de  Saúde  do  DF  e  CBMDF, para  informar  a  situação  ao médico  regulador  no intuito  de  que  o  mesmo  possa  tentar  intervir  tecnicamente, na  condição  de  autoridade  sanitária,  junto  ao médico da unidade no intuito de tentar conseguir a liberação da maca da viatura. Não havendo êxito;
          </p>
          <p align="justify">&#8195; 
            4) reportar-se ao Oficial De-Área, de acordo com a região onde a viatura esta  vinculada (área leste ou área oeste) para que o mesmo possa tomar as providências administrativas necessárias. Não havendo êxito;
          </p>
          <p align="justify">&#8195; 
            5) o Oficial De-Área deverá reportar-se-á ao Supervisor De-Área para  que o mesmo possa tomar as providências administrativas necessárias. Não havendo êxito;
          </p>
          <p align="justify">&#8195; 
            6)  o  Supervisor  De-Área  reportar-se-á  ao  Superior  De-Área  para  que  o  mesmo  possa  tomar  as providências administrativas necessárias.
          </p>
          <p align="justify">&#8195; 
            Os militares tripulantes das URs/URSBs, ao constatarem a retenção das macas, devem também de imediato comunicar aos militares de serviço na  função de Dia-a-Garagem, para que este faça constarno SEIOp, o motivo da baixa da viatura.
          </p>
          <p align="justify">&#8195; 
            Os  militares  de  serviço  na  função  de  Dia-a-Garagem,  Dia-a-Prontidão e  Oficial  de  Área,  devem fiscalizar se os procedimentos acima foram obedecidos, antes de desativar as viaturas tipo URs/URSBs.
          </p>
          <p align="justify">&#8195; 
            Cumpra-se.
          </p>
        </IonCardContent>
      </IonCard>
    </IonContent>
  </IonPage>

  );
}

export default desativacao;
