import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const SUAPH: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Serviço Unificado de Atendimento Pré-Hospitalar do DF</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://www.drive.google.com/file/d/1oflhWX6xizIjzJ8mhRreS_soaJagCVrB/view?usp=share_link/">POP SUAPH (BG 162 de 29/08/2022)</a>
            </p>

</ul>
              </Accordion>
          
                    <Accordion title="Objetivos">
            <ul>
 
              <li>Instituir o Serviço Unificado de Atendimento Pré-Hospitalar em Urgências e Emergências (SUAPH), com anuência do Sr. Secretário de Saúde do DF
              e o Sr. Comandante Geral do Corpo de Bombeiros do Distrito Federal;</li>
              <li>Estabelecer critérios, normas e rotinas sobre a atuação da equipe integrada do Serviço de Atendimento Móvel de Urgência (SAMU) e
              Corpo de Bombeiros Militar do Distrito Federal (CBMDF) escalados no SUAPH.</li>
          </ul>
                      
                                    </Accordion>
          
                              <Accordion title="Alcance">
            <ul>
 
              <li>Rede de Urgência e Emergência da Secretaria de Saúde do Distrito Federal (RUE/SESDF)</li>
              <li>Complexo Regulador em Saúde do Distrito Federal (CRDF/SESDF)</li>
              <li>Diretoria do SAMU 192 (SAMU/CRDF/SESDF)</li>
          </ul>
                      
                                    </Accordion>
                              


  </div>
</IonContent>
</IonPage>

  );
}

export default SUAPH;
