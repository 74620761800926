import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import '../Page.css'


const desativacao: React.FC = () => {

  return (

  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>ATO DO COMANDANTE OPERACIONAL</IonTitle>
      </IonToolbar>
    </IonHeader>


    <IonContent fullscreen>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>Publicado no Boletim Geral 143, de 28 de julho de 2017</IonCardSubtitle>

          <IonCardTitle>ATO DO COMANDANTE OPERACIONAL - XLVII - INFORMAÇÃO SOBRE O FORNECIMENTO DE REFEIÇÕES A MILITARES NAS UNIDADES HOSPITARES DO DISTRITO FEDERAL</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p align="justify">&#8195; 

            O COMANDANTE OPERACIONAL, no uso das atribuições que lhe conferem os arts. 22 e 23 do Decreto 31.817, de 21 jun. 2010, que regulamenta o art. 10-B, inciso II, da Lei 8.255, de 20 nov. 1991, que dispõe sobre a organização básica do CBMDF; e considerando a Portaria 50 de 16 out. 2012, publicada no item VII do BG 195, de 17 out. 2012, e
          </p>
          <p align="justify">&#8195; 
            Considerando que os militares do CBMDF percebem mensalmente a etapa alimentação;
          </p>

          <p align="justify">&#8195; 
            Considerando o fim da vigência da Portaria-Conjunta 4/2011, publicada no DODF 173, de 5 set. 2011, desde setembro de 2016, resolve: 
          </p>
          <p align="justify">&#8195; 
            INFORMAR  aos  militares  do  CBMDF que  a  Secretaria  de  Saúde  do  Distrito  Federal  está desobrigada, desde setembro de 2016, a fornecer refeições aos componentes do Serviço de Atendimento Pré-Hospitalar do CBMDF e a minuta de convênio que está em fase de elaboração não contempla tal concessão.  Sendo  assim,  não  há  alimentação  disponível  para  Bombeiros  Militares  nas  Unidades Hospitalares do DF.
          </p>

          <p align="justify">           
            <br></br>&#8195;

            Em  consequência,  os comandantes  de  Grupamentos  deverão  orientar  seus  militares  acerca  do assunto.
          </p>

          <p>&#8195;
            <br></br>
            (NB CBMDF/COMOP/GACOP SEI-053-093720/2016)
          </p>
        </IonCardContent>
      </IonCard>
    </IonContent>
  </IonPage>

);
}

export default desativacao;
