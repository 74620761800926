import { useState } from 'react';
import { IonSearchbar, IonList, IonItem, IonLabel, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import './Page.css';

const Portarias: React.FC = () => {

  const [searchText, setSearchText] = useState("");
  const list = [
    {titulo:"IN 76 - 2022 - Ordem de ativação de URs e URSBs", rota:"./INs/prioridadedeativacao"},
    {titulo:"Autorização de uso do colete operacional no APH", rota:"./INs/uso-colete"},
    {titulo:"Desativação da UR após 15 minutos do horário da rendição", rota:"./INs/desativacao-ur"},
    {titulo:"IN 11 - 2015 - Desconcentração dos recursos humanos do serviço de APH", rota:"./INs/desconcentracao"},
    {titulo:"IN 56 - 2020 - Emprego   de   militares   no   serviço operacional de APH", rota:"./INs/emprego-militares-aph"},
    {titulo:"Informação sobre funcionamento do Núcleo de Saúde Mental do SAMU", rota:"./INs/nusam"},
    {titulo:"Informação sobre alimentação nos hospitais", rota:"./INs/alimentacao-hospitais"},
    {titulo:"Notificação compulsória de situação de violência", rota:"./INs/notificacao-compulsoria"},
    {titulo:"Orientação quanto ao uso dos celulares funcionais das URs", rota:"./INs/celular-funcional"},
    {titulo:"Orientacão sobre atendimento a vítima de animais peçonhentos", rota:"./INs/animais-peconhentos"},
    {titulo:"Procedimentos para casos de retenção de macas em hospitais", rota:"./INs/retencao-macas"},
    {titulo:"Prioridade de URs não reguladas em prevenções", rota:"./INs/viaturas-reguladas"},
    {titulo:"Funcionamento dos postos hospitalares", rota:"./INs/postos-hospitalares"},
    {titulo:"IN 4 - 2015 - Restrição ao atendimento de pacientes em hospitais públicos", rota:"./INs/restricaoredepublica"},
    {titulo:"IN 10 - 2015 - Escala de condutores para a Unidade de Resgate", rota:"./INs/escalacondutores"},
    {titulo:"IN 23 - 2016 - Padronização de materiais e equipamentos nas viaturas de APH", rota:"./INs/padronizacao"},
  ];


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Instruções Normativas e Portarias </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonSearchbar value={searchText} 
          onIonChange={e => setSearchText(e.detail.value!)}>
        </IonSearchbar>
        <IonList>
          {list
            .filter(({ titulo, rota }) => titulo
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toUpperCase()
              .includes(searchText
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toUpperCase()))
            .map(l => (
              <IonItem key={l.titulo} routerLink={l.rota}>
                <IonLabel className="ion-text-wrap">{l.titulo}</IonLabel>
              </IonItem>
          ))}
        </IonList>

      </IonContent>
    </IonPage>
  );
};

export default Portarias;
