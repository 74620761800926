import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const SinaisVitais: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Aferição dos Sinais Vitais</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://www.cbm.df.gov.br/download/sinais-vitais/">POP Aferição de Sinais Vitais (BG 031 de 14/02/2022)</a>
            </p>

</ul>
              </Accordion>
          
                  <Accordion title="Procedimentos">
            <ul>
              
              <li><b>PULSO/FREQUÊNCIA CARDIÁCA</b></li>
              
            <ul>
              <img src="assets/sinaisvitais/variaveisFC.png" className="figura"/>
              
            <li>Determine o acesso/localização do pulso. Utilize, preferencialmente, o pulso
radial. Alternativas são a artéria carótida, braquial, femoral, poplítea ou pediosa; </li>
            <li>Avalie sinais e sintomas e a existência de fatores que influenciam a frequência
e o ritmo (exercícios, posição, medicações, temperatura e idade);</li>
            <li>Coloque as pontas dos primeiros dois dedos (indicador e médio) de sua mão
no sulco ao longo do lado radial ou do lado do polegar no pulso do paciente. Estenda suavemente o pulso do paciente com a palma da mão virada para
baixo até que você perceba a pulsação mais forte;</li>
            <li>Faça uma pressão da artéria contra o osso rádio, inicialmente realizando uma
pressão maior para depois diminuir a pressão, de forma que a pulsação se
torne facilmente palpável.</li>
            <li>Após sentir um pulso regularmente, olhe para o relógio na outra mão e comece
a contar a frequência.</li>
            <li>Se o pulso está regular, tome a frequência durante 30 segundos e multiplique o
total por dois.</li>
            <li>Se o pulso está irregular (traduz arritmia cardíaca), tome a frequência
durante 1 minuto. Avalie a frequência e o padrão da irregularidade.
Compare os pulsos radiais bilateralmente.</li>
            </ul>

              
                        <li><b>RESPIRAÇÃO/FREQUÊNCIA RESPIRATÓRIA</b></li>
            <ul>
              <img src="assets/sinaisvitais/variaveisFR.png" className="figura"/>
              
          <li>Determine a necessidade de avaliar a respiração. Identifique fatores de risco
para alterações, incluindo febre, dor, ansiedade, doenças na parede torácica ou
pulmonar, lesão cerebral e idade.
</li>
         <li>Observe o ciclo respiratório completo (inspiração e expiração). Utilize o relógio
e comece a contar a frequência.
</li>
           <li>Se o ritmo estiver regular, conte o número de respirações em 30 segundos e
multiplique por dois.</li>
             <li>Se o ritmo estiver irregular, conte durante 1 minuto.</li>
          <li>Observe a profundidade das respirações e o ritmo. Compare ambos os
lados do tórax.</li>
             </ul>                    

              <li><b>AFERIÇÃO DA TEMPERATURA</b></li>
            <ul>
          <li>Primeiramente, devem ser avaliados os sinais físicos que indicam temperatura
anormal (tremor, tontura, pele quente/fria).
</li>
         <li>A medida da temperatura corporal pode ser feita na axila, na boca ou no ânus,
usando o termômetro digital. Os valores considerados normais, de acordo com
o sítio de medição são:
</li>
                          <ul>
         <li>Temperatura axilar: 35,5 a 37°C, em média de 36 a 36,5°C;</li>
         <li>Temperatura bucal: 36 a 37,4°C;</li>
         <li>Temperatura retal: 36 a 37,5°C (0,5°C maior que a axilar).</li>
            </ul>
           
         <li>As alterações da temperatura corporal podem ser assim classificadas: </li>
                          <ul>
         <li>Febre leve ou febrícula: até 37,5°C</li>
         <li>Febre moderada: 37,6 a 38,5°C</li>
           <li>Febre alta ou elevada: acima de 38,6°C</li>
         <li>Hipotermia: abaixo de 35ºC (a temperatura axilar não é a melhor para
identificar, mas deve ser valorizada se abaixo de 35,5°C, principalmente
em idosos com processo infeccioso).</li>
                           </ul>
              
<li>Para aferir a temperatura axilar, adotar a seguinte sequência:</li>
              
                                        <ul>
             <li>Seque a região axilar do paciente, se necessário.
</li>
          <li>Coloque o bulbo do termômetro exatamente no oco axilar,
posicionando o braço do paciente sobre o peito.
</li>
             <li>Os termômetros digitais dependem de bateria. São mais
sensíveis, bastando a permanência de 1 minuto na região axilar.
Aproveite esse período para observar os outros sinais vitais.</li>

             <li>Retire o termômetro segurando pelo lado oposto ao bulbo.
Realize a leitura da temperatura.</li>
                     
                          </ul>
              
         </ul>           
                 <li><b>AFERIÇÃO DA PRESSÃO ARTERIAL COM ESFIGMOMANÔMETRO
ANEROIDE</b></li>
            <ul>
              
                <img src="assets/sinaisvitais/classificacao.png" className="figura"/>
          <li>Determine a necessidade de avaliar a pressão arterial, identificando fatores de
risco como condições clínicas ou lesão traumática;
</li> 
          <li>Obtenha a pressão arterial basal prévia do cliente, se disponível;</li> 
              
          <li>Selecione um manguito adequado para cada paciente (de acordo com a
circunferência do braço), se possível;</li> 
              
          <li>Faça o paciente permanecer sentado ou deitado. Com ele sentado ou
deitado, posicione e examine o braço pesquisando alterações físicas e
remova roupas apertadas.</li> 
              
          <li>Palpe a artéria braquial (braço) e coloque o manguito centralizado acima da
artéria. Posicione a seta marcada no manguito sobre a artéria.</li> 
              
          <li>Localize novamente o pulso braquial ou o pulso radial. Palpe a artéria enquanto
infla o manguito rapidamente até o pulso desaparecer. Após o
desaparecimento do pulso, posicione o diafragma do estetoscópio sobre a
artéria braquial, na fossa cubital e sem compressão excessiva. Infle mais
30mmHg. Solte lentamente o bulbo da válvula de pressão e deixe a agulha do
manômetro cair a uma taxa de 2 a 3 mmHg/segundo. Exemplo: Pulso
desapareceu quando o manômetro marcava 120 mmHg. Nesse caso o
socorrista deve inflar até 150 mmHg.</li> 

          <li>Observe no manômetro o ponto em que você ouve/escuta o primeiro som claro,
o som irá aumentar de intensidade lentamente, continue a desinflar o manguito
notando o ponto no qual um som abafado ou reduzido aparece, observe o
ponto no qual o som desaparece.</li> 

          <li>O primeiro som claro é a pressão sistólica e o último som é a pressão
diastólica.</li> 
              
            </ul>        
              
                               <li><b>OXIMETRIA DE PULSO</b></li>
            <ul>
          <li>Determine a necessidade de avaliação do funcionamento/comprometimento
ventilatório e/ou cardiovascular.
</li> 
          <li>Comunique e oriente o paciente sobre o procedimento a ser realizado.</li> 
              
          <li>Escolha e prepare a região em que será colocado o sensor (em adultos,
preferir MMSS, dedo indicador).</li> 
              
          <li>Certifique-se de que a área onde será feita a aferição está limpa
(eventualmente, remova esmalte das unhas, se possível).
</li> 
              
          <li>Ligue o oxímetro: ele fará calibração interna e verificações. Aguardar o tempo
de calibração para aferição.</li> 
              
          <li>Posicione o aparelho com cuidado, evitando deixar muito solto ou muito
apertado.</li> 

          <li>Aguarde para que o oxímetro detecte o pulso e calcule a saturação de oxigênio
(se possível, deixe cerca de 30 segundos para que estabilize a leitura).</li> 

          <li>Uma vez que o pulso tenha sido detectado, a saturação de oxigênio e
frequência de pulso serão exibidas.</li> 
          <li>Registre dados na ficha do paciente.</li> 
          <li>Embora haja divergências, a maioria das referências considera que o valor
normal para a saturação de oxigênio é {'≥'} 95%.</li> 

              
            </ul> 
              
      </ul>
    </Accordion>
          
                         
             <Accordion title="Resultados Esperados">
      <ul>
        <li>Padronização de procedimentos;</li>
        <li>Identificação de achados indicadores de urgências e emergências.</li>
        <li>Oferecimento de assistência adequada.</li>              
      </ul>
    </Accordion>

    <Accordion title="Material recomendado">
      <ul>
        <li>Equipamentos de proteção individual (avental, luvas de látex, máscara
facial e óculos de proteção);</li>
        <li>Termômetro digital;</li>
        <li>Estetoscópio;</li>
        <li>Esfigmomanômetro aneroide ou digital; </li>
        <li>Oxímetro de pulso;</li>
        <li>Relógio de pulso.</li>
      </ul>
    </Accordion>

    <Accordion title="Sinais e sintomas">
      <ul>
        <li>Não se aplica.</li>
      </ul>
    </Accordion>      
                                <Accordion title="Recomendações">
      <ul>
        <li>Todos os equipamentos utilizados na aferição dos sinais vitais que
entrarem em contato com o paciente devem ser higienizados antes e
depois do procedimento com algodão embebido em álcool a 70%.</li>
        <li>Em relação ao <b>pulso</b>:</li>
        
        <ul>
                  <li>Na suspeita de parada cardíaca ou parada cardiorrespiratória, ou na
presença de instabilidade do quadro do paciente, optar pela
avaliação em pulsos centrais: carotídeo ou femoral nos adultos e
braquial ou femoral nos menores de 1 ano e crianças.</li>
                  <li>Compare com a medida obtida no oxímetro, caso tenha feito essa
leitura.</li>
        </ul>
        
                <li>Em relação à <b>frequência respiratória</b>:</li>
        
        <ul>
                  <li>Evite que o paciente perceba que está sendo avaliado, para não
ocorrer a indução da ventilação e a medida incorreta dos valores.</li>
                  <li>Avalie também a regularidade, ritmo e profundidade da ventilação,
bem como a utilização ou não de musculatura acessória.</li>
        </ul>
        
        <li>Em relação à verificação da <b>temperatura</b> corporal:</li>
        
        <ul>
                  <li>Pacientes em mau estado geral, indivíduos em estado de choque,
pessoas idosas e diabéticos podem não apresentar febre ou ter
apenas febre leve, quando acometidos por processos infecciosos.</li>
        </ul>
        
        <li>Em relação à aferição da <b>pressão arterial</b>:</li>
        
        <ul>
                  <li>Os esfigmomanômetros devem ser calibrados anualmente, de acordo
com as orientações do INMETRO.</li>
                  <li>Os erros mais comuns (e que devem ser evitados) ao se aferir a
pressão arterial são:</li>
                  <ul>
                  <li>Colocação do manguito por cima da roupa do paciente.</li>
                  <li>Posição inadequada do manguito e do receptor do estetoscópio.</li>
                  <li>Inadequação do manguito à circunferência do braço.</li>
                  <li>Não calibração do esfigmomanômetro.</li>
                                      </ul>
         
        
        <li>Em relação à <b>oximetria de pulso:</b></li>
        
        <ul>
                  <li>Caso haja dificuldade para a leitura do oxímetro no dedo, pode-se
tentar no lobo da orelha.</li>
         <li>É importante que a fonte de luz e o detector estejam alinhados,
secos e limpos.</li>
        <li>Em caso de dúvida sobre a fidelidade da leitura apresentada pelo
oxímetro, pode-se checar as pilhas e aferir a oximetria em si mesmo.</li>
          
        <li>Atentar para algumas condições que podem interferir na saturação
periférica de oxigênio:</li>
          
        <li>Fumantes ou pessoas cronicamente expostas a altos níveis de
monóxido de carbono podem apresentar níveis altos de
carboxihemoglobina, resultando em leituras de saturação artificialmente altas. Nesses casos, atentar para outros
parâmetros que possam indicar dificuldades respiratórias.</li>
        <li>Hipotermia, hipotensão, edema local, suspeita de anemia,
convulsões, PCR ou choque são algumas situações clínicas que
interferem na medida da saturação.</li>
      </ul>
                        </ul>
              </ul>
    </Accordion>
          
                    <Accordion title="Observações">
      <ul>
        
        <li>Os sinais vitais trazem importantes informações sobre o funcionamento
e possíveis alterações dos órgãos e/ou sistemas mais relacionados com
a vida.</li>
        <li>A correta avaliação dos sinais vitais é fundamental para oferecer uma
assistência qualificada ao paciente.</li>
        <li>São considerados como sinais vitais:</li>
        
      <ul>
        <li> o pulso;</li>
        <li>o ritmo e a frequência respiratórios;</li>
                  <li>a temperatura corporal; e</li>
        <li>a pressão arterial.</li>
      </ul>
        
      
        <li>A Resolução – RDC nº 145, de 21 de março de 2017, da ANVISA,
passou a vigorar em 1º de janeiro de 2019 e proibiu a fabricação,
importação e comercialização, assim como o uso em serviços de saúde,
dos termômetros e esfigmomanômetros com coluna de mercúrio. Por
esse motivo, o procedimento descrito neste POP considera o uso de
termômetro digital para aferição da temperatura.</li>
      </ul>
    </Accordion>
          

    <Accordion title="Fatores Complicadores">
      <ul>
        <li>Dificuldade na palpação de artérias;</li>
        <li>Indisponibilidade ou descalibração de equipamentos;</li>
        <li>Pouca habilidade.</li>
      </ul>
    </Accordion>

    <Accordion title="Glossário">
      <ul>
        <li><b>ANVISA:</b>Agência Nacional de Vigilância Sanitária</li>
        <li><b>INMETRO:</b> Instituto Nacional de Metrologia, Qualidade e Tecnologia</li>
        <li><b>PCR:</b>parada cardiorrespiratória</li>
        <li><b>MMSS:</b>membros superiores</li>
         </ul>
    </Accordion>

    <Accordion title="Base Legal e referencial">
      <ul>  
        <li>BARROS, ALB. Anamnese e exame físico: avaliação diagnóstica de enfermagem no
adulto. 2 ed. Porto Alegre: Artmed, 2002.
</li>
        <li>BRASIL. Agência Nacional de Vigilância Sanitária (ANVISA). Resolução – RDC nº
145, de 21 de março de 2017.</li>
        <li>BRASIL. Ministério da Saúde. Secretaria de Atenção à Saúde. Protocolos de
Intervenção para o SAMU 192 - Serviço de Atendimento Móvel de Urgência. Brasília:
Ministério da Saúde, 2016.
</li>
        <li>BRASIL. Ministério da Saúde (MS). Protocolo de manejo clínico do coronavírus
(Covid-19) na Atenção Primária à Saúde [Internet]. Brasília: Ministério da Saúde; 2020.
Disponível em:
http://www.paho.org/bra/index.php?option=com_content&view=article&id=6101:covid19
&Itemid=875. Acesso em: 09 de mar 2021.
</li>
        <li>CARRARA, D.; AVELAR, A.F,M; KUSAHARA, D.M; PEDREIRA, M.L.G. Oximetria de
pulso arterial. Conselho Regional de Enfermagem de São Paulo. Disponível em:
https://portal.coren- sp.gov.br/sites/default/files/oximetria%2022-12.pdf. Acesso em: 09
de mar 2021.
</li>
        <li>FA, CAMPOS Jr. D. Tratado de Pediatria. Sociedade Brasileira de Pediatria. 4ª Ed.
Editora Manole, 2017</li>
        <li>MALACHIAS MVB, Souza WKSB, Plavnik FL, Rodrigues CIS, Brandão AA, Neves
MFT, et al. 7ª Diretriz Brasileira de Hipertensão Arterial. Arq Bras Cardiol 2016;
107(3Supl.3):1-83</li>
        <li>MENDES, TAB.; Andreoli, PBA; Cavalheiro LV; Talerman C.; Laselva C. Adequação
do uso do oxigênio por meio da oximetria de pulso: um processo importante de
segurança do paciente. Einstein. 2010; 8(4 Pt 1):449-55. Disponível em:
https://www.scielo.br/pdf/eins/v8n4/pt_1679-4508-eins-8-4-0449.pdf. Acesso em:
Acesso em: 09 de mar 2021.</li>
        <li>PORTO, Celmo Celeno Exame clínico / Celmo Celeno Porto, Arnaldo Lemos Porto.
8. ed. Rio de Janeiro: Guanabara Koogan, 2017.</li>
        <li>POTTER, PA. Perry, AG. Fundamentos de enfermagem. 7 ed. Rio de Janeiro:
Elsevier; 2009</li>
        

      </ul>
    </Accordion>


  </div>
</IonContent>
</IonPage>

  );
}

export default SinaisVitais;
