import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import '../Page.css'


const desativacao: React.FC = () => {

  return (

  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>TUTORIAL DE PREENCHIMENTO DAS FICHAS DIGITAIS DE APH</IonTitle>
      </IonToolbar>
    </IonHeader>


    <IonContent fullscreen>

      <IonCard>
        <IonCardHeader>

          <IonCardTitle>COMO PREENCHER A FICHA</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p align="justify">&#8195; 

            <ol>
<li>As ocorrências de até 4 dias atrás ficam armazenadas no sistema para preenchimento da ficha. <b>Após, não há mais acesso à ocorrência e não é mais possível preencher sua ficha.</b></li>
              <li>Após selecionar a ocorrência, clique em “salvar rascunho” e envie a ficha. Nesse momento aparecerá uma mensagem de erro. Isso significa que seu rascunho foi salvo com sucesso (se deslogar e logar novamente, todas as informações que você preencheu até o momento de enviar ficarão salvas). <b>Lembre-se que sua ficha só será enviada em sua versão final ao GAEPH se você desmarcar a opção “salvar rascunho” e enviar.</b></li>
              <img src="assets/fichas/6rascunho.png" className="figura"/>
              <li>Se o paciente recusar atendimento, clique em “adicionar testemunha” e coloque os dados do paciente ou de outra testemunha. Se você desejar, pode utilizar o modelo de recusa de atendimento que disponibilizamos no app, tirar uma foto com seu celular e anexar à ficha (não é necessário transformar em PDF).</li>
              <img src="assets/fichas/7testemunha.png" className="figura"/>
                            <li>Preencha todos os dados da ficha. Apesar da maioria dos itens não serem obrigatórios, é de extrema importância o preenchimento correto e completo de todas as condutas adotadas na ocorrência. Se achar necessário, utilize o campo “Conduta” para detalhar quaisquer informações que achar que não foram contempladas pela ficha digital.</li>
              <li>A responsabilidade de preenchimento da ficha é do socorrista e, portanto, não é possível alterar o nome do responsável técnico, mas é possível colocar os nomes dos auxiliares e condutores por meio do SIAPE.</li>              
              <img src="assets/fichas/8guarnicao.png" className="figura"/>
              <li>Se houver qualquer dúvida no preenchimento de algum dos itens, entre em contato com a seção de estatística do GAEPH (98321-7494).</li>
              <li>Após o preenchimento e conferência de todos os itens, desmarque a opção “salvar rascunho” e clique em “enviar”. Aparecerá uma mensagem em verde, o que significa que sua ficha foi enviada com sucesso ao GAEPH e você não tem mais acesso a ela. Qualquer dúvida entre em contato com a seção que poderemos acessar sua ficha em 24 horas do envio.</li>
            <img src="assets/fichas/9envio.png" className="figura"/>
            </ol>
          </p>
          
                    <p align="justify">&#8195; 
          
          Se tiver dúvida sobre como preencher outros itens, entre em contato conosco!
                                </p>
 
        </IonCardContent>
      </IonCard>
    </IonContent>
  </IonPage>

);
}

export default desativacao;
