import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import '../Page.css'


const desativacao: React.FC = () => {

  return (

  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>TUTORIAL DE PREENCHIMENTO DAS FICHAS DIGITAIS DE APH</IonTitle>
      </IonToolbar>
    </IonHeader>


    <IonContent fullscreen>

      <IonCard>
        <IonCardHeader>

          <IonCardTitle>PERGUNTAS FREQUENTES</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p align="justify">&#8195; 

            <ol>
              <li><b>COMO VOU PASSAR AS INFORMAÇÕES AO SECOM DO MEU QUARTEL?</b></li>
              Você pode tirar printscreen da tela com as informações que o SECOM precisa, pode digitar e enviar via grupo de whatsapp da SECOM ou escrever em um papel – nesse caso, é de suma importância que esse rascunho seja eliminado logo após, por conter informações sigilosas.
                            <li><b>POSSO PREENCHER A FICHA FÍSICA E DEPOIS PASSAR PARA O INOVA?</b></li>
              Se você achar mais conveniente assim, pode preencher sim. Porém, é de suma importância que esse rascunho seja eliminado logo após, por conter informações sigilosas. Não mande esse rascunho ao GAEPH.
                            <li><b>POSSO PREENCHER MAIS DE UMA FICHA AO MESMO TEMPO?</b></li>
              Sim, basta realizar o processo desde o início novamente. Porém tome cuidado para não se confundir e deixar de enviar alguma ficha. É altamente recomendado preencher somente uma ficha por vez.
                            <li><b>POSSO ACESSAR A FICHA QUE EU JÁ ENVIEI?</b></li>
              Não. Após o envio ao GAEPH somente a SUCOE terá acesso à ficha. Se tiver alguma dúvida entre em contato com a seção que nós acessaremos sua ficha.
                            <li><b>POSSO PREENCHER A FICHA FÍSICA SE O INOVA NÃO ESTIVER FUNCIONANDO?</b></li>
              Se o INOVA apresentar problemas durante horário de expediente, entre em contato com a seção (98321-7494) que nós iremos te informar se o preenchimento deve ser feito de forma física ou não. Se o problema ocorrer fora do horário de expediente, preencha a ficha em forma física e, assim que possível, entre em contato com a seção para ser orientado.
                            <li><b>QUAIS GBMs SÃO OBRIGADOS A PREENCHER A FICHA DIGITAL?</b></li>
              No momento somente GBMs com URs não medicalizadas devem preencher a ficha de forma obrigatória:
3º GBM, 6º GBM, 9º GBM, 11º GBM, 13º GBM, 17º GBM, 19º GBM, 22º GBM, 34º GBM, 36º GBM e SIERRA 3.
Ademais, os Grupamentos Especializados ainda não tiveram a implementação da ficha digital.


            </ol>
          </p>
          
 
        </IonCardContent>
      </IonCard>
    </IonContent>
  </IonPage>

);
}

export default desativacao;
