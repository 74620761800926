import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import '../Page.css'


const gerarnovaficha: React.FC = () => {

  return (

  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>TUTORIAL DE PREENCHIMENTO DAS FICHAS DIGITAIS DE APH</IonTitle>
      </IonToolbar>
    </IonHeader>


    <IonContent fullscreen>

      <IonCard>
        <IonCardHeader>

          <IonCardTitle>GERAR NOVA FICHA OU ACESSAR RASCUNHO</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p align="justify">&#8195; 

            <ol>
<li><a href="https://singular.cbm.df.gov.br/inova">No INOVA (externo)</a>, clique em <b>“com solicitante ou pendência”</b>. Localize, <b>dentre suas pendências</b>, o requerimento “OPERACIONAL – Ficha de Atendimento Pré-Hospitalar”. </li>
              <img src="assets/fichas/4novaficha.png" className="figura"/>
              
   <li>Clique em “Editar”. Após, clique em “gerar nova ficha de APH”. <b>Esse procedimento deve ser feito toda vez que você quiser gerar uma nova ficha de APH ou acessar um rascunho que você criou.</b></li>
             
                            <img src="assets/fichas/5novaficha2.png" className="figura"/>
            </ol>
          </p>
          
                    <p align="justify">&#8195; 
          
            Agora que você gerou uma ficha, veja no próximo passo como preenchê-la!
                                </p>
 
        </IonCardContent>
      </IonCard>
    </IonContent>
  </IonPage>

);
}

export default gerarnovaficha;
