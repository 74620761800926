import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const GlicemiaCapilar: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Glicemia Capilar</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://www.cbm.df.gov.br/download/glicemia-capilar/?tmstv=1667925370">POP Glicemia Capilar (BG 201 de 27/10/2022)</a>
            </p>

</ul>
              </Accordion>
                              
              <Accordion title="Resultados Esperados">
            <ul>
             <li>Aumento do poder de avaliação clínica do paciente</li>
             <li>Confirmar hipótese clínicas.</li>
              <li>Descartar hipótese clínicas</li>

</ul>
              </Accordion>
          
                              <Accordion title="Material Recomendado">
            <ul>
             <li>Uso de precaução padrão ( touca, óculos, máscara e luvas)</li>
             <li>Algodão ou swab umedecido com álcool à 70%</li>
             <li>Álcool líquido 70% ou swab umedecido com álcool à 70%</li>
             <li>Glicosímetro</li>
             <li>Tiras reagentes compatíveis com o código da embalagem e aparelho</li>
             <li>Lancetas</li>
             <li>Coletador de perfurocortante</li>
             

</ul>
              </Accordion>
          
                                        <Accordion title="Sinais e Sintomas">
            <ul>
             <li>Justifica-se a aferição da glicemia capilar em todo paciente com histórico de diabetes, alteração do nível de consciência e/ou déficit neurológico ou naqueles que apresentem características que sugiram condição clínica decorrente de intercorrência glicêmica, ou seja, hiperglicemia ou hipoglicemia.</li>
             
</ul>
              </Accordion>
          
                                                  <Accordion title="Notas">
            <ul>
             <li>A verificação da glicemia capilar na forma como prevista neste documento poderá ser realizada por bombeiro militar possuidor do Curso de Socorros de Urgência em Atendimento Pré-Hospitalar com treinamento na técnica de aferição, bem como por profissionais cuja a formação pressupõe a habilitação, tais como Técnicos de Enfermagem, Enfermeiros e Médicos.</li>
         
                             
              
</ul>
              </Accordion>
           <Accordion title="Procedimentos Adulto">
             
             <ul>
               <li>Verifique o funcionamento do glicosímetro.</li>
               <li>Verifique a validade e compatibilidade das tiras reagentes e garanta que as tiras utilizadas pertençam a embalagem e código correspondentes. Após o teste do visor, será exibido o número de código. Verifique se esse número de código corresponde ao número de código da etiqueta do frasco de tiras-teste.</li>
               <li>Explique para o paciente consciente que realizará o procedimento.</li>
               <li>Coloque os EPIs previsto (precaução padrão).</li>
               <li>Ligue o glicosímetro e insira a fita reagente.</li>
               <li>Selecione o local de punção (face lateral da falange distal).</li>
               <li>Realize a antissepsia do local a ser puncionado com algodão e álcool líquido 70%. Deixe secar antes de realizar a punção.</li>
               <li>Pressione o dedo (da base para a extremidade) para favorecer o enchimento capilar.</li>
               <li>Segure firmemente o dedo, posicione a lanceta ou lancetador perpendicularmente ao local de punção e puncione.</li>
               <li>Pressione gentilmente para favorecer o surgimento de uma gota que seja suficiente para a verificação. Caso o aparelho permita, introduza a tira-teste até que o aparelho solicite o sangue, retire-a e aplique sangue enquanto a tira-teste estiver fora do monitor de glicemia, retornando a fita ao monitor logo em seguida.</li>
               <li>Preencha o local apropriado da fita reagente com a gota de sangue. O tempo para preencher a tira é de aproximadamente 20 segundos quando a tira estiver fora do monitor. Se ultrapassado o tempo, o monitor irá apresentar a mensagem de erro.</li>
               <li>Pressione o local da punção com algodão a fim de cessar o sangramento.</li>
               <li>Aguarde enquanto o glicosímetro processa o exame.</li>
               <li>Tome nota da glicemia indicada</li>
               <li>Realize regulação médica e aguarde orientações.</li>
               <p>CRITÉRIOS DEFINIDORES DE HIPOGLICEMIA</p>
               
               <ol>
                 <li>Glicemia capilar {'<'} 60 mg/dL com sintomas relacionados: cefaleia, fraqueza, alteração visual, tremores, alteração do nível de consciência.</li>
                 <li>Em diabéticos, considere glicemia capilar {'<'} 70 mg/dL, com sintomas relacionados.</li>
                
               </ol>
               
               <p>CRITÉRIOS DEFINIDORES DE HIPERGLICEMIA</p>
               <ol>
                 <li>Cetoacidose diabética: {'>'} 250 mg/dL mais sintomas clínicos específicos.</li>
                 <li>Estado hiperglicêmico hiperosmolar: {'>'} 600 mg/dL mais sintomas clínicos específicos.</li>
               </ol>
               <p>CUIDADOS APÓS O USO:</p>
               <ul>
                 <li>Descarte a lanceta em recipiente para material perfurocortante.</li>
                 <li>Descarte EPIs e tiras reagentes em local para lixo potencialmente infectante.</li>
                 <li>Realize a limpeza e desinfecção do glicosímetro e o acondicione no devido lugar.</li>
               </ul>
             </ul>
              </Accordion>
           <Accordion title="Observações">
             <ul>
               <li>Nos aparelhos Accuchek cada vez que for usada uma nova embalagem de tiras-teste, deve-se retirar do monitor de glicemia o chip de código anterior e substituí-lo pelo da nova embalagem de tiras-teste.</li>
               <li>Glicosímetros possuem amplitude de aferição. A amplitude de cada um depende do fabricante. Alguns trabalham com faixa de detecção entre 10 mg/dL e 600 mg/dL. Em outros, essa faixa é compreendida entre 20 mg/dL e 500 mg/dL. É importante saber que nesses aparelhos sempre que a glicemia aferida estiver abaixo do limite inferior, aparecerá o símbolo “Lo” no visor, bem como aparecerá o símbolo “Hi” quando estiver acima do limite superior.</li>
             </ul>
          </Accordion>
          <Accordion title="Possibilidade de erro">
            <ul>
              <li>Quebra de técnica asséptica.</li>
              <li>Uso de tiras-testes não correspondentes ao código da embalagem.</li>
              <li>Fitas reagentes não compatíveis.</li>
              <li>Não reconhecer glicemia compatível com emergência.</li>
              <li>Não regular.</li>
            </ul>
            
            </Accordion>
             <Accordion title="Fatores Complicadores">
               <ul>
                 <li>Hipoperfusão.</li>
                 <li>Processo inflamatório no local de punção.</li>
                 <li>Edema no local de punção.</li>
               </ul>
            
               
               </Accordion>
                             
             <Accordion title="Referencial bibliográfico">
               <ul>
                 <li>Diretrizes da Sociedade Brasileira de Diabetes (2019-2020). Sociedade Brasileira de Diabetes. 2019.</li>
                 <li>Paula MFC, et al. Semiotécnica: fundamentos para a prática assistencial de enfermagem. 1 ed. Rio de Janeiro: Elsevier, 2017.</li>
                 <li>Brandão Neto RA, Shiraiwa RK. Hipoglicemia. In: Velasco IT. et al. Medicina de Emergência: abordagem prática. Barueri - SP: Manole, 2021.</li>
                 <li>Brandão Neto RA, Shiraiwa RK.Tavares AA. Hiperglicemia. In: Velasco IT. et al. Medicina de Emergência: abordagem prática. Barueri - SP: Manole, 2021.</li>
               </ul>
            
               
               </Accordion>  


  </div>
</IonContent>
</IonPage>

  );
}

export default GlicemiaCapilar;
