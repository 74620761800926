import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import './Page.css'


const apoio: React.FC = () => {

  return (

  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>Apoio ao Socorrista</IonTitle>
      </IonToolbar>
    </IonHeader>


    <IonContent fullscreen>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle></IonCardSubtitle>

          <IonCardTitle>Apoio ao Socorrista</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p>&#8195;
          Olá, Socorrista!

          <p>&#8195;
            A Seção Técnica em Saúde (SETES) do GAEPH está disponível para auxiliar nas diversas demandas de ordem técnica do serviço de APH.</p>
          </p>
          <p>&#8195;
            Estamos aqui também para apoiá-lo em demandas de ordem psicossocial, que impactem na qualidade de vida do socorrista.
          </p>
          <p>&#8195;
            Caso necessite, faça-nos uma visita, ou contate-nos pelo telefone 3901-5977.
          </p>
          <p>&#8195;
            Veja também abaixo como acessar os serviços de assistência em saúde mental do CBMDF.
          </p>



        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>Serviços de assistência em saúde mental</IonCardSubtitle>

          <IonCardTitle>
            1. Os atendimentos de saúde mental são feitos somente no CEABM?
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          
          <p>&#8195;
            Não, os pacientes também podem ser atendidos pelas clínicas da rede credenciada.
          </p>
        </IonCardContent>
      </IonCard>


      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle></IonCardSubtitle>

          <IonCardTitle>
            2. Como agendar consulta de psicologia, psiquiatria ou serviço social?
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

          <p>&#8195;
            Atendimento interno (CEABM) - Os agendamentos podem feitos por meio do telefone: 3901-3630. Horário de funcionamento: 08h às 18h.
          </p>
          <p>&#8195;
            Atendimento externo (rede credenciada) - Por meio do Sistema INOVA: Vá em Externo - Nova Solicitação - Autorização para Procedimentos de Saúde - Assistência Psicológica/Psiquiátrica.

          </p>
        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle></IonCardSubtitle>

          <IonCardTitle>
            3. Preciso de encaminhamento médico para ser atendido?
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

          <p>&#8195;
            Não, os atendimentos em saúde mental são agendados por demanda espontânea, tanto no CEABM quanto na rede credenciada.
          </p>
        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle></IonCardSubtitle>

          <IonCardTitle>
            4. O atendimento clínico no CEABM é confidencial?
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

          <p>&#8195;
            Sim; os profissionais da equipe de saúde mental do CEABM respondem pelas normas e regulamentações dos órgãos que fiscalizam o exercício de suas profissões (Psicologia, Serviço Social e Medicina), sobretudo no que diz respeito ao sigilo profissional.
          </p>
        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle></IonCardSubtitle>

          <IonCardTitle>
            5. Quais os serviços disponíveis na rede credenciada?
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

          <p>&#8195;
            A rede de clínicas credenciadas oferece consultas de psicologia e psiquiatria para diagnóstico e tratamento, bem como internação psiquiátrica. Após a autorização no INOVA, a marcação de dia e horário da consulta deve ser feito em contato telefônico diretamente com a clínica credenciada – a lista de clínicas credenciadas está no site do CBMDF.
          </p>
        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle></IonCardSubtitle>

          <IonCardTitle>
            6. Ainda tenho dúvidas sobre os serviços de saúde mental do CEABM, como posso solucionar?
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

          <p>&#8195;
    Quaisquer dúvidas sobre agendamento interno, liberação de guias para atendimento externo e demais serviços oferecidos podem ser solucionadas por meio do telefone: 3901-3630.
          </p>
        </IonCardContent>
      </IonCard>


    </IonContent>
  </IonPage>

);
}

export default apoio;
