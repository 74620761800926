import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const AbordagemDoPaciente: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Avaliação do Paciente</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://www.cbm.df.gov.br/download/avaliacao-do-paciente/">POP Avaliação do Paciente (BG 031 de 14/02/2022)</a>
            </p>

</ul>
              </Accordion>
                              
          <Accordion title="Procedimentos">
            <ul>
              
              <li><b>Avalie a cena de emergência</b></li>
         
              <ul>
            <li>Utilize equipamentos de proteção individual;</li>
            <li>Gerencie os riscos para equipe de atendimento, paciente e terceiros;</li>
            <li>Dimensione o número de vítimas e, se necessário, acione recursos adicionais (especialistas em salvamento e/ou combate a incêndio, outros órgãos de apoio);</li>
            <li>Confirme ao COCB ou Central de Regulação o local exato do
evento e sua natureza.</li>
          </ul>
              
                        <li><b>Avaliação Primária</b></li>
              <ul>
        
          <li>Forme uma impressão geral do paciente (estado respiratório,
circulatório e neurológico).</li>
         <li> Avalie a responsividade do paciente – chame por ele.</li>
           <li>Verifique a existência de hemorragias com grave ameaça à vida.
Sangramentos com essa magnitude devem ser prontamente
controlados. As técnicas de controle de hemorragias externas são
descritas no POP de Choque circulatório.</li>
             <li>Avalie a via aérea e a coluna cervical. Intervir em caso de via
aérea com dificuldade em permanecer aberta. No SBV, a via aérea
deve ser mantida permeável por meio da inclinação da cabeça
com elevação do queixo em pacientes clínicos e empurre
mandibular em traumas. Verifique a presença de próteses ou
corpos estranhos. Se necessário, realize aspiração</li>
          <li>Avalie se o paciente está respirando. Se estiver respirando,
verifique o padrão e a qualidade da ventilação. Monitorize a SpO2
com um oxímetro de pulso. Pacientes com SpO2 {'<'} 95% devem
receber oxigenoterapia. Inicie a tentativa de correção da hipoxemia
com baixos fluxos de oxigênio suplementar e depois titule
conforme necessidade. Aqueles pacientes com SpO2 {'≥'} 95% e que
apresentem sinais de dificuldade respiratória devem receber O2
suplementar em baixo fluxo. Considere ventilação assistida naqueles pacientes com frequência ventilatória menor que 10 ou
maior que 30 respirações por minuto.</li>
              <li>Avalie o estado circulatório. Verifique frequência e qualidade do
pulso periférico (preferência ao pulso radial): forte ou fraco?
Rápido ou lento? Avalie o reenchimento capilar: se {'>'} 2 segundos,
pode indicar baixo débito cardíaco. Avalie o aspecto da pele (cor,
temperatura e umidade).
</li>
                <li>Avalie o estado neurológico. Use a Escala de Coma da Glasgow
(Apêndice 1).</li>
                <li>Avalie e trate a queixa principal do paciente.</li>
                <li>Utilize o acrônimo ALICIA para investigar a queixa principal do
paciente.</li>
                <li>Realize a exposição do paciente para visualizar possíveis lesões.
Retire somente as vestes realmente necessárias para a
identificação de uma lesão. Garanta o resguardo da intimidade do
paciente ao retirar suas vestes.
                  <p align="justify">&#8195;
         <b>A</b>parição: quando surgiu a queixa?
          </p>
                  <p align="justify">&#8195;
         <b>L</b>ocalização: em que parte do corpo se refere a queixa?
          </p>
                  <p align="justify">&#8195;
         <b>I</b>ntensidade: o quanto essa queixa o incomoda? Utilize
escala de dor de 1 a 10.
          </p>
                  <p align="justify">&#8195;
         <b>C</b>ronologia: há quanto tempo tem a queixa?
          </p>
                  <p align="justify">&#8195;
         <b>I</b>ncremento: essa queixa irradia ou permanece localizada?
          </p>
                  <p align="justify">&#8195;
         <b>A</b>lívio: algo alivia ou aumenta a queixa?
          </p>
                  </li>
<ul>
                <img src="assets/escaladedor.png" className="figura"/>
                                             
                            </ul>


               </ul>
                              <p> <b>Se existem sinais de gravidade, transporte nesse momento para um
hospital de referência. Avalie a necessidade de Restrição do
            Movimento da Coluna Vertebral.</b>
                </p>

              <li><b>Avaliação Secundária</b></li>
        <ul>
          <li>Realize uma avaliação física detalhada no sentido céfalo-caudal;</li>
          <li>Obtenha um histórico do doente. Utilize o método mnemônico SAMPLA para guiar sua entrevista:
          <p align="justify">&#8195; 
            <b>S</b>intomas: qual a queixa? Sente dor? Não induza o paciente. Direcione sua entrevista de maneira a não influenciar as respostas.
          </p>
          <p align="justify">&#8195; 
            <b>A</b>lergias: Como se trata de assistência em saúde, pesquise principalmente por alergias medicamentosas.
          </p>
          <p align="justify">&#8195; 
            <b>M</b>edicações: verifique se o paciente faz uso regular de algum medicamento.
          </p>
          <p align="justify">&#8195; 
            <b>P</b>assado médico: histórico de doenças ou problemas clínicos importantes.
          </p>
          <p align="justify">&#8195; 
            <b>L</b>íquido e alimentos: informe-se sobre alimentos e líquidos que o paciente possa ter ingerido. Essa informação é importante para que se previna vômitos e aspiração na indução anestésica no centro cirúrgico.
          </p>
          <p align="justify">&#8195; 
            <b>A</b>mbiente: condições que causaram ou estão relacionados
ao evento.
          </p>
            
        </li>
          <li>Avalie sinais vitais: temperatura, frequência cardíaca, frequência respiratória e pressão arterial (ver descrição da técnica no POP de sinais vitais).</li>
          <li>Prepare o paciente para o transporte ao hospital de referência. Avalie a necessidade de restrição do movimento da coluna
vertebral.</li>
         
         </ul>
              
                            <li><b>Avaliação Continuada</b></li>
                      <ul>
          <li>Deve ser realizada durante todo o atendimento e transporte para a
verificação da evolução do paciente e suas respostas à
intervenções. Reavalie constantemente o nível de consciência, o
XABCDE e sinais vitais.</li>
                        
                       </ul>
              
                   
        <h4>Recomendações</h4>

        <li>Pacientes graves não devem ser mantidos na cena para a realização da avaliação secundária. Se possível, nesses casos, a avaliação secundária só deverá ser realizada durante o transporte. Esses pacientes devem ser transportados rapidamente a um hospital de referência. Por isso, não retarde seu transporte para a realização de procedimentos em demasia.</li>
        <ul>
          <li>Exemplos de pacientes graves: Grande hemorragias,
comprometimento de vias-aéreas, respiração, circulação e
estado neurológico. Ferimentos penetrantes de tórax e/ou
abdômen. Traumatismo cranioencefálico, múltiplas fraturas
de ossos longos, sinais e sintomas sugestivos de Síndrome
Coronariana Aguda e Acidente Vascular Encefálico. </li>
              </ul>
        <li>No paciente clínico, explore bem a entrevista, já que ela pode trazer informações sobre o quadro clínico apresentado.</li>
        <li>Considere que uma avaliação bem feita leva a uma identificação
precoce de problemas que podem ameaçar a vida do paciente ou
trazer-lhe complicações.
</li>
        <li>Seja numa UR ou numa URSB, procure sempre fazer contato com o médico regulador na Central de Regulação Médica;</li>
        <li>Durante a avaliação inicial fique atento a sinais de deterioração fisiológica, tais como: sinais de choque, padrão de respiração ineficaz, ferimentos graves, dentre outros. Nesse caso, acione Suporte Avançado de Vida, por meio da regulação médica. </li>
      </ul>
    </Accordion>

    <Accordion title="Resultados Esperados">
      <ul>
        <li>Otimização do atendimento;</li>
        <li>Identificação de agravos que requerem cuidados de emergência;</li>
        <li>Oferecimento de assistência adequada.</li>              
      </ul>
    </Accordion>

    <Accordion title="Material recomendado">
      <ul>
        <li>Equipamentos de proteção individual (joelheiras, luvas de látex, máscara de proteção facial, óculos de proteção e capacete);</li>
        <li>Estetoscópio;</li>
        <li>Esfigmomanômetro;</li>
        <li>Oxímetro de pulso;</li>
        <li>Termômetro;</li>
        <li>Tesoura de ponta romba;</li>
        <li>Lanterna pupilar.</li>
      </ul>
    </Accordion>

    <Accordion title="Sinais e sintomas">
      <ul>
        <li>Variam conforme a emergência clínica ou trauma.</li>
      </ul>
    </Accordion>       
          
          <Accordion title="Observações">
      <ul>
        <li>O sistema ABCDE de avaliação do paciente foi alterado para XABCDE.
O X é referente a hemorragias eXsanguinantes. Dessa forma, a
sistematização da avaliação do paciente passa a admitir que sangramentos que ameacem a vida do paciente deve ser controlados
antes mesmo dos cuidados com as vias aéreas.</li>
        <li>A avaliação do nível de consciência deve ser realizada por meio da
Escala de Coma de Glasgow, na fase D do XABCDE.</li>
        <li>Inclusão da Escala de Dor.</li>
        <li>O estímulo doloroso por meio da fricção do esterno deixa de ser
recomendado. Para verificação de respostas, utilize estímulo de pressão
periférica no leito ungueal, ou pressão central no trapézio ou incisura
supraorbitária.</li>
        <li>Inclusão da reatividade pupilar na Escala de Coma de Glasgow.</li>
      </ul>
    </Accordion>

    <Accordion title="Fatores Complicadores e/ou Possibilidades de erro">
      <ul>
        <li>Ambientes públicos;</li>
        <li>Condições de segurança;</li>
        <li>Falta de equipamentos de proteção individual;</li>
        <li>Retardar o transporte graves para realizar procedimentos não
prioritários;</li>
        <li>Negligenciar a segurança da cena;</li>
        <li>Não solicitar recursos especializados adicionais;</li>
        <li>Negligenciar a avaliação do paciente;</li>
        <li>Deixar de observar prioridades na avaliação;</li>
        <li>Negligenciar equipamentos de avaliação</li>
      </ul>
    </Accordion>

    <Accordion title="Glossário">
      <ul>
        <li><b>SpO2:</b>saturação periférica de oxigênio medida em percentual de
hemoglobina ligada ao oxigênio.</li>
        <li><b>BVM:</b> dispositivo de reanimação ventilatória manual do tipo bolsa-valva-máscara (BVM). É também conhecida como Ambú®.</li>
        <li><b>Reenchimento capilar:</b>preenchimento dos capilares periféricos após diminuição abrupta da circulação na região realizada por pressão externa. O teste é normalmente realizado no dedo polegar.</li>
        <li><b>Suporte Avançado de Vida (SAV):</b>modalidade de assistência especializada na realização de procedimentos avançados invasivos, tais como intubação e alguns procedimentos cirúrgicos na cena de emergência.</li>
        <li><b>Suporte Básico de Vida (SBV):</b>modalidade especializada de assistência que realiza procedimentos básicos minimamente invasivos.</li>
         </ul>
    </Accordion>

    <Accordion title="Base Legal e referencial">
      <ul>  
        <li>Atendimento Pré-Hospitalar ao Traumatizado – PHTLS (NAEMT).
9th ed. Bolingbrook: Jones & Bartlett Learning, 2019.</li>
        <li>Atendimento Pré-Hospitalar às Emergências Clínicas – AMLS. Rio
de Janeiro: Elsevier, 2014.</li>
        <li>Brennan PM, Murray GD, Teasdale GM. Simplifying the use of
prognostic information in traumatic brain injury. Part 1: The
GCS-Pupils score: an extended index of clinical severity. J.
Neurosurg. 2018:1–9.</li>
        <li>Frink M, Lechler P, Debus F, Ruchholtz S. Multiple Trauma and
Emergency Room Management. Dtsch Arztebl Int. 2017 Jul
24;114(29-30):497-503. doi: 10.3238/arztebl.2017.0497. PMID:
28818179; PMCID: PMC5569556.</li>
        <li>Ketelaars R, Reijnders G, van Geffen GJ, Scheffer GJ, Hoogerwerf
N. ABCDE of prehospital ultrasonography: a narrative review. Crit
Ultrasound J. 2018;10(1):17.</li>
        <li>Maschmann C, Jeppesen E, Rubin MA, Barfod C. New clinical
guidelines on the spinal stabilisation of adult trauma patients -
consensus and evidence based. Scand J Trauma Resusc Emerg
Med. 2019 Aug 19;27(1):77. doi: 10.1186/s13049-019-0655-x.
PMID: 31426850; PMCID: PMC6700785.</li>
        
        

      </ul>
    </Accordion>
<Accordion title="Apêndice">
              <ul>
                <img src="assets/apendice.png" className="figura"/>
                
                
                
                            </ul>
          </Accordion>
  </div>
</IonContent>
</IonPage>

  );
}

export default AbordagemDoPaciente;
