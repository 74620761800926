import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const FraturaCostelas: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Fratura de costelas</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://drive.google.com/file/d/1YfN4anvrzAz9xjZ49eBqeUog-nfy6wTQ/view?usp=share_link">POP Fratura de Costelas (BG 237 de 17/12/2015)</a>
            </p>

</ul>
              </Accordion>
          <Accordion title="Procedimentos">
            <ul>
              <li>Avaliar a cena</li>
              <li>Gerenciar riscos na cena de emergência;</li>
              <li>Avaliar a biomecânica envolvida;</li>
              <li>Acalmar o paciente;</li>
              <li>Manter via aérea pérvia e, se necessário, estabilizar manualmente a coluna cervical;</li>
              <li>Avaliar a necessidade de aplicação de colar cervical;</li>
              <li>Avaliar a qualidade da respiração;</li>
              <li>Avaliar a oximetria de pulso.</li>
              <li>Iniciar oxigenoterapia (10 – 15 L/min) se %SpO2 {'<'} 95%, ou se {'>'} 95%, mas com sinais de dificuldade respiratória (2 – 5 L/min).</li>
              <li>Controlar hemorragias externas;</li>
              <li>Avaliar a possibilidade de hemorragias internas;</li>
              <li>Considerar necessidade de acesso venoso;</li>
              <li>Imobilizar o braço (do mesmo lado da lesão) sobre o tórax utilizando uma tipoia.</li>
              <li>Realize avaliação secundária;</li>
              <li>Exame físico detalhado;</li>
              <li>Sinais Vitais;</li>
              <li>SAMPLA;</li>
              <li>Encaminha o paciente para hospital de referência.</li>
            </ul>
          </Accordion>

          <Accordion title="Resultados Esperados">
            <ul>
              <li>Minimizar danos;</li>
              <li>Minimizar a dor;</li>
            </ul>
          </Accordion>

          <Accordion title="Material recomendado">
            <ul>
              <li>Colar cervical;</li>
              <li>Prancha longa;</li>
              <li>Tirantes;</li>
              <li>Gazes;</li>
              <li>Ataduras;</li>
              <li>Compressas cirúrgicas;</li>
              <li>Manta aluminizada</li>
              <li>Lençóis;</li>
              <li>Esfigmomanômetro;</li>
              <li>Estetoscópio;</li>
              <li>Oxímetro de pulso;</li>
              <li>|Termômetro;</li>
              <li>Conjunto de oxigenoterapia com fluxômetro;</li>
              <li>Jelco no 14 ou 16;</li>
              <li>Equipo simples.</li>
              <li>Ringer Lactato ou Solução Fisiológica 0,9%– 2 frascos de 1000 ml cada ou 4 de 500 ml.</li>
            </ul>
          </Accordion>

          <Accordion title="Sinais e sintomas">
            <ul>
              <li>Dor à palpação ou durante inspiração e expiração;</li>
              <li>Deformidade;</li>
              <li>Desconforto respiratório;</li>
              <li>Hematomas;</li>
              <li>Ferimentos;</li>
              <li>Crepitação</li>

              <h3>Observações</h3>
              <li>Extremidades de costelas fraturas podem lesionar tecidos e/ou órgão próximos e causar sangramentos. Dessa forma, fique atento a sinais de choque.</li>
            </ul>
          </Accordion>

          <Accordion title="Possibilidades de erro">
            <ul>
              <li>Hemorragias internas associadas;</li>
              <li>Não identificação de sinais de choque;</li>
            </ul>
          </Accordion>

          <Accordion title="Fatores Complicadores">
            <ul>
              <li>Segurança da cena;</li>
              <li>Choque circulatório associado;</li>
              <li>Múltiplas fraturas</li>
            </ul>
          </Accordion>

          <Accordion title="Glossário">
            <ul>
              <li><b>Crepitação:</b> som áspero produzido pelo atrito de partes ósseas.;</li>
            </ul>
          </Accordion>

          <Accordion title="Referencial bibliográfico">
            <ul>
              <li>Atendimento Pré-Hospitalar ao Traumatizado – PHTLS (NAEMT). 7a ed. Rio de Janeiro: Elsevier, 2014.</li>
              <li>Brasil. Ministério da Saúde. Secretaria de Atenção à Saúde. Protocolos de intervenção para o SAMU 192 – Serviço de Atendimento Móvel de Urgência. Brasília: Ministério da Saúde, 2014.</li>
              <li>Rasia CA, Barros CC, Marcelino SC, Fernandes RWC, Pontes FC, Pedroso, GB, et al. Manual de atendimento pré-hospitalar. Brasília: Corpo de Bombeiros Militar do Distrito Federal, 2007.</li>
            </ul>
          </Accordion>

    </div>
  </IonContent>
</IonPage>

  );
}

export default FraturaCostelas;
