import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import '../Page.css'


const prioridadedeativacao: React.FC = () => {

  return (

  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>ATO DO COMANDANTE OPERACIONAL</IonTitle>
      </IonToolbar>
    </IonHeader>


    <IonContent fullscreen>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>Publicado no Boletim Geral 205, de 04 de novembro de 2012</IonCardSubtitle>

          <IonCardTitle>ATO DO COMANDANTE OPERACIONAL - XLIX - ORDEM DE ATIVAÇÃO DE URs e URSBs</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p align="justify">&#8195; 

            O  COMANDANTE  OPERACIONAL,  no  uso  das  atribuições  que  lhe  conferem  os  art.  22 e 27, inciso II,  do  Decreto 31.817, de 21 jun. 2010, que regulamenta o art. 10-B, inciso II, da Lei 8.255, de 20 nov. 1991, que dispõe sobre a organização básica do CBMDF, resolve:
          </p>
          
          <p align="justify">&#8195; 
           <br></br>&#8195;

            <b>TORNAR PÚBLICA</b> a Instrução Normativa n° 76/2022 que estabelece instruções gerais para
Ordem de Prioridade de Ativação das viaturas tipo UR/URSB e dá outras providências.
          </p>

          <p>&#8195;
            <b>Art. 1º</b> Esta norma regula a ordem de prioridade de ativação de Unidades de Resgate (UR) e
Unidades de Resgate de Suporte Básico (URSB) do CBMDF e tem como objetivo:
          </p>
          <p>&#8195;
            I - Estabelecer a nova ordem de ativação das UR/URSB, preferencialmente dentro dos Comandos
de Área, conforme Anexo;
          </p>
          <p>&#8195;
            II - Considerar a importância de sempre haver UR/URSB ativadas em regiões de maior demanda de
atendimento da comunidade do Distrito Federal;
          </p>
          <p>&#8195;
            III - Considerar a premente necessidade de otimização do emprego dos recursos humanos e
operacionais;
          </p>
          <p>&#8195;
            IV - Considerar o Plano de Emprego Operacional e os estudos promovidos pelos diversos setores
do Comando Operacional.
          </p>
          <p>&#8195;
            <b>Art. 2°</b> Para fins de classificação define-se os tipos de viaturas empregadas no serviço de
Atendimento Pré-Hospitalar (APH) do CBMDF:
          </p>
          <p>&#8195;
            I - Unidades de Resgate (UR) - veículo de atendimento de urgências pré-hospitalares composto por
3 profissionais militares, sendo um condutor, um socorrista e um auxiliar;
          </p>
          <p>&#8195;
           II - Unidades de Resgate de Suporte Básico (URSB) - veículo de atendimento de urgências préhospitalares de pacientes com risco de vida desconhecido, não classificado com potencial de necessitar
de intervenção médica no local e/ou durante transporte até o serviço de destino, composta por 3
integrantes, sendo um condutor, um socorrista técnico ou auxiliar de enfermagem e um auxiliar.
          </p>
          <p>&#8195;
           a) Tal guarnição poderá ser formada por militares do CBMDF, desde que o socorrista possua o
Curso de Socorros de Urgência (CSU), curso de técnico ou auxiliar de enfermagem e habilitação para
atuar em URSB.
          </p>
          <p>&#8195;
           b) A guarnição poderá ser mista, sendo composta por militares da corporação e servidores
profissionais de enfermagem do Serviço de Atendimento Móvel de Urgência (SAMU192).
          </p>
          <p>&#8195;
           c) A URSB, devido a presença de profissional de enfermagem, está habilitada para administração
de medicações mediante prescrição por médico pertencente à central de regulação médica.
          </p>
          <p>&#8195;
           Parágrafo Único. As medicações contidas nesta viatura não deverão ser remanejadas, pois
pertencem à carga que compõe a unidade de atendimento.
          </p>
          <p>&#8195;
            <b>Art. 3°</b> Os critérios considerados na escolha de prioridade de ativação serão:

          </p>
          <p>&#8195;
            I - Viaturas tipo UR, classificada como URSB (regulada), com guarnição exclusiva do CBMDF ou
compartilhada;

          </p>
           <p>&#8195;
            II - Quantidade de ocorrências de Atendimento Pré-Hospitalar atendidas pelos Grupamentos;

          </p>
<p>&#8195;
            III - Demanda operacional de cada Grupamento, de acordo com as características da densidade
demográfica, da geografia, das edificações, das áreas de riscos e da natureza das ocorrências.

          </p>
          <p>&#8195;
            <b>Art. 4°</b> Na ausência de um Técnico de Enfermagem para as URSB, estas passarão a figurar no
Poder Operacional como UR, mantendo a mesma ordem de prioridade de ativação.

          </p>
          <p>&#8195;
            <b>Art. 5°</b> Quando for necessário desativar uma UR por falta de um dos integrantes da guarnição, os
demais componentes atenderão, preferencialmente, os GBMs do respectivo COMAR, no caso de
remanejamento.

          </p>
          <p>&#8195;
            <b>Art. 6°</b> Considerada a localidade, a URSB do GAEPH será incluída na lista de prioridade do
COMAR I.

          </p>
          <p>&#8195;
            <b>Art. 7°</b> A ordem de ativação de URs passa a vigorar conforme anexo à presente instrução.

          </p>
          <p>&#8195;
            <b>Art. 8°</b> Revoga-se o item XXXVI do BG n° 240, de 21 dez. 2017 (Ordem de ativação de viaturas), e
o item XLIX BG n° 203, de 26 out. 2016 (Remanejamento de militares que compõe as guarnições de UR
de suporte básico de vida).

          </p>
          <p>&#8195;
            <b>Art. 9°</b> Esta norma entra em vigor em 5 (cinco) dias após sua publicação.

          </p>
          <p>&#8195;
            Em consequência, segue como <a href="https://drive.google.com/file/d/1DKB-xt_ZqWFvFvjAhj_7IiERXBE4mOlr/view?usp=sharing">Anexo 12</a> tabela com a Ordem de Prioridade de Ativação das
viaturas tipo UR/URSB.

          </p>
          
          
          
          
          <p>&#8195;
            <br></br>
            (NB CBMDF/COMOP/GACOP 00053-00136595/2021-11)
          </p>
        </IonCardContent>
      </IonCard>
    </IonContent>
  </IonPage>

  );
}

export default prioridadedeativacao;
