import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import '../Page.css'


const desativacao: React.FC = () => {

  return (

  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>TUTORIAL DE PREENCHIMENTO DAS FICHAS DIGITAIS DE APH</IonTitle>
      </IonToolbar>
    </IonHeader>


    <IonContent fullscreen>

      <IonCard>
        <IonCardHeader>

          <IonCardTitle>CADASTRO INICIAL</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p align="justify">&#8195; 

            <ol>
<li>Se você socorrista ainda não está cadastrado, ligue no 98321-7494 (SUCOE) durante o expediente ou no 3901-2886 (Dia a prontidão GAEPH) a qualquer horário para ser cadastrado.</li>
              <li><a href="https://singular.cbm.df.gov.br/inova">Acesse o INOVA e clique em “Externo”</a>. Faça o Login.</li>
              <img src="assets/fichas/1cadastro.png" className="figura"/>
              <li><b>Se este é seu primeiro acesso,</b> clique em “nova solicitação”. Após, localize o requerimento “OPERACIONAL – Ficha de Atendimento Pré-Hospitalar” e clique em “Criar”.</li>
              <img src="assets/fichas/2novasolicitacao.png" className="figura"/>
                            <li>Aceite o termo de abertura e clique em “Enviar”.</li>
                            <img src="assets/fichas/3termo.png" className="figura"/>
            </ol>
          </p>
          
                    <p align="justify">&#8195; 
          
          Pronto! A partir de agora você está apto a enviar fichas digitais. <a href="https://www.gaeph.com.br/FICHASaph/gerarnovaficha">Siga para o próximo passo.</a>
                                </p>
 
        </IonCardContent>
      </IonCard>
    </IonContent>
  </IonPage>

);
}

export default desativacao;
