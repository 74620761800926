import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import '../Page.css'


const desativacao: React.FC = () => {

  return (

  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>ATO DO COMANDANTE OPERACIONAL</IonTitle>
      </IonToolbar>
    </IonHeader>


    <IonContent fullscreen>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>Publicado no Boletim Geral 096, de 22 de maio de 2015</IonCardSubtitle>

          <IonCardTitle>INSTRUÇÃO NORMATIVA 4/2015 – COMOP</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p align="right">&#8195; 
            Estabelece os procedimentos operacionais nos casos
de restrição ao atendimento de pacientes nos hospitais
da rede pública do Distrito Federal e dá outras
providências.
          </p>
          <br></br>
          <p align="justify">&#8195; 
            O COMANDANTE OPERACIONAL, no uso das atribuições que lhe confere o art. 22 do Decreto
31.817, de 21 jun. 2010, que regulamenta o art.10-B, inciso I, da Lei 8.255, de 20 nov. 1991(LOB), e
          </p>
          <p align="justify">&#8195; 
            Considerando a Portaria 46, de 19 set. 2012, publicada no BG 177, de 20 set. 2012;
          </p>
          <p align="justify">&#8195; 
            Considerando os eventos adversos e transtornos operacionais decorrentes das eventuais restrições
hospitalares;
          </p>
          <p align="justify">&#8195; 
            Considerando a iminência de implantação do serviço de Fiscal de Dia ao GAEPH;
          </p>
          <p align="justify">&#8195; 
            Considerando o fato de a Central de Regulação Medica – 192 dispor de software específico e de
profissionais exclusivamente destinados ao acompanhamento, em tempo integral, das restrições
hospitalares, podendo fornecer orientação técnica atualizada, resolve:
          </p>
          <p align="justify">&#8195; 
          <b>Art. 1°</b> A presente Instrução Normativa tem por finalidade estabelecer os procedimentos operacionais
nos casos de restrição ao atendimento de pacientes nos hospitais da rede pública do Distrito Federal.  
          </p>
          <p align="justify">&#8195; 
            <b>Art. 2°</b> Os fiscais de Dia ao GAEPH ou, em sua ausência, os Socorristas à disposição da CIADE
farão o acompanhamento constante das restrições hospitalares e manterão os socorristas informados, por
meio da comunicação, nas redes de rádio do CBMDF, quanto às restrições e possíveis hospitais de
referência para apoio nestes casos.
            </p>
          <p align="justify">&#8195; 
            <b>Art. 3°</b> Caso se deparem com situações de restrição ao atendimento do paciente, os responsáveis
técnicos das URs deverão entrar em contato com a Central de Regulação Medica (192 - ligação gratuita) e
informar o evento ao Médico Regulador, inclusive citando corretamente os sinais vitais do paciente, além
de outras informações que julgar necessárias.
          </p>
          <p align="justify">&#8195; 
            <b>Art. 4°</b> Os militares da UR devem ficar atentos à constante reavaliação primária e secundária do
paciente, no período em que aguardam atendimento hospitalar, registrando estes dados na Ficha de
Atendimento do Paciente, bem como todas as informações referentes à Restrição Hospitalar.
          </p>
          <p align="justify">&#8195; 
            <b>Art. 5°</b> Revogam-se as disposições em contrário.
          </p>
          
          <p>&#8195;
            <br></br>
  (NB 35/2015-SELEG/CBMDF-COMOP-ALUJD /00053-SEI004573/2015-0020449)
</p>
                 </IonCardContent>
      </IonCard>
    </IonContent>
  </IonPage>

  );
}

export default desativacao;
