import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import '../Page.css'


const desativacao: React.FC = () => {

  return (

  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>ATOS DO COMANDANTE OPERACIONAL</IonTitle>
      </IonToolbar>
    </IonHeader>


    <IonContent fullscreen>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>Publicado no Boletim Geral 246, de 31 de dezembro de 2015</IonCardSubtitle>

          <IonCardTitle>ATOS DO COMANDANTE OPERACIONAL - XXXII - INSTRUÇÃO NORMATIVA 11/2015-COMOP</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p align="justify">&#8195; 

            O  COMANDANTE  OPERACIONAL,  no  uso  das  atribuições que  lhe  confere  o  art.  22  do  Decreto 31.817, de 21 jun. 2010, que regulamenta o art. 10-B, inciso II, da Lei 8.255, de 20 nov. 1991, que dispõe sobre a organização básica do CBMDF, resolve:
          </p>
          <p align="justify">&#8195; 
            1) APROVAR a Instrução Normativa 11/2015 COMOP, conforme anexo 5.
          </p>
          <p align="justify">&#8195; 
            2) DETERMINAR sua publicação e prática dos demais atos de estilo.
          </p>
          <p>&#8195;
            <br></br>
            (NB CBMDF_COMOP_GACOP - 00053-055663/2015)
          </p>
        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>ANEXO 5</IonCardSubtitle>

          <IonCardTitle>INSTRUÇÃO NORMATIVA N°11, DE 03 DE DEZEMBRO DE 2015.</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

          <p align="right">&#8195;
            Disciplina  a  desconcentração  de  recursos  humanos  do  serviço  de  atendimento  pré-hospitalar  no âmbito do COMOP e dá outras providências.
          </p>


          <p align="center">&#8195;
            TÍTULO I 
          </p>
          <p align="center">&#8195;
            DAS DISPOSIÇÕES GERAIS
          </p>
          <p align="justify">&#8195;
            Art.  1°A  presente  Instrução  Normativa  disciplina  a  desconcentração  de  Recursos  Humanos disponíveis para a realização do serviço de Atendimento Pré-Hospitalar no âmbito do COMOP.
          </p>
          <p align="justify">&#8195;
            Art. 2°. A desconcentração consistirá na transferência dos militares lotados no GAEPH (possuidores de  Curso  de  Socorros  de  Urgência  em  Atendimento  Pré-Hospitalar – CSU/APH,  Curso  Técnico  em Emergência  Pré-Hospitalar - CTE-PH  e  Curso  em  Atendimento  Pré-Hospitalar – APH),  aptos  para  serem Responsáveis Técnicos e Auxiliares das Unidades de Resgate (UR) da Corporação, em número suficiente para   que   somado   ao   efetivo   especializado   de   cada   Grupamento   Multiemprego   ou   Especializado, componham as guarnições das Unidade de Resgate – UR.
          </p>
          <p align="center">&#8195;

            TÍTULO II
          </p>
          <p align="center">&#8195;
            DA DESCONCENTRAÇÃO
          </p>
          <p align="center">&#8195;
            Capítulo I
          </p>
          <p align="center">&#8195;
            Das Características da Desconcentração
          </p>
          <p align="justify">&#8195;
            Art.3°A desconcentração do efetivo obedecerá ao disposto a seguir:
          </p>
          <p align="justify">&#8195;
            I – para  que  não  haja  descontinuidade  do  serviço  operacional  de  Atendimento  Pré-Hospitalar,  os militares  transferidos  do  GAEPH  serão  lotados  nas  unidades  envolvidas,  cabendo  aos  comandantes  das unidades   a   responsabilidade   pela   ativação   da   UR,   bem   como   a   gestão   dos   recursos   humanos especializados  em  APH,  mantendo  um  banco  de  dados  permanente  com  todos  os  militares  possuidores dos citados cursos;
          </p>
          <p align="justify">&#8195;
            II - competem  unidades  envolvidas  no  serviço  de  APH  a  responsabilidade  de  disponibilizar  os recursos humanos especializados em APH sob seu comando, quando necessário ou convocado por ordem superior, para o cumprimento das diversas atividades do CBMDF na área de atuação do GBM, tais como: palestras,   demonstrações   técnico-profissionais,   demonstrações   de   materiais,   ordens   de   missão, prevenções, entre outros da mesma natureza;
          </p>
          <p align="justify">&#8195;
            III -o GAEPH e os Grupamentos Multiemprego, deverão acompanhar o desempenho do serviço de APH, em conformidade com os indicadores de desempenho setoriais e institucionais.
          </p>
          <p align="center">&#8195;
            Capítulo II
          </p>
          <p align="center">&#8195;
            Das Competências
          </p>
          <p align="justify">&#8195;
            Art. 4°Compete ao GAEPH:
          </p>
          <p align="justify">&#8195;
            I-manter  e  otimizar  ferramentas  de  gestão  administrativa  para  garantira  qualidade  do  serviço prestado em APH.
          </p>
          <p align="justify">&#8195;
            II -convocar  militares  especialistas  em  APH  para  prestar  esclarecimentos  de  ordem  técnica  no desempenho  de  suas  atividades  operacionais,  capacitações  de  rotina  ou  individualizadas,  convocações gerais e demais convocaçõesque se fizerem necessárias;
          </p>
          <p align="justify">&#8195;
            III – propor ao Comandante Operacional a criação e implementação de atividades de fiscalização em APH, com as seguintes finalidades:
          </p>
          <p align="justify">&#8195;
            a) fiscalização do emprego de militares especializados em APH nas URs;
          </p>
          <p align="justify">&#8195;
            b) organização e padronização das viaturas, limpeza dos materiais, apresentação pessoal, execução de  testes  de  prontidão  em  APH,  bem  como  para  colher  informações,  queixas  e  sugestões  dos  militares socorristas,  permitindo  a  formulação  de  diagnóstico  de  problemas  operacionais  em  APH  e  possíveis soluções administrativas;
          </p>
          <p align="justify">&#8195;
            c)  emprego  militares  da  Seção  de  Operações  Pré-Hospitalares  (SEOPH)  do  GAEPH  em  ações  de fiscalização específicas, visando manter o padrão de excelência na área de APH.
          </p>
          <p align="justify">&#8195;
            IV –Enviar proposta ao Comandante Operacionalacerca de ações de gestão estratégica no que se refere  à  implantação  da  regulação  médica  e  atuação  operacional  de  recursos  em  APH  regulados  por médicos, devendo pautar-se pelas seguintes medidas:
          </p>
          <p align="justify">&#8195;
            V-controlar  e  coordenar,  mediante  funcionamento  em  tempo  integral,  a  equipe  de  militares  do GAEPH que presta serviço na Central de Regulação Médica;
          </p>
          <p align="justify">&#8195;
            VI -Propor  ao  Comandante  Operacional  normas  de  emprego  operacional  de  Oficias  Médicos  da Corporação,  lotados  no  COMOP,conforme  perfil  pessoal  nas  atividades  operacionais  de  médicos,  seja atuando como Médico Regulador, seja como Médico Intervencionista em Ambulância tipo E;
          </p>
          <p align="justify">&#8195;
            VII -definir estrategicamente os Grupamentos Multiemprego que devem atuar com viaturas do tipo B (URs  de  Suporte  Básico  de  Vida),  desempenhandogestão  compartilhada  em  conjunto  com  essas unidades, no sentido da solução imediata de problemas encontrados de ordem técnica e operacional.
          </p>
          <p align="justify">&#8195;
            VIII -sugerir  ao  Comando  Operacional,  com  base  em  levantamento  estatístico  do  serviço  de  APH, alterações no emprego dos recursos humanos e materiais;
          </p>
          <p align="justify">&#8195;
            IX –implementar  indicadores  de  desempenho  setoriais,  com  a  finalidade  de  controlar  e  medir  as ações no serviço de APH, de maneira continuada e homogênea nos Grupamentos Multiemprego;
          </p>
          <p align="justify">&#8195;
            X –identificar  e  sugerir  ao  Comando  Operacional  notas  de  boletim  que  apontem  situações  de destaque técnico operacional de militares do CBMDF, na área de APH.
          </p>
          <p align="justify">&#8195;
            Art. 5°Compete, ainda, ao GAEPH promover de forma concentrada:
          </p>
          <p align="justify">&#8195;
            I -gestão do serviço de APH na Sede, nos Postos Avançados em hospitais, na Central de Regulação Médica e na CIADE;
          </p>
          <p align="justify">&#8195;
            II -serviço de Motorresgate;
          </p>
          <p align="justify">&#8195;
            III -logística operacional em APH: materiais operacionais, materiais de consumo e medicamentos;
          </p>
          <p align="justify">&#8195;
            IV -atividades de limpeza e desinfecção de materiais operacionais em APH;
          </p>
          <p align="justify">&#8195;
            V -instrução, padronização e monitoramento de Normas de Biossegurança aplicada ao APH;
          </p>
          <p align="justify">&#8195;
            VI -controle  e  orientação  de  casos  de  pós-exposição  a  materiais  biológicos  (acidentes  com perfurocortantes e demais acidentes envolvendo contato direto ou indireto com material biológico);
          </p>
          <p align="justify">&#8195;
            VII -ensino, doutrina e pesquisa em APH;
          </p>
          <p align="justify">&#8195;
            VIII -responsabilidade técnica perante conselhos de classe afetos ao serviço de APH;
          </p>
          <p align="justify">&#8195;
            IX -atividades de estatística e controle dos indicadores de desempenho em APH;
          </p>
          <p align="justify">&#8195;
            X –identificação  de  ações  meritórias  no  âmbito  do  serviço  de  APH,  com  possíveis  sugestões  ao gestor do militar indicado para recompensas previstas em regulamento, bem como análise correcional das questões técnico-profissionais.
          </p>
          <p align="justify">&#8195;
            Art.  6°Cabe  aos  Grupamentos  Multiemprego  ou  Especializados,  que  possuem  serviço  operacional em  APH,  seja  ambulância  tipo  C,  B,  D  ou  E,  conforme  definições  da  Portaria  GM/MS  n°2048/2002 (Resgate, Suporte Básico ou Suporte Avançado) as seguintes atribuições:
          </p>
          <p align="justify">&#8195;
            I -cooperar  com  as  ações  de  fiscalização  do  GAEPH,  inclusive  apresentando  os  militares  sob  seu comando que assim forem requisitados, para esclarecimentos de natureza técnica, independentemente da complexidade do serviço executado, ou seja, suporte avançado ou básico de vida e atividades de resgate;
          </p>
          <p align="justify">&#8195;
            II -escalar de acordo com a Portaria n.°16, de 30 de abril de 2015, art. 2°inciso I letra “b”, militares especializados  em  APH  para  compor  as  guarnições  de  URs  e  URSBs,  na  qualidade  de  Responsável Técnico, sendo possuidores de pelo menos um dos cursos da seguinte forma:
          </p>
          <p align="justify">&#8195;
            a. viaturas  do  tipo  C  (URs):  militares  possuidores  do  Curso  de  Socorros  de  Urgências  em Atendimento Pré-Hospitalar (CSU/APH) ou Curso Técnico de Emergência (CTE-PH);
          </p>
          <p align="justify">&#8195;
            b. viaturas  do  tipo  B  (UR  de  Suporte  Básico):  militares  com  Curso  Técnico  em  Enfermagem, possuidores  do  Curso  de  Socorros  de  Urgências  em  Atendimento  Pré-Hospitalar  (CSU/APH)  ou  Curso Técnico de Emergência (CTE-PH), bem como treinamento específico de administração de medicamentos e capacitação em regulação médica;
          </p>
          <p align="justify">&#8195;
            c. viaturas  do  tipo D  ou  E  (UR  de  Suporte  Avançado)  ou  Aeronave  de  Transporte  Aeromédico: militares com formação em medicina ou enfermagem, com treinamento ou formação específica na área de emergência.
          </p>
          <p align="justify">&#8195;
            d.auxiliares de guarnição da UR devem possuir CTE, CSU ou APH, obrigatoriamente;
          </p>
          <p align="justify">&#8195;
            III –enviar ao GAEPH a escala mensal das Guarnições de URs via SEI, até o penúltimo dia útil do mês anterior à escala;
          </p>
          <p align="justify">&#8195;
            IV -apresentar ao Comandante do GAEPH, militares sob seu comando nas seguintes convocações:
          </p>
          <p align="justify">&#8195;
            a)  reuniões  gerais  de  todo  efetivo  operacional  em  APH  sempre  que  se  fizer  necessário,  seja  para padronização de condutas técnicas, seja para assuntos diversos atinentes à temática do APH;
          </p>
          <p align="justify">&#8195;
            b) capacitações continuadas, conforme estabelecido pelo COMOP;
          </p>
          <p align="justify">&#8195;
            c)  instruções  específicas  e  individualizadas,  conforme  identificado  pelo  serviço  de  fiscalização  do GAEPH.
          </p>
          <p align="justify">&#8195;
            d)  Programas  de  Educação  Permanente  em  Atendimento  Pré-Hospitalar  e  outras  capacitações (internas ou externas) julgadas pertinentes pela Seção de Ensino, Doutrina e Pesquisa do GAEPH.
          </p>
          <p align="justify">&#8195;
            V - zelar  pelo  cumprimento  da  doutrina  operacional  em  APH,seja  com  informações,  críticas construtivas ou sugestões para aprimoramento contínuo do serviço de APH;
          </p>
          <p align="justify">&#8195;
            VI -acompanhar  as  guarnições  que  atuam  nas  viaturas  do  tipo  UR  de  Suporte  Básico  e  UR  de Suporte  Avançado  e/ou  Aeronave  de  Transporte  Aeromédico,  com  atuação  regulada  por  médico,  no sentido de propor ações resolutivas para a implementação bem-sucedida da regulação médica no serviço de APH do CBMDF, auxiliando o cumprimento das metas do Plano de Comando da Corporação, conforme abaixo:
          </p>
          <p align="justify">&#8195;
            a)  intermediar  a  comunicação  das  guarnições  com  o  Comando  do  GAEPH  e  demais  oficiais  da estrutura  para  resolução  de  problemas  operacionais  e  de  ordem  técnica  que  possam  interferir  no  bom andamento do serviço;
          </p>
          <p align="justify">&#8195;
            b)  inteirar-se  das  ações  conjuntas  entre  instituições  no  que  se  refere  a  implantação  da  regulação médica,  especialmente  das  decisões  entre  GAEPH/COESP/COMOP  e  SAMU-DF,  para  cooperar  com  o bom andamento do serviço operacional.
          </p>
          <p align="justify">&#8195;
            VII -encaminhar as fichas de atendimento pré-hospitalar geradas por ocorrências atendidas pela UR sob sua subordinação semanalmente ao GAEPH;
          </p>
          <p align="justify">&#8195;
            VIII -remanejar  os  militares  desconcentrados  para  outra  atividade  operacional  quando  necessário, desde que não haja descontinuidade das ações de APH desenvolvidas na Unidade.
          </p>
          <p align="center">&#8195;
            TÍTULO III
          </p>
          <p align="center">&#8195;
            DAS DISPOSIÇÕES TRANSITÓRIAS
          </p>
          <p align="justify">&#8195;
            Art.7°O  GAEPH  terá  por  atribuição  fiscalizar  e  monitorar  todo  o  processo  de  desconcentração  de recursos humanos do serviço de APH, a fim de preservar o alto nível de capacitação técnica dos militares e propor eventuais ajustes que se façam necessários.
          </p>
          <p align="justify">&#8195;
            Art.8°A  presente  Instrução  Normativa  entra  em  vigor  na  data  de  sua  publicação,  revogando-se  as disposições em contrário.
          </p>
        </IonCardContent>
      </IonCard>
    </IonContent>
  </IonPage>

  );
}

export default desativacao;
