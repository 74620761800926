import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const RCPGestante: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>RCP em gestantes</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://www.cbm.df.gov.br/download/pop-de-aph-a-pcr-em-gestante-bg-141-de-2022/?tmstv=1667848762">POP RCP em gestante (BG 141 de 28/07/2022)</a>
            </p>

</ul>
              </Accordion>
            
         
                    <Accordion title="Resultados esperados">
            <ul>
            <li>Eficiência no reconhecimento e abordagem da gestante vítima de PCR.</li>
            <li>Atendimento à Reanimação Cardiopulmonar com manobras precoces e efetivas no contexto da paciente gestante.</li>
            <li>Eficiência na escolha e utilização dos recursos disponíveis.</li>

</ul>
              </Accordion>
          
                              <Accordion title="Material recomendado">
            <ul>
            <li>Capacete</li>
            <li>Máscara cirúrgica</li>
            <li>Óculos de proteção</li>
            <li>Luvas de procedimento</li>
            <li>Desfibrilador Externo Automático (DEA)</li>
            <li>Reanimador manual (Bolsa-Valva Máscara)</li>
            <li>Prancha longa</li>
            <li>Conjunto de oxigenoterapia com fluxômetro</li>
            <li>Oxímetro de pulso</li>
            <li>Cânula Orofaríngea</li>
            <li>Aspirador manual ou elétrico</li>

</ul>
              </Accordion>
          
                                       <Accordion title="Sinais e sintomas">
            <ul>
              <li>Abdome gravídico;</li>
            <li>Arresponsividade;</li>
            <li>Ausência de respiração efetiva;</li>
            <li>Ausência de pulso central.</li>
</ul>
              </Accordion>
          
                                                 <Accordion title="Observações">
            <ul>
            <li>Avaliar pulso carotídeo</li>
            <li>A cicatriz umbilical é referência para estimar idade gestacional.</li>
                          
</ul>
              </Accordion>
          
                                                           <Accordion title="Procedimentos">
            <ul>
            <p>Todos os procedimentos relativos à avaliação da cena do paciente adulto em PCR e as técnicas de Reanimação Cardiopulmonar, já descritos no POP de RCP adulto devem ser realizados, acrescidos de:</p> 

            <li>Identificar que se trata de uma paciente gestante;</li>
            <li>Acionar a equipe de Suporte Avançado de Vida;</li>
            <li>Ventilação e oxigenação são prioridades no atendimento:
              <ol>
                <li>Durante a gestação a respiração é primordial devido a alta demanda de oxigênio para o feto;</li>
                <li>Redução na capacidade de reserva funcional devido ao útero gravídico;</li>
                <li>Risco de lesão no cérebro do feto devido à hipoxemia;</li>
              </ol>
              </li>
            <li>Idades gestacionais (IG) acima de 20 semanas, deve ser aplicada a técnica da descompressão aortocava, lateralizando o útero para a esquerda durante a RCP;
             <ol>
                <li>Caso não saiba a IG, deve-se avaliar a altura do fundo uterino, se alcançar o nível do umbigo ou acima, a técnica deve ser aplicada.</li>
                <li>A descompressão aortocava deve ser realizada por um outro socorrista que não esteja fazendo a RCP.</li>
               <p>  <img src="assets/gravida.png" className="figura"/></p>
              </ol>
              </li>
              
              <li>Se, durante a RCP, a gestante com fundo do útero na altura da cicatriz umbilical ou acima (23 semanas de gestação ou mais), não tiver apresentado resposta com as técnicas de reanimação após 4 minutos,
mesmo com a lateralização uterina para a esquerda, é aconselhável
que, havendo o profissional médico na cena, se prepare para esvaziar
                o útero (cesárea <i>perimortem</i>) enquanto a ressuscitação continua. A
cesárea <i>perimortem</i> aumenta a chance de RCE à mãe e fornece
melhores chances de sobrevida ao feto, devendo ser realizada
somente por médico capacitado.</li>
                          
            <li>A prioridade é manter a vitalidade materna.</li>
                            <p><b><font color="red">A Avaliação de batimento cardíaco fetal não é prioritário durante a PCR
                              materna, posto que se torna uma distração dos elementos de reanimação maternos necessários.</font></b></p>
            <li>No pós PCR em que a gestante sobrevive:
              <ol><li>Monitoramento dos sinais vitais</li>
                <li>Controle rigoroso da Temperatura;</li>
                <li>Monitorização da bradicardia fetal, que pode surgir como complicação.</li>
              
              </ol>
                            
                            </li>
           
              

              
</ul>
              </Accordion>
          
          
          
          
                                                           <Accordion title="Recomendações">
            <ul>
            <li>Reveze, obrigatoriamente, os socorristas na realização das
compressões a cada 2 minutos em adultos, bem como na descompressão aortocava.</li>
            <li>Nos ritmos chocáveis, a desfibrilação é prioritária. Ela não deve ser
retardada para obtenção de via aérea avançada, acesso vascular ou
outros procedimentos.</li>
              <li>Durante a PCR na gravidez, o monitoramento do feto deve ser ignorado.</li>
              
                                                             </ul>
              
              
              </Accordion>
          
                                                           <Accordion title="Fatores complicadores">
            <ul>
            <li>Segurança da cena.</li>
            <li>Dificuldade de acesso à Regulação Médica.</li>
            <li>Diminuição do retorno venoso com a compressão da veia cava pelo
útero gravídico.</li>
            <li>Indisponibilidade de EPIs.</li>
            <li>Número reduzido de profissionais</li>
              <li>Comunicação ineficaz. Ressalta-se a importância da comunicação em
alça fechada</li>
</ul>
              </Accordion>
          
                                                                     <Accordion title="Glossário">
            <ul>
            <li><b>Briefing:</b> conjunto de informações utilizadas para definições de
estratégias de execução de uma tarefa. Aqui se definem as funções de
cada um e as ações esperadas para o bom desempenho da equipe.</li>
            <li><b>Comunicação em alça fechada:</b> a comunicação verbal é repetida para
o solicitante, de forma a confirmar aquilo que foi pedido e fazer uma
checagem dupla.</li>
            <li><b>CERU/SAMU-DF:</b> Central de Regulação de Urgências do SAMU-DF.</li>
            <li><b>Debriefing:</b> é a maximização da aprendizagem por meio da
experiência. Nesse momento, por meio da experiência compartilhada,
se analisam as ações da equipe, se o planejado foi cumprido (e os
motivos do não cumprimento) e se propõem estratégias de melhoria
para a próxima ocorrência.</li>
            <li><b>Suporte Avançado de Vida:</b> modalidade de assistência em saúde ao
paciente gravemente enfermo, com a presença obrigatória do
profissional médico e enfermeiro, necessitando de materiais e
equipamentos que possibilitem a realização de procedimentos
invasivos.</li>
</ul>
              </Accordion>
                              
          
           <Accordion title="Base legal e referencial">
            <ul>
            <li>American Heart Association – AHA, Diretrizes de Reanimação
Cardiopulmonar e Atendimento Cardiovascular de Emergência, 2020.
</li>
            <li>SCHMIDT, A.; SEMPSROTT, J.; HAWKINS, S.; ARASTU, A.; CUSHING, T.;
</li>
            <li>AUERBACH, P.; Wilderness Medical Society Clinical Practice Guidelines
for the Treatment and Prevention of Drowning: 2019 Update.</li>
            <li>SZPILMAN D, BIERENS JML, HANDLEY A, ORLOWSKI JP. DROWNING.
              Nem Engl J Med. 2012, 366:2102-2110</li>
</ul>
            
             </Accordion>

  </div>
</IonContent>
</IonPage>

  );
}

export default RCPGestante;
