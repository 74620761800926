import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import '../Page.css'


const desativacao: React.FC = () => {

  return (

  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>ATOS DO COMANDANTE GERAL</IonTitle>
      </IonToolbar>
    </IonHeader>


    <IonContent fullscreen>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>Publicado no Boletim Geral 146, de 6 de agosto de 2012</IonCardSubtitle>

          <IonCardTitle>ATOS DO COMANDANTE GERAL - IX -AUTORIZAÇÃO PARA USO DE COLETE OPERACIONAL NA COR PRETA NA ATIVIDADE DE ATENDIMENTO PRÉ-HOSPITALAR</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p align="justify">&#8195; 

            O  COMANDANTE-GERAL,  no  uso  das  atribuições  que  lhe  confere  o  art.  7°,  incisos  II,  III  e  VI,  do Decreto  Federal  n°  7.163,  de  29  abr.  2010,  que  regulamenta  o  art.  10-B,  inciso  I,  da  Lei  n°  8.255,  de  20 nov. 1991, que dispõe sobre a organização básica do CBMDF, e
          </p>
          <p align="justify">&#8195; 
            Considerando  que  após  a  promulgação  do  Decreto  n°  32.784,  de  1°  mar.  2011,  que  aprova  o  RU-CBMDF, novas peças complementares foram adotadas visando melhorar o desempenho das atividades do bombeiro  militar,  além  de  proporcionar  uma  melhor  apresentação  pessoal  do bombeiro  militar perante acomunidade;
          </p>

          <p align="justify">&#8195; 
            Considerando  a  necessidade  da  utilização  do  colete  operacional  na  atividade  de  atendimento  pré-hospitalar;
          </p>
          <p align="justify">&#8195; 
            Considerando  que  o  colete  operacional  na  cor  azul  adotado  pelo  GAEPH foi  idealizado  para  ser empregado com o extinto 1° CIEM (macacão azul);
          </p>
          <p align="justify">&#8195; 
            Considerando que o colete operacional na cor preta apresenta uma melhor estética com o uniforme 3° A (prontidão laranja);
          </p>
          <p align="justify">&#8195; 
            Considerando que o bombeiro militar devidamente uniformizado representa o elevado compromisso com  o  sentimento  do  dever,  senso  de  responsabilidade,  zelo  e  abnegação  pela  profissão,  enaltecendo  o CBMDF perante acomunidade do Distrito Federal;
          </p>
          <p align="justify">&#8195; 
            Considerando a necessidade de se adotar normas e procedimentos para adequação do RU-CBMDF à realidade existente na Corporação, resolve:
          </p>
          <p align="justify">&#8195; 
            Considerando  que  os  militares  devem  realizar  a  limpeza  e  desinfecção  de  viatura  (limpeza concorrente), conforme item XLV do BG 164, de 29 ago.2013 para a passagem de serviço,prolongando assim, a carga horária de trabalho estipulada;
          </p>
          <p align="justify">&#8195; 
            Considerando  casos  recorrentes  em  que,  principalmente,  o  Responsável  Técnico  não  possui rendição, ficando além do horárioestipulado até que se providencie seu substituto;
          </p>
          <p align="justify">&#8195; 
            Considerando que o Responsável Técnico fica na dependência da subjetividade do Superior de Dia em liberá-lo, mesmo após cumprindo a Escala de Serviço de UR/URSB, conforme previsto, resolve:
          </p>

          <p align="justify">&#8195; 
           <br></br>&#8195;

           <b>AUTORIZAR</b> o uso de colete operacional na cor preta nas atividades de atendimento pré-hospitalar, ressaltando  que  o  uso  do  colete  azul  será  permitido  até  a  aquisição  e  distribuição  de  novos  coletes operacionais.
          </p>
          <p>&#8195;
            <br></br>
            (NB n° 62/2012/GAEPH/Cmt.-Geral)
          </p>
        </IonCardContent>
      </IonCard>
    </IonContent>
  </IonPage>

  );
}

export default desativacao;
