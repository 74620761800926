import { useState } from 'react';
import { IonSearchbar, IonList, IonItem, IonLabel, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import './Page.css';

const Portarias: React.FC = () => {

  const [searchText, setSearchText] = useState("");
  const list = [
    {titulo:"Cadastro inicial", rota:"./FICHASaph/cadastroinicial"},
    {titulo:"Gerar nova ficha ou Acessar rascunho", rota:"./FICHASaph/gerarnovaficha"},
    {titulo:"Como preencher a ficha", rota:"./FICHASaph/comopreencher"},
    {titulo:"Modelo de recusa do paciente", rota:"./FICHASaph/modeloderecusa"},
    {titulo:"Perguntas Frequentes", rota:"./FICHASaph/perguntas"},
  ];


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Orientações de preenchimento das fichas digitais de APH </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonSearchbar value={searchText} 
          onIonChange={e => setSearchText(e.detail.value!)}>
        </IonSearchbar>
        <IonList>
          {list
            .filter(({ titulo, rota }) => titulo
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toUpperCase()
              .includes(searchText
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toUpperCase()))
            .map(l => (
              <IonItem key={l.titulo} routerLink={l.rota}>
                <IonLabel className="ion-text-wrap">{l.titulo}</IonLabel>
              </IonItem>
          ))}
        </IonList>

      </IonContent>
    </IonPage>
  );
};

export default Portarias;
