import React, { useState } from 'react';
import { chevronDownOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

type Props = {
  title: string,
  children: any,
  isExpand?: boolean,
  procedimentos?: boolean,
}

export const Accordion: React.FC<Props> = ({ children , title, isExpand = false, procedimentos = false }) => {
  const [expand, setExpand] = useState(isExpand);
  return (
    <div className="box">
      <div className={`${procedimentos ? 'title-box procedimentos' : 'title-box'}`} onClick={() => setExpand(expand => !expand)}>
        <span className="title">{title}</span>
    <span className='icon' ><IonIcon className={`fa ${!expand ? ' down' : ''}`} icon={chevronDownOutline}></IonIcon></span>
        <div className="clearfix"></div>
      </div>
      <div className={"content " + (expand ? "" : "esconder")}>{children}</div>
    </div>
  )
};

