import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonImg,
  IonAvatar,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import { book, document, home,  medkit, notifications, heart, handLeft } from 'ionicons/icons';
import './Menu.css';
import { Logout } from "./Logout";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
{
    title: 'Início',
    url: '/home',
    iosIcon: home,
    mdIcon: home
},
{
    title: 'POPs',
    url: '/pops',
    iosIcon: medkit,
    mdIcon: medkit
  },
  {
    title: 'INs e Portarias',
    url: '/portarias',
    iosIcon: document,
    mdIcon: document 
  },
  {
    title: 'Manual de APH',
    url: '/manual',
    iosIcon: book,
    mdIcon: book 
  },
   {
    title: 'Apoio ao socorrista',
    url: '/apoio',
    iosIcon: heart,
    mdIcon: heart 
  
  },
   {
    title: 'Libras',
    url: '/libras',
    iosIcon: handLeft,
    mdIcon: handLeft
  },
    {
    title: 'Notícias',
    url: '/noticias',
    iosIcon: book,
    mdIcon: book 
  },
    {
    title: 'Fichas de Atendimento',
    url: '/fichas',
    iosIcon: medkit,
    mdIcon: medkit 
  }
  
   
  ];


const Menu: React.FC = () => {
  const location = useLocation();

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
    <IonAvatar>
    <IonImg src="/assets/logoGaeph.png" />
    </IonAvatar>
        <IonList id="inbox-list">
          <IonListHeader>GAEPHweb</IonListHeader>
          <IonNote>Socorrista - CBMDF</IonNote>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
