import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import '../Page.css'


const desativacao: React.FC = () => {

  return (

  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>ATO DO COMANDANTE OPERACIONAL</IonTitle>
      </IonToolbar>
    </IonHeader>


    <IonContent fullscreen>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>Publicado no Boletim Geral 081, de 2 de maio de 2019</IonCardSubtitle>

          <IonCardTitle>ATO DO COMANDANTE OPERACIONAL - XXXV - ORIENTAÇÕES QUANTO AO USO DOS APARELHOS CELULARES FUNCIONAIS DISTRIBUÍDOS ÀS URs</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p align="justify">&#8195; 

            O  COMANDANTE  OPERACIONAL, no  uso  das  atribuições  que  lhe  conferem  os  arts  22  e  23,  do Decreto 31.817, de 21 jun. 2010, que regulamenta o art. 10-B, inciso II, da Lei 8.255, de 20 nov. 1991, que dispõe sobre a organização básica do CBMDF, e:
          </p>
          <p align="justify">&#8195; 
            Considerando   que o   telefone   móvel   corporativo   tem   caráter   operacional,   sendo   depronto atendimento, devendo ser acessível 24 (vinte e quatro horas) por dia; resolve:
          </p>

          <p align="justify"> 
            <br></br>&#8195;
            DETERMINAR aos Chefes de Guarnição das Unidades de Resgate - URs e Motorresgate- MR que o  telefone  móvel  corporativo,  que  foi  distribuído  pelo  GAEPH para  ser  utilizado  nas  ocorrências  de Atendimento Pré-Hospitalar, permaneçam ligados 24h por dia, para servir delinha de contato direto entre o COCB e as URs e MRs.
          </p>
          <p>
            <br></br>&#8195;

            Em conseqüência:
          </p>
          <p align="justify">&#8195; 
            a) fica  vedado  o  uso  de  telefone  móvel  corporativo  ao  usuário  afastado  das  suas  atividades  em férias,  abonos,  afastamentos  regulares  ou  não  programados.  Excetuam-se  os Órgãos  de  Direção  Geral, Direção Setorial e Comandante Operacional;
          </p>
          <p align="justify">           
            b) o usuário tem total responsabilidade pela guarda e conservação do aparelho disponibilizado. Em caso de furto, extravio ou roubo, o responsável pelo telefone móvel corporativo deverá registrar boletim de ocorrência e providenciar novo aparelho, com as mesmas características ou superior, em até 48 (quarenta e  oito)  horas  corridas,  conforme  Termo  de  Guarda  e  Responsabilidade  assinado  pelo  Comandante  da Unidade ao receber o aparelho.
          </p>
          <p align="justify">&#8195; 
            c) todo o volume de conversação via intragrupo é ilimitado e não será tarifado;
          </p>
          <p align="justify">&#8195; 
            d) ao  consumir  a  cota  mensal  de  minutos,  o  aparelho  ficará  habilitado  apenas  para  originar chamadas ao grupo (via Tarifa Zero intragrupo) e recebendo chamadas normalmente.
          </p>
          <p align="justify">&#8195; 
            e) os comandantes dos GBMs fiscalizem quanto ao fiel cumprimento dessa determinação.
          </p>
          <p align="justify">&#8195; 
            f) a  lista  das  Viaturas  e  seus  números  de  celular  funcional  para  o  contato  quando  necessário, conforme Anexo 9.
          </p>
          <p>&#8195;
            <br></br>
            (NB CBMDF/COMOP/GACOP - 00053-00030107/2019-30)
          </p>
        </IonCardContent>
      </IonCard>


      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Anexo 9</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>

              <img src="assets/CelularFuncional/celularFuncional.png" className="figura"/>

        </IonCardContent>
      </IonCard>
    </IonContent>
  </IonPage>

  );
}

export default desativacao;
