import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import '../Page.css'


const desativacao: React.FC = () => {

  return (

  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>ATO DO COMANDANTE OPERACIONAL</IonTitle>
      </IonToolbar>
    </IonHeader>


    <IonContent fullscreen>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>Publicado no Boletim Geral 096, de 22 de maio de 2015</IonCardSubtitle>

          <IonCardTitle>INSTRUÇÃO NORMATIVA 10/2015-COMOP</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p align="right">&#8195; 
            A presente Instrução Normativa estabelece nova norma, no
âmbito do Comando Operacional, para a escala de
condutores das Unidades de Resgate (URs).
          </p>
          <br></br>
          <p align="justify">&#8195; 
            O COMANDANTE OPERACIONAL, no uso das atribuições que lhe confere o art. 22 do Decreto
31.817, de 21 jun. 2010, que regulamenta o art. 10-B, inciso II, da Lei 8.255, de 20 nov. 1991, que dispõe
sobre a organização básica do CBMDF, resolve:
          </p>
          <p align="justify">&#8195; 
            <b>Art. 1°</b> No caso de falta de condutor de 12 (doze) horas escalado para as Unidades de Resgate
(UR), este deverá ser substituído pelos condutores da escala de 24 (vinte e quatro) horas, que se
revezarão em turnos de até 6 (seis) horas, não podendo cada condutor exceder 12 (doze) horas de serviço
na Unidade de Resgate (UR).
          </p>
          <p align="justify">&#8195; 
            <b>Art. 2°</b> Esta presente Instrução Normativa entra em vigor na data de sua publicação.
          </p>
          <p align="justify">&#8195; 
            <b>Art. 3°</b> Revogam-se as disposições contrárias.
          </p>
                    
          <p>&#8195;
            <br></br>
  (NB CBMDF_COMOP_GACOP /00053-055099/2015)
</p>
                 </IonCardContent>
      </IonCard>
    </IonContent>
  </IonPage>

  );
}

export default desativacao;
