import React, { useState } from 'react';
import OAuth2Login from "react-simple-oauth2-login";
import {
  authorizationUrl,
  clientId,
  redirectUri,
  REACT_APP_SERVER_OAUTH_URL
} from '../settings-code';

type Props = {
  onAuthenticate: any;
};

export function Login() {
  const [accessToken, setAccessToken] = useState(null);

  const onSuccess = ({ code }) => {
    try{
      fetch(
        `${REACT_APP_SERVER_OAUTH_URL}/users/oauthToken?code=${code}&clientId=${clientId}&redirectUri=${redirectUri}`
      )
        .then(x => {
          debugger;
          return x;
        })
        .then((res) => res.json())
        .then(x => {
          debugger;
        })
        .catch(x => {
          debugger;
        })
      //.then((data) => setAccessToken(data.access_token));
    } catch (e) {
      console.error(e);
    }
  };


  return (
    <OAuth2Login
        authorizationUrl={authorizationUrl}
        clientId={clientId}
        redirectUri={redirectUri}
        responseType="code"
        state={''+Math.random()}
        buttonText="Entrar"
        isCrossOrigin={false}
        onSuccess={onSuccess}
        onFailure={x => { 
          debugger;
        }}
      />
  );
}
