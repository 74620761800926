import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const TraumatismoFace: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Traumatismo de face</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://drive.google.com/file/d/16oeYN1bCreIvCfJWnvUJENgYaYPpoLzT/view?usp=share_link">POP Traumatismo de Face (BG 237 de 17/12/2015)</a>
            </p>

</ul>
              </Accordion>
          
              <Accordion title="Procedimentos">
                <ul>
                  <li>Avaliar a cena;</li>
                  <li>Avaliar a biomecânica envolvida;</li>
                  <li>Gerenciar riscos na cena de emergência;</li>
                  <li>Manter via aérea pérvia e, se necessário, estabilizar manualmente a coluna cervical. Se necessário, aspirar via aérea;</li>
                  <li>Considerar possível trauma cervical. Se necessário, mensurar e aplicar colar cervical.</li>
                  <li>Avaliar a qualidade da respiração;</li>
                  <li>Avaliar a oximetria de pulso.</li>
                  <li>Iniciar oxigenoterapia (10 – 15 L/min) se %SpO2 {'<'} 95%, ou se {'>'} 95%, mas com sinais de dificuldade respiratória (2 – 5 L/min).</li>
                  <li>Controlar hemorragias e realizar curativos.</li>
                  <li>Avaliar a necessidade de acesso venoso.</li>
                  <li>Realizar avaliação secundária</li>
                  <li>Exame físico detalhado</li>
                  <li>Sinais vitais</li>
                  <li>SAMPLA;</li>
                  <li>Encaminhar para o hospital de referência.</li>

                  <h3>Observações</h3>
                  <li>Esteja atento à obstrução das vias aéreas. Essa é a principal complicação relacionada.</li>
                </ul>
              </Accordion>

          <Accordion title="Resultados Esperados">
            <ul>
              <li>Desobstrução de via aérea;</li>
              <li>Controle de sangramentos.</li>
            </ul>
          </Accordion>

          <Accordion title="Material recomendado">
            <ul>
              <li>Colar cervical;</li>
              <li>Prancha longa;</li>
              <li>Tirantes;</li>
              <li>Gazes;</li>
              <li>Ataduras;</li>
              <li>Compressas cirúrgicas;</li>
              <li>Manta aluminizada</li>
              <li>Lençóis;</li>
              <li>Esfigmomanômetro;</li>
              <li>Estetoscópio;</li>
              <li>Oxímetro de pulso;</li>
              <li>Termômetro;</li>
              <li>Conjunto de oxigenoterapia com fluxômetro;</li>
              <li>Jelco no 14 ou 16;</li>
              <li>Equipo simples.</li>
              <li>Ringer Lactato ou Solução Fisiológica 0,9%– 2 frascos de 1000 ml cada ou 4 de 500 ml.</li>
            </ul>
          </Accordion>

          <Accordion title="Sinais e sintomas">
            <ul>
              <li>Dor local;</li>
              <li>Deformidade facial;</li>
              <li>Hematoma periorbitário;</li>
              <li>Dentes quebrados ou avulsionados;</li>
              <li>Edema;</li>
              <li>Ferimentos e sangramentos;</li>
              <li>Deformidade na mandíbula.</li>
            </ul>
          </Accordion>

          <Accordion title="Possibilidades de erro">
            <ul>
              <li>Não desobstruir via aérea;</li>
            </ul>
          </Accordion>

          <Accordion title="Fatores Complicadores">
            <ul>
              <li>Segurança da cena;</li>
              <li>Presença de sangue e secreções na via aéra.</li>
            </ul>
          </Accordion>

          <Accordion title="Glossário">
            <ul>
              <li><b>Avulsionado:</b> arrancado.</li>
              <li><b>Oximetria:</b> procedimento que visa medir a concentração de oxigênio no sangue.</li>
              <li><b>Periorbitário:</b> ao redor dos olhos.</li>
            </ul>
          </Accordion>

          <Accordion title="Referencial bibliográfico">
            <ul>
              <li>Atendimento Pré-Hospitalar ao Traumatizado – PHTLS (NAEMT). 7a ed. Rio de Janeiro: Elsevier, 2014.</li>
              <li>Barak M, Bahouth H, Leiser Y, El-Naaj IA. Airway Management of the patient with maxillofacial trauma: review of the literature and suggested clinical approach. BioMed Research International. 2015.</li>
              <li>Brasil. Ministério da Saúde. Secretaria de Atenção à Saúde. Protocolos de intervenção para o SAMU 192 – Serviço de Atendimento Móvel de Urgência. Brasília: Ministério da Saúde, 2014.</li>
              <li>Krausz AA, Krausz MM, Picetti E. Maxillofacial and neck trauma: a damage control approach. 2015; 10 (31).</li>
              <li>Scheyerer MJ, Döring R, Fuchs N, Metzler P, Sprengel K, Werner CML, et al. Maxillofacial injuries in severely injured patients. Journal of Trauma Management and Outcomes. 2015; 9 (4).</li>
            </ul>
          </Accordion>

    </div>
  </IonContent>
</IonPage>

  );
}

export default TraumatismoFace;
