import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const TraumaAbdominal: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Trauma abdominal</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://drive.google.com/file/d/1bD7VaecvEl4f678kKGStE-8jun0A46YB/view?usp=share_link">POP Trauma Abdominal (BG 237 de 17/12/2015)</a>
            </p>

</ul>
              </Accordion>
          
          <Accordion title="Procedimentos">
            <ul>
              <li>Avaliar a cena</li>
              <li>Gerenciar riscos na cena de emergência;</li>
              <li>Avaliar a biomecânica envolvida;</li>
              <li>Realizar avalição primária e secundária;</li>
              <li>Manter via aérea pérvia e, se necessário;</li>
              <li>Avaliar a necessidade de estabilização da coluna cervical a aplicação do colar cervical;</li>
              <li>Avaliar a qualidade da respiração;</li>
              <li>Avaliar a oximetria de pulso.</li>
              <li>Iniciar oxigenoterapia (10 – 15 L/min) se %SpO2 {'<'} 95%, ou se {'>'} 95%, mas com sinais de dificuldade respiratória (2 – 5 L/min).</li>
              <li>Controlar hemorragias externas com utilização de pressão direta com a mão ou compressas.</li>
              <li>Avaliar a necessidade de acesso venoso e reposição volêmica*.</li>
              <li>Promover controle da temperatura corporal por meio da utilização de lençóis, mantas aluminizadas e/ou controle da temperatura do salão de atendimento da viatura</li>
              <li>Imobilizar o paciente em prancha longa em casos de trauma abdominal fechado. Pacientes com trauma abdominal aberto sem suspeita de trauma de coluna vertebral não precisam ser imobilizados.</li>
              <li>Realizar avaliação secundária</li>
              <li>Exame físico detalhado (Inspecionar e palpar o abdômen)</li>
              <li>Sinais vitais;</li>
              <li>SAMPLA;</li>
              <li>Encaminhar com prioridade para hospital que possua capacidade de intervenção abdominal cirúrgica. Transporte o paciente, preferencialmente, com as pernas flexionadas.</li>
    *Procedimentos realizado somente por profissionais habilitados (médicos, enfermeiros, técnicos e auxiliares de enfermagem, inscritos em seus respectivos conselhos de classe).
            </ul>

            <h4>Situações especiais</h4>
            <b>Objeto encravado</b>
            <li>Não retirar objetos encravados. Tentar estabilizá-los;</li>
            <li>Se houver sangramento ativo ao redor do objeto, realizar pressão direta com a mão ou compressa em volta do ferimento;</li>
            <li>Não realizar palpação abdominal.</li>
            <li>Oferecer suporte emocional.</li>

            <b>Evisceração</b>
            <li>Não tentar recolocar as vísceras na cavidade abdominal;</li>
            <li>Cobrir as vísceras com curativo (limpo ou estéril) umedecidos com solução salina e sem seguida revestir com material plástico ou pedaço da manta aluminizada;</li>
            <li>Oferecer suporte emocional.</li> 

            <h4>Observações</h4>
            <li>A infusão de fluidos deve ser feita de maneira cautelosa. A não ser que a situação exija a permanência no local, não se justifica retardar o transporte ao hospital a fim de se obter o acesso venoso periférico. Não se recomenda a infusão agressiva de líquidos, já que tal conduta tem sido associada a consequências deletérias para o paciente, tais como: hemodiluição, diminuição dos fatores de coagulação, coagulopatias e rompimento de coágulos ativos. Considere manutenção da pressão arterial sistólica de 90 mmHg em
pacientes hipotensos.</li>
            <li>O Ringer Lactato é a solução de primeira escolha devido à sua composição ser mais semelhante ao plasma e servir como solução tampão, desejável na acidose metabólica. Em sua falta, o socorrista deve optar pela Solução Fisiológica 0,9%.</li>
          </Accordion>

          <Accordion title="Resultados Esperados">
            <ul>
              <li>Prevenção do choque;</li>
              <li>Aumento da sobrevida;</li>
              <li>Transporte rápido.</li>
            </ul>
          </Accordion>

          <Accordion title="Material recomendado">
            <ul>
              <li>Colar cervical;</li>
              <li>Prancha longa;</li>
              <li>Tirantes;</li>
              <li>Gazes;</li>
              <li>Ataduras;</li>
              <li>Compressas cirúrgicas;</li>
              <li>Manta aluminizada</li>
              <li>Lençóis;</li>
              <li>Esfigmomanômetro;</li>
              <li>Estetoscópio;</li>
              <li>Bastão de madeira ou de material resistente;</li>
              <li>Oxímetro de pulso;</li>
              <li>Conjunto de oxigenoterapia com fluxômetro;</li>
              <li>Jelco no 14 ou 16;</li>
              <li>Equipo simples.</li>
              <li>Solução salina isotônica (Ringer Lactato ou Solução Fisiológica 0,9%) – 2 frascos de 1000 ml cada ou 4 de 500 ml.</li>
            </ul>
          </Accordion>

          <Accordion title="Sinais e sintomas">
            <ul>
              <li>Dor abdominal;</li>
              <li>Hematomas;</li>
              <li>Taquipneia;</li>
              <li>Taquicardia</li>
              <li>Posição característica proteção;</li>
              <li>Abdômen distendido.</li>
              <li>Hematomas nos flancos abdominais e ao redor do umbigo;</li>
              <li>Ferimentos (armas brancas ou de fogo, outros);</li>
              <li>Sinais de compressão do cinto de segurança;</li>
              <li>Sinais de choque.</li>

              <h4>Observações</h4>
              <li>O socorrista deve suspeitar de hemorragia de origem abdominal na presença de sinais de choque sem explicação aparente.</li>
            </ul>
          </Accordion>

          <Accordion title="Possibilidades de erro">
            <ul>
              <li>Retardar o transporte;</li>
              <li>Não reconhecer sinais de choque;</li>
              <li>Transportar para hospital sem infraestrutura pra cirurgia abdominal.</li>
            </ul>
          </Accordion>

          <Accordion title="Fatores Complicadores">
            <ul>
              <li>Segurança da cena;</li>
              <li>Objetos encravados;</li>
              <li>Evisceração;</li>
              <li>Choque associado;</li>
              <li>Conflitos institucionais.</li>
            </ul>
          </Accordion>

          <Accordion title="Glossário">
            <ul>
              <li><b>Taquipneia:</b> frequência respiratória elevada.</li>
              <li><b>Taquicardia:</b> frequência cardíaca elevada.</li>
              <li><b>Evisceração:</b> exposição de vísceras.</li>
            </ul>

          </Accordion>

          <Accordion title="Referencial bibliográfico">
            <ul>
              <li>Atendimento Pré-Hospitalar ao Traumatizado – PHTLS (NAEMT). 7a ed. Rio de Janeiro: Elsevier, 2014.</li>
              <li>Brasil. Ministério da Saúde. Secretaria de Atenção à Saúde. Protocolos de intervenção para o SAMU 192 – Serviço de Atendimento Móvel de Urgência. Brasília: Ministério da Saúde, 2014.</li>
              <li>Shere-Wolfe RF, Galvagno Jr SM, Grissom TE. Critical care considerations in the management of the trauma patient following initial resuscitation. Scandinavian Journal of Trauma, Resuscitation and Emergency Medicine 2012, 20 (68).</li>
              <li>Spahn DR, Bouillon B, Cerny V, Coats TJ, Duranteau J, Fernández-Mondéjar E, et al. Management of bleeding and coagulopathy following major trauma: an updated European guideline. Critical Care 2013;17 (2).</li>
            </ul>
          </Accordion>

    </div>
  </IonContent>
</IonPage>

  );
}

export default TraumaAbdominal;
