import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonCard, 
  IonCardHeader, 
  IonCardSubtitle, 
  IonCardTitle, 
  IonCardContent, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import '../Page.css'


const desativacao: React.FC = () => {

  return (

  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>ATO DO COMANDANTE OPERACIONAL</IonTitle>
      </IonToolbar>
    </IonHeader>


    <IonContent fullscreen>

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>Publicado no Boletim Geral 009, de 12 de janeiro de 2018</IonCardSubtitle>

          <IonCardTitle>ATO DO COMANDANTE OPERACIONAL - XLIII - DETERMINAÇÃO REFERENTE À DESATIVAÇÃO TEMPORÁRIA DE VIATURAS DO TIPO UR/URSB</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p align="justify">&#8195; 

            O  COMANDANTE  OPERACIONAL,  no  uso  das  atribuições  que  lhe  confere  o  art.  22  do  Decreto 31.817, de 21 jun. 2010, que regulamenta o art. 10-B, inciso II, da Lei 8.255, de 20 nov. 1991, que dispõe sobre a organização básica do CBMDF;e,Considerando  que  a  escala  de  serviço  do  Socorrista  e  Auxiliarde  UR/URSB  é  de  12x24/12X72, conforme Portaria 16, de 30 abr 2015, publicada no BG 082, de 4 maio 2015;
          </p>
          <p align="justify">&#8195; 
            Considerando a primazia em se manter a qualidade do serviço de Atendimento Pré Hospitalar; 
          </p>

          <p align="justify">&#8195; 
            Considerando que o Responsável Técnico e o Auxiliar fazem parte da Guarnição de Viatura que mais atende ocorrências na Corporação;
          </p>
          <p align="justify">&#8195; 
            Considerando  que  temos  cada  vez  menos  militares  interessados  em  exercer  a  função  de Responsável Técnico e Auxiliar de UR/URSB por diversos motivos;
          </p>
          <p align="justify">           
            Considerando que a guarnição de UR/URSB estáficando além do horário estipulado para passagem de  serviço,  estará  sujeita  a  uma  maior  exposição  biológica  devido,  ao  stress  da  função  e  da  própria situação em não possuir rendição;
          </p>
          <p align="justify">&#8195; 
            Considerando que uma ocorrência  dura  um  tempo razoável desde  o  acionamento,  deslocamento, início, término de operação e regresso ao GBM e que por isso a Guarnição será rendida bem aquém do horário estipulado;
          </p>
          <p align="justify">&#8195; 
            Considerando  ainda  que  a  guarnição  ao  término  da  Escala  de  Serviço  deve  transmitir  todas as ordens, alterações e informações afetas ao serviço ao militar que lhe suceder, conforme Portaria n°16, de 30 abr 2015 e isso demanda um tempo razoável;
          </p>
          <p align="justify">&#8195; 
            Considerando  que  os  militares  devem  realizar  a  limpeza  e  desinfecção  de  viatura  (limpeza concorrente), conforme item XLV do BG 164, de 29 ago.2013 para a passagem de serviço,prolongando assim, a carga horária de trabalho estipulada;
          </p>
          <p align="justify">&#8195; 
            Considerando  casos  recorrentes  em  que,  principalmente,  o  Responsável  Técnico  não  possui rendição, ficando além do horárioestipulado até que se providencie seu substituto;
          </p>
          <p align="justify">&#8195; 
            Considerando que o Responsável Técnico fica na dependência da subjetividade do Superior de Dia em liberá-lo, mesmo após cumprindo a Escala de Serviço de UR/URSB, conforme previsto, resolve:
          </p>

          <p align="justify">&#8195; 
           <br></br>&#8195;

            <b>DETERMINAR</b> que as viaturas do tipo UR/URSB sejam desativadas temporariamente caso não haja rendição  para  quaisquer  militares  da  guarnição  ou  que  não  se  consiga  providenciar  um  substituto  por qualquer motivo em no máximo 15 (quinze) minutos.
          </p>

          <p>
            <br></br>&#8195;

            Em conseqüência:
          </p>
          <p>&#8195;
            a) os militares da guarnição deverão ser liberados ao término do serviço de acordo com a tolerânciacitada acima;
          </p>
          <p>&#8195;
            b)  omilitar  mais  antigo  na  OBM  dará  ciência  ao  Superior  de  Dia  e  o  CGD  deverá  adotar  as providencias cabíveis;
          </p>
          <p>&#8195;
            c) as baixas de viaturas do tipo UR/URSBem decorrência de falha administrativa em virtude do não cumprimento,  por  parte  dos  secretários  e/ou  escalantes,  das  Normas  em  vigor  estarão  passíveis  de sanções disciplinares.
          </p>

          <p>&#8195;
            <br></br>
            (NB CBMDF/COMOP/GACOP -00053-00040888/2017-17)
          </p>
        </IonCardContent>
      </IonCard>
    </IonContent>
  </IonPage>

  );
}

export default desativacao;
