import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton,
  IonCard,
  IonImg
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const DEA: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>DEA com Eletrodos Reutilizáveis</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://www.cbm.df.gov.br/download/uso-do-dea-bg-061-30mar2022-pdf/?tmstv=1669233375">POP USO DO DEA (BG 061 de 30/03/2022)</a>
            </p>

</ul>
              </Accordion>
                              
          <Accordion title="Resultados Esperados">
         
              <ul>
            <li>Agilidade e efetividade na utilização do DEA, durante o atendimento a vítimas de PCR;</li>
            <li> Mitigação de falhas na operação do DEA;</li>
            <li>Eficiência na escolha e utilização dos recursos disponíveis.</li>
          </ul>

    </Accordion>

    <Accordion title="Material Recomendado">
      <ul>
        <li>Desfibrilador Externo Automático (DEA);</li>
        <li>Material para tricotomia;</li>
        <li>Papel toalha ou toalha seca.</li>              
      </ul>
    </Accordion>

    <Accordion title="Sinais e Sintomas">
      <ul>
        <li>Arresponsividade; </li>
        <li>Ausência de respiração efetiva</li>
        <li>Ausência de pulso central</li>
      </ul>
    </Accordion>

    <Accordion title="Observações">
      <ul>
        <p align="justify">&#8195;
          <b>AVALIAÇÃO DE PULSO CENTRAL</b>
        </p>
        <li>Adulto: Pulso Carotídeo;</li>
        <li>Crianças: Pulso Carotídeo;</li>
        <li>Lactentes: Pulso Braquial ou femoral</li>
        <p></p>
        *O presente documento trata do atendimento a adultos uma vez que os eletrodos utilizados para crianças e lactentes ainda são do tipo descartável.
      </ul>
    </Accordion>    
          
          <Accordion title="Procedimentos Adulto">
      <ol>
        <li>Avalie a segurança da Cena;</li>
        <li>Acione o Suporte Avançado de Vida;</li>
        <li>Inicie a Reanimação Cardiopulmonar (RCP) com 3 socorristas enquanto o DEA é instalado. O DEA deve ser utilizado assim que possível;</li>
        <li>Retire o DEA da sua bolsa protetora;</li>
        <img src="assets/DEA/figura1.jpg" className="figura"/>
        <p></p>
        <li>Ligue o DEA</li>
        <li>Abra o envelope que contém as duas placas de gel adesivo para eletrodos;</li>
         <img src="assets/DEA/figura2.jpg" className="figura"/>
        <p></p>
        <li>Retire apenas a película de proteção amarela do gel adesivo;</li>
        <img src="assets/DEA/figura3.jpg" className="figura"/>
        <p></p>
        <li>Cole pressionando com firmeza os eletrodos reutilizáveis na placa com as partes metálicas totalmente no gel;</li>
         <img src="assets/DEA/figura4.jpg" className="figura"/>
        <p></p>
        <li>Retire o conjunto eletrodo/gel puxando pela <b>aba vermelha</b>;</li>
        <li>Posicione o primeiro eletrodo (branco) no quadrante inferior esquerdo do tórax do paciente de modo que não se interrompam as manobras de RCP;</li>
        <img src="assets/DEA/figura5.jpg" className="figura"/>
        <p></p>
        <li>Retire o segundo conjunto eletrodo/gel puxando pela <b>aba vermelha</b>;</li>
        <li>Posicione o segundo eletrodo (azul) no quadrante superior direito do tórax do paciente.</li>
        <img src="assets/DEA/figura6.jpg" className="figura"/>
        <p></p>
        <li>Aguarde a leitura do ritmo cardíaco pelo DEA;</li>
        <li>Não toque no paciente;</li>
        <p><font color="red">*O DEA indicará o ritmo e recomendará ou não o choque</font></p>
        <li>Caso seja indicado o choque, pressione o botão de choque que estará com luz intermitente;</li>
        <li>Caso o choque não seja recomendado, siga as instruções do DEA e retome a RCP.</li>
<p><font color="red">*Na hipótese de deflagração do choque, efetue o registro para controle da vida útil das pás reutilizáveis.</font></p>
       
            </ol>
    </Accordion>
          
  

    <Accordion title="Recomendações">
      <ul>
        <li>Mantenha os eletrodos reutilizáveis plugados no desfibrilador o tempo todo;</li>
        <li>Certifique-se de que o tórax do paciente está seco e não possui pelos ou sujidades que possam prejudicar a adesão do gel descartável;</li>
        <li>Caso seja necessário, execute a tricotomia;</li>
        <li>Não abra a embalagem do gel adesivo descartável até que esteja pronto para uso no paciente;</li>
        <li>Não remova a película protetora de cor amarela do gel adesivo descartável sem que os eletrodos estejam prontos para serem utilizados;</li>
        <li>Atenção para que o gel adesivo não entre em contato com a luva de látex do socorrista, ficando a critério do socorrista, realizar esta etapa, sem uso de luvas.</li>
        <li>Não deixe nenhuma bolha de ar entre o eletrodo e o gel durante o passo <b>"9"</b> dos procedimentos</li>
        <li>Cole os eletrodos no tórax do paciente com firmeza;</li>
        <li>Mantenha o material sempre bem acondicionado e protegido de intempéries.</li>
      </ul>
    </Accordion>

    <Accordion title="Fatores Complicadores">
      <ul>
        <li>Pele úmida ou molhada;</li>
        <li>Paciente com muitos pelos no tórax.</li>
         </ul>
    </Accordion>

    <Accordion title="Glossário">
      <ul>  
        <li>DEA - Desfibrilador Externo Autormático</li>
        <li>RCP - Ressuscitação Cardiopulmonar</li>
     
        

      </ul>
    </Accordion>
<Accordion title="Base legal e referencial">
              <ul>
<p>  - American Heart Association – AHA, Diretrizes de Reanimação Cardiopulmonar e Atendimento Cardiovascular de Emergência, 2020.</p>
<p>- OLASVEENGEN, T. M. et al. European Resuscitation Council Guidelines 2021: Basic Life Support. Resuscitation, v. 161, p. 98–114, 2021.</p>
<p>- ZOLL. Guia do Administrador do AED Plus da ZOLL. 2015.</p>
                
                
                
                            </ul>
          </Accordion>
  </div>
</IonContent>
</IonPage>

  );
}

export default DEA;
