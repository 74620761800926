import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const Suicidio: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Abordagem à Tentativa de Suicídio</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://drive.google.com/file/d/1lA7il7ZmhI_1V986KJRodSWEqmvLN2Ev/view?usp=share_link">POP Abordagem à Tentativa de Suicídio (BG 202 de 27/10/2021)</a>
            </p>

</ul>
              </Accordion>
          
                    <Accordion title="Procedimentos">
            <ul>
              
              <li><b>ORIENTAÇÕES GERAIS</b></li>
         
              <ul>
            <li>Fazer uma chegada discreta ao local, com sinais luminosos e sonoros desligados;</li>
            <li>Buscar informações adicionais sobre o histórico ou motivo do comportamento apresentado
com familiares e/ou testemunhas;</li>
            <li>Aproximar-se da vítima de forma calma até onde o tentante permitir, para que ele se
acostume com a presença do abordador, criando confiança;</li>
            <li>Apresentar-se à vítima;</li>
            <li>Chamar a vítima pelo nome;</li>
            <li>Concentrar-se na comunicação com a vítima a todo instante;</li>
            <li>Falar de forma clara e simples, sem termos técnicos ou jargões militares e pausadamente;</li>
            <li>Não deve haver conversas paralelas entre os próprios membros da equipe durante o
atendimento;</li>
            <li>Adotar postura respeitosa e calma, aparentar confiança e transmitir segurança;</li>
            <li>Sempre evitar: gritar e usar força física na vítima;</li>
            <li>A vítima possui fala livre e o abordador escuta aberta - a vítima deve ficar o mais
confortável possível;</li>
            <li>A abordagem deve ser conduzida sem julgamentos por parte do abordador;</li>
            <li>Caso a vítima faça exigências:</li>
                
      <ul>
            <li>Não dar alimentos;</li>                                
            <li>Barganhar o acatamento das exigências para que a vítima saia da situação de
risco, com as devidas ressalvas;</li>
            <li>Evitar o acatamento de exigências como: trazer familiares/companheiros à cena;
solicitação de cigarros e/ou drogas;</li>
               </ul>
                
            <li>Nunca deixar a vítima sozinha até o desfecho da ocorrência;</li>                 
            <li>Atenção plena e constante - antecipar possíveis reações para a sua própria segurança e dos
envolvidos;</li>        
            <li>Buscar informações adicionais sobre o histórico ou motivo do comportamento apresentado
com familiares e/ou testemunhas;</li>
            <li>O Abordador será aquele com o qual a vítima se sentir mais confortável, de modo que, caso
necessário, este será substituído pelo auxiliar;</li> 
            <li>Realizar a regulação médica e conduzir o tentante ao hospital;</li>
            <li>O médico regulador, autoridade sanitária do caso, decidirá para onde o paciente será
encaminhado, devendo o militar obrigatoriamente, anotar na Ficha de Atendimento PréHospitalar o número da GAE que conterá o nome da unidade e o responsável pelo paciente a
partir daquele momento.</li>
          </ul>
              
                        <li><b>COCB</b></li>
              <ul>
        
          <li>Acionamento imediato do Grupamento responsável pela área.</li>
         <li>Em caso de tentativa de suicídio a partir de plano elevado, acionamento imediato do
Grupamento de Busca e Salvamento.</li>
                
</ul>
  
                          <li><b>PRIMEIRA RESPOSTA</b></li>
              <ul>
        
         <li>GBM do local acionado via COCB ou solicitante.</li>
         <li>Guarnição de salvamento – viaturas tipo ASE, ABSL, ABR.</li>
         <li>Guarnição de atendimento pré-hospitalar - viatura tipo UR.</li>
               
</ul>
  
                <li><b>AÇÕES EMPREGADAS EM ORDEM DE ACONTECIMENTO</b></li>
         
              <ul>
            <li>Confirmação da solicitação.</li>
            <li>Coleta de informações com solicitante (check-list de entrevista) que deve ser repassada em
meios físicos (papel impresso) ou virtual (preenchimento pelo link) ao Comandante da VTR de
Salvamento do próprio GBM ou do GBS.</li>
            <li>Em caso de presença de arma de fogo ou arma branca, acionamento da PM.
</li>
            <li>Chegada ao local com sinais sonoros e luminosos desligados.</li>
            <li>Informar ao COCB a chegada ao local e assumir o comando do incidente.</li>
            <li>Em caso do tentante ser Bombeiro Militar, informar o chefe imediato e o CEABM da
ocorrência (esse último, para acompanhamento do caso pós-ocorrência).</li>
            <li>Isolamento da área.</li>
            <li>Definição de abordador e auxiliar (2 militares) e equipe de intervenção física (2 militares
especialistas em altura).</li>
            <li>Buscar mais informações, seja com o solicitante, seja com transeuntes que observam a
cena.</li>
            
          </ul>
  
                              <li><b>ABORDAGEM HUMANIZADA</b></li>
         
              <ul>
            <li>Aproximação calma e silenciosa até onde o tentante permitir (em caso de altura, deverá ser
garantida a ancoragem dos socorristas).</li>
            <li>Silêncio inicial por alguns segundos.</li>
            <li>Apresentação inicial (Sugestão: "Meu nome é _____________, sou do Corpo de Bombeiros
e estou aqui para te escutar.").</li>
            <li>Caso o tentante esteja:</li>
                
                              <ul>
            <li>Agressivo - Adotar uma posição inferior, olhando de baixo para cima e evitando
contato visual direto, procurando olhar para o nariz ou entre os olhos. Não ceder a
provocações;</li>
            <li>Depressivo - Adotar uma posição mais energética que a do tentante (Guardadas as
devidas proporções), com um tom de voz firme;</li>
            <li>Psicótico - É necessário fazer uma análise no momento para saber se deve entrar no
delírio do tentante ou não.</li>
                                                            </ul>  
                
            <li>Após a apresentação, iniciar a abordagem com perguntas simples cujas respostas sejam
"sim" ou "não" para mapear fatores protetivos e de risco (Sempre evitando fatores de risco, onde
a reação vai ser negativa e/ou prejudicial). Exemplos de assuntos que podem ser fatores de risco
ou protetivos:</li>
                                              <ul>
            <li>Família (Ex.: O senhor(a) possui filhos? Quantos filhos?);</li>
            <li>Trabalho (Ex.: O senhor(a) trabalha?);</li>                                                
             <li>Hobbies (Ex.: O que o senhor(a) gosta de fazer? Gosta de esportes?);</li>                                               
             <li>Religião (As perguntas podem ser voltadas para a religião do tentante, não devendo
o abordador proceder com julgamentos ou impor algo de sua própria religião);</li>
            <li>Relacionamentos (Ex.: O senhor(a) é casado(a)?)</li>                                                 
                                          </ul>
                
              <li>Perguntas complexas abordando o motivo principal da tentativa de suicídio.</li>
                
                                                              <ul>
              <li>Paráfrase resumida contextualizando todo o diálogo e retomando a motivação principal;</li> 
              <li>Fazer uma "pausa silenciosa", caso haja necessidade;</li> 
              <li>"Guiar" o tentante pela conversa de modo que ele encontre sua solução para a situação
vivenciada, que não o suicídio;</li> 
              <li>Sempre repetir fatos positivos ao tentante;</li> 
              <li>Evitar falar de fatores de risco, mas se uma vez abordado, trocar de assunto;</li> 
              <li>Buscar pontos em comum com o tentante dentro dos fatores protetivos.</li> 
              <li>Após o contato físico com o tentante, evitar a intervenção ou contenção física, e conduzir o
tentante da situação de risco de forma calma;</li> 
              <li>Conduzir o tentante para o hospital, após feita a regulação médica;</li> 
              <li>Caso o tentante demonstre claramente a intenção de consumação, acionar a equipe de
intervenção;</li> 
              <li>Caso seja necessária a equipe de intervenção, o abordador não deve participar, a menos
que necessário;</li> 
              <li>Caso haja o acionamento do GBS, este assumirá a equipe de intervenção, enquanto a
abordagem humanizada deverá permanecer com o militar que manteve o diálogo aberto com o
tentante;</li> 
              <li>Uma vez que o tentante é transportado para o hospital, o abordador com o qual o tentante
criou o vínculo deverá acompanhá-lo até que seja entregue aos cuidados da equipe hospitalar.</li> 
                   </ul>
  
                         </ul>
              <li><b>EQUIPE DE INTERVENÇÃO (em caso de altura)</b></li>
         
              <ul>
            <li>Identificação de pontos de ancoragem.</li>
            <li>Fornecimento de segurança para todos os expostos ao risco.</li>
            <li>Estabelecimento da melhor técnica para a situação.</li>
            <li>Definir os recursos necessários.</li>
            <li>Montagem dos sistemas pré-estabelecidos.</li>
            <li>Se possível, promover meios para a redução da queda, tais como o posicionamento de
viatura ou ônibus abaixo de viadutos, de forma coordenada com o Comandante do Incidente.</li>
            <li>Permanecer pronto para atuação aguardando a ordem do Comandante do Incidente.</li>
            <li>A equipe de intervenção deve estar preparada para realizar a intervenção, contenção e
extração da vítima do local de risco, planejando as técnicas empregadas para a amarração e
descensão da vítima de forma antecipada, com materiais previamente separados.</li>
            <li>Sistemas para intervenção envolvendo altura:</li>

              <ul>
            <li><b>Vítima no mesmo nível da equipe:</b> contenção manual por resgatista ancorado (ex:
cabo da vida), sistema de freio debreável com resgatista ancorado em corda, ou similar
que forneça a segurança necessária.</li>
            <li><b>Vítima em pavimento inferior à equipe de intervenção:</b> contenção por meio de
descida técnica vertical de impacto ("salto do suicida") ou similar. Esta técnica deverá ser
realizada exclusivamente por bombeiro militar especializado (CESALT, COBS ou CESEI) ou
formalmente habilitado em capacitação específica para tal.</li>
            <li><b>Vítima em pavimento superior à equipe de intervenção:</b> situação de extremo risco
e qualquer intervenção nesse contexto deverá ser realizada apenas em último caso. Esta
técnica deverá ser realizada exclusivamente por bombeiro militar especializado (CESALT,
COBS ou CESEI) ou formalmente habilitado em capacitação específica para tal.</li>
              </ul>
            
          </ul>

<li><b>AÇÕES EMPREGADAS EM ORDEM DE ACONTECIMENTO</b></li>
         
              <ul>
            <li>A guarnição do GBS deve assumir a equipe de intervenção, no entanto, o Comando do
Incidente será do mais antigo presente no local.</li>
            <li>Reunião com os primeiros abordadores para recebimento das informações.</li>
            <li>Revisão de pontos de ancoragem.</li>
            <li>Restabelecimento da melhor técnica para a situação.</li>
            <li>Redefinir os recursos necessários.</li>
            <li>Caso seja necessário/aconselhável a mudança de tática, esta deverá ser feita de modo que
a abordagem possa ser executada mesmo durante a troca de sistema. Não sendo possível, os
primeiros militares deverão permanecer até o final do incidente.</li>
            <li>Remontagem dos sistemas pré-estabelecidos.</li>
            <li>Permanecer pronto para atuação aguardando a ordem do Comandante do Incidente.</li>
          </ul>
              
                         </ul>
    </Accordion>

          
          

    <Accordion title="Resultados Esperados">
      <ul>
        <li>Evitar acidentes e incidentes ao Bombeiro Militar no atendimento à ocorrência;</li>
        <li>Empregar uma abordagem humanizada, que reduza as chances de reincidência exitosa por
parte do tentante;</li>
        <li>Optar pela abordagem humanizada antes da intervenção física, sempre que possível;</li>   
        <li>Evitar a consumação da tentativa de suicídio ou quaisquer lesões ao paciente;</li> 
        <li>Padronizar uma conduta de atuação que respeite os princípios da abordagem humanizada.</li> 
      </ul>
    </Accordion>

    <Accordion title="Material recomendado">
      <ul>
        <li><b>RESPONSABILIDADE DO COMANDANTE DO SOCORRO:</b></li>
              <ul>
              <li>COLETIVO:</li>
                    <ul>
                    <li>Viatura de busca, salvamento e resgate.</li>
                    <li>Viatura de Atendimento Pré-hospitalar.</li>
                    <li>Material de sinalização e isolamento.</li>
                    <li>Material de iluminação.</li>
                    <li>Material de comunicação (rádios portáteis).</li>
                    <li>Equipamentos e materiais de salvamento em altura.</li>
                    <li>Material para ancoragem.</li>
                    <li>Materiais e equipamentos de atendimento pré-hospitalar (Bolsa de APH).</li>
                    </ul>        
                <li>INDIVIDUAL:</li>
                    <ul>
                    <li>Equipamento de iluminação individual (lanterna).</li>
                    <li>Equipamentos de salvamento em altura.</li>
                    </ul> 
              </ul>
        <li><b>UNIFORME PARA ATENDIMENTO À OCORRÊNCIA DE ABORDAGEM A TENTATIVA DE SUICÍDIO:</b></li>
              <ul>
              <li>3°A ou EPI específico de Salvamento.</li>
              <li>Não é recomendada a utilização do EPI de incêndio nesse tipo de ocorrência.</li>
              </ul>
         </ul>
    </Accordion>

 
    <Accordion title="Possibilidades de erro">
      <ul>
        <li>Mobilizar recursos insuficientes ou inadequados;</li>
        <li>Empregar a abordagem tática de maneira inadequada/incorreta ou precocemente;</li>
        <li>Permitir a interferência de pessoas alheias à operação;</li>
        <li>Comunicação ineficaz ou desrespeito à dor do tentante.</li>
        <li>Atuação da Equipe de Intervenção de forma descoordenada ou sem a autorização do
Comandante do Incidente.</li>

      </ul>
    </Accordion>
          
              <Accordion title="Fatores Complicadores">
      <ul>
        <li>Locais de difícil acesso;</li>
        <li>Trabalho em plano elevado;</li>
        <li>Condições climáticas adversas;</li>
        <li>Ocorrências no período noturno (redução da visibilidade);</li>
        <li>Falta de materiais para comunicação;</li>
        <li>Paciente agressivo ou em surto psicótico;</li>
        <li>Paciente portando material perfuro-cortante.</li>
        
      </ul>
    </Accordion>

    <Accordion title="Glossário">
      <ul>
        <li>Abordagem humanizada: realizada por 2 militares ( 1 abordador + 1 auxiliar, que ficará
atrás, no campo de visão do tentante, mas sem se comunicar com o mesmo), por meio da qual se
busca retirar o tentante da situação de risco pelo convencimento à desistência do ato.
- Fase da ocorrência suicida em que o Bombeiro faz uso iminente da comunicação - verbal ou
gestual - para atingir o objetivo</li>
        <li>Intervenção física: na ineficiência da abordagem humanizada, consiste na montagem de
sistemas ou definição de táticas de retirar do tentante mesmo contra sua própria vontade.Um ou mais bombeiros militares utilizam de poder coercitivo físico para imobilizar, conter,
paralisar o tentante, evitando a consumação do suicídio</li>
        <li>Equipamentos e materiais de salvamento em altura: são aqueles utilizados nas operações
de salvamento/resgate de pessoas ou animais, tais como: triângulo de resgate/salvamento ou
similar, cabos da vida, cordas de salvamento, cadeira de resgate, mochila, equipamentos
metálicos (mosquetão, roldanas, freios, blocantes, ascensores, etc.) e similares.</li>
        <li>EPI: equipamento destinado à proteção de riscos suscetíveis de ameaçar a segurança e a
saúde do Bombeiro Militar, composto por: Capacete, óculos de proteção, luvas para atividade em
altura, cadeirinha, etc.</li>
        </ul>
    </Accordion>

    <Accordion title="Base Legal e referencial">
      <ul>  
        <li>ACRE, Corpo de Bombeiros Militar do Estado do. Procedimento Operacional Padrão.
Tentativa a suicídio. Versão 1, 2020.</li>
        <li>BORGES, Kelvia Maria Oliveira et al. Abordagem na tentativa de suicídio: manual teóricoprático para profissionais da segurança pública. Editora FAMPER, 2018.</li>
        <li>BRASIL. Constituição da República Federativa do Brasil. Diário Oficial da União. Poder
Legislativo, Brasília, DF, p. 1, col. 1, 05 de outubro de 1988. Brasília, DF: Senado Federal: Centro
Gráfico, 1988.</li>
        <li>DISTRITO FEDERAL. Poder Executivo. Decreto n° 31.817, de 21 de junho de 2010.
Regulamenta o inciso II, do artigo 10-B, da Lei n° 8.255, de 20 de novembro de 1991, que dispõe
sobre a Organização Básica do Corpo de Bombeiros Militar do Distrito Federal. Diário Oficial
[do] Distrito Federal. Poder Executivo, Brasília, DF, n. 118, 22 de junho de 2010, Seção 1, p. 2.</li>
        <li>MUNHOZ, Diógenes Martins. Abordagem técnica a tentativas de suicídio. 1ª Edição. São
Paulo: Editora AuthenticFire, 2018</li>
   
        

      </ul>
    </Accordion>
<Accordion title="Fluxograma">
              <ul>
                <img src="assets/suicidio/fluxograma.jpg" className="figura"/>
                
                
                
                            </ul>
          </Accordion>
          
          <Accordion title="Anexo">
              <ul>
                <img src="assets/suicidio/qrcode.png" className="figura"/>
                <img src="assets/suicidio/checklist.jpg" className="figura"/>                
                            </ul>
          </Accordion>


  </div>
</IonContent>
</IonPage>

  );
}

export default Suicidio;
