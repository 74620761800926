import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const TCE: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Traumatismo Cranioencefálico</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://drive.google.com/file/d/1wZWAULUNaDk2geXdcW0PYr8ZLGnt3bhq/view?usp=share_link">POP TCE (BG 237 de 17/12/2015)</a>
            </p>

</ul>
              </Accordion>
          
                    <Accordion title="Procedimentos">
            <ul>
              
              <li>Gerenciar riscos na cena de emergência;</li>
              <li>Avaliar a biomecânica envolvida;</li>  
              <li>Manter via aérea pérvia com manobra para trauma;</li>  
              <li>Estabilizar manualmente a coluna cervical;</li>   
              <li>Mensurar e aplicar colar cervical;</li>
              <li>Avaliar a qualidade da respiração;</li>  
              <li>Monitorizar a oximetria de pulso;</li>  
              <li>Iniciar oxigenoterapia (10 – 15 L/min) se %SpO2 {'<'} 95%, ou se {'>'} 95%, mas com sinais de dificuldade respiratória (2 – 5 L/min);</li>
              <li>Considerar ventilação com BVM se frequência respiratória {'<'} 12 rpm ou {'>'} 30 rpm;</li>  
              <li>Controlar hemorragias;</li>  
              <li>Realizar Escala de Coma de Glasgow;</li>  
              <ul>
                <img src="assets/escalaglasgow.png" className="figura"/>
                           
                
                            </ul>
              <li>Realizar acesso venoso periférico com jelco nº 14 ou 16, preferencialmente em veias
antecubitais, conforme orientação do médico regulador; *</li>  
              <li>Iniciar a infusão de Ringer Lactato, conforme orientação do regulador médico a fim de
manter pressão arterial sistólica {'>'} 90mmHg.</li>
               <li>Promover controle da temperatura corporal por meio da utilização de lençóis, mantas
aluminizadas e/ou controle da temperatura do salão de atendimento da viatura;</li>  
              <li>Estar atento para a ocorrências de vômito ou convulsão;</li>
              <li>Realizar avaliação secundária;</li>  
              <ul>
              <li>Realizar exame físico detalhado (atenção para resposta e simetria pupilar);</li>
              <li>Sinais Vitais;</li> 
              <li>SAMPLA.</li>
              </ul>
              <li>Imobilizar em prancha longa;</li> 
              <li>Encaminhar ao hospital de referência.</li>  
              <b>* Procedimentos realizados somente por profissionais habilitados (médicos, enfermeiros,
                técnicos e auxiliares de enfermagem, inscritos em seus respectivos conselhos de classe.</b>
          </ul>
    </Accordion>

    <Accordion title="Resultados Esperados">
      <ul>
        <li>Evitar o agravamento do quadro;</li>
        <li>Diminuir a morbimortalidade.</li>            
      </ul>
    </Accordion>

    <Accordion title="Material recomendado">
      <ul>
        <li>Colar cervical;</li>
        <li>Prancha longa;</li>
        <li>Tirantes;</li>
        <li>Gazes;</li>
        <li>Ataduras;</li>
        <li>Compressas cirúrgicas;</li>
        <li>Manta aluminizadas;</li>
        <li>Lençóis;</li>
        <li>Esfigmomanômetro;</li>
        <li>Estetoscópio;</li>
        <li>Oxímetro de pulso;</li>
        <li>Termômetro;</li>
        <li>Conjunto de oxigenoterapia com fluxômetro;</li>
        <li>Jelco nº 14 ou 16;</li>
        <li>Equipo simples;</li>
        <li>Ringer Lactato ou Solução Fisiológica 0,9%– 2 frascos de 1000 ml cada ou 4 de 500 ml.</li>
      </ul>
    </Accordion>

    <Accordion title="Sinais e sintomas">
      <ul>
        <li>Dor localizada;</li>
        <li>Náuseas e vômitos;</li>   
        <li>Alteração visual;</li>
        <li>Alteração do nível de consciência;</li> 
        <li>Corte profundo, laceração ou hematoma no couro cabeludo, ou testa;</li>
        <li>Deformidade no crânio;</li>   
        <li>Sinais de contusão;</li>
        <li>Pupilas assimétricas;</li>   
        <li>Sangramento e/ou saída de líquor por ouvido e/ou nariz;</li>
        <li>Hematoma periorbitário;</li>   
        <li>Arroxeamento retroauricular (sinal de Batle);</li>
        <li>Flexão e/ou extensão anormais.</li>    
      </ul>
    </Accordion>       
          
          <Accordion title="Observações">
      <ul>
        <li>A infusão de fluidos deve ser feita de maneira cautelosa. A não ser que a situação exija a
permanência no local, não se justifica retardar o transporte ao hospital a fim de se obter o
acesso venoso periférico. Não se recomenda a infusão agressiva de líquidos, já que tal
conduta tem sido associada à consequências deletérias para o paciente, tais como:
hemodiluição, diminuição dos fatores de coagulação, coagulopatias e rompimento de
coágulos ativos. Considere manutenção da pressão arterial sistólica {'>'} 90 mmHg em pacientes
hipotensos.</li>
        <li>O Ringer Lactato é a solução de primeira escolha devido à sua composição ser mais
semelhante ao plasma e servir como solução tampão, desejável na acidose metabólica. Em
sua falta, o socorrista deve optar pela Solução Fisiológica 0,9%.</li>
        <li>Não conter com pressão sangramentos no ouvido e/ou nariz;</li>
        <li>A imobilização de fraturas na cena de emergência só deve ser considerada em condições
clínica estáveis.</li>
        <li>A ingestão de álcool pelo paciente é um fator complicador na avaliação do nível de
consciência e Escala de Coma de Glasgow.</li>
         <li>Em situações de choque hemorrágico o socorrista deverá sempre considerar o acionamento
de Suporte Avançado de Vida (SAV), avaliando a relação tempo resposta do SAV e tempo
de chegada do pronto socorro.</li>
      </ul>
    </Accordion>

    <Accordion title="Possibilidades de erro">
      <ul>
        <li>Reconhecimento tardio;</li>
        <li>Retardo no transporte.</li>
      </ul>
    </Accordion>
              <Accordion title="Fatores complicadores">
      <ul>
        <li>Ingestão de álcool pelo paciente;</li>
        <li>Dificuldade no acesso ao Suporte Avançado de Vida;</li>
        <li>Conflitos institucionais;</li>
      </ul>
    </Accordion>

    <Accordion title="Glossário">
      <ul>
        <li><b>Equipo:</b>dispositivo fechado utilizado para a infusão de líquidos.</li>
        <li><b>Hematoma periorbital:</b> hematoma ao redor dos olhos.</li>
        <li><b>Jelco:</b>dispositivo composto por agulha e cânula para acesso à vasos sanguíneos.</li>
        <li><b>Oximetria:</b>procedimento que visa medir a concentração de oxigênio no sangue.</li>
        <li><b>Sinal de Batle:</b>arroxeamento atrás da orelha.</li>
        <li><b>Suporte Avançado de Vida:</b>modalidade de assistência em saúde na qual são realizadas intervenções
invasivas.</li>
         </ul>
    </Accordion>

    <Accordion title="Referencial Bibliográfico">
      <ul>  
        <li>Atendimento Pré-Hospitalar ao Traumatizado – PHTLS (NAEMT). 7ª ed. Rio de Janeiro:
Elsevier, 2014.</li>
        <li>Badjatia N, Carney N, Crocco TJ, Fallat ME, Hennes HM, Jagoda AS, et al. Guidelines for
prehospital management of traumatic brain injury 2nd edition. PreHospital Emergency Care. 2008;
12(1).</li>
        <li>Brasil. Ministério da Saúde. Secretaria de Atenção à Saúde. Protocolos de intervenção para o
SAMU 192 – Serviço de Atendimento Móvel de Urgência. Brasília: Ministério da Saúde, 2014.</li>
        <li>Nelson RD, Stopyra J, March JA. Prehospital care of traumatic brain injury in North
Carolina. 2015; 76(2).</li>
        <li>Shahin H, Gopinath SP, Robertson CS. Influence of alcohol on early Glasgow Coma Scale
in head injured patients. Journal of Trauma. 2010; 69(5).</li>
        <li>Tohme S, Delhumeau C, Zuercher M, Haller G, Walder B. Prehospital risk factors of
mortality and impaired consciousness after severe traumatic brain injury: an epidemiological study.
Scandinavian Journal of Trauma, Resuscitation and Emergency Medicine. 2014; 22 (1).</li>
        
        

      </ul>
                            
    </Accordion>

  </div>
</IonContent>
</IonPage>

  );
}

export default TCE;
