import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const AcidenteVascularCerebral: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Acidente Vascular Cerebral</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://drive.google.com/file/d/1GMVd8ypQ6dQzcif_ZO6RJtC_4jIvWROe/view?usp=share_link">POP AVC (BG 237 de 17/12/2015)</a>
            </p>

</ul>
              </Accordion>
          
          <Accordion title="Procedimentos">
            <ul>
              <li>Avaliar a cena;</li>
              <li>Gerenciar riscos;</li>
              <li>Manter permeabilidade das vias aéreas;</li>
              <li>Avaliar a respiração;</li>
              <li>Monitorizar %SpO2;</li>
              <li>Iniciar oxigenoterapia (10 – 15 L/min) se %SpO2 {'<'} 95%, ou se {'>'} 95%, mas com sinais de dificuldade respiratória (2 – 5 L/min).</li>
              <li>Avaliar a circulação (qualidade do pulso);</li>
              <li>Manter o paciente com a cabeça ligeiramente elevada se estiver consciente;</li>
              <li>Aplicar Escala de AVC de Cincinnati;</li>
              <li>Realizar avaliação secundária;</li>
              <li>Sinais Vitais – atentar para a pressão arterial;</li>
              <li>SAMPLA (identificar o tempo de início dos sintomas – investigue sobre qual o último momento em que o paciente foi visto normal pela última vez);</li>
              <li>Aferir glicemia capilar* e corrigir hipoglicemia, se necessário, conforme orientação do médico regulador;</li>
              <li>Realizar acesso venoso* (membro não afetado);</li>
              <li>Ficar atento à possiblidade de convulsões;</li>
              <li>Encaminhar o paciente p</li>
              *Procedimentos realizado somente por profissionais habilitados (médicos, enfermeiros, técnicos e auxiliares de enfermagem, inscritos em seus respectivos conselhos de classe.
              <img src="assets/AcidenteVascularCerebral/AcidenteVascularCerebral1.png" className="figura"/>

              <h4>Observações</h4>

              <li>A anormalidade de 2 parâmetros na escala de AVC de Cincinnati indica uma probabilidade de 72% de ocorrência de um AVC. A anormalidade em 3 parâmetros indica uma probabilidade é de mais de 85%.</li>
              <li>Embora a Escala de AVC de Cincinnati tenha sensibilidade alta (grande capacidade de identificar pessoas com AVC), sua especificidade (identificar pessoas que não tenham AVC) é moderada. Por isso, mesmo que o paciente não apresente anormalidade nos parâmetros, a suspeita de AVC não deve ser descartada na presença de outros sinais e sintomas;</li>
              <li>Estudos sugerem benefícios da terapia trombolítica em até 4,5 horas do início dos sinais e sintomas. Dessa forma, é fundamental que o socorrista investigue sobre qual foi o último momento em que o paciente foi visto sem os sinais e sintomas e informe ao médico regulador, para que dessa forma decidam em conjunto sobre a possibilidade de transporte para unidade especializada. Exemplo: Unidade de Neurocardiologia do Hospital de Base.</li>
            </ul>
          </Accordion>

          <Accordion title="Resultados Esperados">
            <ul>
              <li>Otimizar a identificação e a avaliação de pacientes com AVC;</li>
              <li>Aumentar o número de pacientes submetidos à trombólise;</li>
              <li>Aumentar a sobrevida;</li>
              <li>Utilizar escala pré-hospitalar de AVC de Cincinnati;</li>
              <li>Diminuir sequelas.Otimizar a identificação e a avaliação de pacientes com AVC;</li>
              <li>Aumentar o número de pacientes submetidos à trombólise;</li>
              <li>Aumentar a sobrevida;</li>
              <li>Utilizar escala pré-hospitalar de AVC de Cincinnati;</li>
              <li>Diminuir sequelas.</li>
            </ul>
          </Accordion>

          <Accordion title="Material recomendado">
            <ul>
              <li>Prancha longa;</li>
              <li>Maca;</li>
              <li>Tirantes;</li>
              <li>Gazes;</li>
              <li>Lençóis;</li>
              <li>Esfigmomanômetro;</li>
              <li>Estetoscópio;</li>
              <li>Oxímetro de pulso;</li>
              <li>Termômetro;</li>
              <li>Conjunto de oxigenoterapia com fluxômetro;</li>
              <li>Jelco no 14 ou 16;</li>
              <li>Equipo simples.</li>
              <li>Solução salina isotônica (Ringer Lactato ou Solução Fisiológica 0,9%) – 2 frascos de 1000 ml cada ou 4 de 500 ml.</li>
              <li>Glicosímetro com fitas e agulha para punção.</li>
            </ul>
          </Accordion>

          <Accordion title="Sinais e sintomas">
            <ul>
              <li>Alteração do nível de consciência; confusão mental;</li>
              <li>Cefaleia;</li>
              <li>Tontura, perda do equilíbrio;</li>
              <li>Fraqueza súbita;</li>
              <li>Paralisia, Parestesia, paresia;</li>
              <li>Assimetria facial;</li>
              <li>Fala arrastada;</li>
              <li>Sinais de déficit neurológico;</li>
              <li>Dificuldade respiratória;</li>
            </ul>
          </Accordion>

          <Accordion title="Notas">
            <ul>
              <li>Solicitantes de socorro deverão receber orientações sobre como realizar a desobstrução via telefone, preferencialmente por videochamada.</li>
              <li>Na comunicação com leigos, prefira o termo desengasgamento, em substituição à Obstrução de Via Aérea por Corpo Estranho (OVACE).</li>
              <li>No Distrito Federal estão em vigor duas Leis Distritais que tratam sobre o tema:</li>
              <p>Lei 6.355, de 07 de agosto de 2019, que <b>"dispõe sobre a obrigatoriedade da inclusão do Curso de Manobras Heimlich no pré-natal das gestantes da rede hospitalar pública e privada no Distrito Federal"</b>.</p>
              <p>Lei 6258, de 29 de janeiro de 2019, que <b>dispõe sobre a obrigatoriedade de afixação, em restaurantes, lanchonetes, praças de alimentação de centros comerciais, shopping centers e estabelecimentos similares, de material publicitário de interesse do consumidor que demonstre a aplicação da manobra da vida ou manobra de Heimlich (compressão abdominal), empregada para desobstruir rapidamente as vias respiratórias.</b></p>
              <li>Cursos e treinamentos oferecidos pelo Corpo de Bombeiros Militar do Distrito Federal, que tratem sobre o tema deverão abordar, além das condutas, formas de prevenção da OVACE, principalmente em crianças.</li>
            </ul>
          </Accordion>

          <Accordion title="Possibilidades de erro">
            <ul>
              <li>Mau uso da Escala de AVC de Cincinnati;</li>
              <li>Decisão de transporte para hospital regional sem centro de emergência neurológica quando o tempo de início de sinais e sintomas e o histórico do paciente eram indicativos de terapia trombolítica em hospital referenciado.</li>
            </ul>
          </Accordion>

          <Accordion title="Fatores Complicadores">
            <ul>
              <li>Paciente monossintomático;</li>
              <li>Dificuldade em se determinar o tempo de início de sinais e sintomas;</li>
              <li>Negativa da CIADE em autorizar o transporte para hospital de referência neurológica;</li>
              <li>Conflitos institucionais.</li>
            </ul>
          </Accordion>

          <Accordion title="Glossário">
            <ul>
              <li><b>Cefaleia:</b> dor de cabeça;</li>
              <li><b>Assimetria facial:</b> face com aspecto de tônus muscular desigual em suas partes.</li>
              <li><b>Parestesia:</b> sensação cutânea subjetiva. Nesse caso, manifestada em forma de formigamento.</li>
              <li><b>Paresia:</b> limitação da motricidade, fraqueza.</li>
              <li><b>Glicemia:</b> quantidade de glicose no sangue. É medida em miligramas por decilitro (mg/dl).</li>
              <li><b>Trombólise:</b> dissolução de um coágulo existente na corrente sanguínea</li>
            </ul>
          </Accordion>

      <Accordion title="Referencial bibliográfico">
        <ul>
          <li>ACLS: American Heart Association. Edição em Português do Suporte Avançado de Vida em Cardiologia. Manual do Profissional. Impresso no Brasil: 2014.</li>
          <li>AMLS: atendimento pré-hospitalar às emergências clínicas. 1a ed. Rio de Janeiro: Elsevier, 2014.</li>
          <li>Boden-Albala B, Edwards DF, St Clair S, Wing JJ. Fernandez S, Gibbons C, et al. Methodology for a Community Based Stroke Preparedness Intervention: The ASPIRE Study. Stroke. 2014; 45(7).</li>
          <li>Brasil. Ministério da Saúde. Secretaria de Atenção à Saúde. Protocolos de intervenção para o SAMU 192 – Serviço de Atendimento Móvel de Urgência. Brasília: Ministério da Saúde, 2014.</li>
          <li>De Luca A, Rossi PG, Francesco G. The use of Cincinnati prehospital stroke scale during telephone dispatch interview increases the accuracy in identifying stroke and transient ischemic attack symptoms. BMC Health Services Research 2013;13.</li>
          <li>Eduardo OR, Félix VM, Silva AGB. Protocolo de atendimento pré-hospitalar CBMDF. Brasília: CBMDF, 2003.</li>
          <li>Govindarajan P, DeSouza NT, Pierog J, Ghilarducci D, Johnston SC. Feasibility study to assess the use of the Cincinnati stroke scale by emergency medical dispatchers: a pilot study. Emergency Medicine Journal. 2012; 29(10).</li>
          <li>Hurwitz AS, Brice JH, Overby BA, Evenson KR. Directed use of the Cincinnati Prehospital Stroke Scale by laypersons. Prehospital Emergency Care. 2005; 9(3).</li>
          <li>Kothari RU, Pancioli A, Liu T, Brott T, Broderick J. Cincinnati Prehospital Stroke Scale: reproducibility and validity. Annals of Emergency Medicine.1999; 33(4).</li>
          <li>Patel MD, Rose KM, O’Brien EC, Rosamond WD. Prehospital Notification by Emergency Medical Services Reduces Delays in Stroke Evaluation: Findings from the North Carolina Stroke Care Collaborative. Stroke. 2011; 42(8).</li>
          <li>Ragoschke-Schumm A, Walter S, Haass A, Balucani C, Lesmeister M, Nasreldein A. Translation of the ‘time is brain’ concept into clinical practice: focus on prehospital stroke management. International Journal of Stroke. 2014;9.</li>
          <li>Rasia CA, Barros CC, Marcelino SC, Fernandes RWC, Pontes FC, Pedroso, GB, et al. Manual de atendimento pré-hospitalar. Brasília: Corpo de Bombeiros Militar do Distrito Federal, 2007.</li>
          <li>Sheppard JP, Mellor RM, Greenfield S, Mant J, Quinn T, Sandler D, et al. The association between prehospital care and in-hospital treatment decisions in acute stroke: a cohort study. Emergency Medicine Journal. 2015;32.</li>
          <li>Tsai AW. Prehospital and Emergency Department Capacity for Acute Stroke Care in Minnesota. Preventing Chronic Diseases: public health research, practice, and policy. 2008; v. 5(2).</li>
          <li>You JS, Chung SP, Chung HS, Lee HS, Park JW, Kim HJ. Predictive value of the Cincinnati Prehospital Stroke Scale for identifying thrombolytic candidates in acute ischemic stroke. American Journal of Emergency Medicine. 2013;31.</li>
        </ul>
      </Accordion>

    </div>
  </IonContent>
</IonPage>

  );
}

export default AcidenteVascularCerebral;
