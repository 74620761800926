import React, { useState } from 'react';
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar, 
  IonItem, 
  IonIcon, 
  IonButtons, 
  IonMenuButton 
} from '@ionic/react';
import { chevronDownOutline } from 'ionicons/icons';
import { Accordion } from '../../components/Accordion';
import '../Page.css'

const MaterialBiologico: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Contaminação por Material Biológico</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        <div>
          <Accordion procedimentos={true} title="Download">
            <ul>
                  <p>            
            <a href="https://www.cbm.df.gov.br/download/pop-contaminacao-por-material-biologico-bg-154-de-2022/?tmstv=1667848762">POP Contaminação por Material Biológico (BG 154 de 15/09/2022)</a>
            </p>

</ul>
              </Accordion>
                              
                   
              <Accordion title="Resultados Esperados">
            <ul>
             <li>Orientar os militares de serviço prezando pela integridade da saúde do
bombeiro militar em casos de contaminação com material biológico;</li>
             <li>Capacitar o bombeiro militar sobre os procedimentos iniciais em casos
de contaminação com material biológico;</li>
              <li>Tornar o militar capaz de identificar e classificar os riscos de exposição
a materiais biológicos.</li>

</ul>
              </Accordion>
          
                              <Accordion title="Material Recomendado">
            <ul>
              <li><b>Viatura:</b> UR ou outra viatura que esteja disponível com capacidade para
transporte seguro.</li>
              <li><b>Equipamentos de proteção individual:</b> luvas, máscaras, gorros ou
capacete, óculos de proteção, uniforme 3°A completo com mangas
desdobradas, capotes (aventais) e coturno.</li>
            
             

</ul>
              </Accordion>
          
                                        <Accordion title="Classificações em Riscos Biológicos">
            <ul>
              <p>Quanto aos agentes de risco biológico:</p>
             <li>bactérias;</li>
              <li>fungos;</li>
              <li>parasitas;</li>
              <li>vírus.</li>
              <p>Quanto aos riscos de transmissão:</p>
              <li>Risco de Transmissão do Vírus da Imunodeficiência Humana;</li>
              <li>Risco de Transmissão do Vírus da Hepatite B;</li>
              <li>Risco de Transmissão do Vírus da Hepatite C;</li>
              <li>Risco de Transmissão do COVID-19;</li>
              <li>entre outros.</li>
              <p>Quanto à exposição ocupacional:</p>
              <li>Exposição percutânea – por meio de objeto perfuro cortante que
provoque incisão ou perfuração na pele;</li>
              <li>Exposição em mucosa – respingo de fluidos nos olhos, nariz e
boca;</li>
              <li>Exposição cutânea (em pele não íntegra) – contato de fluidos com
feridas abertas na pele ou mordeduras humanas, quando
envolverem a presença de sangue.</li>
              <p>Quanto ao tipo de fluido e tecido:</p>
              <li>Fluidos biológicos de risco: sangue, líquido orgânico contendo
sangue visível e líquidos orgânicos potencialmente infectantes
(sêmen, secreção vaginal, líquor e líquidos peritoneal, pleural,
sinovial, pericárdico e amniótico). Esses fluidos estão associados à
transmissão de HIV, HBV e HCV;</li>
              <li>Materiais biológicos potencialmente não-infectantes: suor, escarro,
lágrima, urina, vômitos, fezes, secreção nasal, saliva. Esses
materiais estão associados somente à transmissão de HBV e HCV.</li>
              <p><font color="red">Classificação dos acidentes com material biológico:</font> </p>
              <li>Acidente leve: contato com secreções, urina ou sangue em pele
íntegra;</li>
              <li>Acidente moderado: contato com secreções ou urina em mucosas;
sem sangue visível;</li>
              <li>Acidente grave: contato de líquido orgânico contendo sangue
visível com mucosas ou exposição percutânea com material
pérfuro-cortante.</li>
              <p><b>Obs: Essas classificações são meramente descritivas para
registro mais preciso do fato ocorrido. Independentemente do
grau do acidente o acidentado deve ser encaminhado para a
avaliação médica.</b></p>
              
             
              
</ul>
              </Accordion>
          
                                                  <Accordion title="Sinais e sintomas">
            <ul>
              <p>Possíveis sinais e sintomas após contato com material biológico:</p>
             <li>Eritemas, edemas, prurido, ardor, mialgia, diarreia, febre, inapetência,
vertigem, tontura, cefaléia, dispnéia, paresia, parestesia, entre outros.</li>
         
                             
              
</ul>
              </Accordion>
           <Accordion title="Procedimentos">
             
             <ul>
               <li>Mantenha o cartão de vacinação atualizado;</li>
               <li>Averiguar se o exame Anti-Hbs apresenta resultado reagente, ou
seja, maior que 10 mUI/mL. O resultado “reagente” indica que o
militar possui resposta adequada à vacinação;</li>
               <li>Utilize os <b>EPIs necessários</b> (3º A, luvas, máscaras, óculos de
proteção, avental descartável, joelheiras, etc.) em todos os
atendimentos.</li>
               <li>Ter a máxima atenção durante a realização dos procedimentos;</li>
               <li>Se for necessário o manuseio de agulhas que seja realizado com
cautela e, se possível, com uso de ferramentas para evitar
acidentes;</li>
               <li>Todo material perfurocortante (agulhas, scalp, lâminas de bisturi,
vidrarias, entre outros), mesmo que estéril, deve ser desprezado
em recipientes resistentes à perfuração e com tampa. Ao chegar
ao limite de ⅔ da capacidade da caixa, esta deverá ser
devidamente fechada e encaminhada para fluxo próprio de
descarte de resíduos infectantes;</li>
               <li>Os resíduos infectantes deverão ser coletados em saco plástico
branco identificado e posteriormente descartados em lixeiras
maiores (bombonas) específicas para resíduos infectante dentro da
unidade operacional;</li>
               <li>Durante o aviso e deslocamento, tentar coletar informações sobre
os possíveis contaminantes biológicos presentes no local da
ocorrência;</li>
               <li>Investigar, no local da ocorrência, possíveis focos de contaminação
(seja paciente ou objetos no local) com o intuito de tomar as
devidas precauções;</li>
               <li>Higienizar as mãos com água e sabão sempre que possível.
Quando não for viável, proceder a higienização com álcool em gel;</li>
               <li>Proceder a higienização dos equipamentos de proteção individual
sempre que possível e manter o ambiente da viatura limpo e
organizado;</li>
               <li><b>Durante os plantões, sempre checar, em qual unidade
hospitalar, da sua área de atuação de momento, existe um
fluxo de atendimento às vítimas de contaminação com
                 material biológico, inteirando-se das suas etapas.</b></li>
               <p><b>CONDUTA</b></p>
               <p>Em caso de exposição do bombeiro militar, adotar os seguintes
procedimentos:</p>
               <p><b>No local da ocorrência:</b></p>
               <li>Lave a ferida e/ou os locais que estiveram em contato com sangue
                 ou fluidos corporais com <b>água e sabão neutro</b>, ou soro fisiológico;</li>
               <li>Preferencialmente, em caso de pele íntegra, água e sabão, em
caso do contato com olhos ou mucosas e/ou pele não íntegra, soro
fisiológico, lembrando sempre de retirar as possíveis lentes de
contato;</li>
               <li>Não aperte ou esfregue com força o local da lesão;</li>
               <li>A aplicação de soluções fortes, como éter, glutaraldeído ou
                 hipoclorito de sódio <b>não é recomendada</b> na pele ou em feridas;</li>
               <li>Coletar dados a respeito do paciente fonte e informar ao médico
para que sejam feitos exames sorológicos;</li>
               <li>Fazer contato com a Regulação Médica, informar sobre o acidente
e encaminhar <b>imediatamente o BM e o paciente fonte ao
                 hospital indicado pela regulação</b>, mesmo em casos de
exposição apenas a fluidos corporais. Segundo Manual do
Ministério da Saúde (2006), o risco de soroconversão de Hepatite
B varia de seis a 30%, podendo chegar até a 60%, dependendo do
estado do paciente-fonte e outros fatores.</li>
               
               <p><b>TRANSPORTE AO HOSPITAL:</b></p>
               
               
               <li>Dar suporte emocional contínuo e atencioso à vítima (paciente
fonte);</li>
               <li>Manter todas as barreiras de segurança biológica recomendadas
ao caso (EPIs, máscara, óculos de proteção etc) durante todo o
transporte;</li>
               <li>Ao chegar ao hospital, repassar todas as informações como tipo,
local, material biológico relacionado à exposição e informações
sobre o paciente fonte ao médico responsável pelo atendimento;</li>
               <li>Solicitar ao médico os exames laboratoriais relacionados no item
6 deste POP, tanto para o paciente fonte quanto para o bombeiro
militar. Caso o médico não forneça os pedidos, fazer contato com
a Regulação Médica ou com o Médico de Dia do CBMDF;</li>
               <li>Solicitar termo de consentimento do paciente fonte ou
responsável para coleta de exames sorológicos;</li>
               <li>Aguardar resultado do teste rápido de HIV do paciente fonte - se
positivo, aguardar a prescrição da quimioprofilaxia para HIV;</li>
               <li>A quimioprofilaxia anti-HIV deverá ser iniciada preferencialmente
em até 2 horas após a exposição, sendo possível sua
administração em até 72 horas. A partir de 72 horas não há
indicação de quimioprofilaxia;</li>
               <li>Informar ao médico resultado do Anti-Hbs:</li>
               <ul>
                 <li>Reagente: não está indicado imunoglobulina anti-hepatite B;</li>
                 <li>Não reagente: indicado imunoglobulina anti-hepatite B.</li>
                  <p>A administração de imunoglobulina anti-hepatite B deverá ocorrer
em até 7 dias, sendo, preferencialmente, administrada nas primeiras 24
horas pós-exposição.</p>
               </ul>
               
           
               <li>  O uso de quimioprofilaxia fica a critério médico. Na dúvida, questione a fim de esclarecê-las;</li>
               <li>Em caso de tratamento estabelecido, entenda todas as etapas e
tome ciência dos períodos de seguimento ambulatorial;</li>
               <p><b>Após o atendimento em unidade hospitalar:</b></p>
             
               <li>Deve ser feito o <font color="red">Memorando de Acidente</font> (vide apêndice) pelo
militar mais antigo na cena;</li>
               <li><font color="red">O Memorando de Acidente e o Atestado Médico deverão ser
encaminhados imediatamente ao Comandante do militar
acidentado, que por sua vez, deverá encaminhá-los ao CPMED
                 em até 48h do fato ocorrido;</font></li>
                 <li>Busque suporte emocional devido ao estresse pós-acidente, se
houver necessidade.</li>
               <p><b>Ao chegar na OBM</b></p>
               <li>Realizar procedimento de descontaminação da viatura e demais
membros da guarnição;</li>
               <li>Descrever o fato em relatório da ocorrência, contendo informações
do tipo de contaminante, tempo de exposição, sintomas
apresentados pelo socorrista, se for o caso, e outros fatores que
julgar relevantes;</li>
               <p><b>Acompanhamento</b></p>
               <li></li>
               <li></li>
               <ul>
                 <li>Suspender aleitamento materno;</li>
                 <li>Realizar relações sexuais somente com proteção;</li>
                 <li>Não realizar doação de sangue ou de materiais biológicos
até liberação do CPMED.</li>
                 </ul>
             </ul>

              </Accordion>
           <Accordion title="Exames a serem realizados no BM e no paciente fonte:">
             <p><b>Exames a serem realizados no BM e no paciente fonte, se houver:</b></p>
             <ul>
               <li>Teste rápido para HIV, HCV, HBV;</li>
               <li>Anti-HIV 1e 2;</li>
               <li>Anti-HCV;</li>
               <li>Anti-HBs;</li>
               <li>HBsAG;</li>
               <li>Anti-HBC (IgM e IgG);</li>
               <li>Teste rápido Sífilis.</li>
               <p><b>Exames que devem ser realizados apenas no BM:</b></p>
               <li>Hemograma;</li>
               <li>TGO / TGP;</li>
               <li>Uréia;</li>
               <li>Creatinina;</li>
               <li>Glicemia;</li>
               <li>Teste rápido Beta-HCG (se sexo feminino em idade fértil).</li>
                <img src="assets/MaterialBiologico/contaminacao1.png" className="figura"/>
               
               
               
             </ul>
          </Accordion>
          <Accordion title="Possibilidade de erro">
            <ul>
              <li>Uso inadequado ou falta de uso de EPI pelos socorristas ou pelos
bombeiros militares presentes na cena;</li>
              <li>Desconhecimento das medidas profiláticas em caso de exposição;</li>
              <li>Desconhecimento dos riscos inerentes ao paciente em atendimento;</li>
              <li>Não realização da descontaminação da viatura de maneira adequada e
tempestiva, após atendimento;</li>
              <li>Falta de capacitação continuada na área de atuação;
</li>
              <li>Negligência ou imperícia nos procedimentos realizados.</li>
            </ul>
            
            </Accordion>
             <Accordion title="Fatores Complicadores">
               <ul>
                 <li>Indisponibilidade de EPI para os socorristas;</li>
                 <li>Segurança da cena (pacientes agressivos);</li>
                 <li>Pacientes portadores doença infectocontagiosa;</li>
                 <li>Pacientes usuário de entorpecentes;</li>
                 <li>Pacientes portadores de transtornos mentais;</li>
                 <li>Extravasamento sanguíneo no interior da viatura;
</li>
                 <li>Precaução por contato (microorganismos resistentes, infecções de pele,
secreções não contidas por curativo);</li>
                 <li>Precaução por gotículas (meningite bacteriana, caxumba, COVID-19,
rubéola);</li>
                 <li>Descarte inadequado de materiais pérfuro-cortantes;</li>
                 <li>Ausência do relato de ocorrência da exposição.
</li>
                  
               </ul>
            
               
               </Accordion>
                             
             <Accordion title="Glossário">
               <ul>
                 <li><b>APH:</b> Atendimento Pré-Hospitalar.</li>
                 <li><b>OBM:</b> Organização Bombeiro Militar.</li>
                 <li><b>EPI:</b> Equipamento de Proteção Individual</li>
                 <li><b>UR:</b> Viatura do CBMDF do tipo Unidade de Resgate.
</li>
                 <li><b>Quimioprofilaxia:</b> Uso de substâncias ou meios químicos para impedir o
desenvolvimento de uma doença ou de uma infecção.
</li>
               </ul>
            
               
               </Accordion>  
          
          <Accordion title="Fluxograma de procedimentos">
          <img src="assets/MaterialBiologico/contaminacao2.jpg" className="figura"/>
</Accordion>
          <Accordion title="Apêndice - Modelo de Memorando de Acidente">
             <img src="assets/MaterialBiologico/contaminacao3.jpg" className="figura"/>
          </Accordion>
          <Accordion title="Base legal e referencial">
            <ul>
              <li>AMORIM, Stephanie Patsch. Segurança no APH: análise da
necessidade de criação de um guia orientativo de condutas em
exposição ocupacional por material biológico. 2021. Trabalho de
Conclusão de Curso (Curso de Formação de Oficiais) - Corpo de
Bombeiros Militar do Distrito Federal, Brasília, 2021.</li>
              
              <li>CBMDF. Condutas após acidente com material biológico. Guia
orientativo completo. Brasília, 2021.</li>
              
              <li>MINISTÉRIO DA SAÚDE. Exposição a Materiais Biológicos. Série A.
Normas e Manuais Técnicos. Editora MS: Brasília, 2006</li>
              
              <li>MINISTÉRIO DA SAÚDE. Biblioteca Virtual em Saúde MS. Brasília,
2021. Disponível em: https://bvsms.saude.gov.br. Acesso em 11 de abr.
de 2022</li>
              
            </ul>
          </Accordion>
          

  </div>
</IonContent>
</IonPage>

  );
}

export default MaterialBiologico;
